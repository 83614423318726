import { useControllerSiblings } from '~pages/controllers/hooks/useControllerSiblings';

import { SiblingsList } from '~components/siblings/SiblingsList';

type Props = {
  id: string;
};

export function ControllerSiblingsList({ id }: Props) {
  const controllerSiblings = useControllerSiblings(id);

  return <SiblingsList entityType="controller" entityId={id} siblings={controllerSiblings} />;
}
