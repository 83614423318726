import { Grid } from '@mui/material';

import { useGroupedFleetDiagnostics } from '~hooks/useGroupedFleetDiagnostics';

import { FleetIssuesCard } from '~pages/fleets/components/FleetIssuesCard';
import { FleetLayout } from '~pages/fleets/components/FleetLayout';
import { useFleetsNavigation } from '~pages/fleets/hooks/useFleetsNavigation';

import { FleetFaultsCard } from '~components/FaultsCard';
import { FleetTemperatureHistogram } from '~components/charts/FleetTemperatureHistogram';
import { FleetCellVoltageDiffHistogram } from '~components/charts/FleetCellVoltageDiffHistogram';

export function FleetsDiagnostics() {
  const { fleetId } = useFleetsNavigation();
  const { data, isLoading, error } = useGroupedFleetDiagnostics(fleetId);

  return (
    <FleetLayout title="Fleet Diagnostics">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FleetIssuesCard fleetId={fleetId} />
        </Grid>
        <Grid item xs={12}>
          <FleetFaultsCard fleetId={fleetId} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FleetTemperatureHistogram
            title="Battery max temperatures"
            data={data.byMaxCellTemperature}
            isLoading={isLoading}
            error={error}
            height={250}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FleetTemperatureHistogram
            title="Battery min temperatures"
            data={data.byMinCellTemperature}
            isLoading={isLoading}
            error={error}
            height={250}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FleetTemperatureHistogram
            title="Ambient temperatures"
            data={data.byMaxAmbientTemperature}
            isLoading={isLoading}
            error={error}
            height={250}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FleetCellVoltageDiffHistogram
            data={data.byVoltageDifference}
            isLoading={isLoading}
            error={error}
            height={250}
          />
        </Grid>
      </Grid>
    </FleetLayout>
  );
}
