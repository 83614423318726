import type { MRT_ColumnDef, MRT_RowData } from 'material-react-table';

/** Recursively adds a prefix to the keys of a list of MRT column definitions. */
export const prefixMRTColumnIds = <T extends MRT_RowData>(
  prefix: string,
  columns: MRT_ColumnDef<T>[],
): MRT_ColumnDef<T>[] => {
  return columns.map((column) => {
    const result = { ...column };
    if (column.id == null) {
      throw new Error(`Column id for column '${column.header}' must be defined`);
    }
    result.id = `${prefix}###${column.id}`;
    if (column.columns != null) {
      result.columns = prefixMRTColumnIds(prefix, column.columns);
    }
    return result;
  });
};
