import { type HTMLAttributes, useEffect, useMemo } from 'react';
import {
  Alert,
  Autocomplete,
  Box,
  Card,
  Checkbox,
  Chip,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { differenceInDays } from 'date-fns';
import deepEqual from 'fast-deep-equal';

import { type ResourceID } from '~hooks/timeseries';
import {
  createAggregateQuery,
  createLatestForecastQuery,
  createLatestQuery,
  createRangeQuery,
  useTimeseriesRaw,
} from '~hooks/timeseries/queries';
import type { RawQueryResult, TimeSeriesName, TimeSeriesQuery } from '~hooks/timeseries/types';
import { useDebounce } from '~hooks/common/useDebounce';

import { Iconify } from '~components/Iconify';

export type QueryVariant = 'latest' | 'range' | 'aggregate' | 'latest_forecast';

export type EditorFields = {
  variant: QueryVariant;
  resources: string[];
  columns: string[];
  latestQueryStartDefined: boolean;
  aggregateStep: number | null;
};

export type ResultWithQuery = { data: RawQueryResult | undefined; query: TimeSeriesQuery | null };

export type QueryEditorProps = {
  fields: EditorFields;
  onFieldsChange: (fields: EditorFields) => void;
  onOutputChange: (output: ResultWithQuery) => void;
  onRemove: () => void;
  startTime: Date;
  endTime: Date;
  knownColumns: string[];
  knownAggregateColumns: string[];
  knownResources: string[];
  displayNameForResource: Map<string, string>;
};

const AUTOCOMPLETE_MAX_OPTIONS = 100;

export function QueryEditor({
  fields,
  onFieldsChange: setFields,
  onOutputChange: setOutput,
  onRemove: remove,
  startTime,
  endTime,
  knownColumns,
  knownAggregateColumns,
  knownResources,
  displayNameForResource,
}: QueryEditorProps) {
  const isLatest = fields.variant === 'latest' || fields.variant === 'latest_forecast';

  const { query = null, parsingError = null } = useMemo(() => {
    try {
      return {
        query: createQuery(fields, startTime, endTime),
      };
    } catch (e) {
      return {
        parsingError: e instanceof Error ? e.message : 'Unknown error',
      };
    }
  }, [fields, startTime, endTime]);

  const debouncedValue = useDebounce(query, 2_000);
  // don't debounce if the new query is not valid anyways
  const debouncedQuery = query === null ? query : debouncedValue;

  const { data, isLoading, error } = useTimeseriesRaw(debouncedQuery);

  // send the output to the parent component
  useEffect(() => {
    setOutput({ data, query: debouncedQuery });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, debouncedQuery]);

  // we want give more information about the error than asCactosError lets through
  const errorMessage = (() => {
    if (error == null) return null;
    const message = error?.response?.data?.exception?.message;
    if (typeof message === 'string') return message;
    if (typeof error?.message === 'string') return error.message;
    return 'Unknown error';
  })();

  const queryIsDebounced = !deepEqual(debouncedQuery, query);

  return (
    <Card>
      <LinearProgress
        style={{ visibility: isLoading || queryIsDebounced ? 'visible' : 'hidden' }}
        color={queryIsDebounced ? 'info' : 'primary'}
      />

      <Stack direction="column" margin={2} spacing={2}>
        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" gap={2}>
          <Tooltip title="Remove query" placement="top">
            <IconButton onClick={() => remove()}>
              <Iconify icon="mdi:remove-circle-outline" />
            </IconButton>
          </Tooltip>

          {isLatest && (
            <Tooltip title="Include start time in query" placement="top">
              <Checkbox
                checked={fields.latestQueryStartDefined}
                onChange={(event) =>
                  setFields({ ...fields, latestQueryStartDefined: event.target.checked })
                }
              />
            </Tooltip>
          )}

          <TextField
            label="Query Variant"
            select
            value={fields.variant}
            onChange={(event) => {
              setFields({ ...fields, variant: event.target.value as QueryVariant });
            }}
            sx={{ minWidth: 180, flexBasis: 180, flexGrow: 2 }}
          >
            <MenuItem value="latest">Latest</MenuItem>
            <MenuItem value="range">Range</MenuItem>
            <MenuItem value="aggregate">Aggregate</MenuItem>
            <MenuItem value="latest_forecast">Latest forecast</MenuItem>
          </TextField>

          {fields.variant === 'aggregate' && (
            <TextField
              label="Aggregate Step"
              InputProps={{ endAdornment: <InputAdornment position="end">s</InputAdornment> }}
              error={fields.aggregateStep == null}
              defaultValue={fields.aggregateStep?.toString() ?? '60'}
              onChange={(event) => {
                const int = parseInt(event.target.value, 10);
                setFields({
                  ...fields,
                  aggregateStep: Number.isFinite(int) && int > 0 ? int : null,
                });
              }}
              sx={{ minWidth: 180, flexBasis: 180, flexGrow: 2 }}
            />
          )}
        </Box>

        <Autocomplete
          multiple
          freeSolo
          options={knownResources}
          value={fields.resources}
          onChange={(_, value) => setFields({ ...fields, resources: value })}
          filterOptions={(options, state) => {
            const input = state.inputValue.toLowerCase();
            return options
              .filter(
                (option) =>
                  option.includes(input) ||
                  displayNameForResource.get(option)?.toLowerCase().includes(input),
              )
              .slice(0, AUTOCOMPLETE_MAX_OPTIONS);
          }}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Resources" />}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => {
              const { key, ...tagProps } = getTagProps({ index });
              const displayName = displayNameForResource.get(option);
              const label =
                displayName !== undefined ? `${option.split(':')[0]}:${displayName}` : option;
              return <Chip variant="outlined" label={label} key={key} {...tagProps} />;
            })
          }
          renderOption={(props, option) => {
            const { key, ...optionProps } = props as HTMLAttributes<HTMLLIElement> & {
              key: string;
            };
            return (
              <Box key={key} component="li" {...optionProps}>
                <Typography variant="data">{option}</Typography>{' '}
                <Chip label={displayNameForResource.get(option)} sx={{ ml: 2 }} />
              </Box>
            );
          }}
        />

        <Autocomplete
          freeSolo
          multiple
          options={fields.variant === 'aggregate' ? knownAggregateColumns : knownColumns}
          value={fields.columns}
          onChange={(_, value) => setFields({ ...fields, columns: value })}
          filterOptions={(options, state) => {
            const input = state.inputValue.toLowerCase();
            return options
              .filter((option) => option.toLowerCase().includes(input))
              .slice(0, AUTOCOMPLETE_MAX_OPTIONS);
          }}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Timeseries" />}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <Chip
                  variant="outlined"
                  label={<Typography variant="data">{option}</Typography>}
                  key={key}
                  {...tagProps}
                />
              );
            })
          }
          renderOption={(props, option) => {
            const { key, ...optionProps } = props as HTMLAttributes<HTMLLIElement> & {
              key: string;
            };
            const [series, column] = option.split('.', 2);
            return (
              <Box key={key} component="li" {...optionProps}>
                <Typography variant="data">
                  {series}.{column}
                </Typography>
              </Box>
            );
          }}
        />

        {parsingError != null && (
          <Alert severity="error">Error parsing query: {parsingError}</Alert>
        )}

        {errorMessage != null && (
          <Alert severity="error">Error completing query: {errorMessage}</Alert>
        )}
      </Stack>
    </Card>
  );
}

function getMaxRangeDays(columnsBySeries: { [series: string]: string[] }): number {
  const seriesNames = Object.keys(columnsBySeries);

  const limits = seriesNames.map((series) => {
    switch (series as TimeSeriesName) {
      case 'ems_optimizer_configuration':
      case 'group_configuration':
      case 'group_optimizer_configuration':
        // Infrequent series (TimeSeriesStorageType.INFREQUENT)
        // can be fetched since the beginning of time.
        return 366 * 10;
      case 'day_ahead_price':
      case 'entsoe_day_ahead_price':
      case 'nord_pool_day_ahead_price':
        // Day-ahead price series are hourly so it should be OK to
        // fetch a few years of data.
        return 366 * 5;
      case 'fcr_allocation':
        // The deprecated fcr_allocation series is hourly.
        return 366 * 5;
      case 'energy_meter_15m':
      case 'energy_meter_60m':
      case 'esu_meter_15m':
      case 'esu_meter_60m':
      case 'group_energy_15m':
      case 'group_energy_60m':
      case 'grid_meter_15m':
      case 'grid_meter_60m':
        // These series are aggregated onto disk at 15m or 60m intervals,
        // allow a longer range than for more frequent series.
        return 31 * 6;
      default:
        return 1;
    }
  });
  return Math.min(...limits);
}

function createQuery(fields: EditorFields, start: Date, end: Date): TimeSeriesQuery {
  const resourceIDs: ResourceID[] = fields.resources.map((resource) => {
    const [type, id, ...rest] = resource.split(':');
    if (!type || !id || rest.length > 0) throw new Error(`Invalid resource: '${resource}'`);
    return `${type}:${id}` as ResourceID;
  });

  const columnsBySeries: { [series: string]: string[] } = {};
  for (const column of fields.columns) {
    const [seriesName, columnName, ...rest] = column.split('.');
    if (!seriesName || !columnName || rest.length > 0) throw new Error(`Invalid column: ${column}`);
    columnsBySeries[seriesName] = columnsBySeries[seriesName] ?? [];
    columnsBySeries[seriesName].push(columnName);
  }

  if (resourceIDs.length === 0) throw new Error('No resources selected');
  if (Object.keys(columnsBySeries).length === 0) throw new Error('No columns selected');

  if (!Number.isFinite(end.getTime())) throw new Error('Invalid at/end time');
  if (
    !(fields.variant === 'latest' || fields.variant === 'latest_forecast') ||
    fields.latestQueryStartDefined
  ) {
    // checks relevant for queries where start time is defined
    if (!Number.isFinite(start.getTime())) throw new Error('Invalid start time');
    if (start.getTime() >= end.getTime()) throw new Error('Start time should be before end time');
    if (
      (fields.variant === 'range' &&
        differenceInDays(end, start) > getMaxRangeDays(columnsBySeries)) ||
      (fields.variant === 'aggregate' && differenceInDays(end, start) > 30)
    ) {
      throw new Error('Time range too large');
    }
  }

  switch (fields.variant) {
    case 'latest':
      return createLatestQuery({
        resources: resourceIDs,
        columns: columnsBySeries,
        start: fields.latestQueryStartDefined ? start : undefined,
        atTime: end,
      });
    case 'range':
      return createRangeQuery({
        resources: resourceIDs,
        columns: columnsBySeries,
        start,
        end,
      });
    case 'aggregate':
      if (fields.aggregateStep == null) throw new Error(`Invalid time step for aggregate query`);
      return createAggregateQuery({
        resources: resourceIDs,
        columns: columnsBySeries,
        start,
        end,
        step: `${fields.aggregateStep}s`,
      });
    case 'latest_forecast':
      return createLatestForecastQuery({
        resources: resourceIDs,
        columns: columnsBySeries,
        start: fields.latestQueryStartDefined ? start : undefined,
        atTime: end,
      });
    default:
      throw new Error(`Unknown query variant: ${fields.variant}`);
  }
}
