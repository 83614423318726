import { Reports } from '~pages/esus/components/Reports';
import { ESULayout } from '~pages/esus/components/ESULayout';

export function ESUReports() {
  return (
    <ESULayout title="ESU Reports">
      <Reports />
    </ESULayout>
  );
}
