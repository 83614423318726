import { useESUList } from '~hooks/useESUList';

import { SidebarItem } from '~layouts/AppLayout/SidebarItem';

import { Iconify } from '~components/Iconify';

const icon = <Iconify icon="mdi:battery-charging-50" fontSize={20} />;

export function ESUsSidebarItem() {
  const { data: esus } = useESUList();

  if (esus?.length === 1) {
    return <SidebarItem path={`/esus/${esus[0].id}`} label="Cactos Unit" icon={icon} />;
  }

  return <SidebarItem path="/esus" label="Cactos Units" icon={icon} />;
}
