import { Alert, Box } from '@mui/material';

import { useAuth } from '~hooks/useAuth';
import { useLocalStorage } from '~hooks/useLocalStorage';

export function UiV2InfoBanner() {
  const { user } = useAuth();

  const [hasSeenBanner, setHasSeenBanner] = useLocalStorage('has-seen-UiV2InfoBanner', false);

  if (!user || hasSeenBanner) {
    return null;
  }

  return (
    <Box
      position="fixed"
      top={10}
      left={{
        xs: 8,
        md: '50%',
      }}
      right={{
        xs: 8,
        md: '50%',
      }}
      display="flex"
      justifyContent="center"
      zIndex={9999}
    >
      <Alert
        severity="info"
        onClose={() => setHasSeenBanner(true)}
        sx={{
          border: '1px solid',
          flexShrink: {
            xs: 1,
            md: 0,
          },
        }}
      >
        Welcome to the new version of the Cactos Spine app.
      </Alert>
    </Box>
  );
}
