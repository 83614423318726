import { ErrorOutline } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Card,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, type GridColDef, type GridValueFormatterParams } from '@mui/x-data-grid';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { useMemo } from 'react';

import type { MarketProductResult } from '~types';

import { checkIntegralDecimal } from '~utils/parsing';
import { formatDateTime } from '~utils/time';
import type { Field, Schema } from '~utils/timeseries';

import { useFleetESUList } from '~hooks/useESUList';
import { useMarketProductResults } from '~hooks/useOptimizerRuns';

type OptimizerMarketProductResultsViewProps = {
  fleetId: string;
  optimizerRunId: string;
};

export function OptimizerMarketProductResultsView({
  fleetId,
  optimizerRunId,
}: OptimizerMarketProductResultsViewProps) {
  const { data, isLoading, error } = useMarketProductResults(fleetId, optimizerRunId);
  if (isLoading)
    return (
      <Stack>
        <Box mt={2}>
          <Skeleton variant="rectangular" sx={{ height: 60 }} animation="wave" />
        </Box>
        <Box mt={2}>
          <Skeleton variant="rectangular" sx={{ height: 60 }} animation="wave" />
        </Box>
        <Box mt={2}>
          <Skeleton variant="rectangular" sx={{ height: 60 }} animation="wave" />
        </Box>
      </Stack>
    );
  if (error)
    return (
      <Tooltip title="Error loading FCR-N Allocations." placement="right">
        <ErrorOutline
          color="error"
          fontSize="inherit"
          sx={{ ml: 1.2, position: 'relative', top: 2 }}
        />
      </Tooltip>
    );
  if (data == null) return null;
  return Object.entries(data.results).map(([product, result]) => (
    <MarketProductDetails key={product} fleetId={fleetId} product={product} result={result} />
  ));
}

function MarketProductDetails({
  fleetId,
  product,
  result,
}: {
  fleetId: string;
  product: string;
  result: MarketProductResult;
}) {
  const { data: esuList } = useFleetESUList(fleetId);
  const esusById = useMemo(() => new Map((esuList ?? []).map((esu) => [esu.id, esu])), [esuList]);
  function nameFor(column: string): string {
    if (column in NAMES) return NAMES[column];
    return esusById.get(column)?.name ?? column;
  }
  function columnsFor(schema: Schema): GridColDef[] {
    return schema.fields.map((field: Field) => ({
      field: field.name,
      headerName: nameFor(field.name),
      sortable: false,
      headerClassName: esusById.has(field.name) ? 'text-vertical' : 'text-horizontal-bottom',
      width: WIDTHS[field.name] ?? 65, // TODO Base on something
      valueFormatter: formatterFor(field.name),
    }));
  }
  return (
    <Card sx={{ mt: 2, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography variant="h4" color="primary">
            {product} Allocations
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              '& .text-vertical': {
                writingMode: 'vertical-lr',
                transform: 'rotate(-180deg)',
                height: 200,
              },
              '& .text-horizontal-bottom .MuiDataGrid-columnHeaderTitleContainer': {
                flexFlow: 'column-reverse',
              },
            }}
          >
            <DataGrid
              columnHeaderHeight={200}
              rows={result.allocations_df.data}
              columns={columnsFor(result.allocations_df.schema)}
              getRowId={(row) => row[result.allocations_df.schema.primaryKey]}
              autoHeight
              initialState={{ pagination: { paginationModel: { page: 1, pageSize: 48 } } }}
              pageSizeOptions={[48]}
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  textOverflow: 'clip',
                  whiteSpace: 'break-spaces',
                  lineHeight: 1,
                },
              }}
            />
          </Box>

          {result.warnings.length !== 0 && (
            <Box paddingTop={2}>
              <Typography variant="subtitle2">Warnings</Typography>
              {result.warnings.map((warning) => (
                <Box key={warning} paddingTop={1}>
                  <Alert severity="warning">{warning}</Alert>
                </Box>
              ))}
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}

const WIDTHS: { [column: string]: number } = {
  index: 235,
  time: 235,
  hourly_price: 100,
  duration: 100,
  allocation: 100,
  assigned_allocation: 100,
};
const NAMES: { [column: string]: string } = {
  index: 'Time',
  time: 'Time',
  hourly_price: 'Price (€ / kW)',
  duration: 'Duration (s)',
  allocation: 'Allocation (kW)',
  assigned_allocation: 'Assigned allocation (kW)',
};
const FORMATTERS: Record<string, (p: GridValueFormatterParams) => string> = {
  index: (p: GridValueFormatterParams) => formatDateTime(p.value),
  time: (p: GridValueFormatterParams) => formatDateTime(p.value),
  duration: (p: GridValueFormatterParams) => Math.floor(p.value).toString(),
};

function formatterFor(column: string): (p: GridValueFormatterParams) => string {
  const defaultFormatter = (p: GridValueFormatterParams) =>
    checkIntegralDecimal(p.value) ?? p.value;
  return FORMATTERS[column] ?? defaultFormatter;
}
