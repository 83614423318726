import { Stack, useTheme } from '@mui/material';
import { useCallback } from 'react';

import { formatRange } from '~utils/time';
import { getAggregageQueryTimeStepSeconds } from '~utils/timeseries';

import type { ResourceID } from '~hooks/timeseries/types';
import { useURLRange } from '~hooks/useURLRange';
import { useESU } from '~hooks/useESUList';
import { useESUSchedule } from '~hooks/useESUSchedule';

import { useESUChartData } from '~pages/esus/hooks/useESUChartData';
import { useESUsNavigation } from '~pages/esus/hooks/useESUsNavigation';

import { CopyLinkButton } from '~components/CopyLinkButton';
import { TimeSeriesMultiLineChart } from '~components/charts';
import { DateTimeRangePicker } from '~components/DateTimeRangePicker';
import { StickyBar } from '~components/StickyBar';

export function ESUInsights() {
  const { esuId } = useESUsNavigation();
  const { data: esu } = useESU(esuId);
  const resourceId: ResourceID | null = esu?.resource_id ?? null;

  const theme = useTheme();

  const [range, setRange] = useURLRange(6 * 3600);

  const {
    data: chartData,
    isLoading: isLoadingChartData,
    error: chartDataError,
  } = useESUChartData(resourceId, range);
  const isAggregatedOverTime = chartData.isAggregatedOverTime;
  const {
    data: scheduleData,
    extendedData: extendedScheduleData,
    isLoading: isLoadingSchedule,
    error: scheduleError,
  } = useESUSchedule(resourceId, range);

  const esuName = esu?.name;
  const chartExportFilename = useCallback(
    (startTime: Date, endTime: Date, chartTitle: string) =>
      `${chartTitle} ${esuName ?? esuId} at ${startTime.toISOString()}`,
    [esuName, esuId],
  );

  const rangeString = formatRange(range);

  // don't draw over gap when 5 pings dropped
  const rawDataLongestGapMs = 60_000;
  // don't draw over gap when 5 data points are missing
  const aggregateLongestGapMs = getAggregageQueryTimeStepSeconds(range) * 5_000;

  const annotationLineColor = theme.palette.grey[500];
  const annotationStrokeDasharray = '4,2';

  const timeAxis = {
    clipMin: range.start,
    include: [range.start, range.end],
    clipMax: range.end,
  };

  return (
    <>
      <StickyBar px={2}>
        <DateTimeRangePicker value={range} onChange={setRange} />
        <CopyLinkButton link={window.location.href} />
      </StickyBar>

      <Stack spacing={2} px={2} pb={2}>
        <TimeSeriesMultiLineChart
          title="State of charge"
          subtitle={rangeString}
          height={280}
          data={{
            esu_status: chartData.esu_status,
            schedule_history: extendedScheduleData,
            'schedule_history#export': scheduleData,
          }}
          isLoading={isLoadingChartData || isLoadingSchedule}
          error={chartDataError ?? scheduleError}
          units="%"
          tooltipDecimalDigits={0}
          timeAxis={timeAxis}
          valueAxis={{ include: [0, 100] }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            esu_status: {
              variables: [
                {
                  name: isAggregatedOverTime ? 'SoC (mean)' : 'SoC',
                  sourceColumn: isAggregatedOverTime ? 'soc:mean' : 'soc',
                  color: theme.palette.chart.single,
                },
              ],
              longestDrawnGapMs: isAggregatedOverTime ? aggregateLongestGapMs : rawDataLongestGapMs,
            },
            schedule_history: {
              variables: [
                {
                  name: 'SoC Target Max',
                  sourceColumn: 'max_target_soc',
                  color: theme.palette.chart.gray,
                  interpolation: 'step-after',
                  strokeDasharray: '6,3',
                },
                {
                  name: 'SoC Target Min',
                  sourceColumn: 'min_target_soc',
                  color: theme.palette.chart.gray,
                  interpolation: 'step-after',
                  strokeDasharray: '6,3',
                  lowerBoundOfArea: {
                    upperBoundSeriesName: 'SoC Target Max',
                    areaColor: theme.palette.chart.gray,
                    areaOpacity: 0.2,
                  },
                },
              ],
            },
          }}
        />

        <TimeSeriesMultiLineChart
          title="ESU active power"
          subtitle={rangeString}
          height={280}
          data={{ esu_power_control: chartData.esu_power_control }}
          isLoading={isLoadingChartData}
          error={chartDataError}
          units="kW"
          timeAxis={timeAxis}
          valueAxis={{ include: [-1, 1] }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            esu_power_control: {
              variables: isAggregatedOverTime
                ? [
                    {
                      name: 'Power (max)',
                      sourceColumn: 'esu_realized_power:max',
                      denominator: 1000,
                      color: theme.palette.chart.single,
                      opacity: 0.25,
                      includeInLegend: false,
                    },
                    {
                      name: 'Power (mean)',
                      sourceColumn: 'esu_realized_power:mean',
                      denominator: 1000,
                      color: theme.palette.chart.single,
                      centerOfArea: {
                        name: 'Power',
                        lowerBoundSeriesName: 'Power (min)',
                        upperBoundSeriesName: 'Power (max)',
                        areaOpacity: 0.2,
                      },
                    },
                    {
                      name: 'Power (min)',
                      sourceColumn: 'esu_realized_power:min',
                      denominator: 1000,
                      color: theme.palette.chart.single,
                      opacity: 0.25,
                      includeInLegend: false,
                    },
                  ]
                : [
                    {
                      name: 'Power',
                      sourceColumn: 'esu_realized_power',
                      denominator: 1000,
                      color: theme.palette.chart.single,
                    },
                  ],
              longestDrawnGapMs: isAggregatedOverTime ? aggregateLongestGapMs : rawDataLongestGapMs,
              snapTooltip: true,
            },
          }}
          chartAnnotations={[
            {
              type: 'line',
              height: 0,
              color: annotationLineColor,
              strokeDasharray: annotationStrokeDasharray,
            },
          ]}
        />
      </Stack>
    </>
  );
}
