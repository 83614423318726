import { USER_ROLES } from '~constants/auth';

import { useUserHasRootRole } from '~hooks/useUserHasRootRole';

import { Schedule } from '~pages/esus/components/Schedule';
import { CustomerSchedule } from '~pages/CustomerSchedule';
import { ESULayout } from '~pages/esus/components/ESULayout';

export function ESUSchedule() {
  const canViewCactosSchedule = useUserHasRootRole(USER_ROLES.OPERATOR_VIEW);

  return (
    <ESULayout title="ESU Schedule">
      {canViewCactosSchedule ? <Schedule /> : <CustomerSchedule />}
    </ESULayout>
  );
}
