import { memo } from 'react';
import { Box, Card, CardHeader, CircularProgress, Stack, Typography } from '@mui/material';
import { ParentSize } from '@visx/responsive';
import { scaleSequential } from 'd3-scale';
import { interpolateRdYlBu } from 'd3-scale-chromatic';

import { formatDateTime } from '~utils/time';

import { type BatteryInfo } from '~hooks/useDiagnosticsData';

import { RelativeTime } from '~components/RelativeTime';
import { GroupedBarChart } from '~components/charts/GroupedBarChart';

interface ESUCellTempGraphProps {
  bmsTemperatures: Record<string, number | null> | undefined;
  isLoading: boolean;
  batteryInfo: BatteryInfo;
  height?: number;
  title: string;
}

function ESUCellTempGraphComponent(props: ESUCellTempGraphProps) {
  const { isLoading, batteryInfo, height, title } = props;
  const probeTemperatures = props.bmsTemperatures ?? {};

  const nOfProbes = batteryInfo.module_count * batteryInfo.temp_probes_per_module;
  const probeIndices = [...Array(nOfProbes).keys()];
  const temperatureArray = probeIndices.map((index) => probeTemperatures[`col_${index}`] ?? NaN);

  const dataTime = probeTemperatures?.time?.toString() ?? undefined;

  const barColorScale = scaleSequential(interpolateRdYlBu).domain([45, 4]).clamp(true);

  return (
    <Stack>
      <Card>
        <CardHeader
          title={
            <Typography gutterBottom variant="h6" component="h4">
              {title}
              {isLoading && <CircularProgress size={12} sx={{ mt: 1, ml: 1 }} />}
            </Typography>
          }
          subheader={
            dataTime ? (
              <>
                {formatDateTime(dataTime)} (
                <RelativeTime date={dataTime} />)
              </>
            ) : (
              'Loading...'
            )
          }
        />
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <ParentSize debounceTime={100}>
            {({ width }) => (
              <GroupedBarChart
                width={width}
                height={height ?? 300}
                data={temperatureArray}
                groupSize={batteryInfo.temp_probes_per_module}
                yAxisLimitGranularity={0}
                barColoring={(groupIndex, barIndex, value) => barColorScale(value)}
                tooltipUpperText={(data) => `Module ${data.groupIndex} sensor ${data.barIndex}`}
                tooltipLowerText={(data) => `${data.value.toFixed(0)} °C`}
              />
            )}
          </ParentSize>
        </Box>
      </Card>
    </Stack>
  );
}

export const ESUCellTempGraph = memo(ESUCellTempGraphComponent);
