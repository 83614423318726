import { EdgeControllerDiagnostics } from '~pages/controllers/components/EdgeControllerDiagnostics';
import { ControllerLayout } from '~pages/controllers/components/ControllerLayout';

export function ControllerDiagnostics() {
  return (
    <ControllerLayout title="Controller Diagnostics">
      <EdgeControllerDiagnostics />
    </ControllerLayout>
  );
}
