import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

const CHART_RANGE_CHECK_INTERVAL = 10_000;

const getRangeEndTimeStamp = (): number => dayjs.utc().add(1, 'hour').startOf('hour').valueOf();

export const useGraphRange = () => {
  const [rangeEndTimeStamp, setRangeEndTimeStamp] = useState<number>(getRangeEndTimeStamp());

  useEffect(() => {
    const interval = setInterval(() => {
      // because rangeEndTimeStamp is a number, this will only trigger a re-render
      // if the end time actually changes (once every hour)
      setRangeEndTimeStamp(getRangeEndTimeStamp());
    }, CHART_RANGE_CHECK_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const range = useMemo(() => {
    const end = new Date(rangeEndTimeStamp);
    return { start: dayjs.utc(end).subtract(6, 'hours').toDate(), end };
  }, [rangeEndTimeStamp]);

  return range;
};
