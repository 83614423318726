import { useFleetList } from '~hooks/useFleetList';

import { SidebarItem } from '~layouts/AppLayout/SidebarItem';

import { Iconify } from '~components/Iconify';

const icon = <Iconify icon="mdi:sitemap" fontSize={20} />;

export function FleetsSidebarItem() {
  const { data: fleets } = useFleetList();

  if (fleets?.length === 1) {
    return <SidebarItem path={`/fleets/${fleets[0].id}`} label="Fleet" icon={icon} />;
  }

  return <SidebarItem path="/fleets" label="Fleets" icon={icon} />;
}
