export const ALLOCATION_TZ = 'CET';
export const BIDDING_DOMAIN = '10YFI-1--------U';

export const ROUTER_MATCH_PATH = '/fleets/:fleetId/:tab';

export const TABS = {
  dashboard: 'Dashboard',
  market: 'Market',
  diagnostics: 'Diagnostics',
  optimizer: 'Optimizer',
};
