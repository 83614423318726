import type { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

import { CurrentOrganizationIdProvider } from '~hooks/useCurrentOrganizationId';
import { useOrganization } from '~hooks/useOrganization';

import { PageLayout } from '~layouts/PageLayout';
import { PageLayoutLoader } from '~layouts/PageLayout/PageLayoutLoader';

import { TABS } from '~pages/organizations/constants';
import { useOrganizationsNavigation } from '~pages/organizations/hooks/useOrganizationsNavigation';
import { useOrganizationBreadcrumbs } from '~pages/organizations/hooks/useOrganizationBreadcrumbs';
import { createOrganizationPath } from '~pages/organizations/utils/createOrganizationPath';
import { NotFound } from '~pages/NotFound';

import { Tabs } from '~components/Tabs';
import { ErrorInfo } from '~components/ErrorInfo';

type Props = PropsWithChildren<{
  title: string;
}>;

export function OrganizationLayout({ title, children }: Props) {
  const { organizationId, tab } = useOrganizationsNavigation();
  const { data: organization, isLoading, error } = useOrganization(organizationId);

  useOrganizationBreadcrumbs();

  if (!organization) {
    if (error) return <ErrorInfo error={error} />;
    if (isLoading) return <PageLayoutLoader />;

    return <NotFound />;
  }

  return (
    <CurrentOrganizationIdProvider value={organizationId}>
      <PageLayout
        title={title}
        entityType="organization"
        entityId={organizationId}
        entityName={organization?.human_name}
      >
        <Tabs tabs={TABS} tab={tab} pathname={createOrganizationPath(organizationId)} />
        <Box overflow="auto">
          <Box display="flex" flexDirection="column" position="relative">
            {children}
          </Box>
        </Box>
      </PageLayout>
    </CurrentOrganizationIdProvider>
  );
}
