import { type PropsWithChildren, useMemo, useState } from 'react';
import { Box } from '@mui/material';

import type { PageLayoutAction } from '~types';

import { USER_ROLES } from '~constants/auth';

import { useMeteringGroup } from '~hooks/useMeteringGroup';
import { useUserHasRole } from '~hooks/useUserHasRole';

import { PageLayout } from '~layouts/PageLayout';
import { PageLayoutLoader } from '~layouts/PageLayout/PageLayoutLoader';

import { TABS } from '~pages/sites/constants';
import { useSitesNavigation } from '~pages/sites/hooks/useSitesNavigation';
import { useSiteBreadcrumbs } from '~pages/sites/hooks/useSiteBreadcrumbs';
import { createSitePath } from '~pages/sites/utils/createSitePath';
import { NotFound } from '~pages/NotFound';
import { MoveEsuDialog } from '~pages/sites/components/GroupMemberList';

import { Tabs } from '~components/Tabs';
import { ErrorInfo } from '~components/ErrorInfo';

type Props = PropsWithChildren<{
  title: string;
}>;

export function SiteLayout({ title, children }: Props) {
  const { meteringGroupId, tab } = useSitesNavigation();
  const { data: meteringGroup, isLoading, error } = useMeteringGroup(meteringGroupId);
  const canAddEsuToSite = useUserHasRole(USER_ROLES.EDIT_EMS);

  useSiteBreadcrumbs();

  const [isMoveEsuDialogOpen, setIsMoveEsuDialogOpen] = useState(false);

  const actions = useMemo(() => {
    if (!canAddEsuToSite) return [];

    const action: PageLayoutAction = {
      icon: 'mdi:plus',
      label: 'Add ESU to Site',
      onClick: () => setIsMoveEsuDialogOpen(true),
    };

    return [action];
  }, [canAddEsuToSite]);

  if (!meteringGroup) {
    if (error) return <ErrorInfo error={error} />;
    if (isLoading) return <PageLayoutLoader />;

    return <NotFound />;
  }

  return (
    <PageLayout
      title={title}
      entityType="site"
      entityId={meteringGroupId}
      entityName={meteringGroup?.name}
      actions={actions}
    >
      <Tabs tabs={TABS} tab={tab} pathname={createSitePath(meteringGroupId)} />
      <Box flexGrow={1} overflow="auto">
        <Box minHeight="100%" display="flex" flexDirection="column" gap={2} position="relative">
          {children}
        </Box>
      </Box>
      {isMoveEsuDialogOpen && (
        <MoveEsuDialog group={meteringGroup} closeDialog={() => setIsMoveEsuDialogOpen(false)} />
      )}
    </PageLayout>
  );
}
