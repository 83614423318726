import { Navigate, useLocation } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

import { isRoutePermitted } from '~utils/isRoutePermitted';

import { useAuth } from '~hooks/useAuth';

import { NotFound } from '~pages/NotFound';

type Props<T extends string> = {
  tabs: Record<string, string>;
  currentTab: T;
  allowedTabs?: Record<string, boolean>;
  createPath: (tab: T) => string;
};

const KEEP_PARAMETERS = ['start', 'end'];

// Redirect to the first allowed tab
export function TabsRedirection<T extends string>({
  tabs,
  currentTab,
  allowedTabs,
  createPath,
}: Props<T>) {
  const { user } = useAuth();
  const { search } = useLocation();
  const tabKeys = Object.keys(tabs) as T[];

  const isAllowed = useCallback(
    (tabKey: T) => {
      return (
        isRoutePermitted(user, createPath(tabKey)) &&
        (typeof allowedTabs?.[tabKey] !== 'boolean' || allowedTabs[tabKey])
      );
    },
    [createPath, allowedTabs, user],
  );

  const searchString = useMemo(() => {
    const currentSearchParams = new URLSearchParams(search);
    const newSearchParams = new URLSearchParams();
    for (const key of KEEP_PARAMETERS) {
      const value = currentSearchParams.get(key);
      if (value != null) newSearchParams.set(key, value);
    }
    return [...newSearchParams.keys()].length > 0 ? `?${newSearchParams}` : '';
  }, [search]);

  // If the current tab is allowed, redirect to it
  for (const tabKey of tabKeys) {
    if (currentTab === tabKey && isAllowed(tabKey)) {
      return <Navigate replace to={tabKey + searchString} />;
    }
  }

  // Otherwise redirect to the first allowed tab
  for (const tabKey of tabKeys) {
    if (isAllowed(tabKey)) {
      return <Navigate replace to={tabKey + searchString} />;
    }
  }

  return <NotFound />;
}
