import { useMemo } from 'react';

import { type DateTimeRange } from '~utils/time';
import { getAggregageQueryTimeStepSeconds } from '~utils/timeseries';

import { createAggregateQuery, useTimeseriesAggregate } from '~hooks/timeseries/queries';
import type { AggregateRow } from '~hooks/timeseries/types';

export function useLegacyGridMeterData(
  meteringGroupId: string | null,
  range: DateTimeRange,
): {
  data: {
    isAggregatedOverTime: true;
    energy_meter_momentary: AggregateRow<'energy_meter_momentary'>[] | undefined;
  };
  isLoading: boolean;
  error: any;
} {
  const timeStepSeconds = getAggregageQueryTimeStepSeconds(range);
  const { data, isLoading, error } = useTimeseriesAggregate(
    createAggregateQuery({
      resources: meteringGroupId != null ? [`metering_group:${meteringGroupId}`] : [],
      columns: {
        grid_meter: [
          'grid_active_power:mean',
          'grid_active_power:min',
          'grid_active_power:max',
          'current_a:mean',
          'current_a:min',
          'current_a:max',
          'current_b:mean',
          'current_b:min',
          'current_b:max',
          'current_c:mean',
          'current_c:min',
          'current_c:max',
        ],
      },
      start: range.start,
      end: range.end,
      step: `${timeStepSeconds}s`,
    }),
  );

  const compatibleData = useMemo(() => {
    const gridMeterData = data?.[`metering_group:${meteringGroupId}`].grid_meter;
    return {
      isAggregatedOverTime: true as const,
      // Rename the columns to match the energy_meter_momentary schema,
      // to avoid custom handling in the rest of the UI.
      energy_meter_momentary: gridMeterData?.map((row) => ({
        ...row,
        'active_power:mean': row['grid_active_power:mean'],
        'active_power:min': row['grid_active_power:min'],
        'active_power:max': row['grid_active_power:max'],
      })) as AggregateRow<'energy_meter_momentary'>[] | undefined,
    };
  }, [data, meteringGroupId]);

  return {
    data: compatibleData,
    isLoading,
    error,
  };
}
