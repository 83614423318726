import { Popover, useTheme } from '@mui/material';
import { type MouseEvent, useCallback, useMemo, useState } from 'react';

import type { Breadcrumb } from '~types';

import { OrganizationHierarchy } from '~pages/organizations/components/OrganizationHierarchy';
import { SiteHierarchy } from '~pages/sites/components/SiteHierarchy';
import { ControllerHierarchy } from '~pages/controllers/components/ControllerHierarchy';

import { Iconify } from '~components/Iconify';
import { TinyIconButton } from '~components/TinyIconButton';

type Props = {
  breadcrumb: Breadcrumb;
};

export function BreadcrumbHierarchy({ breadcrumb }: Props) {
  const theme = useTheme();
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const handleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorElement(null);
  }, []);

  const HierarchyComponent = useMemo(() => {
    switch (breadcrumb.entityType) {
      case 'organization':
        return OrganizationHierarchy;
      case 'site':
        return SiteHierarchy;
      case 'controller':
        return ControllerHierarchy;
      default:
        return null;
    }
  }, [breadcrumb.entityType]);

  if (!HierarchyComponent) return null;
  if (!breadcrumb.entityId) return null;

  return (
    <>
      <TinyIconButton
        title="Hierarchy"
        onClick={handleOpen}
        sx={{ color: theme.palette.text.secondary }}
      >
        <Iconify icon="mdi:dots-horizontal" width={20} height={20} />
      </TinyIconButton>
      <Popover
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <HierarchyComponent id={breadcrumb.entityId} />
      </Popover>
    </>
  );
}
