import { type MouseEvent, useCallback } from 'react';
import { type TableRowProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export type LinkingCompatibleRow = { original: { id: string } };

export function useGetTableBodyRowProps(createPath: (entityId: string) => string) {
  const navigate = useNavigate();

  return useCallback(
    (row: LinkingCompatibleRow) =>
      ({
        sx: { cursor: 'pointer' },
        onClick: (event: MouseEvent) => {
          const path = createPath(row.original.id);
          if (event.ctrlKey || event.metaKey) {
            window.open(path, '_blank');
          } else {
            navigate(path);
          }
        },
        // on firefox middle clicking in a scrollable element doesn't
        // trigger an auxclick event, so we have to do this
        onMouseDown: (event: MouseEvent) => {
          if (event.button === 1) {
            event.preventDefault();
          }
        },
        onMouseUp: (event: MouseEvent) => {
          if (event.button === 1) {
            window.open(createPath(row.original.id), '_blank');
          }
        },
      }) satisfies TableRowProps,
    [createPath, navigate],
  );
}
