import { useMemo } from 'react';

import { useMeteringGroup } from '~hooks/useMeteringGroup';
import { useMeteringGroupList } from '~hooks/useMeteringGroupList';

export function useSiteSiblings(id: string) {
  const { data: meteringGroup } = useMeteringGroup(id);
  const { data: meteringGroups = [] } = useMeteringGroupList();

  const organizationMeteringGroups = useMemo(
    () => meteringGroups.filter((mg) => mg.organization_id === meteringGroup?.organization_id),
    [meteringGroups, meteringGroup],
  );

  return organizationMeteringGroups;
}
