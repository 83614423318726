import { type PropsWithChildren, useCallback, useMemo, useState } from 'react';

import type { Breadcrumb, EntityType } from '~types';

import { isRoutePermitted } from '~utils/isRoutePermitted';
import { createEntityPath } from '~utils/createEntityPath';

import { useAuth } from '~hooks/useAuth';
import { BreadcrumbsContext, type BreadcrumbsContextType } from '~hooks/useBreadcrumbs';

export function BreadcrumbsProvider({ children }: PropsWithChildren) {
  const { user } = useAuth();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  const registerBreadcrumbs = useCallback(
    (breadcrumb: Breadcrumb) => {
      const { entityType, entityId, label } = breadcrumb;

      // A null label does not register the breadcrumb
      // Empty string label shows "..." (breadcrumb data loading)
      if (label === null) return () => {};

      const path = entityType && entityId ? createEntityPath(entityType, entityId) : null;

      if (path !== null && !isRoutePermitted(user, path)) return () => {};

      setBreadcrumbs((previousBreadcrumbs) =>
        previousBreadcrumbs.some((b) => areSameBreadcrumbs(breadcrumb, b))
          ? previousBreadcrumbs
          : [...previousBreadcrumbs, { entityType, entityId, label }].sort(sortBreadcrumbs),
      );

      return () => {
        setBreadcrumbs((previousBreadcrumbs) =>
          previousBreadcrumbs.filter((b) => !areSameBreadcrumbs(breadcrumb, b)),
        );
      };
    },
    [user],
  );

  const breadcrumbsContext = useMemo<BreadcrumbsContextType>(
    () => ({
      breadcrumbs,
      setBreadcrumbs,
      registerBreadcrumbs,
    }),
    [breadcrumbs, registerBreadcrumbs],
  );

  return (
    <BreadcrumbsContext.Provider value={breadcrumbsContext}>{children}</BreadcrumbsContext.Provider>
  );
}

const breadcrumbTypeSortOrder: EntityType[] = [
  'fleet',
  'organization',
  'site',
  'controller',
  'esu',
];

function sortBreadcrumbs(a: Breadcrumb, b: Breadcrumb) {
  if (a.entityType && b.entityType && a.entityType !== b.entityType) {
    return (
      breadcrumbTypeSortOrder.indexOf(a.entityType) - breadcrumbTypeSortOrder.indexOf(b.entityType)
    );
  }

  return 0;
}

function areSameBreadcrumbs(a: Breadcrumb, b: Breadcrumb) {
  return a.entityType === b.entityType && a.entityId === b.entityId && a.label === b.label;
}
