import { Alert, Box, Typography } from '@mui/material';
import { useMemo } from 'react';

import { collator } from '~utils/localization';

import { type Row } from '~hooks/timeseries';
import { useOptimizerResultTimeseries } from '~hooks/useOptimizerRuns';

import { OptimizerResultGraph } from '~components/charts/OptimizerResultGraph';

import { type RunDetails, type RunDetailsGroupResult } from './types';

type GroupViewProps = {
  group: RunDetailsGroupResult;
  run: RunDetails;
  esusByID: Map<string, { name: string }>;
};

export function GroupView({ group, run, esusByID }: GroupViewProps) {
  // instead of fetching data for each group and ESU separately,
  // it's better to have the hook fetch data for the whole run and trust SWR to cache it
  const {
    groupData: groupTimeseriesResult,
    groupDataLoading,
    groupDataError,
    esuData: esuTimeseriesResult,
    esuDataLoading,
    esuDataError,
  } = useOptimizerResultTimeseries(run);

  const esus = Object.values(group.esu_results);
  esus.sort((a, b) =>
    collator.compare(esusByID.get(a.esu_id)?.name ?? '', esusByID.get(b.esu_id)?.name ?? ''),
  );

  const groupResultRows =
    groupTimeseriesResult?.[`metering_group:${group.group_id}`]?.group_optimizer_result ?? null;

  const loadShiftingProfit = useMemo(
    () => calculateLoadShiftingProfit(groupResultRows),
    [groupResultRows],
  );

  return (
    <Box>
      {group.init_error && (
        <Alert severity="error" sx={{ mb: 1 }}>
          Optimizer had init error for group: {group.init_error}
        </Alert>
      )}
      {loadShiftingProfit != null && (
        <Typography variant="body2">
          Group load shifting profit: {loadShiftingProfit.toFixed(2)} €
        </Typography>
      )}
      <OptimizerResultGraph
        variant="group"
        groupData={groupResultRows}
        isLoading={groupDataLoading}
        error={groupDataError}
      />
      {esus.map((esu) => {
        const esuResultRows =
          esuTimeseriesResult?.[`esu:${esu.esu_id}`]?.esu_optimizer_result ?? null;
        return (
          <Box key={esu.esu_id} mt={2}>
            <Typography variant="body1" color="primary">
              {esusByID.get(esu.esu_id)?.name ?? esu.esu_id}
            </Typography>
            {esu.init_error && (
              <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                Optimizer had init error for ESU: {esu.init_error}
              </Alert>
            )}
            {esu.warnings.length > 0 &&
              esu.warnings.map((warning, index) => (
                <Alert severity="warning" sx={{ mt: 1, mb: 1 }} key={index}>
                  {warning}
                </Alert>
              ))}
            <Typography variant="body1" mt={1}>
              Optimizer comment: {esu.comment}
            </Typography>
            <OptimizerResultGraph
              variant="esu"
              groupData={groupResultRows}
              esuData={esuResultRows}
              isLoading={groupDataLoading || esuDataLoading}
              error={groupDataError ?? esuDataError}
            />
          </Box>
        );
      })}
    </Box>
  );
}

const calculateLoadShiftingProfit = (
  groupResultRows: Row<'group_optimizer_result'>[] | null,
): number | null => {
  if (!groupResultRows) return null;
  const startsWithPartialBalanceInterval =
    groupResultRows[0] && !Number.isFinite(groupResultRows[0].start_energy_total);
  // if the first two rows are for partial balance intervals, they should not be included
  const profits = (
    startsWithPartialBalanceInterval ? groupResultRows.slice(2) : groupResultRows
  ).flatMap((row) =>
    Number.isFinite(row.esu_energy_profit) ? (row.esu_energy_profit as number) : [],
  );
  return profits.reduce((a, b) => a + b, 0);
};
