import {
  type MRT_ColumnDef,
  type MRT_TableInstance,
  MaterialReactTable,
  useMRT_Rows,
  useMaterialReactTable,
} from 'material-react-table';
import { Box } from '@mui/material';

import type { Region } from '~types';

import { collator } from '~utils/localization';

import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useTableColumnState } from '~hooks/useTableColumnState';
import { useFleetList } from '~hooks/useFleetList';
import { useSetTableEntities } from '~hooks/useTableEntities';
import { useTableCommonProps } from '~hooks/useTableCommonProps';
import { useGetTableBodyRowProps } from '~hooks/useGetTableBodyRowProps';

import { PageLayout } from '~layouts/PageLayout';

import { createFleetPath } from '~pages/fleets/utils/createFleetPath';

import { MRTEmptyRowsFallback } from '~components/MRTEmptyRowsFallback';

const COLUMNS: MRT_ColumnDef<Region>[] = [
  {
    header: 'Name',
    id: 'name',
    accessorFn: (row) => row.name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    Footer: NameFooter,
    size: 300,
  },
];

export function Fleets() {
  const { data: fleets, error } = useFleetList();

  useRegisterBreadcrumbs({ label: 'Fleets' });

  const {
    columnSizing,
    columnFilters,
    columnVisibility,
    sorting,
    setColumnSizing,
    setColumnFilters,
    setColumnVisibility,
    setSorting,
  } = useTableColumnState('fleets');

  const tableCommonProps = useTableCommonProps<Region>();
  const getTableBodyRowProps = useGetTableBodyRowProps(createFleetPath);
  const table = useMaterialReactTable<Region>({
    ...tableCommonProps,
    data: fleets ?? [],
    columns: COLUMNS,
    state: {
      columnSizing,
      columnFilters,
      columnVisibility,
      sorting,
      columnPinning: {
        left: ['name'],
      },
    },
    onColumnSizingChange: setColumnSizing,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => getTableBodyRowProps(row),
    renderEmptyRowsFallback: () => (
      <MRTEmptyRowsFallback error={error} noItemsMessage="No fleets to display" />
    ),
  });

  useSetTableEntities(
    'fleet',
    table.getRowModel().rows.map((row) => ({ id: row.original.id })),
  );

  return (
    <PageLayout hasDivider title="Fleets">
      <MaterialReactTable table={table} />
    </PageLayout>
  );
}

function NameFooter({ table }: { table: MRT_TableInstance<Region> }) {
  const rows = useMRT_Rows(table);

  return (
    <Box height="100%" display="flex" alignItems="center">
      {rows.length} fleet{rows.length > 1 ? 's' : ''}
    </Box>
  );
}
