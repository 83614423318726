import { useCallback, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { Form, Formik, type FormikHelpers, useFormikContext } from 'formik';

import { asCactosError, http } from '~http';

import { SCHEDULE_COLUMNS, type ScheduleRow, toScheduleRow } from '~constants/schedule';

import { checkIntOrThrow } from '~utils/parsing';

import { useScheduleHistory } from '~hooks/useScheduleHistory';
import { useActiveControls } from '~hooks/useActiveControls';

import { ScheduleControls } from '~pages/esus/components/ScheduleControls';
import { useESUsNavigation } from '~pages/esus/hooks/useESUsNavigation';

type ScheduleOverride = ScheduleRow & {
  duration: number;
};

const FORM_ELEMENT_STYLE = {
  marginRight: '1rem',
  marginBottom: '1rem',
  minWidth: '10ch',
  flexBasis: '15ch',
  flexGrow: 1,
};

const FCR_N_SPECIFIC_FIELDS = ['max_fcrn_charge', 'max_fcrn_discharge'];
const FCR_D_SPECIFIC_FIELDS = ['fcrd_up_allocated_cap', 'fcrd_down_allocated_cap'];

function OverrideFormBody() {
  const { isSubmitting, handleSubmit, getFieldProps, values } = useFormikContext();

  const fields = SCHEDULE_COLUMNS.filter((detail) => {
    const mode = (values as any).mode as string;
    if (FCR_N_SPECIFIC_FIELDS.includes(detail.id)) {
      return mode === 'fcr_n';
    }
    if (FCR_D_SPECIFIC_FIELDS.includes(detail.id)) {
      return mode === 'fcr_d';
    }
    return true;
  });

  return (
    <Form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      style={{
        display: 'flex',
        flexFlow: 'row wrap',
      }}
    >
      {fields.map((detail) =>
        detail.id === 'mode' ? (
          <TextField
            key={detail.id}
            size="small"
            sx={FORM_ELEMENT_STYLE}
            select
            label="Mode"
            {...getFieldProps(detail.id)}
          >
            <MenuItem value="soc_range">SOC range</MenuItem>
            <MenuItem value="fcr_n">FCR-N</MenuItem>
            <MenuItem value="fcr_d">FCR-D</MenuItem>
          </TextField>
        ) : (
          <TextField
            key={detail.id}
            size="small"
            sx={FORM_ELEMENT_STYLE}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{detail.unit}</InputAdornment>,
            }}
            label={detail.label}
            {...getFieldProps(detail.id)}
          />
        ),
      )}
      <TextField
        size="small"
        sx={FORM_ELEMENT_STYLE}
        key="duration"
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">s</InputAdornment>,
        }}
        label="Duration"
        {...getFieldProps('duration')}
      />
      <Box sx={{ ...FORM_ELEMENT_STYLE, display: 'flex', justifyContent: 'flex-end' }}>
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          Submit override
        </LoadingButton>
      </Box>
    </Form>
  );
}

const idleSchedule = {
  mode: 'soc_range',
  min_target_soc: 0,
  max_target_soc: 100,
  charge_power: 0,
  discharge_power: 0,
  max_fcrn_charge: 0,
  max_fcrn_discharge: 0,
  peak_shaving_threshold: 0,
  max_peak_shaving_output: 0,
  fcrd_up_allocated_cap: 0,
  fcrd_down_allocated_cap: 0,
};

export function OverrideForm() {
  const { esuId } = useESUsNavigation();
  const [overrideError, setOverrideError] = useState<string | undefined>(undefined);

  const {
    data: scheduleHistory,
    mutate: refetchScheduleHistory,
    error: scheduleHistoryError,
  } = useScheduleHistory(esuId);

  const currentSchedule =
    scheduleHistory != null && scheduleHistory.length > 0
      ? scheduleHistory[scheduleHistory.length - 1]
      : null;

  const {
    data: activeControls,
    mutate: refetchActiveControls,
    isLoading: loadingActiveControls,
    error: activeControlsError,
  } = useActiveControls(esuId);

  const setOverride = useCallback(
    (fromTime: 'now' | Date, scheduleData: ScheduleRow, duration: number) => {
      const data = {
        from_time: fromTime === 'now' ? 'now' : fromTime.toISOString().replace('Z', ''),
        schedule_data: scheduleData,
        duration,
      };
      return http.post(`/v1/ems/${esuId}/schedule`, data, { responseType: 'text' });
    },
    [esuId],
  );

  const setImmediateOverride = useCallback(
    async (formValues: ScheduleOverride, formik: FormikHelpers<ScheduleOverride>) => {
      const scheduleData = toScheduleRow(formValues);
      const duration = checkIntOrThrow(formValues.duration);
      setOverrideError(undefined);
      try {
        await setOverride('now', scheduleData, duration);

        const nextHour = new Date();
        nextHour.setMinutes(0, 0, 0);
        nextHour.setHours(nextHour.getHours() + 1);
        await setOverride(nextHour, scheduleData, duration);

        await refetchScheduleHistory();
      } catch (e) {
        if (!axios.isCancel(e)) {
          const error = asCactosError(e);
          const message = `Failed to set immediate override: ${error.message}`;
          setOverrideError(message);
        }
      } finally {
        formik.setSubmitting(false);
        refetchActiveControls();
      }
    },
    [setOverride, setOverrideError, refetchActiveControls, refetchScheduleHistory],
  );

  return (
    <Card sx={{ marginBottom: '1rem' }}>
      {scheduleHistoryError && <Alert severity="error">{scheduleHistoryError}</Alert>}
      <CardHeader title="Manual override" />
      <CardContent>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Box width="340px" pb={3} pr={3} flexGrow={1}>
            <ScheduleControls
              activeControls={activeControls ?? {}}
              isLoading={loadingActiveControls}
              error={activeControlsError}
            />
          </Box>
          <Stack
            sx={{ flexBasis: '350px', flexGrow: 10 }}
            direction="column"
            justifyContent="space-between"
          >
            <Formik<ScheduleOverride>
              initialValues={{
                ...(currentSchedule ?? idleSchedule),
                mode: 'soc_range',
                max_fcrn_charge: 0,
                max_fcrn_discharge: 0,
                fcrd_up_allocated_cap: 0,
                fcrd_down_allocated_cap: 0,
                duration: 3600,
              }}
              enableReinitialize
              onSubmit={setImmediateOverride}
            >
              <OverrideFormBody />
            </Formik>
            {overrideError && <Alert severity="error">{overrideError}</Alert>}
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
}
