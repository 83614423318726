import { useCallback, useMemo, useState } from 'react';
import { Document, Page as PdfPage, pdfjs } from 'react-pdf';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import useSWR from 'swr';

import { BASE_URL, plainHttp } from '~http';

// Use external CDN because I can't figure out how to include it in the build
// see e.g. https://github.com/wojtekmaj/react-pdf/issues/782
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type Props = {
  acceptTerms: (termsId: string) => void;
  token: string | null;
};

type Term = {
  language: string;
  terms_id: string;
  version: string;
};

export function Terms({ acceptTerms, token }: Props) {
  const [currentTermId, setCurrentTermId] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);

  const { data: terms } = useSWR<Term[]>(token ? ['/v1/terms', token] : null, {
    fetcher: fetchTermsList,
  });

  const { data: termDocument } = useSWR<ArrayBuffer>(
    currentTermId && token ? [`v1/terms/${currentTermId}`, token] : null,
    { fetcher: fetchTermDocument, revalidateOnFocus: false },
  );

  // Pass a copy of the ArrayBuffer as pdf-js clears it
  const termDocumentCopy = useMemo(() => termDocument?.slice(0), [termDocument]);

  const onDocumentLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
    setPagesCount(numPages);
    setPage(1);
  }, []);

  const handlePageChange = useCallback((_event: any, page: number) => {
    setPage(page);
  }, []);

  const handleDownload = useCallback(() => {
    if (!termDocument) return;

    const blob = new Blob([termDocument], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const fileLink = document.createElement('a');

    fileLink.href = url;
    fileLink.download = 'Cactos_terms_of_use.pdf';
    fileLink.click();
    URL.revokeObjectURL(url);
  }, [termDocument]);

  if (!currentTermId && terms?.length) {
    setCurrentTermId(terms[0].terms_id);
  }

  return (
    <Stack>
      <Typography mb={4}>
        You must accept the Cactos Spine terms of use before logging in.
      </Typography>
      {terms && terms.length > 0 && (
        <FormControl sx={{ mb: 2, maxWidth: 200 }}>
          <InputLabel>Language</InputLabel>
          <Select
            size="small"
            value={currentTermId ?? ''} // '' is needed to make the select controlled
            onChange={(event) => setCurrentTermId(event.target.value)}
            label="Language"
          >
            {terms.map((terms) => (
              <MenuItem key={terms.terms_id} value={terms.terms_id}>
                {terms.language}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {currentTermId && (
        <>
          <Stack spacing={1}>
            <Pagination
              count={pagesCount}
              defaultPage={1}
              page={page}
              onChange={handlePageChange}
            />
            <Document file={termDocumentCopy} onLoadSuccess={onDocumentLoadSuccess}>
              <PdfPage
                key={page}
                pageNumber={page}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>
            <Pagination
              count={pagesCount}
              defaultPage={1}
              page={page}
              onChange={handlePageChange}
            />
          </Stack>
          <Stack mt={4} direction="row" spacing={1}>
            <Button variant="contained" onClick={() => acceptTerms(currentTermId)}>
              Accept terms of use
            </Button>
            <Button onClick={handleDownload}>Download PDF</Button>
          </Stack>
        </>
      )}
    </Stack>
  );
}

async function fetchTermsList([url, userlessToken]: [string, string]): Promise<Term[]> {
  const response = await plainHttp.get(url, {
    headers: { Authorization: `Bearer-Userless ${userlessToken}` },
  });
  return response.data;
}

async function fetchTermDocument([url, userlessToken]: [string, string]): Promise<ArrayBuffer> {
  const response = await fetch(new URL(url, BASE_URL), {
    headers: { Authorization: `Bearer-Userless ${userlessToken}` },
  });
  return await response.arrayBuffer();
}
