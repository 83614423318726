import { Box } from '@mui/material';

import { FCRNRevenueEstimateCard } from '~pages/sites/components/FCRNRevenueEstimateCard';
import { SiteDashboardCharts } from '~pages/sites/components/SiteDashboardCharts';
import { useSitesNavigation } from '~pages/sites/hooks/useSitesNavigation';
import { SiteLayout } from '~pages/sites/components/SiteLayout';

export function SiteDashboard() {
  const { meteringGroupId } = useSitesNavigation();

  return (
    <SiteLayout title="Site Dashboard">
      <Box p={2}>
        <Box display="flex">
          <FCRNRevenueEstimateCard groupId={meteringGroupId} />
        </Box>
        <SiteDashboardCharts />
      </Box>
    </SiteLayout>
  );
}
