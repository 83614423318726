import { useMeteringGroupList } from '~hooks/useMeteringGroupList';

import { SidebarItem } from '~layouts/AppLayout/SidebarItem';

import { Iconify } from '~components/Iconify';

const icon = <Iconify icon="mdi:home-lightning-bolt-outline" fontSize={20} />;

export function SitesSidebarItem() {
  const { data: sites } = useMeteringGroupList();

  if (sites?.length === 1) {
    return <SidebarItem path={`/sites/${sites[0].id}`} label="Site" icon={icon} />;
  }

  return <SidebarItem path="/sites" label="Sites" icon={icon} />;
}
