import { type PropsWithChildren, useMemo } from 'react';

import type { Entity } from '~types';

import { useLocalStorage } from '~hooks/useLocalStorage';
import { TableEntitiesContext, type TableEntitiesContextType } from '~hooks/useTableEntities';

export function TableEntitiesProvider({ children }: PropsWithChildren) {
  const [fleetEntities, setFleetEntities] = useLocalStorage<Entity[]>(
    'filtered-entities.fleets',
    [],
  );
  const [organizationEntities, setOrganizationEntities] = useLocalStorage<Entity[]>(
    'filtered-entities.organizations',
    [],
  );
  const [siteEntities, setSiteEntities] = useLocalStorage<Entity[]>('filtered-entities.sites', []);
  const [controllerEntities, setControllerEntities] = useLocalStorage<Entity[]>(
    'filtered-entities.controllers',
    [],
  );
  const [esuEntities, setEsuEntities] = useLocalStorage<Entity[]>('filtered-entities.esus', []);

  const tableEntitiesContextValue = useMemo<TableEntitiesContextType>(
    () => ({
      fleetEntities,
      organizationEntities,
      siteEntities,
      controllerEntities,
      esuEntities,
      setFleetEntities,
      setOrganizationEntities,
      setSiteEntities,
      setControllerEntities,
      setEsuEntities,
    }),
    [
      fleetEntities,
      organizationEntities,
      siteEntities,
      controllerEntities,
      esuEntities,
      setFleetEntities,
      setOrganizationEntities,
      setSiteEntities,
      setControllerEntities,
      setEsuEntities,
    ],
  );

  return (
    <TableEntitiesContext.Provider value={tableEntitiesContextValue}>
      {children}
    </TableEntitiesContext.Provider>
  );
}
