import { useCallback, useMemo } from 'react';
import useSWR, { preload } from 'swr';

import type { CommonGroupMemberESU, ESUWithController, ListEdgeController } from '~types';

import { fetcher } from '~http';

import { collator } from '~utils/localization';

export async function preloadESUList() {
  try {
    return await preload('/v1/edge_controller', fetcher);
  } catch {
    return null;
  }
}

export function useESUList(): {
  data: ESUWithController[] | undefined;
  error: Error | undefined;
  isLoading: boolean;
  refetch: () => void;
} {
  const {
    data: controllers,
    error,
    isLoading,
    mutate,
  } = useSWR<ListEdgeController[]>('/v1/edge_controller', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });

  const esus = useMemo(
    () =>
      controllers
        ?.flatMap((ec) => ec.esus.map((esu) => ({ ...esu, edge_controller: ec })))
        .sort((a, b) => collator.compare(a.name, b.name)),
    [controllers],
  );

  const refetch = useCallback(() => mutate(), [mutate]);

  return { data: esus, error, isLoading, refetch };
}

export function useESU(esuId: string | null) {
  const {
    data: controllers,
    error,
    isLoading,
  } = useSWR<ListEdgeController[]>('/v1/edge_controller', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });

  const esus: ESUWithController[] | undefined = useMemo(
    () => controllers?.flatMap((ec) => ec.esus.map((esu) => ({ ...esu, edge_controller: ec }))),
    [controllers],
  );

  const esu = useMemo(() => {
    if (esus == null) return undefined;
    if (esuId == null) return null;
    return esus.find((esu) => esu.id === esuId) ?? null;
  }, [esus, esuId]);

  return { data: esu, error, isLoading };
}

export function preloadFleetESUList(fleetId: string) {
  try {
    return preload(`/v1/region/fleet/${fleetId}/esu`, fetcher);
  } catch {
    return null;
  }
}

export function useFleetESUList(fleetId: string) {
  const { data, error, isLoading, mutate } = useSWR<CommonGroupMemberESU[]>(
    `/v1/region/fleet/${fleetId}/esu`,
    { refreshInterval: 60_000, revalidateOnFocus: false, fetcher },
  );

  const sortedData = useMemo(
    () => (data != null ? [...data].sort((a, b) => collator.compare(a.name, b.name)) : undefined),
    [data],
  );

  return {
    data: sortedData,
    error,
    isLoading,
    mutate,
  };
}
