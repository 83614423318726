import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useFleet } from '~hooks/useFleetList';

import { useFleetsNavigation } from '~pages/fleets/hooks/useFleetsNavigation';

export function useFleetBreadcrumbs() {
  const { fleetId } = useFleetsNavigation();
  const { data: fleet } = useFleet(fleetId);

  useRegisterBreadcrumbs({
    entityType: 'fleet',
    entityId: fleet?.id,
    label: fleet?.name ?? null,
  });
}
