// TODO
// - [x] separate into more files
// - [x] add to sidebar/navigation, remember to set permissions, should not be visible to customers
// - [x] better time selector -> 2 date selectors, latest query start time should be optional
// - [x] limit max query time range
// - [x] debounce querying
// - [x] add resource types, truncate auto-complete options to around 200 (?)
// - [x] plotting, multiple plots
// - [x] allow hiding chart variable controls
// - [x] multiple queries
// - [x] large queries to infrequent series should be allowed
// - [x] investigate ResultCard lag (it's the MUI table, not a lot we can do about it)
// - [x] wildcard does not query received timestamp column so it should not be in result card
// - [x] save/share queries, probably in URL
// - [ ] allow "generated columns" by applying functions to one or more columns (across multiple series is difficult)
// - [ ] dual y-axis
// - [x] better ids for queries and charts
// - [x] charts in url?
// - [ ] rotate resultcard labels only if necessary

import { Box, Button, Card, Container, IconButton, Stack, Tooltip } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { subHours } from 'date-fns';
import dayjs, { type Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';
import useSWR from 'swr';

import { type Region } from '~types';

import { fetcher } from '~http';

import { collator } from '~utils/localization';

import { useEdgeControllerList } from '~hooks/useEdgeControllerList';
import { useESUList } from '~hooks/useESUList';
import { useMeteringGroupList } from '~hooks/useMeteringGroupList';
import { useDateTimePickerViewRenderers } from '~hooks/useDateTimePickerViewRenderers';
import { useQueryParamState } from '~hooks/useQueryParamState';

import { type ChartState, EditableChart, type Variable } from '~pages/explorer/EditableChart';
import {
  type EditorFields,
  QueryEditor,
  type QueryVariant,
  type ResultWithQuery,
} from '~pages/explorer/QueryEditor';
import { ResultCard } from '~pages/explorer/ResultCard';

import { Iconify } from '~components/Iconify';
import { Page } from '~components/Page';

export type TimeseriesSchemas = {
  [resourceType: string]: {
    [seriesName: string]: {
      columns: {
        [columnName: string]: 'int' | 'float' | 'string' | 'timestamp' | 'decimal';
      };
    };
  };
};

type FieldsById = { [key: string]: EditorFields };
type ChartStatesById = { [key: string]: ChartState };

export function TimeSeriesExplorer() {
  const { data: controllers = [] } = useEdgeControllerList();
  const { data: esus = [] } = useESUList();
  const { data: groups = [] } = useMeteringGroupList();
  const { data: biddingZones = [] } = useSWR<Region[]>('/v1/region/entsoe_bz', { fetcher });
  const { data: reserveObjects = [] } = useSWR<Region[]>('/v1/region/fcr', { fetcher });
  const { data: schemas = {} } = useSWR<TimeseriesSchemas>('/v2/timeseries/schemas', {
    fetcher,
    revalidateOnFocus: false,
  });

  const displayNameForResource: Map<string, string> = useMemo(() => {
    const controllerNames = new Map(controllers.map((ec) => [ec.id, ec.name]));
    const energyMeters = groups
      .flatMap((group) =>
        group.grid_meters
          .filter((meter) => meter.series === 'energy_meter')
          .map((meter) => {
            const controllerId = meter.edge_controller_id?.split(':')[1];
            if (controllerId == null)
              return [meter.resource_id, meter.resource_id.replace(/^.*?:/, '')];
            const ecName = controllerNames.get(controllerId) ?? meter.edge_controller_id!;
            const meterName = meter.resource_id.replace(/^.*?\./, '');
            return [meter.resource_id, `${ecName} ${meterName}`];
          }),
      )
      .sort((a, b) => collator.compare(a[1], b[1]));
    const pairs = [
      ...esus.map((esu) => [esu.resource_id, esu.name]),
      ...groups.map((group) => [`metering_group:${group.id}`, group.name]),
      ...biddingZones.map((region) => [`region:${region.id}`, region.name]),
      ...reserveObjects.map((region) => [`region:${region.id}`, region.name]),
      ...energyMeters,
    ];
    return new Map(pairs as [string, string][]);
  }, [esus, groups, controllers, biddingZones, reserveObjects]);

  const knownResources: string[] = Array.from(displayNameForResource.keys());

  const { knownColumns, knownAggregateColumns } = useMemo(() => {
    // don't worry about it
    const columns: string[] = Object.values(schemas).flatMap((type) =>
      Object.entries(type).flatMap(([seriesName, seriesData]) => [
        `${seriesName}.*`,
        ...Object.keys(seriesData.columns).map((column) => `${seriesName}.${column}`),
      ]),
    );

    const knownColumns = Array.from(new Set(columns)).sort();

    const knownAggregateColumns = knownColumns.flatMap((column) =>
      column.endsWith('.*')
        ? []
        : [`${column}:mean`, `${column}:min`, `${column}:max`, `${column}:sum`],
    );

    return { knownColumns, knownAggregateColumns };
  }, [schemas]);

  const [chartStateById, setChartStateById] = useQueryParamState<ChartStatesById>({
    key: 'c',
    defaultValue: {},
    stringify: stringifyChartStates,
    parse: parseChartStates,
  });

  const [fieldsById, setFieldsById] = useQueryParamState<FieldsById>({
    key: 'q',
    defaultValue: { [createRandomKey('q')]: newEditorFields() },
    stringify: stringifyFields,
    parse: parseFields,
  });

  const [startTime, setStartTime] = useQueryParamState({
    key: 's',
    defaultValue: subHours(new Date(), 1),
    stringify: stringifyDate,
    parse: parseDate,
  });

  const [endTime, setEndTime] = useQueryParamState({
    key: 'e',
    defaultValue: new Date(),
    stringify: stringifyDate,
    parse: parseDate,
  });

  const [outputsById, setOutputsById] = useState<{ [key: string]: ResultWithQuery }>({});

  const results = Object.keys(fieldsById)
    .map((key) => outputsById[key])
    .filter((result) => result != null);

  const viewRenderers = useDateTimePickerViewRenderers();

  return (
    <Page title="Time Series Explorer">
      <Container maxWidth="xl" sx={{ padding: { xs: 2 } }}>
        <Stack direction="column" spacing={2}>
          <Card>
            <Box
              margin={2}
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              alignItems="center"
              gap={2}
            >
              <Box
                display="flex"
                gap={1}
                alignItems="center"
                sx={{ minWidth: 250, flexBasis: 250, flexGrow: 1 }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Start time"
                    value={dayjs(startTime)}
                    onChange={(value: Dayjs | null): void => {
                      if (value != null) setStartTime(value.startOf('second').toDate());
                    }}
                    ampm={false}
                    format="YYYY-MM-DD HH:mm:ss"
                    viewRenderers={viewRenderers}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </LocalizationProvider>
              </Box>

              <Box
                display="flex"
                gap={1}
                alignItems="center"
                sx={{ minWidth: 250, flexBasis: 250, flexGrow: 1 }}
              >
                <Tooltip title="Set end time to current time" placement="top">
                  <IconButton onClick={() => setEndTime(new Date())}>
                    <Iconify icon="mdi:recent" />
                  </IconButton>
                </Tooltip>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="End time"
                    value={dayjs(endTime)}
                    onChange={(value: Dayjs | null): void => {
                      if (value != null) setEndTime(value.startOf('second').toDate());
                    }}
                    ampm={false}
                    format="YYYY-MM-DD HH:mm:ss"
                    viewRenderers={viewRenderers}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </Card>

          {Object.entries(fieldsById).map(([key, fields]) => (
            <QueryEditor
              key={key}
              fields={fields}
              onFieldsChange={(fields) => setFieldsById((prev) => ({ ...prev, [key]: fields }))}
              onOutputChange={(output) => setOutputsById((prev) => ({ ...prev, [key]: output }))}
              onRemove={() => {
                setFieldsById((prev) =>
                  Object.fromEntries(Object.entries(prev).filter(([k]) => k !== key)),
                );
                setOutputsById((prev) =>
                  Object.fromEntries(Object.entries(prev).filter(([k]) => k !== key)),
                );
              }}
              startTime={startTime}
              endTime={endTime}
              knownColumns={knownColumns}
              knownAggregateColumns={knownAggregateColumns}
              knownResources={knownResources}
              displayNameForResource={displayNameForResource}
            />
          ))}

          <Button
            fullWidth
            variant="outlined"
            onClick={() =>
              setFieldsById((prev) => ({ ...prev, [createRandomKey('q')]: newEditorFields() }))
            }
          >
            Add query
          </Button>

          {Object.entries(chartStateById).map(([key, state]) => (
            <EditableChart
              key={key}
              results={results.flatMap((r) => r.data?.results ?? [])}
              displayNameForResource={displayNameForResource}
              state={state}
              onStateChange={(newState) => {
                setChartStateById((prev) => ({ ...prev, [key]: newState }));
              }}
              onRemove={() =>
                setChartStateById((prev) =>
                  Object.fromEntries(Object.entries(prev).filter(([k]) => k !== key)),
                )
              }
            />
          ))}

          <Button
            fullWidth
            variant="outlined"
            onClick={() =>
              setChartStateById((prev) => ({
                ...prev,
                [createRandomKey('c')]: { variables: [], showControls: true },
              }))
            }
          >
            Add chart
          </Button>

          {results.flatMap(({ data, query }) =>
            (data?.results ?? []).map((resultItem) => (
              <ResultCard
                key={`${resultItem.resource}-${resultItem.series}`}
                result={resultItem}
                query={query}
                schemas={schemas}
                displayNameForResource={displayNameForResource}
              />
            )),
          )}
        </Stack>
      </Container>
    </Page>
  );
}

const createRandomKey = (prefix: string): string =>
  `${prefix}-${Math.random().toString(36).slice(2)}`;

const newEditorFields = (): EditorFields => ({
  resources: [],
  columns: [],
  variant: 'range',
  latestQueryStartDefined: true,
  aggregateStep: 60,
});

const stringifyFields = (fields: FieldsById) => {
  return JSON.stringify(
    Object.fromEntries(
      Object.entries(fields).map(([key, fields]) => [
        key,
        {
          v: fields.variant,
          r: fields.resources,
          c: fields.columns,
          as: fields.aggregateStep,
          ls: fields.latestQueryStartDefined,
        },
      ]),
    ),
  );
};

const parseFields = (param: string): FieldsById | null => {
  const parsed = JSON.parse(param) as unknown;
  if (parsed == null || typeof parsed !== 'object') return null;
  return Object.fromEntries(
    Object.entries(parsed).map(([key, fields]) => [
      key,
      {
        variant: fields.v as QueryVariant,
        resources: fields.r as string[],
        columns: fields.c as string[],
        aggregateStep: fields.as as number | null,
        latestQueryStartDefined: fields.ls as boolean,
      },
    ]),
  );
};

const stringifyChartStates = (states: ChartStatesById) => {
  return JSON.stringify(
    Object.fromEntries(
      Object.entries(states).map(([key, state]) => [
        key,
        {
          v: state.variables.map((variable) => ({
            k: variable.key,
            r: variable.resource,
            s: variable.series,
            c: variable.column,
            h: variable.color,
            m: variable.multiplier,
            i: variable.interpolation,
          })),
          c: state.showControls,
        },
      ]),
    ),
  );
};

const parseChartStates = (param: string): ChartStatesById | null => {
  const parsed = JSON.parse(param) as unknown;
  if (parsed == null || typeof parsed !== 'object') return null;
  return Object.fromEntries(
    Object.entries(parsed).map(([key, state]) => [
      key,
      {
        variables: (state.v as any[]).map((variable) => ({
          key: variable.k,
          resource: variable.r,
          series: variable.s,
          column: variable.c,
          color: variable.h,
          multiplier: variable.m,
          interpolation: variable.i,
        })) as Variable[],
        showControls: state.c as boolean,
      },
    ]),
  );
};

const stringifyDate = (date: Date) => (Number.isFinite(date.getTime()) ? date.toISOString() : '-');

const parseDate = (str: string) => new Date(str);
