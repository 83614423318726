import { addMinutes, differenceInMinutes, subMinutes } from 'date-fns';

import { type DateTimeRange } from '~utils/time';
import { getAggregageQueryTimeStepSeconds } from '~utils/timeseries';

import {
  createAggregateQuery,
  createRangeQuery,
  useTimeseriesAggregate,
  useTimeseriesRange,
} from '~hooks/timeseries/queries';
import { useMeteringGroup } from '~hooks/useMeteringGroup';

export function useSiteChartData(meteringGroupId: string, range: DateTimeRange) {
  const {
    data: meteringGroup,
    isLoading: loadingMeteringGroup,
    error: meteringGroupError,
  } = useMeteringGroup(meteringGroupId);

  const shouldAggregateOverTime = differenceInMinutes(range.end, range.start) > 120;
  const aggregateTimeStepSeconds = getAggregageQueryTimeStepSeconds(range);

  const esus = meteringGroup?.esus;
  const resources = esus?.map((esu) => esu.resource_id) ?? [];

  const {
    data: aggregateData,
    isLoading: loadingAggregateData,
    error: aggregateDataError,
  } = useTimeseriesAggregate(
    createAggregateQuery({
      resources: shouldAggregateOverTime ? resources : [],
      columns: {
        esu_power_control: [
          'control_grid_f:mean',
          'control_grid_f:min',
          'control_grid_f:max',
          'esu_realized_power:mean',
          'esu_realized_power:min',
          'esu_realized_power:max',
        ],
      },
      start: range.start,
      end: range.end,
      step: `${aggregateTimeStepSeconds}s`,
    }),
  );

  const {
    data: rawData,
    isLoading: loadingRawData,
    error: rawDataError,
  } = useTimeseriesRange(
    createRangeQuery({
      resources: shouldAggregateOverTime ? [] : resources,
      columns: {
        esu_power_control: ['control_grid_f', 'esu_realized_power'],
      },
      start: range.start,
      end: range.end,
      fillStart: subMinutes(range.start, 1),
      fillEnd: addMinutes(range.end, 1),
    }),
    {
      fetchSerially: true,
    },
  );

  if (shouldAggregateOverTime) {
    const data = esus?.map((esu) => ({
      esu,
      data: aggregateData?.[esu.resource_id]?.esu_power_control,
    }));
    return {
      data: {
        isAggregatedOverTime: true,
        // TODO: There's currently no time series to get the grid frequency by metering group
        // so we pick an arbitrary ESU and fetch its control_grid_f instead.
        esu_power_control: data?.[0]?.data,
        esus: data,
      },
      isLoading: loadingMeteringGroup || loadingAggregateData,
      error: meteringGroupError ?? aggregateDataError,
    };
  } else {
    const data = esus?.map((esu) => ({
      esu,
      data: rawData?.[esu.resource_id]?.esu_power_control,
    }));
    return {
      data: {
        isAggregatedOverTime: false,
        // TODO: There's currently no time series to get the grid frequency by metering group
        // so we pick an arbitrary ESU and fetch its control_grid_f instead.
        esu_power_control: data?.[0]?.data,
        esus: data,
      },
      isLoading: loadingMeteringGroup || loadingRawData,
      error: meteringGroupError ?? rawDataError,
    };
  }
}
