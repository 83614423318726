import { useMemo } from 'react';
import type { MRT_RowData, MRT_TableOptions } from 'material-react-table';

import { useIsDesktop } from '~hooks/useIsDesktop';

import { SIDEBAR_MOBILE_HEIGHT, SIDEBAR_USER_HEIGHT } from '~layouts/AppLayout/SidebarLayout';
import { PAGE_LAYOUT_HEADER_HEIGHT } from '~layouts/PageLayout';

const TABLE_CONTAINER_HEADER_HEIGHT = 56;

export function useTableCommonProps<T extends MRT_RowData>() {
  const isDesktop = useIsDesktop();

  return useMemo<Partial<MRT_TableOptions<T>>>(
    () => ({
      initialState: {
        density: 'compact',
      },
      enableBottomToolbar: false,
      enableColumnActions: false,
      enableColumnResizing: true,
      enableDensityToggle: false,
      enableFacetedValues: true,
      enableFullScreenToggle: false,
      enablePagination: false,
      enableStickyFooter: true,
      enableStickyHeader: true,
      positionToolbarAlertBanner: 'none',
      muiTableContainerProps: {
        sx: {
          maxHeight: `calc(100vh - ${PAGE_LAYOUT_HEADER_HEIGHT}px - ${
            isDesktop ? 0 : SIDEBAR_MOBILE_HEIGHT
          }px - ${TABLE_CONTAINER_HEADER_HEIGHT}px - 1px)`,
        },
      },
      muiTablePaperProps: {
        sx: { borderRadius: 0 },
      },
      muiTableFooterCellProps: {
        sx: { height: SIDEBAR_USER_HEIGHT },
      },
    }),
    [isDesktop],
  );
}
