import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import type { User } from '~types';

import { getMe } from '~utils/user';

import { useLocalStorage } from '~hooks/useLocalStorage';
import { useAbortController } from '~hooks/useAbortController';

import { DevRoleSelector } from '~components/DevRoleSelector';

type AuthContextType = {
  user: User | null;
  login: (data: User) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useLocalStorage<User | null>('user', null);
  const [userUpdated, setUserUpdated] = useState(false);
  const navigate = useNavigate();
  const getSignal = useAbortController();

  const updateUserData = useCallback(async () => {
    const userData = await getMe(getSignal());
    setUser(userData);

    if (userData) Sentry.setUser({ id: userData.id });
  }, [setUser, getSignal]);

  useEffect(() => {
    if (!userUpdated && user !== null) {
      setUserUpdated(true);
      updateUserData();
    }
  }, [user, userUpdated, updateUserData]);

  const login = useCallback(
    (data: User) => {
      setUserUpdated(true);
      setUser(data);
      Sentry.setUser({ id: data.id });
      navigate('/', { replace: true });
    },
    [setUser, navigate],
  );

  const logout = useCallback(() => {
    setUserUpdated(true);
    setUser(null);
    Sentry.setUser(null);
    navigate('/login', { replace: true });
  }, [setUserUpdated, setUser, navigate]);

  const value = useMemo<AuthContextType>(() => ({ user, login, logout }), [user, login, logout]);

  return (
    <AuthContext.Provider value={value}>
      {import.meta.env.DEV === true && <DevRoleSelector user={user} setUser={setUser} />}
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
