import type React from 'react';
import { ErrorOutline } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  NativeSelect,
  Tooltip,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { endOfDay, startOfYear, subMinutes, subYears } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { asCactosError } from '~http';

import { type DateTimeRange, formatDateTime } from '~utils/time';

import { useDateTimePickerViewRenderers } from '~hooks/useDateTimePickerViewRenderers';
import { useOptimizerFleetRuns } from '~hooks/useOptimizerRuns';

import { useFleetsNavigation } from '~pages/fleets/hooks/useFleetsNavigation';
import { useFleetBreadcrumbs } from '~pages/fleets/hooks/useFleetBreadcrumbs';
import { FleetLayout } from '~pages/fleets/components/FleetLayout';

export function OptimizerFleetRuns() {
  const { fleetId } = useFleetsNavigation();
  const { optimizerRunId } = useParams();

  const navigate = useNavigate();
  useFleetBreadcrumbs();

  const [endTime, setEndTime] = useState<Date>(() => new Date());
  const range = useMemo<DateTimeRange>(
    () => ({ start: subMinutes(endTime, 30), end: endTime }),
    [endTime],
  );

  const { data, isLoading, error } = useOptimizerFleetRuns(fleetId, range.start, range.end);

  const resultsByRunID = useMemo(() => new Map((data ?? []).map((run) => [run.id, run])), [data]);

  const lastRunId = [...resultsByRunID.keys()].at(-1);

  useEffect(() => {
    if (optimizerRunId == null && lastRunId != null) {
      navigate(`./${lastRunId}`);
    }
  }, [optimizerRunId, lastRunId, navigate]);

  const handleSelectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const id = event.target.value;
    const run = resultsByRunID.get(id);
    if (run) {
      navigate(`./${run.id}`);
    }
  };

  const now = new Date();

  const viewRenderers = useDateTimePickerViewRenderers();

  return (
    <FleetLayout title="Optimizer Runs">
      <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" columnGap={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="End time"
            value={endTime}
            onChange={(value: Date | null) => {
              if (value !== null) {
                const date = new Date(value);
                date.setSeconds(0);
                date.setMilliseconds(0);
                setEndTime(date);
              }
            }}
            minDateTime={startOfYear(subYears(now, 10))}
            maxDateTime={endOfDay(now)}
            ampm={false}
            format="yyyy-MM-dd HH:mm"
            viewRenderers={viewRenderers}
            slotProps={{
              textField: {
                size: 'small',
              },
            }}
          />
        </LocalizationProvider>

        <FormControl>
          <InputLabel variant="standard" htmlFor="select-run">
            {isLoading ? 'Loading runs...' : 'Select Run'}
          </InputLabel>
          <NativeSelect
            value={resultsByRunID.has(optimizerRunId ?? '') ? optimizerRunId : 'none'}
            defaultChecked={false}
            inputProps={{ name: 'select run', id: 'select-run', style: { minWidth: '240px' } }}
            onChange={handleSelectionChange}
          >
            <option value="none" disabled>
              -
            </option>
            {(data ?? []).map((run) => (
              <option value={run.id} key={run.id}>
                {formatDateTime(run.run_time)}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
        {isLoading && <CircularProgress size={18} sx={{ ml: 1.5 }} />}
        {error != null && (
          <Tooltip
            title={'Error fetching optimizer runs: ' + asCactosError(error).message}
            placement="right"
          >
            <ErrorOutline color="error" fontSize="medium" sx={{ ml: 2 }} />
          </Tooltip>
        )}
      </Box>
      <Outlet />
    </FleetLayout>
  );
}
