import { useCallback, useMemo } from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Decimal from 'decimal.js';
import dayjs from 'dayjs';

import { type FetchHookData, type Region } from '~types';

import { asCactosError } from '~http';

import { currentTimeZone, formatDateTimeNoTimeZone } from '~utils/time';

import { useTimeseriesLatest } from '~hooks/timeseries';
import { useFleet } from '~hooks/useFleetList';
import { createLatestQuery } from '~hooks/timeseries/queries';
import { type AllocationRow, useAllocations } from '~hooks/useMultiMarketSnapshot';

export const CurrentStatusTable = ({
  now,
  fleetId,
  productId,
  reserveObjects,
}: {
  now: Date;
  fleetId: string;
  productId: string | null;
  reserveObjects: Region[];
}) => {
  const { data: fleet } = useFleet(fleetId);

  const {
    data: currentAllocation,
    isLoading: loadingAllocation,
    error: allocationError,
  } = useLatestAllocation(fleetId, now, productId);

  const {
    data: activationsResult,
    isLoading: loadingActivations,
    error: activationsError,
  } = useLatestActivations(reserveObjects, now);

  const activationInMw = useCallback(
    (reserveObjectId: string) => {
      if (!productId) return undefined;
      const row = activationsResult?.[`region:${reserveObjectId}`]?.fcr_reporting_region;
      if (!row) return undefined;
      const value = row[activationColumnName(productId)];
      const valueInMw = value != null ? new Decimal(value).div(1_000_000) : null;
      return { time: row.time, value: valueInMw };
    },
    [activationsResult, productId],
  );

  const totalActivationCapacity = useMemo(() => {
    const allActivations = reserveObjects.flatMap((reserveObject) => {
      const value = activationInMw(reserveObject.id)?.value;
      return value != null ? value : [];
    });
    return allActivations.length > 0
      ? allActivations.reduce((acc, num) => acc.plus(num), new Decimal(0))
      : null;
  }, [reserveObjects, activationInMw]);

  const isLoading = loadingAllocation || loadingActivations;

  return (
    <Box>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="subtitle1">Latest operation status</Typography>
        {isLoading && <CircularProgress size={16} />}
      </Stack>
      {allocationError != null && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Error loading allocation data: {asCactosError(allocationError).message}
        </Alert>
      )}
      {activationsError != null && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Error loading activation data: {asCactosError(activationsError).message}
        </Alert>
      )}
      <TableContainer sx={{ minWidth: 900 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Associated resource</TableCell>
              <TableCell>
                <div>Time</div>
                <div>({currentTimeZone()})</div>
              </TableCell>
              <TableCell>Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Latest allocation</TableCell>
              <TableCell>{fleet != null && `${fleet.id} (${fleet.name})`}</TableCell>
              <TableCell>
                {currentAllocation != null
                  ? formatDateTimeNoTimeZone(currentAllocation.start_time)
                  : 'N/A'}
              </TableCell>
              <TableCell>
                {currentAllocation != null &&
                new Decimal(currentAllocation.allocation_in_kw).isFinite()
                  ? `${new Decimal(currentAllocation.allocation_in_kw).div(1000).toFixed(1)} MW`
                  : 'N/A'}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Total reported maintained capacity</TableCell>
              <TableCell>{fleet != null && `${fleet.id} (${fleet.name})`}</TableCell>
              <TableCell>-</TableCell>
              <TableCell>
                {totalActivationCapacity?.isFinite() ? `${totalActivationCapacity} MW` : 'N/A'}
              </TableCell>
            </TableRow>

            {reserveObjects.map((reserveObject) => {
              const activation = activationInMw(reserveObject.id);
              return (
                <TableRow key={reserveObject.id}>
                  <TableCell>Reported maintained capacity</TableCell>
                  <TableCell>
                    {reserveObject.id} ({reserveObject.name})
                  </TableCell>
                  <TableCell>
                    {activation?.time != null ? formatDateTimeNoTimeZone(activation.time) : 'N/A'}
                  </TableCell>
                  <TableCell>
                    {activation?.value?.isFinite() ? `${activation.value} MW` : 'N/A'}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

function useLatestAllocation(
  fleetId: string,
  atTime: Date,
  productId: string | null,
): FetchHookData<AllocationRow> {
  const { data, isLoading, error, mutate } = useAllocations(fleetId, {
    start: dayjs.utc(atTime).startOf('hour').toDate(),
    end: dayjs.utc(atTime).startOf('hour').add(1, 'hour').toDate(),
  });
  const allocation = productId ? data?.[productId]?.data.at(-1) : undefined;
  return {
    data: allocation,
    isLoading,
    error,
    mutate,
  };
}

const useLatestActivations = (reserveObjects: Region[], atTime: Date) => {
  return useTimeseriesLatest(
    createLatestQuery({
      resources: reserveObjects.map(({ id }) => `region:${id}` as const),
      columns: {
        fcr_reporting_region: [
          'fcrn_maintained_cap_reported_w',
          'fcrd_up_maintained_cap_reported_w',
          'fcrd_down_maintained_cap_reported_w',
        ],
      },
      atTime,
      start: { hours: 1 },
    }),
  );
};

const activationColumnName = (productId: string) => {
  switch (productId) {
    case 'FI FCR-N':
      return 'fcrn_maintained_cap_reported_w';
    case 'FI FCR-D UP':
      return 'fcrd_up_maintained_cap_reported_w';
    case 'FI FCR-D DOWN':
      return 'fcrd_down_maintained_cap_reported_w';
    default:
      throw new Error(`Unknown product ID: ${productId}`);
  }
};
