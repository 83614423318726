import { useCallback, useState } from 'react';
import { Form, type FormikHelpers, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { http } from '~http';

import { getMe } from '~utils/user';

import { useAuth } from '~hooks/useAuth';
import { useAbortController } from '~hooks/useAbortController';

import { Terms } from '~pages/authentication/Terms';

import { Iconify } from '~components/Iconify';

type PostData = {
  email: string;
  password: string;
  mfa_token?: string;
  accept_terms?: string;
};

const TOKEN_LENGTH = 6;

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
  mfaToken: Yup.string()
    .max(TOKEN_LENGTH, `Token must have exactly ${TOKEN_LENGTH} digits`)
    .matches(/^[0-9]*$/, { message: 'Token must have only digits' }),
  acceptedTermId: Yup.string(),
});

type LoginSchemaType = Yup.InferType<typeof LoginSchema>;

export function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [mfaRequired, setMfaRequired] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userlessToken, setUserlessToken] = useState<string | null>(null);
  const { login, logout } = useAuth();
  const getSignal = useAbortController();

  const handleLogin = useCallback(
    async (values: LoginSchemaType, helpers: FormikHelpers<LoginSchemaType>) => {
      const postData: PostData = {
        email: values.email,
        password: values.password,
      };
      if (mfaRequired) {
        if (!values.mfaToken || values.mfaToken.length !== TOKEN_LENGTH) {
          helpers.setErrors({ mfaToken: `Token must have exactly ${TOKEN_LENGTH} digits` });

          return;
        }

        postData.mfa_token = values.mfaToken;
      }
      if (values.acceptedTermId) {
        postData.accept_terms = values.acceptedTermId;
      }

      setLoading(true);

      try {
        await http.post('/v1/login', postData);

        const userData = await getMe(getSignal());

        if (!userData) throw new Error('No user data');

        login(userData);
      } catch (error: any) {
        setLoading(false);

        const response = error.response ?? {};

        if (response.status === 403) {
          const reason = response.data?.reason;

          if (reason === 'mfa_token_required') {
            setMfaRequired(true);
            return;
          }
          if (reason === 'mfa_token_invalid') {
            helpers.setErrors({ mfaToken: 'Incorrect token supplied' });
            setMfaRequired(true);
            return;
          }
          if (reason === 'terms_not_accepted') {
            setTermsAccepted(false);
            setUserlessToken(error.response.data.token);
            return;
          }
          helpers.setErrors({ password: 'Something went wrong, please try again' });
        } else if (response.status === 401) {
          helpers.setErrors({
            password:
              'Invalid username or password. If you have just registered, make sure you have opened the confirmation link we sent you by email.',
          });
        } else {
          helpers.setErrors({ password: 'Something went wrong, please try again' });
        }
        logout();
      }
    },
    [mfaRequired, getSignal, login, logout],
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      mfaToken: '',
      acceptedTermId: '',
    },
    validationSchema: LoginSchema,
    // @ts-expect-error handleLogin has the right type
    onSubmit: handleLogin,
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  const acceptTerms = useCallback(
    (termId: string) => {
      setFieldValue('acceptedTermId', termId);
      setTermsAccepted(true);
      handleSubmit();
    },
    [setFieldValue, handleSubmit],
  );

  const handleTogglePassword = useCallback(() => {
    setShowPassword((show) => !show);
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Stack spacing={2}>
          {!mfaRequired && termsAccepted && (
            <>
              <TextField
                margin="dense"
                fullWidth
                autoComplete="username"
                type="email"
                label="Email address"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={(touched.email && errors.email) || ' '}
                autoFocus
              />

              <TextField
                margin="dense"
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword} edge="end">
                        <Iconify icon={showPassword ? 'mdi:eye-off-outline' : 'mdi:eye-outline'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={(touched.password && errors.password) || ' '}
              />
            </>
          )}
          {mfaRequired && (
            <TextField
              margin="dense"
              fullWidth
              type="text"
              label="Two-factor authentication token"
              {...getFieldProps('mfaToken')}
              error={touched.mfaToken && Boolean(errors.mfaToken)}
              helperText={(touched.mfaToken && errors.mfaToken) || ' '}
              autoFocus
              inputProps={{
                pattern: '[0-9]*',
                maxLength: TOKEN_LENGTH,
              }}
            />
          )}
          {!termsAccepted && <Terms acceptTerms={acceptTerms} token={userlessToken} />}
          {termsAccepted && (
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              disabled={!formik.isValid}
            >
              Login
            </LoadingButton>
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
}
