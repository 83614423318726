import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useOrganization } from '~hooks/useOrganization';

import { useOrganizationsNavigation } from '~pages/organizations/hooks/useOrganizationsNavigation';

export function useOrganizationBreadcrumbs() {
  const { organizationId } = useOrganizationsNavigation();
  const { data: organization } = useOrganization(organizationId);

  useRegisterBreadcrumbs({
    entityType: 'organization',
    entityId: organization?.id,
    label: organization?.human_name ?? null,
  });
}
