import { addMinutes, differenceInMinutes, subMinutes } from 'date-fns';

import { type DateTimeRange } from '~utils/time';
import { getAggregageQueryTimeStepSeconds } from '~utils/timeseries';

import {
  createAggregateQuery,
  createRangeQuery,
  useTimeseriesAggregate,
  useTimeseriesRange,
} from '~hooks/timeseries/queries';
import type { ResourceID } from '~hooks/timeseries';
import type { AggregateRow, Row } from '~hooks/timeseries/types';

export function useEnergyMeterMomentaryData(
  resourceId: string | null,
  range: DateTimeRange,
): {
  data:
    | {
        isAggregatedOverTime: false;
        energy_meter_momentary: Row<'energy_meter_momentary'>[] | undefined;
      }
    | {
        isAggregatedOverTime: true;
        energy_meter_momentary: AggregateRow<'energy_meter_momentary'>[] | undefined;
      };
  isLoading: boolean;
  error: any;
} {
  const shouldAggregateOverTime = differenceInMinutes(range.end, range.start) > 120;
  const aggregateTimeStepSeconds = getAggregageQueryTimeStepSeconds(range);

  const resources = resourceId != null ? [resourceId as ResourceID] : [];
  const {
    data: aggregateData,
    isLoading: loadingAggregateData,
    error: aggregateDataError,
  } = useTimeseriesAggregate(
    createAggregateQuery({
      resources: shouldAggregateOverTime ? resources : [],
      columns: {
        energy_meter_momentary: [
          'active_power:mean',
          'active_power:min',
          'active_power:max',
          'reactive_power:mean',
          'reactive_power:min',
          'reactive_power:max',
          'current_a:mean',
          'current_a:min',
          'current_a:max',
          'current_b:mean',
          'current_b:min',
          'current_b:max',
          'current_c:mean',
          'current_c:min',
          'current_c:max',
        ],
      },
      start: range.start,
      end: range.end,
      step: `${aggregateTimeStepSeconds}s`,
    }),
  );

  const {
    data: rawData,
    isLoading: loadingRawData,
    error: rawDataError,
  } = useTimeseriesRange(
    createRangeQuery({
      resources: shouldAggregateOverTime ? [] : resources,
      columns: {
        energy_meter_momentary: [
          'active_power',
          'reactive_power',
          'current_a',
          'current_b',
          'current_c',
        ],
      },
      start: range.start,
      end: range.end,
      fillStart: subMinutes(range.start, 1),
      fillEnd: addMinutes(range.end, 1),
    }),
  );

  if (shouldAggregateOverTime) {
    return {
      data: {
        isAggregatedOverTime: true,
        energy_meter_momentary:
          resourceId != null ? aggregateData?.[resourceId]?.energy_meter_momentary : undefined,
      },
      isLoading: loadingAggregateData,
      error: aggregateDataError,
    };
  } else {
    return {
      data: {
        isAggregatedOverTime: false,
        energy_meter_momentary:
          resourceId != null ? rawData?.[resourceId]?.energy_meter_momentary : undefined,
      },
      isLoading: loadingRawData,
      error: rawDataError,
    };
  }
}
