import useSWR, { preload } from 'swr';
import { useMemo } from 'react';

import type { ListEdgeController } from '~types';

import { fetcher } from '~http';

import { collator } from '~utils/localization';

export async function preloadEdgeControllerList() {
  try {
    return await preload('/v1/edge_controller', fetcher);
  } catch {
    return null;
  }
}

export function useEdgeControllerList(): {
  data: ListEdgeController[] | undefined;
  error: Error | undefined;
  isLoading: boolean;
} {
  const { data, error, isLoading } = useSWR<ListEdgeController[]>('/v1/edge_controller', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });

  const sortedData = useMemo(
    () => (data != null ? [...data].sort((a, b) => collator.compare(a.name, b.name)) : undefined),
    [data],
  );

  return {
    data: sortedData,
    error,
    isLoading,
  };
}

export function useEdgeController(edgeControllerId: string | null) {
  const { data, error, isLoading } = useSWR<ListEdgeController[]>('/v1/edge_controller', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });

  const edgeController = useMemo(() => {
    if (data == null) return undefined;
    if (edgeControllerId == null) return null;
    return data.find((esu) => esu.id === edgeControllerId) ?? null;
  }, [data, edgeControllerId]);

  return { data: edgeController, error, isLoading };
}
