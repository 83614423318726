import { Typography } from '@mui/material';

import { DashboardCard, DashboardCardHeader } from '~components/DashboardCard';

export type ESUChargeCardProps = {
  socPercentage: number | undefined | null;
  isLoading: boolean;
};

export function ESUChargeCard(props: ESUChargeCardProps) {
  const { socPercentage, isLoading } = props;

  if (isLoading && socPercentage == null) {
    return (
      <DashboardCard size="small">
        <DashboardCardHeader>State of charge</DashboardCardHeader>
      </DashboardCard>
    );
  }

  return (
    <DashboardCard size="small">
      <DashboardCardHeader>State of charge</DashboardCardHeader>
      <Typography variant="data" sx={{ fontSize: 24 }}>
        {socPercentage != null ? `${Math.round(socPercentage)} %` : 'N/A'}
      </Typography>
    </DashboardCard>
  );
}
