import { useOrganizationList } from '~hooks/useOrganizationList';
import { useTableEntities } from '~hooks/useTableEntities';

import { useOrganizationsNavigation } from '~pages/organizations/hooks/useOrganizationsNavigation';

import { SiblingsArrows } from '~components/siblings/SiblingsArrows';

export function OrganizationSiblingsArrows() {
  const { organizationId } = useOrganizationsNavigation();
  const { data: organizations = [] } = useOrganizationList();
  const { organizationEntities } = useTableEntities();

  return (
    <SiblingsArrows
      entityType="organization"
      entityId={organizationId}
      siblings={organizations}
      entities={organizationEntities}
    />
  );
}
