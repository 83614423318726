import { ESUControl as EsuControlV1 } from '~pages/esus/components/ESUControlContent';
import { ESULayout } from '~pages/esus/components/ESULayout';

export function ESUControl() {
  return (
    <ESULayout title="ESU Control">
      <EsuControlV1 />
    </ESULayout>
  );
}
