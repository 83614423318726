import type { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

import { useEdgeController } from '~hooks/useEdgeControllerList';

import { PageLayout } from '~layouts/PageLayout';
import { PageLayoutLoader } from '~layouts/PageLayout/PageLayoutLoader';

import { PRESERVE_SEARCH_TABS, TABS } from '~pages/controllers/constants';
import { useControllersNavigation } from '~pages/controllers/hooks/useControllersNavigation';
import { useControllerBreadcrumbs } from '~pages/controllers/hooks/useControllerBreadcrumbs';
import { createControllerPath } from '~pages/controllers/utils/createControllerPath';
import { NotFound } from '~pages/NotFound';

import { Tabs } from '~components/Tabs';
import { ErrorInfo } from '~components/ErrorInfo';

type Props = PropsWithChildren<{
  title: string;
}>;

export function ControllerLayout({ title, children }: Props) {
  const { edgeControllerId, tab } = useControllersNavigation();
  const { data: edgeController, isLoading, error } = useEdgeController(edgeControllerId);

  useControllerBreadcrumbs();

  if (!edgeController) {
    if (error) return <ErrorInfo error={error} />;
    if (isLoading) return <PageLayoutLoader />;

    return <NotFound />;
  }

  return (
    <PageLayout
      title={title}
      entityType="controller"
      entityId={edgeControllerId}
      entityName={edgeController?.name}
    >
      <Tabs
        tabs={TABS}
        preserveSearchTabs={PRESERVE_SEARCH_TABS}
        tab={tab}
        pathname={createControllerPath(edgeControllerId)}
      />
      <Box overflow="auto">
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          position="relative"
          sx={{ containerName: 'panel', containerType: 'inline-size' }}
        >
          {children}
        </Box>
      </Box>
    </PageLayout>
  );
}
