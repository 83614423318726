import { useMemo } from 'react';

import { getAggregageQueryTimeStepSeconds } from '~utils/timeseries';
import type { DateTimeRange } from '~utils/time';

import { createAggregateQuery, useTimeseriesAggregate } from '~hooks/timeseries/queries';

export function useFacilityPower(meteringGroupId: string | undefined, range: DateTimeRange) {
  const aggregateTimeStepSeconds = getAggregageQueryTimeStepSeconds(range, true);

  const { data, isLoading, error } = useTimeseriesAggregate(
    createAggregateQuery({
      resources: meteringGroupId != null ? [`metering_group:${meteringGroupId}`] : [],
      columns: {
        group_power: [
          'grid_active_power:mean',
          'esu_active_power:mean',
          'facility_active_power_corrected:mean',
        ],
      },
      start: range.start,
      end: range.end,
      step: `${aggregateTimeStepSeconds}s`,
    }),
  );

  const filteredData = useMemo(() => {
    const groupPower = data?.[`metering_group:${meteringGroupId}`]?.group_power;
    return {
      gridActivePower: groupPower?.flatMap((row) => {
        const value = row['grid_active_power:mean'];
        return value != null ? { time: row.time, 'grid_active_power:mean': value } : [];
      }),
      esuActivePower: groupPower?.flatMap((row) => {
        const value = row['esu_active_power:mean'];
        return value != null ? { time: row.time, 'esu_active_power:mean': value } : [];
      }),
      facilityActivePower: groupPower?.flatMap((row) => {
        const value = row['facility_active_power_corrected:mean'];
        return value != null
          ? { time: row.time, 'facility_active_power_corrected:mean': value }
          : [];
      }),
      timeStep: aggregateTimeStepSeconds,
    };
  }, [data, aggregateTimeStepSeconds, meteringGroupId]);

  return { data: filteredData, isLoading, error };
}
