import { useTableEntities } from '~hooks/useTableEntities';

import { useESUsNavigation } from '~pages/esus/hooks/useESUsNavigation';
import { useESUSiblings } from '~pages/esus/hooks/useESUSiblings';

import { SiblingsArrows } from '~components/siblings/SiblingsArrows';

export function ESUSiblingsArrows() {
  const { esuId } = useESUsNavigation();
  const esuSiblings = useESUSiblings(esuId);
  const { esuEntities } = useTableEntities();

  return (
    <SiblingsArrows
      entityType="esu"
      entityId={esuId}
      siblings={esuSiblings}
      entities={esuEntities}
    />
  );
}
