import { useMediaQuery } from '@mui/material';

import { useResponsive } from '~hooks/useResponsive';

export function useIsDesktop() {
  const viewportWideEnough = useResponsive('up', 'lg');
  const inputDeviceHasHover = useMediaQuery('@media (hover: hover)', { defaultMatches: true });

  return !!(viewportWideEnough && inputDeviceHasHover);
}
