import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import { Logo } from '~components/Logo';

export const LOGO_ONLY_LAYOUT_NAV_HEIGHT = 40 + 2 * 16;

export function LogoOnlyLayout() {
  return (
    <>
      <Box display="flex" alignItems="center" p={2} height={LOGO_ONLY_LAYOUT_NAV_HEIGHT}>
        <Logo />
      </Box>

      <Outlet />
    </>
  );
}
