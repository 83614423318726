import { Link } from 'react-router-dom';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { useMemo } from 'react';

import type { EntityType } from '~types';

import { createEntityPath } from '~utils/createEntityPath';

import { ListEmptyPlaceholder } from '~components/ListEmptyPlaceholder';

type Props = {
  entityType: EntityType;
  entityId: string;
  siblings: { id: string; name: string }[];
};

export function SiblingsList({ entityType, entityId, siblings }: Props) {
  const finalSiblings = useMemo(
    () => siblings.filter((sibling) => sibling.id !== entityId),
    [siblings, entityId],
  );

  if (!finalSiblings.length) {
    return <ListEmptyPlaceholder />;
  }

  return (
    <List disablePadding>
      {finalSiblings.map((sibling) => (
        <Link
          key={sibling.id}
          to={createEntityPath(entityType, sibling.id)}
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          <ListItemButton sx={{ padding: 0, paddingLeft: 2, paddingRight: 2 }}>
            <ListItemText primary={sibling.name} />
          </ListItemButton>
        </Link>
      ))}
    </List>
  );
}
