import { Events } from '~pages/controllers/components/Events';
import { ControllerLayout } from '~pages/controllers/components/ControllerLayout';

export function ControllerEvents() {
  return (
    <ControllerLayout title="Controller Events">
      <Events />
    </ControllerLayout>
  );
}
