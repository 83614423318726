import type { Tab } from '~pages/fleets/types';
import { useFleetsNavigation } from '~pages/fleets/hooks/useFleetsNavigation';
import { createFleetPath } from '~pages/fleets/utils/createFleetPath';
import { TABS } from '~pages/fleets/constants';

import { TabsRedirection } from '~components/TabsRedirection';

// Smart redirection for fleets index route
export function FleetRedirect() {
  const { fleetId, tab } = useFleetsNavigation();

  return (
    <TabsRedirection
      tabs={TABS}
      currentTab={tab}
      createPath={(tab: Tab) => createFleetPath(fleetId, tab)}
    />
  );
}
