import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, type FormikHelpers, FormikProvider, useFormik } from 'formik';
import { Box, Container, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import type { OrganizationInvite } from '~types';

import { plainHttp } from '~http';

import { useAbortController } from '~hooks/useAbortController';
import { useLocalStorage } from '~hooks/useLocalStorage';

import { Page } from '~components/Page';
import { Iconify } from '~components/Iconify';

const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(12, 'Password must be at least 12 characters long'),
  passwordConfirmation: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

type SignupSchemaType = Yup.InferType<typeof SignupSchema>;

export function Signup() {
  const { inviteId } = useParams();
  const [inviteData, setInviteData] = useState<OrganizationInvite | null>(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [, setHasSeenBanner] = useLocalStorage('has-seen-UiV2InfoBanner', false);
  const getSignal = useAbortController();
  const navigate = useNavigate();

  const fetchInvite = useCallback(async () => {
    try {
      const response = await plainHttp.get(`/v1/invite/${inviteId}`, { signal: getSignal() });

      setInviteData(response?.data ?? null);
    } catch (error) {
      console.error(error);

      navigate('/404');
    }
  }, [inviteId, getSignal, navigate]);

  const submit = useCallback(
    async (values: SignupSchemaType, helpers: FormikHelpers<SignupSchemaType>) => {
      let status = '';

      try {
        const response = await plainHttp.post(`/v1/invite/${inviteId}`, {
          password: values.password,
        });

        status = response?.data?.message || 'Check your email!';

        setIsSuccess(true);
        setHasSeenBanner(true);
      } catch (error: any) {
        const { response = {} } = error;

        if (response.status === 404 || response.status === 401) {
          status = 'Invite not found. Has it been used already?';
        } else if (response.status === 400) {
          status = response?.data?.message || 'Unexpected failure';
        } else {
          status = 'Unexpected failure';
        }
      }

      helpers.setStatus(status);
      helpers.setSubmitting(false);
    },
    [inviteId, setHasSeenBanner],
  );

  const handleShowPassword = useCallback(() => {
    setIsPasswordVisible((shown) => !shown);
  }, []);

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: SignupSchema,
    onSubmit: submit,
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    fetchInvite();
  }, [fetchInvite]);

  const passwordInputAdornment = (
    <InputAdornment position="end">
      <IconButton onClick={handleShowPassword} edge="end">
        <Iconify icon={isPasswordVisible ? 'mdi:eye-off-outline' : 'mdi:eye-outline'} />
      </IconButton>
    </InputAdornment>
  );

  if (!inviteData) return null;

  return (
    <Page title="Signup | Cactos Spine">
      <Container maxWidth="sm">
        <Box mb={2} textAlign="center">
          <Typography variant="h3" component="h1">
            Welcome to Cactos Spine!
          </Typography>
          {!isSuccess && (
            <Typography color="text.secondary" sx={{ mt: 1 }}>
              To create your account, please choose a secure password. If you need to use a
              different email address, let us know and we'll send a new invitation to that address.
            </Typography>
          )}
        </Box>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            {!isSuccess && (
              <>
                <TextField
                  disabled
                  fullWidth
                  margin="dense"
                  type="text"
                  label="Email"
                  value={inviteData?.email}
                  sx={{ mb: 3 }}
                />
                <TextField
                  fullWidth
                  margin="dense"
                  type={isPasswordVisible ? 'text' : 'password'}
                  label="New password"
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: passwordInputAdornment,
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={(touched.password && errors.password) || ' '}
                />
                <TextField
                  fullWidth
                  margin="dense"
                  type={isPasswordVisible ? 'text' : 'password'}
                  label="Confirm password"
                  {...getFieldProps('passwordConfirmation')}
                  InputProps={{
                    endAdornment: passwordInputAdornment,
                  }}
                  error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                  helperText={(touched.passwordConfirmation && errors.passwordConfirmation) || ' '}
                />
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!formik.dirty || !formik.isValid}
                  sx={{ mt: 1 }}
                >
                  Submit
                </LoadingButton>
              </>
            )}
            {formik.status && (
              <Typography fontWeight="bold" textAlign="center" sx={{ mt: 3 }}>
                {formik.status}
              </Typography>
            )}
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
