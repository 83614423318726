import { Pricing } from '~pages/sites/components/pricing/Pricing';
import { SiteLayout } from '~pages/sites/components/SiteLayout';

export function SitePricing() {
  return (
    <SiteLayout title="Site Pricing">
      <Pricing />
    </SiteLayout>
  );
}
