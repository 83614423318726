import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import type { OrganizationDetails } from '~types';

import { useMeteringGroupList } from '~hooks/useMeteringGroupList';
import { useIsRoutePermitted } from '~hooks/useIsRoutePermitted';
import { useEdgeControllerList } from '~hooks/useEdgeControllerList';

import { createOrganizationPath } from '~pages/organizations/utils/createOrganizationPath';

import { HierarchyLoading } from '~components/hierarchy/HierarchyLoading';
import { EntityIcon } from '~components/EntityIcon';
import { HierarchySite } from '~components/hierarchy/HierarchySite';
import { HierarchyController } from '~components/hierarchy/HierarchyController';
import { ListEmptyPlaceholder } from '~components/ListEmptyPlaceholder';

type Props = {
  organization: OrganizationDetails;
  paddingLeft?: number;
  isRoot?: boolean;
};

export function HierarchyOrganization({ organization, paddingLeft = 2, isRoot = false }: Props) {
  const { data: meteringGroups = [], isLoading: isLoadingMeteringGroups } = useMeteringGroupList();
  const { data: edgeControllers = [], isLoading: isLoadingEdgeControllers } =
    useEdgeControllerList();
  const isRoutePermitted = useIsRoutePermitted(createOrganizationPath(organization.id));

  const organizationMeteringGroups = useMemo(
    () =>
      meteringGroups.filter((meteringGroup) => meteringGroup.organization_id === organization.id),
    [meteringGroups, organization.id],
  );
  const organizationEdgeControllersWithoutMeteringGroup = useMemo(
    () =>
      edgeControllers.filter(
        (edgeController) =>
          edgeController.organization.id === organization.id && !edgeController.metering_group.id,
      ),
    [edgeControllers, organization.id],
  );

  if (isLoadingMeteringGroups || isLoadingEdgeControllers) return <HierarchyLoading />;

  if (isRoot || !isRoutePermitted) {
    if (
      isRoot &&
      !organizationMeteringGroups.length &&
      !organizationEdgeControllersWithoutMeteringGroup.length
    ) {
      return <ListEmptyPlaceholder />;
    }

    return (
      <>
        {organizationMeteringGroups.map((meteringGroup) => (
          <HierarchySite
            key={meteringGroup.id}
            meteringGroup={meteringGroup}
            paddingLeft={paddingLeft}
          />
        ))}
        {organizationEdgeControllersWithoutMeteringGroup.map((edgeController) => (
          <HierarchyController
            key={edgeController.id}
            edgeController={edgeController}
            paddingLeft={paddingLeft}
          />
        ))}
      </>
    );
  }

  return (
    <List disablePadding>
      <Link
        to={createOrganizationPath(organization.id)}
        style={{ color: 'inherit', textDecoration: 'none' }}
      >
        <ListItemButton sx={{ padding: 0, paddingLeft, paddingRight: 2 }}>
          <ListItemIcon>
            <EntityIcon entityType="organization" />
          </ListItemIcon>
          <ListItemText primary={organization.human_name} />
        </ListItemButton>
      </Link>
      {organizationMeteringGroups.map((meteringGroup) => (
        <HierarchySite
          key={meteringGroup.id}
          meteringGroup={meteringGroup}
          paddingLeft={paddingLeft + 2}
        />
      ))}
      {organizationEdgeControllersWithoutMeteringGroup.map((edgeController) => (
        <HierarchyController
          key={edgeController.id}
          edgeController={edgeController}
          paddingLeft={paddingLeft + 2}
        />
      ))}
    </List>
  );
}
