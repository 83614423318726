import { type PropsWithChildren, useCallback, useMemo } from 'react';
import { Box, Divider, styled } from '@mui/material';

import type { EntityType, PageLayoutAction } from '~types';

import { USER_ROLES } from '~constants/auth';

import { useDocumentTitle } from '~hooks/useDocumentTitle';
import { useSidebarWidth } from '~hooks/useSidebarWidth';
import { useUserHasRootRole } from '~hooks/useUserHasRootRole';
import { useIsDesktop } from '~hooks/useIsDesktop';

import { SIDEBAR_MOBILE_HEIGHT } from '~layouts/AppLayout/SidebarLayout';
import { Actions } from '~layouts/PageLayout/Actions';

import { FleetSiblingsArrows } from '~pages/fleets/components/FleetSiblingsArrows';
import { OrganizationSiblingsArrows } from '~pages/organizations/components/OrganizationSiblingsArrows';
import { SiteSiblingsArrows } from '~pages/sites/components/SiteSiblingsArrows';
import { ControllerSiblingsArrows } from '~pages/controllers/components/ControllerSiblingsArrows';
import { ESUSiblingsArrows } from '~pages/esus/components/ESUSiblingsArrows';

import { Breadcrumbs } from '~components/breadcrumbs/Breadcrumbs';

type Props = PropsWithChildren<{
  title?: string;
  entityId?: string;
  entityType?: EntityType;
  entityName?: string;
  hasDivider?: boolean;
  actions?: PageLayoutAction[];
}>;

export const PAGE_LAYOUT_HEADER_HEIGHT = 48;

const Header = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(0.5),
  height: PAGE_LAYOUT_HEADER_HEIGHT,
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  overflowY: 'auto',
  zIndex: theme.zIndex.drawer - 1,
  [theme.breakpoints.up('sm')]: {
    paddingRight: theme.spacing(2),
  },
}));

const Content = styled(Box)(() => ({
  flexGrow: 1,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden',
  overflowY: 'auto',
}));

export function PageLayout({
  title = '',
  entityType,
  entityId,
  entityName,
  hasDivider = false,
  actions = [],
  children,
}: Props) {
  useDocumentTitle(entityName ? `${entityName} | ${title}` : title);

  // Compute content dimensions
  const sidebarWidth = useSidebarWidth();
  const isDesktop = useIsDesktop();
  const contentMaxWidth = `calc(100vw - ${sidebarWidth}px)`;
  const dividerHeight = hasDivider ? 1 : 0;
  const contentMaxHeight = isDesktop
    ? `calc(100vh  - ${PAGE_LAYOUT_HEADER_HEIGHT}px - ${dividerHeight}px)`
    : `calc(100vh - ${PAGE_LAYOUT_HEADER_HEIGHT}px - ${SIDEBAR_MOBILE_HEIGHT}px - ${dividerHeight}px)`;

  const userCanCopyId = useUserHasRootRole(USER_ROLES.OPERATOR_VIEW);

  const handleCopyEntityId = useCallback(() => {
    if (!entityId) return;

    navigator.clipboard.writeText(entityId);
  }, [entityId]);

  const allActions = useMemo(
    () =>
      [
        ...actions,
        entityId && userCanCopyId
          ? {
              icon: 'mdi:content-copy',
              label: 'Copy ID',
              onClick: handleCopyEntityId,
            }
          : null,
      ].flatMap((action) => (action ? action : [])),
    [actions, entityId, userCanCopyId, handleCopyEntityId],
  );

  const siblingArrows = useMemo(() => {
    switch (entityType) {
      case 'fleet': {
        return <FleetSiblingsArrows />;
      }
      case 'organization': {
        return <OrganizationSiblingsArrows />;
      }
      case 'site': {
        return <SiteSiblingsArrows />;
      }
      case 'controller': {
        return <ControllerSiblingsArrows />;
      }
      case 'esu': {
        return <ESUSiblingsArrows />;
      }
      default: {
        return null;
      }
    }
  }, [entityType]);

  return (
    <>
      <Header>
        {/* top-align the <Breadcrumbs> if they don't vertically fit in the container */}
        <Box minHeight="100%" alignSelf="start" display="flex" alignItems="center">
          <Breadcrumbs />
        </Box>
        <Box display="flex" alignContent="center" gap={0.5} flexShrink={0}>
          {siblingArrows}
          <Actions actions={allActions} />
        </Box>
      </Header>
      {hasDivider && <Divider />}
      <Content maxWidth={contentMaxWidth} maxHeight={contentMaxHeight}>
        {children}
      </Content>
    </>
  );
}
