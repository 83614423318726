import { useTableEntities } from '~hooks/useTableEntities';

import { useSitesNavigation } from '~pages/sites/hooks/useSitesNavigation';
import { useSiteSiblings } from '~pages/sites/hooks/useSiteSiblings';

import { SiblingsArrows } from '~components/siblings/SiblingsArrows';

export function SiteSiblingsArrows() {
  const { meteringGroupId } = useSitesNavigation();
  const siteSiblings = useSiteSiblings(meteringGroupId);
  const { siteEntities } = useTableEntities();

  return (
    <SiblingsArrows
      entityType="site"
      entityId={meteringGroupId}
      siblings={siteSiblings}
      entities={siteEntities}
    />
  );
}
