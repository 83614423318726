import { Box } from '@mui/material';

import { useEdgeController } from '~hooks/useEdgeControllerList';
import { useURLRange } from '~hooks/useURLRange';
import { useEdgeControllerDiagnosticsData } from '~hooks/useDiagnosticsData';

import { useControllersNavigation } from '~pages/controllers/hooks/useControllersNavigation';

import { DiagnosticsPanel } from '~components/DiagnosticsPanel';
import { DateTimeRangePicker } from '~components/DateTimeRangePicker';
import { Page } from '~components/Page';
import { CopyLinkButton } from '~components/CopyLinkButton';
import { CopyableID } from '~components/CopyableID';
import { StickyBar } from '~components/StickyBar';

export function EdgeControllerDiagnostics() {
  const { edgeControllerId } = useControllersNavigation();
  const name = useEdgeController(edgeControllerId)?.data?.name ?? '';

  const [range, setRange] = useURLRange(2 * 3600);
  const atTime: Date | 'now' = range.now ? 'now' : range.end;

  const { data, isLoading, isValidating, error } = useEdgeControllerDiagnosticsData(
    edgeControllerId,
    atTime,
  );

  return (
    <Page title={`${name} Diagnostics`}>
      <Box px={2}>
        <StickyBar>
          <DateTimeRangePicker value={range} onChange={setRange} showSkipButtons={true} />
        </StickyBar>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="flex-start"
          justifyContent="space-between"
          marginBottom={1}
        >
          <CopyableID id={edgeControllerId} />
          <CopyLinkButton
            link={
              `${window.location.origin}${window.location.pathname}` +
              `?start=${range.start.toISOString()}&end=${range.end.toISOString()}`
            }
          />
        </Box>
        <DiagnosticsPanel
          data={data}
          isLoading={isLoading}
          isValidating={isValidating}
          error={error}
          viewingHistoricalData={atTime !== 'now'}
        />
      </Box>
    </Page>
  );
}
