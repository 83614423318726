import { useMemo } from 'react';

import { useOrganizationList } from '~hooks/useOrganizationList';

import { SiblingsList } from '~components/siblings/SiblingsList';

type Props = {
  id: string;
};

export function OrganizationSiblingsList({ id }: Props) {
  const { data: organizations = [] } = useOrganizationList();

  const siblings = useMemo(
    () =>
      organizations.map((organization) => ({
        id: organization.id,
        name: organization.human_name,
      })),
    [organizations],
  );

  return <SiblingsList entityType="organization" entityId={id} siblings={siblings} />;
}
