import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';

import { PageLayout } from '~layouts/PageLayout';

import { TimeSeriesExplorer } from '~pages/explorer/TimeSeriesExplorer';

export function Explorer() {
  useRegisterBreadcrumbs({ label: 'Timeseries Explorer' });

  return (
    <PageLayout hasDivider title="Timeseries Explorer">
      <TimeSeriesExplorer />
    </PageLayout>
  );
}
