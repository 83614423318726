import { Box, IconButton } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import type { Entity, EntityType } from '~types';

import { createEntityPath } from '~utils/createEntityPath';

import { Iconify } from '~components/Iconify';

type Props = {
  entityType: EntityType;
  entityId: string;
  siblings: Entity[];
  entities: Entity[];
};

export function SiblingsArrows({ entityType, entityId, siblings, entities }: Props) {
  const navigate = useNavigate();

  const handleEntityChange = useCallback(
    (entities: Entity[], delta: number) => {
      const currentIndex = entities.findIndex((entity) => entity.id === entityId);

      if (currentIndex === -1) return;

      const nextEntityId = entities[(currentIndex + delta + entities.length) % entities.length]?.id;

      if (!nextEntityId || nextEntityId === entityId) return;

      navigate(`${createEntityPath(entityType, nextEntityId)}${window.location.search}`);
    },
    [entityType, entityId, navigate],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (['INPUT', 'TEXTAREA'].includes((event.target as Element).tagName)) return;
      if (event.altKey || event.ctrlKey || event.metaKey) return;

      if (event.key === 'j') handleEntityChange(siblings, 1);
      if (event.key === 'J') handleEntityChange(entities, 1);
      if (event.key === 'k') handleEntityChange(siblings, -1);
      if (event.key === 'K') handleEntityChange(entities, -1);
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [siblings, entities, handleEntityChange]);

  // If there are less than 2 siblings, don't render the arrows as they are not needed
  if (siblings.length < 2) return null;

  return (
    <Box display="flex" gap={0.5}>
      <IconButton onClick={() => handleEntityChange(siblings, -1)}>
        <Iconify icon="mdi:chevron-left" />
      </IconButton>
      <IconButton onClick={() => handleEntityChange(siblings, 1)}>
        <Iconify icon="mdi:chevron-right" />
      </IconButton>
    </Box>
  );
}
