import { useEffect } from 'react';
import { useMatch, useParams } from 'react-router-dom';

import { useLocalStorage } from '~hooks/useLocalStorage';

import type { Tab } from '~pages/organizations/types';
import { ROUTER_MATCH_PATH } from '~pages/organizations/constants';

const DEFAULT_TAB: Tab = 'users';

export function useOrganizationsNavigation() {
  const { organizationId } = useParams();

  if (organizationId == null) {
    throw new Error('useOrganizationsNavigation must be used within a organization route');
  }

  const params = useMatch(ROUTER_MATCH_PATH)?.params;
  const [tabFromStorage, setTabFromStorage] = useLocalStorage<Tab>(
    'organizations.tab',
    DEFAULT_TAB,
  );
  const tab: Tab = (params?.tab as Tab | undefined) || tabFromStorage;

  useEffect(() => {
    setTabFromStorage(tab);
  }, [tab, setTabFromStorage]);

  return { organizationId, tab };
}
