import { useMeteringGroup } from '~hooks/useMeteringGroup';

import { HierarchySite } from '~components/hierarchy/HierarchySite';
import { HierarchyLoading } from '~components/hierarchy/HierarchyLoading';
import { ListEmptyPlaceholder } from '~components/ListEmptyPlaceholder';

type Props = {
  id: string;
};

export function SiteHierarchy({ id }: Props) {
  const { data: meteringGroup, isLoading } = useMeteringGroup(id);

  if (isLoading) return <HierarchyLoading />;
  if (!meteringGroup) return <ListEmptyPlaceholder />;

  return <HierarchySite isRoot meteringGroup={meteringGroup} />;
}
