import { useCallback, useState } from 'react';
import {
  Alert,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { parse } from 'csv-parse/browser/esm/sync';
import PropTypes from 'prop-types';

import { http } from '~http';

import { formatLocalTime } from '~utils/time';

export function BidUpload(props) {
  const [csvData, setCsvData] = useState([]);
  const [csvFile, setCsvFile] = useState();
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [inputValue, setInputValue] = useState('');
  const { fleetId, updateBids } = props;

  const handleFileSelect = (e) => {
    if (e?.target?.files?.[0] === undefined) {
      return;
    }
    const file = e.target.files[0];
    selectFile(file);
  };
  const selectFile = useCallback(
    (file) => {
      const { name } = file;
      setStatus(`Chosen file: ${name}`);
      setError('');

      const reader = new FileReader();
      reader.onload = (evt) => {
        if (!evt?.target?.result) {
          return;
        }
        const { result } = evt.target;
        const records = parse(result, {
          columns: true,
          delimiter: ',',
          trim: true,
          skip_empty_lines: true,
          strict: true,
        });
        setCsvData(records);
        setCsvFile(file);
      };
      reader.readAsBinaryString(file);
    },
    [setStatus, setCsvData, setCsvFile],
  );

  const handleFileUpload = () => {
    if (!fleetId) {
      return;
    }
    uploadFile();
  };

  const resetState = useCallback(() => {
    setCsvData([]);
    setCsvFile(null);
    setStatus('');
    setInputValue('');
  }, [setCsvData, setCsvFile, setStatus]);

  const uploadFile = useCallback(async () => {
    const formdata = new FormData();
    formdata.append('name', 'file');
    formdata.append('file', csvFile);
    try {
      await http.post(`/v1/fcr/bid/${fleetId}`, formdata, { timeout: 20_000 });
      resetState();
      setStatus('File uploaded!');
      setError('');
    } catch (ex) {
      const msg = ex.response.data?.message ?? ex;
      resetState();
      setStatus('');
      setError(`File upload failed: ${msg}`);
    } finally {
      updateBids();
    }
  }, [fleetId, csvFile, resetState, setStatus, updateBids]);

  return (
    <>
      <Button component="label" variant="contained" sx={{ marginRight: '1rem' }}>
        Select CSV
        <input type="file" accept=".csv" hidden onChange={handleFileSelect} value={inputValue} />
      </Button>
      {csvData.length > 0 && (
        <>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mt={5}>
            <Typography variant="h5" gutterBottom>
              Bids from CSV
            </Typography>
          </Stack>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table padding="none">
              <TableHead>
                <TableRow>
                  <TableCell key="start" align="left">
                    Start time
                  </TableCell>
                  <TableCell key="quantity" align="left">
                    Quantity
                    <br />
                    (FCR-N)
                  </TableCell>
                  <TableCell key="price" align="left">
                    Price
                    <br />
                    (FCR-N)
                  </TableCell>
                  <TableCell key="quantity" align="left">
                    Quantity
                    <br />
                    (FCR-D UP)
                  </TableCell>
                  <TableCell key="price" align="left">
                    Price
                    <br />
                    (FCR-D UP)
                  </TableCell>
                  <TableCell key="quantity" align="left">
                    Quantity
                    <br />
                    (FCR-D DOWN)
                  </TableCell>
                  <TableCell key="price" align="left">
                    Price
                    <br />
                    (FCR-D DOWN)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ minWidth: 800 }}>
                {csvData.map((row) => (
                  <TableRow hover key={row.start} tabIndex={-1} role="checkbox">
                    <TableCell key="start" align="left">
                      {formatLocalTime(row.start)}
                    </TableCell>
                    <TableCell key="quantity" align="left">
                      {row.quantity_fcr_n}
                    </TableCell>
                    <TableCell key="price" align="left">
                      {row.price_fcr_n}
                    </TableCell>
                    <TableCell key="quantity" align="left">
                      {row.quantity_fcr_d_up}
                    </TableCell>
                    <TableCell key="price" align="left">
                      {row.price_fcr_d_up}
                    </TableCell>
                    <TableCell key="quantity" align="left">
                      {row.quantity_fcr_d_down}
                    </TableCell>
                    <TableCell key="price" align="left">
                      {row.price_fcr_d_down}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {csvData.length > 0 && (
        <Button
          component="label"
          variant="contained"
          sx={{ marginRight: '1rem' }}
          onClick={handleFileUpload}
        >
          Upload CSV
        </Button>
      )}
      {error && <Alert severity="error">{error}</Alert>}
      {status && <Alert severity="success">{status}</Alert>}
    </>
  );
}

BidUpload.propTypes = {
  fleetId: PropTypes.string,
  updateBids: PropTypes.func,
};
