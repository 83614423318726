import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import { useDocumentTitle } from '~hooks/useDocumentTitle';

export function NotFound() {
  useDocumentTitle('Page not found');

  return (
    <Box
      mt={{ xs: -4, sm: -8 }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100%"
      textAlign="center"
    >
      <Typography variant="h3" paragraph>
        Page not found!
      </Typography>
      <Typography color="text.secondary">
        Sorry, we couldn't find the page you're looking for.
        <br />
        Perhaps you've mistyped the URL? Be sure to check your spelling.
      </Typography>

      <Box
        component="img"
        src="/static/illustrations/illustration_404.svg"
        sx={{ height: 260, my: { xs: 4, sm: 8 } }}
      />

      <Button to="/" size="large" variant="contained" component={Link}>
        Back to home
      </Button>
    </Box>
  );
}
