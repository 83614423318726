import { Typography } from '@mui/material';

import { IDLE_POWER_THRESHOLD } from '~utils/esu';

import { DashboardCard, DashboardCardHeader } from '~components/DashboardCard';

export type ESUPowerCardProps = {
  powerWatts: number | undefined | null; // in watts
  isLoading: boolean;
};

export function ESUPowerCard(props: ESUPowerCardProps) {
  const { powerWatts, isLoading } = props;

  if (isLoading && powerWatts == null) {
    return (
      <DashboardCard size="small">
        <DashboardCardHeader>Power</DashboardCardHeader>
      </DashboardCard>
    );
  }

  let chargingState = '';
  if (powerWatts != null) {
    if (powerWatts >= IDLE_POWER_THRESHOLD) {
      chargingState = 'charging';
    } else if (powerWatts <= -IDLE_POWER_THRESHOLD) {
      chargingState = 'discharging';
    }
  }

  const kilowatts = powerWatts == null ? 'N/A' : ` ${powerWatts / 1000} kW`;

  return (
    <DashboardCard size="small">
      <DashboardCardHeader>Power</DashboardCardHeader>
      <Typography variant="data" sx={{ fontSize: 24 }}>
        {kilowatts}{' '}
        <Typography display="inline" variant="body2">
          {chargingState}
        </Typography>
      </Typography>
    </DashboardCard>
  );
}
