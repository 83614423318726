import { useMemo, useState } from 'react';
import {
  type MRT_ColumnDef,
  type MRT_TableInstance,
  MaterialReactTable,
  useMRT_Rows,
  useMaterialReactTable,
} from 'material-react-table';
import { Box } from '@mui/material';

import type { Organization } from '~types';

import { USER_ROLES } from '~constants/auth';

import { collator } from '~utils/localization';

import { useOrganizationList } from '~hooks/useOrganizationList';
import { useTableColumnState } from '~hooks/useTableColumnState';
import { useUserHasRootRole } from '~hooks/useUserHasRootRole';
import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useSetTableEntities } from '~hooks/useTableEntities';
import { useTableCommonProps } from '~hooks/useTableCommonProps';
import { useGetTableBodyRowProps } from '~hooks/useGetTableBodyRowProps';

import { PageLayout } from '~layouts/PageLayout';

import { OrganizationCreateModal } from '~pages/organizations/components/OrganizationCreateModal';
import { createOrganizationPath } from '~pages/organizations/utils/createOrganizationPath';

import { MRTEmptyRowsFallback } from '~components/MRTEmptyRowsFallback';

const COLUMNS: MRT_ColumnDef<Organization>[] = [
  {
    header: 'Name',
    id: 'human_name',
    accessorFn: (row) => row.human_name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 400,
    Footer: TableFooter,
  },
];

export function Organizations() {
  const { data, error } = useOrganizationList();

  useRegisterBreadcrumbs({ label: 'Organizations' });

  const [isCreateOrganizationModalOpen, setIsCreateOrganizationModalOpen] = useState(false);
  const userCanCreateOrganization = useUserHasRootRole(USER_ROLES.CREATE_ORGANIZATION);

  const actions = useMemo(() => {
    if (!userCanCreateOrganization) return [];

    return [
      {
        icon: 'mdi:plus',
        label: 'Create organization',
        onClick: () => setIsCreateOrganizationModalOpen(true),
      },
    ];
  }, [userCanCreateOrganization]);

  const {
    columnSizing,
    columnFilters,
    columnVisibility,
    sorting,
    setColumnSizing,
    setColumnFilters,
    setColumnVisibility,
    setSorting,
  } = useTableColumnState('organizations');

  const tableCommonProps = useTableCommonProps<Organization>();
  const getTableBodyRowProps = useGetTableBodyRowProps(createOrganizationPath);
  const table = useMaterialReactTable<Organization>({
    ...tableCommonProps,
    data: data ?? [],
    columns: COLUMNS,
    state: {
      columnSizing,
      columnFilters,
      columnVisibility,
      sorting,
    },
    onColumnSizingChange: setColumnSizing,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => getTableBodyRowProps(row),
    renderEmptyRowsFallback: () => (
      <MRTEmptyRowsFallback error={error} noItemsMessage="No organizations to display" />
    ),
  });

  useSetTableEntities(
    'organization',
    table.getRowModel().rows.map((row) => ({ id: row.original.id })),
  );

  return (
    <>
      <PageLayout hasDivider title="Organizations" actions={actions}>
        <MaterialReactTable table={table} />
      </PageLayout>
      <OrganizationCreateModal
        open={isCreateOrganizationModalOpen}
        onClose={() => setIsCreateOrganizationModalOpen(false)}
      />
    </>
  );
}

function TableFooter({ table }: { table: MRT_TableInstance<Organization> }) {
  const rows = useMRT_Rows(table);

  return (
    <Box height="100%" display="flex" alignItems="center">
      {rows.length} organizations
    </Box>
  );
}
