import { useSiteSiblings } from '~pages/sites/hooks/useSiteSiblings';

import { SiblingsList } from '~components/siblings/SiblingsList';

type Props = {
  id: string;
};

export function SiteSiblingsList({ id }: Props) {
  const siteSiblings = useSiteSiblings(id);

  return <SiblingsList entityType="site" entityId={id} siblings={siteSiblings} />;
}
