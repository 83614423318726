import { useEdgeControllerList } from '~hooks/useEdgeControllerList';

import { SidebarItem } from '~layouts/AppLayout/SidebarItem';

import { Iconify } from '~components/Iconify';

const icon = <Iconify icon="mdi:server-network-outline" fontSize={20} />;

export function ControllersSidebarItem() {
  const { data: controllers } = useEdgeControllerList();

  if (controllers?.length === 1) {
    return (
      <SidebarItem path={`/controllers/${controllers[0].id}`} label="Controller" icon={icon} />
    );
  }

  return <SidebarItem path="/controllers" label="Controllers" icon={icon} />;
}
