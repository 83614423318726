import type { PropsWithChildren } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Alert, Box, Button } from '@mui/material';

import { ROOT_ORGANIZATION } from '~constants/auth';

import { useAuth } from '~hooks/useAuth';
import { useUserMfa } from '~hooks/useUserMfa';

export function ForceMfaBouncer({ children }: PropsWithChildren) {
  const { user } = useAuth();
  const { data: mfaData } = useUserMfa();
  const { pathname } = useLocation();

  if (!user || !mfaData) return children;

  const shouldForceMfa =
    pathname !== '/user' && user.roles[ROOT_ORGANIZATION] && !mfaData.mfa_enabled;

  if (!shouldForceMfa) return children;

  return (
    <Box
      position="fixed"
      top={0}
      bottom={0}
      left={0}
      right={0}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={2}
    >
      <Alert severity="error">You must enable 2FA to use this application.</Alert>
      <Link to="/user">
        <Button>Go to settings</Button>
      </Link>
    </Box>
  );
}
