import { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { useMemo } from 'react';
import { ErrorOutline } from '@mui/icons-material';
import dayjs from 'dayjs';

import type { Region } from '~types';

import { asCactosError } from '~http';

import type { DateTimeRange } from '~utils/time';
import { formatRange } from '~utils/time';
import { joinTimeseriesData } from '~utils/timeseries';

import type { AggregateQueryResult } from '~hooks/timeseries/types';
import { type AllocationsResult, useAllocations } from '~hooks/useMultiMarketSnapshot';
import { useTimeseriesAggregate } from '~hooks/timeseries';
import { createAggregateQuery } from '~hooks/timeseries/queries';
import { useNow } from '~hooks/useNow';

import { CurrentStatusTable } from '~pages/fleets/components/fcr/CurrentStatusTable';

import { useGraphRange } from '~components/charts/fcr-graph/hooks';
import {
  type AggregateActivationDataPoint,
  type AllocationDataPoint,
  FCRGraph,
} from '~components/charts/fcr-graph/FCRGraph';

const REFRESH_INTERVAL = 60_000;

export function FleetStatusCard({
  fleetId,
  reserveObjects,
}: {
  fleetId: string;
  reserveObjects: Region[];
}) {
  const now = useNow(REFRESH_INTERVAL);
  const range = useGraphRange();

  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const { data, isLoading, error } = useFleetStatusData(fleetId, reserveObjects, range);

  const products = useMemo(() => {
    if (!data.allocations) return [];
    return Object.keys(data.allocations);
  }, [data.allocations]);

  if (selectedProduct == null && products[0] != null) {
    setSelectedProduct(products[0]);
  }

  const activations = useMemo(
    () => getActivations(selectedProduct, reserveObjects, data.activations),
    [selectedProduct, reserveObjects, data.activations],
  );

  const allocations = useMemo(
    () => getMarketProductAllocations(selectedProduct, data.allocations, range),
    [data.allocations, selectedProduct, range],
  );

  return (
    <Card>
      <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <CardHeader
          title={
            <Stack alignItems="center" direction="row" gap={1}>
              <Box>Ancillary services status</Box>
              {isLoading && <CircularProgress size={12} />}
              {error != null && (
                <Tooltip
                  title={`Error loading data: ${asCactosError(error).message}`}
                  placement="right"
                >
                  <ErrorOutline color="error" fontSize="small" />
                </Tooltip>
              )}
            </Stack>
          }
          subheader={formatRange(range)}
        />
        <ToggleButtonGroup
          exclusive
          size="small"
          sx={{ flexShrink: 0, px: 2 }}
          value={selectedProduct}
          onChange={(_, value) => {
            if (value != null) setSelectedProduct(value);
          }}
        >
          {products.map((product) => (
            <ToggleButton key={product} value={product}>
              {product}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      <CardContent>
        <Stack gap={4}>
          <FCRGraph
            productDisplayName={selectedProduct ?? ''}
            allocations={allocations ?? []}
            activations={activations ?? []}
            now={now}
            range={range}
            reserveObjects={reserveObjects}
          />
          <CurrentStatusTable
            now={now}
            fleetId={fleetId}
            reserveObjects={reserveObjects}
            productId={selectedProduct}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}

const useFleetStatusData = (fleetId: string, reserveObjects: Region[], range: DateTimeRange) => {
  const {
    data: activations,
    isLoading: activationsLoading,
    error: activationsError,
  } = useAggregateActivations(reserveObjects, range);
  const {
    data: allocations,
    isLoading: allocationsLoading,
    error: allocationsError,
  } = useAllocations(fleetId, range);
  const isLoading = activationsLoading || allocationsLoading;
  const error = activationsError ?? allocationsError;
  const data = { activations, allocations };

  return { data, isLoading, error };
};

const getActivations = (
  product: string | null,
  reserveObjects: Region[],
  activationData: AggregateQueryResult | null,
): AggregateActivationDataPoint[] | undefined => {
  switch (product) {
    case 'FI FCR-N':
      return getProductAggregateActivation(
        reserveObjects,
        activationData,
        'fcrn_maintained_cap_reported_w:min',
      );
    case 'FI FCR-D UP':
      return getProductAggregateActivation(
        reserveObjects,
        activationData,
        'fcrd_up_maintained_cap_reported_w:min',
      );
    case 'FI FCR-D DOWN':
      return getProductAggregateActivation(
        reserveObjects,
        activationData,
        'fcrd_down_maintained_cap_reported_w:min',
      );
    default:
      return undefined;
  }
};
const getMarketProductAllocations = (
  productId: string | null,
  allocationData: AllocationsResult | undefined,
  range: DateTimeRange,
) => {
  if (!productId) return undefined;
  const allocations: AllocationDataPoint[] | undefined = allocationData?.[productId]?.data.map(
    (row) => ({
      time: new Date(row.start_time),
      quantity: parseFloat(row.allocation_in_kw) / 1000,
      duration: row.duration,
    }),
  );
  const last = allocations?.at(-1);
  if (last) {
    allocations!.push({
      time: dayjs
        .min(dayjs.utc(range.end), dayjs.utc(last.time).add(last.duration, 'seconds'))
        .toDate(),
      quantity: last.quantity,
      duration: 0,
    });
  }
  return allocations;
};

const AGGREGATE_ACTIVATION_COLUMNS = [
  'fcrn_maintained_cap_reported_w:min',
  'fcrd_up_maintained_cap_reported_w:min',
  'fcrd_down_maintained_cap_reported_w:min',
] as const;

type AggregateActivationColumn = (typeof AGGREGATE_ACTIVATION_COLUMNS)[number];

const useAggregateActivations = (reserveObjects: Region[], range: DateTimeRange) => {
  return useTimeseriesAggregate(
    createAggregateQuery({
      resources: reserveObjects.map((r) => `region:${r.id}` as const),
      columns: {
        fcr_reporting_region: [...AGGREGATE_ACTIVATION_COLUMNS],
      },
      start: range.start,
      end: range.end,
      step: '60s',
    }),
    { refreshInterval: REFRESH_INTERVAL },
  );
};

const getProductAggregateActivation = (
  reserveObjects: Region[],
  activationData: AggregateQueryResult | null,
  timeseriesColumn: AggregateActivationColumn,
): AggregateActivationDataPoint[] => {
  return reserveObjects
    .map((reserveObject) => {
      const data = activationData?.[`region:${reserveObject.id}`].fcr_reporting_region ?? [];
      return {
        columns: [reserveObject.id],
        data: data.map((row) => {
          const value = row[timeseriesColumn] ?? NaN;
          return {
            time: row.time,
            [reserveObject.id]: value / 1_000_000,
          };
        }),
      };
    })
    .reduce(
      (acc, current) => ({
        columns: [...acc.columns, ...current.columns],
        data: joinTimeseriesData(acc.data, acc.columns, current.data, current.columns) as any[],
      }),
      { columns: [], data: [] },
    )
    .data.map((row) => ({
      ...row,
      time: new Date(row.time),
    }));
};
