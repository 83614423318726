import { useTableEntities } from '~hooks/useTableEntities';

import { useControllersNavigation } from '~pages/controllers/hooks/useControllersNavigation';
import { useControllerSiblings } from '~pages/controllers/hooks/useControllerSiblings';

import { SiblingsArrows } from '~components/siblings/SiblingsArrows';

export function ControllerSiblingsArrows() {
  const { edgeControllerId } = useControllersNavigation();
  const controllerSiblings = useControllerSiblings(edgeControllerId);
  const { controllerEntities } = useTableEntities();

  return (
    <SiblingsArrows
      entityId={edgeControllerId}
      entityType="controller"
      siblings={controllerSiblings}
      entities={controllerEntities}
    />
  );
}
