import type { MeteringGroup } from '~types';

import { type DateTimeRange } from '~utils/time';

import type { AggregateRow, Row } from '~hooks/timeseries/types';

import { getEnergyMeterResourceId } from '~pages/sites/utils/getEnergyMeterResourceId';
import { useEnergyMeterMomentaryData } from '~pages/sites/hooks/useEnergyMeterMomentaryData';
import { useLegacyGridMeterData } from '~pages/sites/hooks/useLegacyGridMeterData';

export function useGridMeterData(
  meteringGroup: MeteringGroup | undefined,
  range: DateTimeRange,
): {
  data:
    | {
        isAggregatedOverTime: false;
        energy_meter_momentary: Row<'energy_meter_momentary'>[] | undefined;
      }
    | {
        isAggregatedOverTime: true;
        energy_meter_momentary: AggregateRow<'energy_meter_momentary'>[] | undefined;
      };
  isLoading: boolean;
  error: any;
} {
  const energyMeterResourceId = getEnergyMeterResourceId(meteringGroup);
  const energyMeterResult = useEnergyMeterMomentaryData(energyMeterResourceId, range);

  const shouldUseLegacyGridMeter = energyMeterResourceId == null;

  // Legacy query is only used if there's no sigle compatible energy meter resource.
  const legacyMeteringGroupId = shouldUseLegacyGridMeter ? meteringGroup?.id ?? null : null;
  const legacyResult = useLegacyGridMeterData(legacyMeteringGroupId, range);

  return shouldUseLegacyGridMeter ? legacyResult : energyMeterResult;
}
