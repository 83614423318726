import type { Tab } from '~pages/sites/types';
import { TABS } from '~pages/sites/constants';
import { useSitesNavigation } from '~pages/sites/hooks/useSitesNavigation';
import { createSitePath } from '~pages/sites/utils/createSitePath';

import { TabsRedirection } from '~components/TabsRedirection';

// Smart redirection for sites index route
export function SiteRedirect() {
  const { meteringGroupId, tab } = useSitesNavigation();

  return (
    <TabsRedirection
      tabs={TABS}
      currentTab={tab}
      createPath={(tab: Tab) => createSitePath(meteringGroupId, tab)}
    />
  );
}
