import { useMemo } from 'react';

import { useEdgeController, useEdgeControllerList } from '~hooks/useEdgeControllerList';

export function useControllerSiblings(id: string) {
  const { data: edgeController } = useEdgeController(id);
  const { data: edgeControllers = [] } = useEdgeControllerList();

  return useMemo(
    () =>
      edgeControllers.filter((ec) => ec.metering_group.id === edgeController?.metering_group.id),
    [edgeControllers, edgeController?.metering_group.id],
  );
}
