import {
  MONTHS,
  formatEffectivePeriodMonths,
  printEffectivePeriodMonths,
  validateEffectivePeriodMonths,
} from '~pages/sites/components/pricing/utils/effectivePeriod';
import { BaseRangePicker } from '~pages/sites/components/pricing/effectivePeriod/BaseRangePicker';

type Props = {
  values: number[];
  onChange: (values: number[]) => void;
};

export function MonthRangePicker({ values, onChange }: Props) {
  return (
    <BaseRangePicker
      values={values}
      onChange={onChange}
      label="Months"
      options={MONTHS}
      renderValue={printEffectivePeriodMonths}
      error={!validateEffectivePeriodMonths(formatEffectivePeriodMonths(values))}
    />
  );
}
