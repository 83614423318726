import useSWR, { preload } from 'swr';
import { useMemo } from 'react';

import type { FetchHookData, Region } from '~types';

import { fetcher } from '~http';

export async function preloadFleetList() {
  try {
    return await preload('/v1/region/fleet', fetcher);
  } catch {
    return null;
  }
}

export function useFleetList(): FetchHookData<Region[]> {
  return useSWR<Region[]>('/v1/region/fleet', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });
}
export function useFleet(fleetId: string | null) {
  const { data, error, isLoading } = useSWR<Region[]>('/v1/region/fleet', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });

  const fleet = useMemo(() => {
    if (data == null) return undefined;
    if (fleetId == null) return null;
    return data.find((fleet) => fleet.id === fleetId) ?? null;
  }, [data, fleetId]);

  return { data: fleet, error, isLoading };
}
