import { useMemo } from 'react';
import useSWR from 'swr';

import type { EdgeControllerType } from '~types';

import { fetcher } from '~http';

import { type DateTimeRange } from '~utils/time';

export type EdgeControllerEvent = {
  time: string;
  event_id: string;
  edge_controller_id: string;
  edge_controller_type: EdgeControllerType;
  resource_id: string;
  event_code: string;
  parameters: unknown;
  written_timestamp: string;
};

type EventResponse = {
  events: EdgeControllerEvent[];
  start_time: string;
  end_time: string;
};

export type EventTypeFilter = { select: string[] } | { ignore: string[] };

export const useEdgeControllerEvents = (
  edgeControllerId: string,
  range: DateTimeRange,
  filter: EventTypeFilter,
) => {
  const key = useMemo(() => {
    const params = new URLSearchParams({
      edge_controller_ids: edgeControllerId,
      start: range.start.toISOString(),
      end: range.end.toISOString(),
    });
    if ('select' in filter) {
      params.set('events', filter.select.join(','));
    } else if ('ignore' in filter) {
      params.set('ignore_events', filter.ignore.join(','));
    } else {
      throw new Error('Invalid filter type');
    }
    return `/v1/diagnostics/events?${params.toString()}`;
  }, [edgeControllerId, range.start, range.end, filter]);

  return useSWR<EventResponse>(key, { fetcher, revalidateOnFocus: false });
};
