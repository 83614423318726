import useSWR from 'swr';
import type { SWRConfiguration } from 'swr';
import useSWRImmutable from 'swr/immutable';

import type { DataFrame, FetchHookData, MultiMarketSnapshot } from '~types';

import { fetcher, http } from '~http';

import type { DateTimeRange } from '~utils/time';

async function fetchSnapshot(url: string): Promise<MultiMarketSnapshot> {
  const response = await http.get(url, {
    timeout: 30_000,
  });
  return response.data;
}

export function useMultiMarketSnapshot(
  delivery_period_id: string,
  at_time: string,
  fleet_name: string,
  config?: SWRConfiguration,
): FetchHookData<MultiMarketSnapshot> {
  const searchParams = new URLSearchParams({
    observation_time: at_time,
    include_capacities: 'false',
  });
  return useSWR<MultiMarketSnapshot>(
    `/v1/snapshot/${fleet_name}/${delivery_period_id}?${searchParams.toString()}`,
    {
      ...config,
      fetcher: fetchSnapshot,
    },
  );
}

export type AllocationRow = {
  start_time: string;
  end_time: string;
  duration: number;
  allocation_in_kw: string;
  price_per_kwh: string;
  currency: string | null;
  received_at: string | null;
};

export type AllocationsResult = {
  [productId: string]: DataFrame<AllocationRow>;
};

export function useAllocations(
  fleetId: string,
  range: DateTimeRange,
): FetchHookData<AllocationsResult> {
  const queryString = new URLSearchParams({
    start_time: range.start.toISOString(),
    end_time: range.end.toISOString(),
  }).toString();
  return useSWRImmutable(`/v1/fleet/${fleetId}/allocations?${queryString}`, fetcher);
}
