import type { EntityType } from '~types';

import { createFleetPath } from '~pages/fleets/utils/createFleetPath';
import { createOrganizationPath } from '~pages/organizations/utils/createOrganizationPath';
import { createSitePath } from '~pages/sites/utils/createSitePath';
import { createControllerPath } from '~pages/controllers/utils/createControllerPath';
import { createESUPath } from '~pages/esus/utils/createESUPath';

export function createEntityPath(entityType: EntityType, entityId: string) {
  switch (entityType) {
    case 'fleet':
      return createFleetPath(entityId);
    case 'organization':
      return createOrganizationPath(entityId);
    case 'site':
      return createSitePath(entityId);
    case 'controller':
      return createControllerPath(entityId);
    case 'esu':
      return createESUPath(entityId);
    default: {
      const unknownEntityType: never = entityType;

      throw new Error(`Unknown entity type: ${unknownEntityType}`);
    }
  }
}
