import {
  type MRT_ColumnDef,
  type MRT_TableInstance,
  MaterialReactTable,
  useMRT_Rows,
  useMaterialReactTable,
} from 'material-react-table';
import { Box } from '@mui/material';

import { type EdgeControllerType, type ListEdgeController } from '~types';

import { collator } from '~utils/localization';

import { useEdgeControllerList } from '~hooks/useEdgeControllerList';
import { useTableColumnState } from '~hooks/useTableColumnState';
import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useSetTableEntities } from '~hooks/useTableEntities';
import { useTableCommonProps } from '~hooks/useTableCommonProps';
import { useGetTableBodyRowProps } from '~hooks/useGetTableBodyRowProps';

import { PageLayout } from '~layouts/PageLayout';

import { createControllerPath } from '~pages/controllers/utils/createControllerPath';

import { MRTEmptyRowsFallback } from '~components/MRTEmptyRowsFallback';

const TYPE_DISPLAY_NAME: Map<EdgeControllerType, string> = new Map([
  ['ems', 'EMS'],
  ['ccu', 'CCU'],
  ['cmu', 'CMU'],
  ['legacy_cmu', 'Legacy CMU'],
  ['ccu_alpha', 'CCU Alpha'],
]);

const COLUMNS: MRT_ColumnDef<ListEdgeController>[] = [
  {
    header: 'Controller',
    id: 'name',
    accessorFn: (row) => row.name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 150,
    Footer: NameFooter,
  },
  {
    header: 'Type',
    id: 'type',
    accessorFn: (row) => TYPE_DISPLAY_NAME.get(row.type) ?? row.type,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 120,
    filterVariant: 'autocomplete',
  },
  {
    header: 'Site',
    id: 'metering_group',
    accessorFn: (row) => row.metering_group?.name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 200,
    filterVariant: 'autocomplete',
  },
  {
    header: 'Organization',
    id: 'organization',
    accessorFn: (row) => row.organization?.human_name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 200,
    filterVariant: 'autocomplete',
  },
];

export function Controllers() {
  const { data: edgeControllers, error } = useEdgeControllerList();

  useRegisterBreadcrumbs({ label: 'Controllers' });

  const {
    columnSizing,
    columnFilters,
    columnVisibility,
    sorting,
    setColumnSizing,
    setColumnFilters,
    setColumnVisibility,
    setSorting,
  } = useTableColumnState('controllers');

  const tableCommonProps = useTableCommonProps<ListEdgeController>();
  const getTableBodyRowProps = useGetTableBodyRowProps(createControllerPath);
  const table = useMaterialReactTable<ListEdgeController>({
    ...tableCommonProps,
    data: edgeControllers ?? [],
    columns: COLUMNS,
    state: {
      columnSizing,
      columnFilters,
      columnVisibility,
      sorting,
      columnPinning: {
        left: ['name'],
      },
    },
    onColumnSizingChange: setColumnSizing,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => getTableBodyRowProps(row),
    renderEmptyRowsFallback: () => (
      <MRTEmptyRowsFallback error={error} noItemsMessage="No controllers to display" />
    ),
  });

  useSetTableEntities(
    'controller',
    table.getRowModel().rows.map((row) => ({ id: row.original.id })),
  );

  return (
    <PageLayout hasDivider title="Controllers">
      <MaterialReactTable table={table} />
    </PageLayout>
  );
}

function NameFooter({ table }: { table: MRT_TableInstance<ListEdgeController> }) {
  const rows = useMRT_Rows(table);

  return (
    <Box height="100%" display="flex" alignItems="center">
      {rows.length} edge controllers
    </Box>
  );
}
