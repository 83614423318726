import {
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import useSWRImmutable from 'swr/immutable';
import Decimal from 'decimal.js';

import type { DataFrame } from '~types';

import { asCactosError, fetcher } from '~http';

import { formatMonth } from '~utils/time';

import { DashboardCard, DashboardCardHeader } from '~components/DashboardCard';

type MonthlyAllocationsRow = {
  start_time: string;
  localized_month: string;
  allocation_in_kw: string;
  revenue: string;
  currency: string;
};

type MonthlyAllocationsResult = {
  time_zone: string;
  data: {
    [product_id: string]: DataFrame<MonthlyAllocationsRow>;
  };
};

export type MonthlyAllocationsCardProps = {
  fleetId: string;
};

export function MonthlyAllocationsCard({ fleetId }: MonthlyAllocationsCardProps) {
  const { data, isLoading, error } = useSWRImmutable<MonthlyAllocationsResult>(
    `/v1/fleet/${fleetId}/allocations/monthly?months=3`,
    fetcher,
  );

  const productIds = Object.keys(data?.data ?? {});
  const monthlyAllocations: Record<string, Record<string, string>> = {};
  for (const [productId, dataFrame] of Object.entries(data?.data ?? {})) {
    for (const row of dataFrame.data) {
      monthlyAllocations[row.localized_month] ??= {};
      const value = new Decimal(row.revenue);
      monthlyAllocations[row.localized_month][productId] = `${value.toFixed(2)} ${row.currency}`;
    }
  }
  const months = Object.keys(monthlyAllocations).sort();
  return (
    <DashboardCard size="medium">
      <DashboardCardHeader>
        Monthly allocation values {isLoading && <CircularProgress size={16} sx={{ ml: 1 }} />}
      </DashboardCardHeader>
      {error != null && (
        <Alert severity="error">
          Error loading allocation data: {asCactosError(error).message}
        </Alert>
      )}
      <Table
        size="small"
        sx={{
          '& td, & th': { lineHeight: '1.5rem' },
          '& td:first-of-type, & th:first-of-type': { paddingLeft: 0 },
          '& tr:last-child td': { borderBottom: 'none' },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Month {data != null && <>({data.time_zone})</>}</TableCell>
            {productIds.map((productId) => (
              <TableCell key={productId} align="right">
                {productId}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {months.map((month) => (
            <TableRow key={month}>
              <TableCell>{formatMonth(month)}</TableCell>
              {productIds.map((productId) => (
                <TableCell key={productId} align="right">
                  <Typography variant="data">{monthlyAllocations[month][productId]}</Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DashboardCard>
  );
}
