import { useEffect } from 'react';

export function useDocumentTitle(title: string) {
  useEffect(() => {
    document.title = `${title} | Cactos`;
    return () => {
      document.title = 'Cactos';
    };
  }, [title]);
}
