import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import { useFleetsNavigation } from '~pages/fleets/hooks/useFleetsNavigation';
import { OptimizerGroupResultsView } from '~pages/v2/optimizer/OptimizerGroupResultsView';
import { OptimizerMarketProductResultsView } from '~pages/v2/optimizer/OptimizerMarketProductResultsView';

export function OptimizerFleetRun() {
  const { fleetId, optimizerRunId } = useFleetRunParams();
  return (
    <Box>
      <OptimizerGroupResultsView fleetId={fleetId} optimizerRunId={optimizerRunId} />
      <OptimizerMarketProductResultsView fleetId={fleetId} optimizerRunId={optimizerRunId} />
    </Box>
  );
}

function useFleetRunParams() {
  const { fleetId } = useFleetsNavigation();
  const { optimizerRunId } = useParams();
  if (!optimizerRunId) throw new Error('Missing optimizerRunId');
  return { fleetId, optimizerRunId };
}
