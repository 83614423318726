import { Box } from '@mui/material';

import { USER_ROLES } from '~constants/auth';

import { useURLRange } from '~hooks/useURLRange';
import { useESU } from '~hooks/useESUList';
import { useUserHasRole } from '~hooks/useUserHasRole';
import { useESUDiagnosticsData } from '~hooks/useDiagnosticsData';

import { useESUsNavigation } from '~pages/esus/hooks/useESUsNavigation';
import { TestCyclePanel } from '~pages/esus/components/TestCyclePanel';
import { ESUDiagnosticsCharts } from '~pages/esus/components/ESUDiagnosticsCharts';

import { DiagnosticsPanel } from '~components/DiagnosticsPanel';
import { Page } from '~components/Page';
import { DateTimeRangePicker } from '~components/DateTimeRangePicker';
import { CopyLinkButton } from '~components/CopyLinkButton';
import { CopyableID } from '~components/CopyableID';
import { StickyBar } from '~components/StickyBar';

export function ESUDiagnostics() {
  const { esuId } = useESUsNavigation();
  const { data: esu } = useESU(esuId);

  const userCanViewTestCyclePanel = useUserHasRole(USER_ROLES.FCR_PREQUALIFICATION_TESTING);

  const [range, setRange] = useURLRange(2 * 3600);
  const atTime: Date | 'now' = range.now ? 'now' : range.end;

  const { data, isLoading, isValidating, error } = useESUDiagnosticsData(esuId, atTime);

  return (
    <Page title={esu?.name ? `${esu?.name} Diagnostics` : 'Diagnostics'}>
      <Box px={2} pb={2}>
        <StickyBar>
          <DateTimeRangePicker value={range} onChange={setRange} showSkipButtons={true} />
        </StickyBar>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="flex-start"
          justifyContent="space-between"
          marginBottom={1}
        >
          <CopyableID id={esuId} />
          <CopyLinkButton
            link={
              `${window.location.origin}${window.location.pathname}` +
              `?start=${range.start.toISOString()}&end=${range.end.toISOString()}`
            }
          />
        </Box>
        {userCanViewTestCyclePanel && <TestCyclePanel esuID={esuId} />}
        <DiagnosticsPanel
          data={data}
          isLoading={isLoading}
          isValidating={isValidating}
          error={error}
          viewingHistoricalData={atTime !== 'now'}
        />
        <ESUDiagnosticsCharts range={range} atTime={atTime} setRange={setRange} />
      </Box>
    </Page>
  );
}
