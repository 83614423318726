import dayjs from 'dayjs';
import Decimal from 'decimal.js';

import {
  DEFAULT_ENERGY_TAX,
  DEFAULT_STOCKPILE_FEE,
  EXPORT_SPOT_MARGIN_NAME,
  EXPORT_TRANSFER_NAME,
  STRATEGIC_TAX_NAME,
  TIME_ZONE,
} from '~pages/sites/components/pricing/constants';
import type {
  Pricing,
  PurchaseModel,
  PurchaseTransferModel,
  SaleModel,
  SaleTransferModel,
} from '~pages/sites/components/pricing/types';
import { DEFAULT_EFFECTIVE_PERIOD } from '~pages/sites/components/pricing/utils/effectivePeriod';

export function extractPricing(pricing: Pricing) {
  const startTime = dayjs(pricing.start_time).tz(TIME_ZONE).toDate();

  const purchaseSpotItems = pricing.spot_items.filter((item) => item.direction === 'IMPORT');
  const purchasePricingItems = pricing.items.filter((item) => item.direction === 'IMPORT');

  const purchaseTransferItems = purchasePricingItems.filter((item) => item.category === 'TRANSFER');
  const purchaseEnergyItems = purchasePricingItems.filter((item) => item.category === 'ENERGY');
  const purchaseTransferTaxItems = purchasePricingItems.filter((item) => item.category === 'TAX');
  const purchaseTransferFeeItems = purchasePricingItems.filter((item) => item.category === 'FEE');

  const salesSpotItems = pricing.spot_items.filter((item) => item.direction === 'EXPORT');
  const salesPricingItems = pricing.items.filter((item) => item.direction === 'EXPORT');

  const salesEnergyItems = salesPricingItems.filter((item) => item.category === 'ENERGY');
  const salesTransferItems = salesPricingItems.filter((item) => item.category === 'TRANSFER');

  const purchaseTransferHigherPricingItem = purchaseTransferItems.find(
    (item) => !!item.effective_period,
  );
  const purchaseTransferLowerPricingItem = purchasePricingItems.find(
    (item) => !item.effective_period,
  );

  const purchaseModel: PurchaseModel = purchaseSpotItems.length > 0 ? 'spotMargin' : 'fixed';
  const purchaseTransferModel: PurchaseTransferModel =
    purchaseTransferItems.length > 1 ? 'variable' : 'fixed';

  const purchaseSpotFactor = purchaseSpotItems[0]?.factor ?? '1';
  const purchaseSpotMargin = purchaseEnergyItems[0]?.amount ?? '0';
  const purchaseFixedPrice = purchaseEnergyItems[0]?.amount ?? '0';
  let purchaseTransferHigher = purchaseTransferHigherPricingItem?.amount ?? '0';
  const purchaseTransferLower = purchaseTransferLowerPricingItem?.amount ?? '0';

  try {
    purchaseTransferHigher = new Decimal(purchaseTransferHigher)
      .plus(purchaseTransferLower)
      .toString();
  } catch (e) {
    //
  }

  const purchaseTransferFixedPrice = purchaseTransferHigher;

  const purchaseEffectivePeriod =
    purchaseTransferHigherPricingItem?.effective_period ?? DEFAULT_EFFECTIVE_PERIOD;

  const purchaseHasEnergyTax = purchaseTransferTaxItems.length > 0;
  const purchaseEnergyTax = purchaseTransferTaxItems[0]?.amount ?? DEFAULT_ENERGY_TAX;
  const purchaseStockpileFeeItem = purchaseTransferFeeItems.find(
    (item) => item.name === STRATEGIC_TAX_NAME,
  );
  const purchaseHasStockpileFee = !!purchaseStockpileFeeItem;
  const purchaseStockpileFee = purchaseStockpileFeeItem?.amount ?? DEFAULT_STOCKPILE_FEE;

  const purchaseCustomFeeItem = purchaseTransferFeeItems.find(
    (item) => item.name !== STRATEGIC_TAX_NAME,
  );
  const purchaseHasCustomFee = !!purchaseCustomFeeItem;
  const purchaseCustomFee = purchaseCustomFeeItem?.amount ?? '0';
  const purchaseCustomFeeDescription = purchaseCustomFeeItem?.name ?? '';

  const purchaseHasVat =
    purchaseSpotItems.some((item) => item.includes_vat) ||
    purchasePricingItems.some((item) => item.includes_vat);

  const salesModel: SaleModel = salesSpotItems.length > 0 ? 'spotMargin' : 'fixed';
  const salesTransferModel: SaleTransferModel =
    salesTransferItems.length === 0 ? 'noTransfer' : 'fixed';

  const salesSpotFactor = salesSpotItems[0]?.factor ?? '1';
  let salesSpotMargin = salesEnergyItems[0]?.amount ?? '0';
  let salesTransferFixedPrice = salesTransferItems[0]?.amount ?? '0';
  const salesEnergyDescription = salesEnergyItems[0]?.name ?? EXPORT_SPOT_MARGIN_NAME;
  const salesTransferDescription = salesTransferItems[0]?.name ?? EXPORT_TRANSFER_NAME;

  if (salesModel === 'spotMargin') {
    try {
      salesSpotMargin = new Decimal(salesSpotMargin).neg().toString();
    } catch (e) {
      //
    }
  }

  const salesFixedPrice = salesSpotMargin;

  try {
    salesTransferFixedPrice = new Decimal(salesTransferFixedPrice).neg().toString();
  } catch (e) {
    //
  }

  const salesHasVat =
    salesSpotItems.some((item) => item.includes_vat) ||
    salesPricingItems.some((item) => item.includes_vat);

  const isLegacyPricingAndMayCauseIssues =
    purchasePricingItems.filter((item) => !!item.effective_period).length > 1 ||
    purchaseSpotItems.length > 1 ||
    purchaseTransferItems.length > 2 ||
    purchaseEnergyItems.length > 1 ||
    purchaseTransferTaxItems.length > 1 ||
    purchaseTransferFeeItems.length > 2 ||
    salesSpotItems.length > 1 ||
    salesEnergyItems.length > 1 ||
    salesTransferItems.length > 1;

  return {
    startTime,
    purchaseModel,
    purchaseSpotFactor,
    purchaseSpotMargin,
    purchaseFixedPrice,
    purchaseTransferModel,
    purchaseTransferFixedPrice,
    purchaseTransferHigher,
    purchaseTransferLower,
    purchaseEffectivePeriod,
    purchaseHasEnergyTax,
    purchaseEnergyTax,
    purchaseHasStockpileFee,
    purchaseStockpileFee,
    purchaseHasCustomFee,
    purchaseCustomFee,
    purchaseCustomFeeDescription,
    purchaseHasVat,
    salesModel,
    salesTransferModel,
    salesSpotFactor,
    salesSpotMargin,
    salesFixedPrice,
    salesTransferFixedPrice,
    salesEnergyDescription,
    salesHasVat,
    salesTransferDescription,
    isLegacyPricingAndMayCauseIssues,
  };
}
