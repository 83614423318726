import { Alert, Box, Typography } from '@mui/material';

import { asCactosError } from '~http';

export type MRTEmptyRowsFallbackProps = {
  error: any;
  noItemsMessage: string;
};

export function MRTEmptyRowsFallback({ error, noItemsMessage }: MRTEmptyRowsFallbackProps) {
  return error != null ? (
    <Alert
      severity="error"
      sx={{ borderRadius: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      Error loading data: {asCactosError(error).message}
    </Alert>
  ) : (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography variant="body2">
        <i>{noItemsMessage}</i>
      </Typography>
    </Box>
  );
}
