import { addSeconds, subSeconds } from 'date-fns';
import { preload, useSWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';

import type { MarketProductResult } from '~types';

import { fetcher, http } from '~http';

import { createLatestForecastQuery, useTimeseriesLatestForecast } from '~hooks/timeseries/queries';

import type { RunDetails } from '~pages/v2/optimizer/types';

const ALGORITHM_ID = 'base';

export function useOptimizerFleetRuns(fleetId: string, startTime: Date, endTime: Date) {
  const { mutate } = useSWRConfig();
  const key = `/v1/optimizer/runs/${ALGORITHM_ID}/${fleetId}?start=${startTime.toISOString()}&end=${endTime.toISOString()}`;
  return useSWRImmutable<RunDetails[]>(key, {
    fetcher: fetchRuns,
    onSuccess: (data) => {
      data.forEach((run) => {
        // since we have the run data from the API that probably
        // shouldn't return the full JSON for each run, we might as well use it
        const key = `/v1/optimizer/run/${fleetId}/${run.id}/details.json`;
        mutate(key, run, { revalidate: false });
      });
    },
  });
}

export function useSingleOptimizerFleetRun(fleetId: string, runId: string) {
  const key = `/v1/optimizer/run/${fleetId}/${runId}/details.json`;
  return useSWRImmutable<RunDetails>(key, { fetcher: fetchSingleRun });
}

export function useOptimizerResults(algorithmId: string, startTime: Date, endTime: Date) {
  const { mutate } = useSWRConfig();
  const key = `/v1/optimizer/runs/${algorithmId}?start=${startTime.toISOString()}&end=${endTime.toISOString()}`;
  return useSWRImmutable<RunDetails[]>(key, {
    fetcher: fetchRuns,
    onSuccess: (data) => {
      data.forEach((run) => {
        // since we have the run data from the API that probably
        // shouldn't return the full JSON for each run, we might as well use it
        const key = `/v1/optimizer/run/${run.id}/details.json`;
        mutate(key, run, { revalidate: false });
      });
    },
  });
}

export function useSingleOptimizerResult(runID: string | null) {
  const key = runID ? `/v1/optimizer/run/${runID}/details.json` : null;
  return useSWRImmutable<RunDetails>(key, { fetcher: fetchSingleRun });
}

async function fetchSingleRun(url: string) {
  const response = await http.get(url);
  const run = response.data;
  assertIsRunDetails(run);
  return run;
}

async function fetchRuns(url: string) {
  const response = await http.get(url);
  const runs = response.data.details;
  for (const run of runs) {
    assertIsRunDetails(run);
  }
  return runs;
}

const assertIsRunDetails = (run: any): void => {
  const keys = new Set(Object.keys(run));
  if (
    !['groups', 'units', 'id', 'run_time', 'group_results'].every((key) => keys.has(key)) ||
    run.group_results == null
  ) {
    throw new Error('Invalid response');
  }
};

export function preloadMarketProductResults(fleetId: string, runID: string) {
  try {
    return preload(
      `/v1/optimizer/run/${fleetId}/${runID}/market-product-allocations.json`,
      fetcher,
    );
  } catch {
    return null;
  }
}

export function useMarketProductResults(fleetId: string, runID: string) {
  const key = `/v1/optimizer/run/${fleetId}/${runID}/market-product-allocations.json`;
  return useSWRImmutable<{
    results: { [region: string]: MarketProductResult };
  }>(key, { fetcher });
}

export function useOptimizerResultTimeseries(run: RunDetails) {
  const runTime = new Date(run.run_time);
  const atTime = addSeconds(runTime, 1);
  const start = subSeconds(runTime, 1);

  const {
    data: groupData,
    isLoading: groupDataLoading,
    error: groupDataError,
  } = useTimeseriesLatestForecast(
    createLatestForecastQuery({
      resources: run.groups.map((group_id) => `metering_group:${group_id}` as const),
      columns: {
        group_optimizer_result: [
          'duration',
          'import_price',
          'export_price',
          'start_energy_total',
          'end_energy_total',
          'end_energy_low_limit',
          'end_energy_high_limit',
          'energy_capacity_total',
          'fcrn_allocated_cap_total',
          'fcrd_up_allocated_cap_total',
          'fcrd_up_baseline_power_net',
          'fcrd_down_allocated_cap_total',
          'fcrd_down_baseline_power_net',
          'esu_total_net_energy',
          'constant_power_net',
          'esu_import_energy_max',
          'esu_export_energy_max',
          'facility_total_net_energy',
          'grid_total_net_energy',
          'esu_energy_profit',
        ],
      },
      atTime,
      start,
    }),
  );

  const {
    data: esuData,
    isLoading: esuDataLoading,
    error: esuDataError,
  } = useTimeseriesLatestForecast(
    createLatestForecastQuery({
      resources: run.units.map((esu_id) => `esu:${esu_id}` as const),
      columns: {
        esu_optimizer_result: [
          'start_energy',
          'end_energy',
          'end_energy_low_limit',
          'end_energy_high_limit',
          'energy_capacity',
          'fcrn_allocated_cap',
          'fcrd_up_allocated_cap',
          'fcrd_up_baseline_power',
          'fcrd_down_allocated_cap',
          'fcrd_down_baseline_power',
          'constant_power',
          'esu_import_energy_max',
          'esu_export_energy_max',
        ],
      },
      atTime,
      start,
    }),
  );

  return {
    groupData,
    groupDataLoading,
    groupDataError,
    esuData,
    esuDataLoading,
    esuDataError,
  };
}
