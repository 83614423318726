import type React from 'react';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  List,
  ListItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';

import { asCactosError } from '~http';

import { collator } from '~utils/localization';
import { formatDateTime } from '~utils/time';

import { useFleetESUList } from '~hooks/useESUList';
import { useFleetMeteringGroupList } from '~hooks/useMeteringGroupList';
import { useSingleOptimizerFleetRun } from '~hooks/useOptimizerRuns';

import { GroupView } from '~pages/v2/optimizer/GroupView';

type OptimizerGroupResultsViewProps = {
  fleetId: string;
  optimizerRunId: string;
};

export function OptimizerGroupResultsView({
  fleetId,
  optimizerRunId,
}: OptimizerGroupResultsViewProps) {
  const { isLoading, error } = useViewData(fleetId, optimizerRunId);

  // wrap mutable set in object to re-render on change
  const [openGroups, setOpenGroups] = useState({ set: new Set<string>() });
  if (isLoading)
    return (
      <Box>
        <Skeleton variant="rectangular" sx={{ height: 300 }} animation="wave" />
      </Box>
    );
  if (error)
    return (
      <Alert severity="error">
        {'Error fetching optimizer run: ' + asCactosError(error).message}
      </Alert>
    );
  return (
    <Box>
      <ResultViewHeader
        fleetId={fleetId}
        optimizerRunId={optimizerRunId}
        openGroups={openGroups}
        setOpenGroups={setOpenGroups}
      />
      <GroupList
        fleetId={fleetId}
        optimizerRunId={optimizerRunId}
        openGroups={openGroups}
        setOpenGroups={setOpenGroups}
      />
    </Box>
  );
}

function ResultViewHeader({
  fleetId,
  optimizerRunId,
  openGroups,
  setOpenGroups,
}: {
  fleetId: string;
  optimizerRunId: string;
  openGroups: { set: Set<string> };
  setOpenGroups: React.Dispatch<React.SetStateAction<{ set: Set<string> }>>;
}) {
  const { data, error } = useViewData(fleetId, optimizerRunId);
  const { groups, esus, run } = data;
  if (!run) return null;
  return (
    <Card sx={{ p: 2, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
        <Typography variant="h4" color="primary">
          Optimizer result at {formatDateTime(run.run_time)}
        </Typography>
        <Button
          onClick={() =>
            setOpenGroups(
              openGroups.set.size > 0 ? { set: new Set() } : { set: new Set(run.groups) },
            )
          }
        >
          {openGroups.set.size > 0 ? 'Collapse All' : 'Expand All'}
        </Button>
      </Stack>
      Run ID: <Typography variant="data">{run.id}</Typography>
      {error != null && (
        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
          Error loading data: {asCactosError(error).message}
        </Alert>
      )}
      <Typography>
        Optimizer disabled for {run.disabled_units.length} unit
        {run.disabled_units.length !== 1 && 's'}
        {run.disabled_units.length > 0 && (
          <>
            {': '}
            {run.disabled_units
              .slice(0, 10)
              .map((id) => esus.get(id)?.name ?? id)
              .join(', ')}
            {run.disabled_units.length > 10 && ', ..'}
          </>
        )}
        .
      </Typography>
      {Object.keys(run.init_errors).length > 0 && (
        <Box mt={2}>
          <h4>Optimizer failed for the following groups/units:</h4>
          <List>
            {Object.entries(run.init_errors).map(([groupOrEsuId, error]) => (
              <ListItem key={groupOrEsuId}>
                {groups.get(groupOrEsuId)?.name ?? esus.get(groupOrEsuId)?.name ?? groupOrEsuId}:{' '}
                {error}
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Card>
  );
}

function GroupList({
  fleetId,
  optimizerRunId,
  openGroups,
  setOpenGroups,
}: {
  fleetId: string;
  optimizerRunId: string;
  openGroups: { set: Set<string> };
  setOpenGroups: React.Dispatch<React.SetStateAction<{ set: Set<string> }>>;
}) {
  const { data } = useViewData(fleetId, optimizerRunId);
  const { groups, esus, run } = data;
  const sortedGroups = useMemo(() => {
    if (!run) return [];
    return [...run.groups].sort((a, b) =>
      collator.compare(groups.get(a)?.name ?? '', groups.get(b)?.name ?? ''),
    );
  }, [run, groups]);

  const handleChange = (id: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setOpenGroups(({ set }) => {
      if (isExpanded) set.add(id);
      else set.delete(id);
      // return new object containing the mutated set
      return { set };
    });
  };

  if (!run) return null;
  return sortedGroups.map((groupID) => (
    <Accordion
      key={groupID}
      expanded={openGroups.set.has(groupID)}
      onChange={handleChange(groupID)}
      slotProps={{ transition: { unmountOnExit: true } }}
    >
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        {groups.get(groupID)?.name ?? groupID}
      </AccordionSummary>
      <AccordionDetails>
        <GroupView group={run.group_results[groupID]} run={run} esusByID={esus} />
      </AccordionDetails>
    </Accordion>
  ));
}

const useViewData = (fleetId: string, optimizerRunId: string) => {
  const {
    data: groupList,
    error: groupListError,
    isLoading: groupListLoading,
  } = useFleetMeteringGroupList(fleetId);
  const {
    data: esuList,
    error: esuListError,
    isLoading: esuListLoading,
  } = useFleetESUList(fleetId);
  const {
    data: run,
    error: runError,
    isLoading: runLoading,
  } = useSingleOptimizerFleetRun(fleetId, optimizerRunId);
  const esusById = useMemo(() => new Map((esuList ?? []).map((esu) => [esu.id, esu])), [esuList]);
  const groupsById = useMemo(
    () => new Map((groupList ?? []).map((group) => [group.id, group])),
    [groupList],
  );
  const isLoading = groupListLoading || esuListLoading || runLoading;
  const error = groupListError ?? esuListError ?? runError;
  const data = { groups: groupsById, esus: esusById, run };
  return { data, error, isLoading };
};
