import { useCallback, useMemo } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Typography,
} from '@mui/material';

import { useFleetList } from '~hooks/useFleetList';

export const FLEET_FILTER_NO_FLEET_VALUE = '__NO_FLEET__';
const FLEET_FILTER_TOGGLE_ALL_FLEETS_VALUE = '__ALL_FLEETS__';

export type ESUsFleetFilterProps = {
  value: string[];
  onChange: (value: string[]) => void;
};

export function ESUsFleetFilter({ value, onChange: setValue }: ESUsFleetFilterProps) {
  const { data: fleets } = useFleetList();

  const { allSelected = false, someSelected = false } = useMemo(() => {
    if (fleets == null) return {};
    const selectedFleets = fleets.filter((fleet) => value.includes(fleet.id));
    return {
      allSelected: selectedFleets.length === fleets.length,
      someSelected: selectedFleets.length > 0,
    };
  }, [fleets, value]);

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const value = event.target.value;
      const values = typeof value === 'string' ? value.split(',') : value;
      if (values.includes(FLEET_FILTER_TOGGLE_ALL_FLEETS_VALUE)) {
        const newValues = allSelected ? [] : fleets?.map((fleet) => fleet.id) ?? [];
        if (values.includes(FLEET_FILTER_NO_FLEET_VALUE))
          newValues.push(FLEET_FILTER_NO_FLEET_VALUE);
        setValue(newValues);
      } else {
        setValue(values);
      }
    },
    [allSelected, fleets, setValue],
  );

  return (
    <FormControl variant="outlined" size="small" sx={{ width: 300 }}>
      <InputLabel shrink={true} id="fleet-filter-label">
        Filter by fleet
      </InputLabel>
      <Select
        labelId="fleet-filter-label"
        label="Filter by fleet"
        multiple
        value={value}
        onChange={handleSelectChange}
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <Typography color="text.secondary">All units</Typography>;
          }
          if (allSelected) {
            if (selected.includes(FLEET_FILTER_NO_FLEET_VALUE)) {
              return <Typography>All fleets & Not in fleet</Typography>;
            } else {
              return <Typography>All fleets</Typography>;
            }
          }

          return (
            <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {selected
                .map((value) => {
                  if (value === FLEET_FILTER_NO_FLEET_VALUE) return 'Not in fleet';
                  return fleets?.find((fleet) => fleet.id === value)?.name ?? value;
                })
                .join(', ')}
            </Box>
          );
        }}
      >
        <MenuItem
          value={FLEET_FILTER_TOGGLE_ALL_FLEETS_VALUE}
          sx={{ py: 0.5, px: 2 }}
          classes={allSelected ? { root: 'Mui-selected' } : {}}
        >
          <Checkbox
            size="small"
            checked={allSelected}
            indeterminate={someSelected && !allSelected}
          />
          <ListItemText>All fleets</ListItemText>
        </MenuItem>
        {(fleets ?? []).map((fleet) => (
          <MenuItem key={fleet.id} value={fleet.id} sx={{ py: 0.5, pr: 2, pl: 4 }}>
            <Checkbox size="small" checked={value.includes(fleet.id)} />
            <ListItemText>{fleet.name}</ListItemText>
          </MenuItem>
        ))}
        <MenuItem value={FLEET_FILTER_NO_FLEET_VALUE} sx={{ py: 0.5, px: 2 }}>
          <Checkbox size="small" checked={value.includes(FLEET_FILTER_NO_FLEET_VALUE)} />
          <ListItemText>Not in fleet</ListItemText>
        </MenuItem>
      </Select>
    </FormControl>
  );
}
