import { useFleetList } from '~hooks/useFleetList';

import { SiblingsList } from '~components/siblings/SiblingsList';

type Props = {
  id: string;
};

export function FleetSiblingsList({ id }: Props) {
  const { data: fleets = [] } = useFleetList();

  return <SiblingsList entityType="fleet" entityId={id} siblings={fleets} />;
}
