import { Link } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useMemo } from 'react';

import type { ListEdgeController } from '~types';

import { collator } from '~utils/localization';

import { useIsRoutePermitted } from '~hooks/useIsRoutePermitted';

import { createControllerPath } from '~pages/controllers/utils/createControllerPath';

import { EntityIcon } from '~components/EntityIcon';
import { HierarchyESU } from '~components/hierarchy/HierarchyESU';
import { ListEmptyPlaceholder } from '~components/ListEmptyPlaceholder';

type Props = {
  edgeController: ListEdgeController;
  paddingLeft?: number;
  isRoot?: boolean;
};

export function HierarchyController({ edgeController, paddingLeft = 2, isRoot = false }: Props) {
  const isRoutePermitted = useIsRoutePermitted(createControllerPath(edgeController.id));

  const controllerESUs = useMemo(
    () => [...edgeController.esus].sort((a, b) => collator.compare(a.name, b.name)),
    [edgeController.esus],
  );

  if (isRoot || !isRoutePermitted) {
    if (isRoot && !controllerESUs.length) {
      return <ListEmptyPlaceholder />;
    }

    return controllerESUs.map((esu) => (
      <HierarchyESU key={esu.id} esu={esu} paddingLeft={paddingLeft} />
    ));
  }

  return (
    <List disablePadding>
      <Link
        to={createControllerPath(edgeController.id)}
        style={{ color: 'inherit', textDecoration: 'none' }}
      >
        <ListItemButton sx={{ padding: 0, paddingLeft, paddingRight: 2 }}>
          <ListItemIcon>
            <EntityIcon entityType="controller" />
          </ListItemIcon>
          <ListItemText primary={edgeController.name} />
        </ListItemButton>
      </Link>
      {controllerESUs.map((esu) => (
        <HierarchyESU key={esu.id} esu={esu} paddingLeft={paddingLeft + 2} />
      ))}
    </List>
  );
}
