import { Box, Typography } from '@mui/material';

import { Logo } from '~components/Logo';

type Props = {
  error: Error;
};

export function ErrorInfo({ error }: Props) {
  if (import.meta.env.DEV) {
    return (
      <Box
        p={2}
        position="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={9999}
        bgcolor="Background"
        display="flex"
        flexDirection="column"
        gap={1}
      >
        <Typography variant="h6">An error occurred!</Typography>
        <pre>{error.stack ?? error.message ?? JSON.stringify(error)}</pre>
      </Box>
    );
  }

  return (
    <Box p={2} textAlign="center" display="flex" flexDirection="column" alignItems="center" gap={2}>
      <Logo />
      <Typography>
        An error occurred, please reload the page and contact support if the problem persists.
      </Typography>
      <Typography fontSize={14} color="InactiveCaptionText">
        Error info: {error.message ?? 'Unknown error'}
      </Typography>
    </Box>
  );
}
