import { Link } from 'react-router-dom';
import { Box, type BoxProps } from '@mui/material';

import cactosSymbol from '~assets/cactos-symbol.svg';

export function Logo(props: BoxProps) {
  return (
    <Box width={40} {...props}>
      <Link to="/">
        <img src={cactosSymbol} alt="Logo" />
      </Link>
    </Box>
  );
}
