import { collator } from '~utils/localization';

import { useESU } from '~hooks/useESUList';
import { useMeteringGroup } from '~hooks/useMeteringGroup';

export function useESUSiblings(id: string) {
  const { data: esu } = useESU(id);
  const { data: meteringGroup } = useMeteringGroup(esu?.edge_controller.metering_group.id ?? null);

  return [...(meteringGroup?.esus ?? [])].sort((a, b) => collator.compare(a.name, b.name));
}
