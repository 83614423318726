import { useCallback, useMemo } from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

import type { WizardStepProps } from '~pages/sites/components/pricing/types';
import { HourRangePicker } from '~pages/sites/components/pricing/effectivePeriod/HourRangePicker';
import { WeekdayRangePicker } from '~pages/sites/components/pricing/effectivePeriod/WeekdayRangePicker';
import { MonthRangePicker } from '~pages/sites/components/pricing/effectivePeriod/MonthRangePicker';
import {
  DEFAULT_EFFECTIVE_PERIOD,
  formatEffectivePeriodHours,
  formatEffectivePeriodMonths,
  formatEffectivePeriodWeekdays,
  parseEffectivePeriod,
} from '~pages/sites/components/pricing/utils/effectivePeriod';
import {
  PURCHASE_MODEL_OPTIONS,
  PURCHASE_TRANSFER_MODEL_OPTIONS,
} from '~pages/sites/components/pricing/constants';
import { handleInputWheel } from '~pages/sites/components/pricing/utils/handleInputWheel';
import { ErrorMessage } from '~pages/sites/components/pricing/wizard/ErrorMessage';

export function StepPurchase({ formik }: WizardStepProps) {
  const {
    weekdays: effectivePeriodWeekdays,
    hours: effectivePeriodHours,
    months: effectivePeriodMonths,
  } = useMemo(
    () => parseEffectivePeriod(formik.values.purchaseEffectivePeriod ?? DEFAULT_EFFECTIVE_PERIOD),
    [formik.values.purchaseEffectivePeriod],
  );

  const setEffectivePeriodWeekdays = useCallback(
    (weekdays: number[]) => {
      formik.setFieldValue(
        'purchaseEffectivePeriod',
        `${formatEffectivePeriodWeekdays(weekdays)};${formatEffectivePeriodHours(
          effectivePeriodHours,
        )};${formatEffectivePeriodMonths(effectivePeriodMonths)}`,
      );
    },
    [formik, effectivePeriodHours, effectivePeriodMonths],
  );

  const setEffectivePeriodHours = useCallback(
    (hours: number[]) => {
      formik.setFieldValue(
        'purchaseEffectivePeriod',
        `${formatEffectivePeriodWeekdays(effectivePeriodWeekdays)};${formatEffectivePeriodHours(
          hours,
        )};${formatEffectivePeriodMonths(effectivePeriodMonths)}`,
      );
    },
    [formik, effectivePeriodWeekdays, effectivePeriodMonths],
  );

  const setEffectivePeriodMonths = useCallback(
    (months: number[]) => {
      formik.setFieldValue(
        'purchaseEffectivePeriod',
        `${formatEffectivePeriodWeekdays(effectivePeriodWeekdays)};${formatEffectivePeriodHours(
          effectivePeriodHours,
        )};${formatEffectivePeriodMonths(months)}`,
      );
    },
    [formik, effectivePeriodHours, effectivePeriodWeekdays],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <FormLabel component="legend">Electricity Purchase Model</FormLabel>
          <RadioGroup
            row
            name="purchaseModel"
            value={formik.values.purchaseModel}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {Object.entries(PURCHASE_MODEL_OPTIONS).map(([key, label]) => (
              <FormControlLabel key={key} value={key} control={<Radio />} label={label} />
            ))}
          </RadioGroup>

          {formik.values.purchaseModel === 'spotMargin' && (
            <Box>
              <Box display="flex" gap={1}>
                <TextField
                  type="number"
                  size="small"
                  autoComplete="off"
                  label="Spot factor"
                  name="purchaseSpotFactor"
                  value={formik.values.purchaseSpotFactor}
                  error={!!formik.errors.purchaseSpotFactor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onWheel={handleInputWheel}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">x SPOT</InputAdornment>,
                  }}
                />
                <TextField
                  type="number"
                  size="small"
                  autoComplete="off"
                  label="Margin"
                  name="purchaseSpotMargin"
                  value={formik.values.purchaseSpotMargin}
                  error={!!formik.errors.purchaseSpotMargin}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onWheel={handleInputWheel}
                />
              </Box>
              <ErrorMessage formik={formik} name="purchaseSpotFactor" />
              <ErrorMessage formik={formik} name="purchaseSpotMargin" />
            </Box>
          )}

          {formik.values.purchaseModel === 'fixed' && (
            <Box>
              <TextField
                type="number"
                size="small"
                autoComplete="off"
                label="Fixed price"
                name="purchaseFixedPrice"
                value={formik.values.purchaseFixedPrice}
                error={!!formik.errors.purchaseFixedPrice}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onWheel={handleInputWheel}
              />
              <ErrorMessage formik={formik} name="purchaseFixedPrice" />
            </Box>
          )}
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ my: 1 }} />
      </Grid>

      <Grid item xs={8}>
        <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <FormLabel component="legend">Purchase Transfer</FormLabel>
          <RadioGroup
            row
            name="purchaseTransferModel"
            value={formik.values.purchaseTransferModel}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {Object.entries(PURCHASE_TRANSFER_MODEL_OPTIONS).map(([key, label]) => (
              <FormControlLabel key={key} value={key} control={<Radio />} label={label} />
            ))}
          </RadioGroup>

          <Box>
            {formik.values.purchaseTransferModel === 'fixed' && (
              <TextField
                size="small"
                type="number"
                autoComplete="off"
                label="Fixed price"
                name="purchaseTransferFixedPrice"
                value={formik.values.purchaseTransferFixedPrice}
                error={!!formik.errors.purchaseTransferFixedPrice}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onWheel={handleInputWheel}
                InputProps={{
                  endAdornment: <InputAdornment position="end">EUR/kWh</InputAdornment>,
                }}
              />
            )}

            {formik.values.purchaseTransferModel === 'variable' && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <FormLabel component="legend">Transfer higher</FormLabel>
                      <TextField
                        type="number"
                        size="small"
                        autoComplete="off"
                        label="Price"
                        name="purchaseTransferHigher"
                        value={formik.values.purchaseTransferHigher}
                        error={!!formik.errors.purchaseTransferHigher}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onWheel={handleInputWheel}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">EUR/kWh</InputAdornment>,
                        }}
                      />
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <FormLabel component="legend">Transfer lower</FormLabel>
                      <TextField
                        type="number"
                        size="small"
                        autoComplete="off"
                        label="Price"
                        name="purchaseTransferLower"
                        value={formik.values.purchaseTransferLower}
                        error={!!formik.errors.purchaseTransferLower}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onWheel={handleInputWheel}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">EUR/kWh</InputAdornment>,
                        }}
                      />
                    </Box>
                  </FormControl>
                </Grid>

                {/* Dates and times */}
                <Grid container item spacing={1}>
                  <Grid item xs={6}>
                    <WeekdayRangePicker
                      values={effectivePeriodWeekdays}
                      onChange={setEffectivePeriodWeekdays}
                    />
                  </Grid>

                  <Grid item xs={6} />

                  <Grid item xs={6}>
                    <HourRangePicker
                      values={effectivePeriodHours}
                      onChange={setEffectivePeriodHours}
                    />
                  </Grid>

                  <Grid item xs={6} />

                  <Grid item xs={6}>
                    <MonthRangePicker
                      values={effectivePeriodMonths}
                      onChange={setEffectivePeriodMonths}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Box>
          <ErrorMessage formik={formik} name="purchaseTransferFixedPrice" />
          <ErrorMessage formik={formik} name="purchaseTransferHigher" />
          <ErrorMessage formik={formik} name="purchaseTransferLower" />
          <ErrorMessage formik={formik} name="purchaseEffectivePeriod" />
        </FormControl>
      </Grid>

      <Grid item xs={3}>
        <FormControl
          sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '1em', gap: 0.5 }}
        >
          <FormLabel>Taxes and fees</FormLabel>

          {/* Energy Tax */}
          <FormControl sx={{ gap: 0.5 }}>
            <FormControlLabel
              label="Energy Tax"
              control={
                <Checkbox
                  name="purchaseHasEnergyTax"
                  checked={formik.values.purchaseHasEnergyTax}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              }
            />

            {formik.values.purchaseHasEnergyTax && (
              <>
                <TextField
                  type="number"
                  size="small"
                  autoComplete="off"
                  label="Price"
                  name="purchaseEnergyTax"
                  value={formik.values.purchaseEnergyTax}
                  error={!!formik.errors.purchaseEnergyTax}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onWheel={handleInputWheel}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">EUR/kWh</InputAdornment>,
                  }}
                />
                <ErrorMessage formik={formik} name="purchaseEnergyTax" />
              </>
            )}
          </FormControl>

          {/* Strategic stockpile fee */}
          <FormControl sx={{ gap: 0.5 }}>
            <FormControlLabel
              label="Strategic stockpile fee"
              control={
                <Checkbox
                  name="purchaseHasStockpileFee"
                  checked={formik.values.purchaseHasStockpileFee}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              }
            />

            {formik.values.purchaseHasStockpileFee && (
              <>
                <TextField
                  type="number"
                  size="small"
                  autoComplete="off"
                  label="Price"
                  name="purchaseStockpileFee"
                  value={formik.values.purchaseStockpileFee}
                  error={!!formik.errors.purchaseStockpileFee}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onWheel={handleInputWheel}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">EUR/kWh</InputAdornment>,
                  }}
                />
                <ErrorMessage formik={formik} name="purchaseStockpileFee" />
              </>
            )}
          </FormControl>

          {/* Custom fee */}
          <FormControl sx={{ gap: 0.5 }}>
            <FormControlLabel
              label="Custom fee"
              control={
                <Checkbox
                  name="purchaseHasCustomFee"
                  checked={formik.values.purchaseHasCustomFee}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              }
            />

            {formik.values.purchaseHasCustomFee && (
              <Box display="flex" flexDirection="column" gap={1}>
                <TextField
                  type="number"
                  size="small"
                  autoComplete="off"
                  label="Price"
                  name="purchaseCustomFee"
                  value={formik.values.purchaseCustomFee}
                  error={!!formik.errors.purchaseCustomFee}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onWheel={handleInputWheel}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">EUR/kWh</InputAdornment>,
                  }}
                />
                <TextField
                  size="small"
                  label="Description"
                  name="purchaseCustomFeeDescription"
                  value={formik.values.purchaseCustomFeeDescription}
                  error={!!formik.errors.purchaseCustomFeeDescription}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <ErrorMessage formik={formik} name="purchaseCustomFee" />
                <ErrorMessage formik={formik} name="purchaseCustomFeeDescription" />
              </Box>
            )}
          </FormControl>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ my: 1 }} />
      </Grid>

      <Grid item xs={12}>
        <FormControl>
          <FormControlLabel
            label="VAT included"
            control={
              <Checkbox
                name="purchaseHasVat"
                checked={formik.values.purchaseHasVat}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}
