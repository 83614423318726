import { useEdgeController } from '~hooks/useEdgeControllerList';

import { HierarchyController } from '~components/hierarchy/HierarchyController';
import { HierarchyLoading } from '~components/hierarchy/HierarchyLoading';
import { ListEmptyPlaceholder } from '~components/ListEmptyPlaceholder';

type Props = {
  id: string;
};

export function ControllerHierarchy({ id }: Props) {
  const { data: edgeController, isLoading } = useEdgeController(id);

  if (isLoading) return <HierarchyLoading />;
  if (!edgeController) return <ListEmptyPlaceholder />;

  return <HierarchyController isRoot edgeController={edgeController} />;
}
