import type { ExtendedEntityType } from '~types';

import { Iconify } from '~components/Iconify';

type Props = {
  entityType: ExtendedEntityType;
};

export function EntityIcon({ entityType }: Props) {
  switch (entityType) {
    case 'fleet':
      return <Iconify icon="mdi:sitemap" fontSize={16} />;
    case 'organization':
      return <Iconify icon="mdi:office-building" fontSize={16} />;
    case 'site':
      return <Iconify icon="mdi:home-lightning-bolt-outline" fontSize={16} />;
    case 'controller':
      return <Iconify icon="mdi:server-network-outline" fontSize={16} />;
    case 'esu':
      return <Iconify icon="mdi:battery-charging-50" fontSize={16} />;
    case 'other':
      return <Iconify icon="mdi:view-list" fontSize={16} />;
    default:
      return null;
  }
}
