import { useCallback, useEffect, useState } from 'react';
import { Alert, Card, CardContent, CardHeader, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { asCactosError } from '~http';

import type { DateTimeRange } from '~utils/time';

import { useTimeseriesRange } from '~hooks/timeseries';
import { createRangeQuery } from '~hooks/timeseries/queries';

import { BidUpload } from '~pages/fleets/components/fcr/BidUpload';
import { BidUploadForm } from '~pages/fleets/components/fcr/BidUploadForm';
import { getTimeRange } from '~pages/fleets/utils/getTimeRange';

type Props = {
  biddingDomain: string;
  fleetId: string;
};

export function UploadBids({ biddingDomain, fleetId }: Props) {
  const [tab, setTab] = useState<'form' | 'csv'>('form');
  const [range, setRange] = useState<DateTimeRange>(getTimeRange());

  const { data: rangeQueryResult, error: rangeQueryError } = useTimeseriesRange(
    createRangeQuery({
      resources: [`region:${biddingDomain}`],
      columns: {
        day_ahead_price: ['amount'],
      },
      start: range.start,
      end: range.end,
    }),
  );

  const dayAheadPrices = rangeQueryResult?.[`region:${biddingDomain}`]?.day_ahead_price;

  const refetchBidsAndAllocations: () => void = useCallback(() => {
    // TODO: Refetch snapshot / reset observation time
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setRange(getTimeRange());
    }, 60_000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Card>
      <CardHeader title="Upload bids" />
      <CardContent>
        {!!rangeQueryError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Error loading data: {asCactosError(rangeQueryError).message}
          </Alert>
        )}
        <TabContext value={tab}>
          <TabList onChange={(_, newTabValue) => setTab(newTabValue)}>
            <Tab label="Upload form" value="form" />
            <Tab label="Upload CSV" value="csv" />
          </TabList>
          <TabPanel value="csv">
            <BidUpload fleetId={fleetId} updateBids={refetchBidsAndAllocations} />
          </TabPanel>
          <TabPanel value="form">
            <BidUploadForm
              fleetId={fleetId}
              updateBids={refetchBidsAndAllocations}
              dayAheadPrices={dayAheadPrices ?? []}
            />
          </TabPanel>
        </TabContext>
      </CardContent>
    </Card>
  );
}
