import { TopologyEditor } from '~pages/sites/components/topology/TopologyEditor';
import { SiteLayout } from '~pages/sites/components/SiteLayout';

export function SiteTopology() {
  return (
    <SiteLayout title="Site Topology">
      <TopologyEditor />
    </SiteLayout>
  );
}
