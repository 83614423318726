import { useFleetList } from '~hooks/useFleetList';
import { useTableEntities } from '~hooks/useTableEntities';

import { useFleetsNavigation } from '~pages/fleets/hooks/useFleetsNavigation';

import { SiblingsArrows } from '~components/siblings/SiblingsArrows';

export function FleetSiblingsArrows() {
  const { fleetId } = useFleetsNavigation();
  const { data: fleets = [] } = useFleetList();
  const { fleetEntities } = useTableEntities();

  return (
    <SiblingsArrows
      entityType="fleet"
      entityId={fleetId}
      siblings={fleets}
      entities={fleetEntities}
    />
  );
}
