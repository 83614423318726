import { useOrganizationList } from '~hooks/useOrganizationList';

import { SidebarItem } from '~layouts/AppLayout/SidebarItem';

import { Iconify } from '~components/Iconify';

const icon = <Iconify icon="mdi:office-building" fontSize={20} />;

export function OrganizationsSidebarItem() {
  const { data: organizations } = useOrganizationList();

  if (organizations?.length === 1) {
    return (
      <SidebarItem
        path={`/organizations/${organizations[0].id}`}
        label="Organization"
        icon={icon}
      />
    );
  }

  return <SidebarItem path="/organizations" label="Organizations" icon={icon} />;
}
