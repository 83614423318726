import Decimal from 'decimal.js';

import {
  ENERGY_TAX_NAME,
  EXPORT_TRANSFER_NAME,
  IMPORT_SPOT_MARGIN_NAME,
  IMPORT_TRANSFER_FIXED_NAME,
  IMPORT_TRANSFER_HIGHER_NAME,
  IMPORT_TRANSFER_LOWER_NAME,
  STRATEGIC_TAX_NAME,
} from '~pages/sites/components/pricing/constants';
import type { PartialPricing, Pricing } from '~pages/sites/components/pricing/types';
import type { PricingWizardSchemaType } from '~pages/sites/components/pricing/wizard/PricingWizardSchema';

export function createPricing(
  partialPricing: PartialPricing,
  values: PricingWizardSchemaType,
): Pricing {
  const pricing: Pricing = {
    ...partialPricing,
    items: [],
    spot_items: [],
    start_time: values.startTime.toISOString(),
  };

  if (values.purchaseModel === 'spotMargin') {
    pricing.spot_items.push({
      direction: 'IMPORT',
      factor: values.purchaseSpotFactor ?? '0',
      includes_vat: values.purchaseHasVat,
    });
    pricing.items.push({
      category: 'ENERGY',
      direction: 'IMPORT',
      amount: values.purchaseSpotMargin ?? '0',
      includes_vat: values.purchaseHasVat,
      name: values.purchaseModel === 'spotMargin' ? IMPORT_SPOT_MARGIN_NAME : '',
    });
  }

  if (values.purchaseModel === 'fixed') {
    pricing.items.push({
      category: 'ENERGY',
      direction: 'IMPORT',
      amount: values.purchaseFixedPrice ?? '0',
      includes_vat: values.purchaseHasVat,
      name: '',
    });
  }

  if (values.purchaseTransferModel === 'fixed') {
    pricing.items.push({
      category: 'TRANSFER',
      direction: 'IMPORT',
      amount: values.purchaseTransferFixedPrice ?? '0',
      includes_vat: values.purchaseHasVat,
      name: IMPORT_TRANSFER_FIXED_NAME,
    });
  }

  if (values.purchaseTransferModel === 'variable') {
    let transferImportAmount = '0';

    try {
      transferImportAmount = new Decimal(values.purchaseTransferHigher ?? '0')
        .minus(values.purchaseTransferLower ?? '0')
        .toString();
    } catch (e) {
      //
    }

    pricing.items.push({
      category: 'TRANSFER',
      direction: 'IMPORT',
      amount: values.purchaseTransferLower ?? '0',
      includes_vat: values.purchaseHasVat,
      name: IMPORT_TRANSFER_LOWER_NAME,
    });
    pricing.items.push({
      category: 'TRANSFER',
      direction: 'IMPORT',
      amount: transferImportAmount,
      includes_vat: values.purchaseHasVat,
      name: IMPORT_TRANSFER_HIGHER_NAME,
      effective_period: values.purchaseEffectivePeriod,
    });
  }

  if (values.purchaseHasEnergyTax) {
    pricing.items.push({
      category: 'TAX',
      direction: 'IMPORT',
      amount: values.purchaseEnergyTax ?? '0',
      includes_vat: values.purchaseHasVat,
      name: ENERGY_TAX_NAME,
    });
  }

  if (values.purchaseHasStockpileFee) {
    pricing.items.push({
      category: 'FEE',
      direction: 'IMPORT',
      amount: values.purchaseStockpileFee ?? '0',
      includes_vat: values.purchaseHasVat,
      name: STRATEGIC_TAX_NAME,
    });
  }

  if (values.purchaseHasCustomFee) {
    pricing.items.push({
      category: 'FEE',
      direction: 'IMPORT',
      amount: values.purchaseCustomFee ?? '0',
      includes_vat: values.purchaseHasVat,
      name: values.purchaseCustomFeeDescription ?? '',
    });
  }

  if (values.salesModel === 'spotMargin') {
    let spotMarginAmount = '0';

    try {
      spotMarginAmount = new Decimal(values.salesSpotMargin ?? '0').neg().toString();
    } catch {
      //
    }

    pricing.spot_items.push({
      direction: 'EXPORT',
      factor: values.salesSpotFactor ?? '0',
      includes_vat: values.salesHasVat,
    });
    pricing.items.push({
      category: 'ENERGY',
      direction: 'EXPORT',
      amount: spotMarginAmount,
      includes_vat: values.salesHasVat,
      name: values.salesEnergyDescription ?? '',
    });
  }

  if (values.salesModel === 'fixed') {
    pricing.items.push({
      category: 'ENERGY',
      direction: 'EXPORT',
      amount: values.salesFixedPrice ?? '0',
      includes_vat: values.salesHasVat,
      name: EXPORT_TRANSFER_NAME,
    });
  }

  if (values.salesTransferModel === 'fixed') {
    let fixedTransferAmount = '0';

    try {
      fixedTransferAmount = new Decimal(values.salesTransferFixedPrice ?? '0').neg().toString();
    } catch {
      //
    }

    pricing.items.push({
      category: 'TRANSFER',
      direction: 'EXPORT',
      amount: fixedTransferAmount,
      includes_vat: values.salesHasVat,
      name: values.salesTransferDescription ?? '',
    });
  }

  return pricing;
}
