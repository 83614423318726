import {
  Alert,
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import dayjs from 'dayjs';

import {
  type ActiveControls,
  type ColumnDetail,
  ENERGY_MANAGEMENT_DETAILS,
  SCHEDULE_COLUMNS,
  type ScheduleControl,
  TEMP_CONTROL_DETAILS,
  isControlActiveAt,
} from '~constants/schedule';

import { formatLocalTime } from '~utils/time';

interface ControlTableProps<T> {
  control: T & ScheduleControl;
  details: ColumnDetail<T>[];
}

function Expiry(props: { control: ScheduleControl }) {
  const { control } = props;
  return (
    <Typography>
      Expires: {formatLocalTime(control.time.add(control.duration, 'seconds'))}
    </Typography>
  );
}

function ControlCard<T>(props: { title: string } & ControlTableProps<T>) {
  const { control, title, details } = props;
  return (
    <Card variant="outlined" sx={{ maxWidth: 300, margin: 0.5 }}>
      <CardHeader title={title} titleTypographyProps={{ variant: 'subtitle2' }} />
      <ControlTable control={control} details={details} />
    </Card>
  );
}

function ControlTable<T>(props: ControlTableProps<T>) {
  const { control, details } = props;
  return (
    <TableContainer sx={{ maxWidth: 300 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Expiry control={control} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Control</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {details.map((detail: ColumnDetail<T>) => (
            <TableRow key={detail.id.toString()}>
              <TableCell key={`${detail.id.toString()}-name`} align="left">
                {detail.label}
              </TableCell>
              <TableCell key={`${detail.id.toString()}-value`}>
                {`${detail.formatter(control[detail.id])}`}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface ScheduleControlsProps {
  // TODO: Allow activeControls to be undefined
  // and don't render "No Active Schedule Controls" in that case
  // (when data is still loading or errored)
  activeControls: ActiveControls;
  isLoading?: boolean;
  error?: any;
}

export function ScheduleControls(props: ScheduleControlsProps) {
  const now = dayjs();
  const { activeControls, isLoading = false, error } = props;

  const hasActiveControls =
    Object.values(activeControls).filter(
      (control) => control !== undefined && isControlActiveAt(control, now),
    ).length !== 0;

  return (
    <Alert severity={hasActiveControls ? 'info' : 'success'}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="subtitle2">
          {hasActiveControls ? 'Active Schedule Controls' : 'No Active Schedule Controls'}
        </Typography>
        {isLoading && <CircularProgress size="12px" />}
        {error != null && (
          <Tooltip title="Error loading active schedule controls" placement="right">
            <ErrorOutline color="error" sx={{ height: '16px' }} />
          </Tooltip>
        )}
      </Stack>
      {hasActiveControls && (
        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-around">
          {activeControls.manual && isControlActiveAt(activeControls.manual, now) && (
            <ControlCard
              title="Manual Control"
              control={activeControls.manual}
              details={SCHEDULE_COLUMNS}
            />
          )}
          {activeControls.temp && isControlActiveAt(activeControls.temp, now) && (
            <ControlCard
              title="Temperature Control"
              control={activeControls.temp}
              details={TEMP_CONTROL_DETAILS}
            />
          )}
          {activeControls.energyManagement &&
            isControlActiveAt(activeControls.energyManagement, now) && (
              <ControlCard
                title="FCR-N Energy Management"
                control={activeControls.energyManagement}
                details={ENERGY_MANAGEMENT_DETAILS}
              />
            )}
        </Box>
      )}
    </Alert>
  );
}
