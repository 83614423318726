import { EdgeControllerDetailPage } from '~pages/controllers/components/EdgeControllerDetailPage';
import { ControllerLayout } from '~pages/controllers/components/ControllerLayout';

export function ControllerSettings() {
  return (
    <ControllerLayout title="Controller Settings">
      <EdgeControllerDetailPage />
    </ControllerLayout>
  );
}
