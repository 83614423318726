import { OrganizationLayout } from '~pages/organizations/components/OrganizationLayout';
import { OrganizationUserList } from '~pages/organizations/components/OrganizationUserList';

export function OrganizationUsers() {
  return (
    <OrganizationLayout title="Users">
      <OrganizationUserList />
    </OrganizationLayout>
  );
}
