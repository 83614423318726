import { type PaletteOptions } from '@mui/material/styles';

// --- Chart colors ---
const single = '#2d99ff';
const categorical2 = ['#2d99ff', '#ffe700'];
const categorical3 = ['#2d99ff', '#ffe700', '#d681d0'];
const categorical4 = ['#2d99ff', '#ffe700', '#d681d0', '#4daf4a'];
const categorical5 = ['#2d99ff', '#ffe700', '#d681d0', '#4daf4a', '#e41a1c'];

const categorical10 = [
  '#e41a1c',
  '#4daf4a',
  '#984ea3',
  '#ff7f00',
  '#ffff33',
  '#a65628',
  '#f781bf',
  '#377eb8',
  '#999999',
  '#d12771',
];
const categorical14 = [
  '#8a3ffc',
  '#33b1ff',
  '#007d79',
  '#ff7eb6',
  '#fa4d56',
  '#fff1f1',
  '#6fdc8c',
  '#4589ff',
  '#d12771',
  '#d2a106',
  '#08bdba',
  '#bae6ff',
  '#ba4e00',
  '#d4bbff',
];

const gray = '#BBBBBB';

const area = '#9B9B9B';

const coldHot = {
  cold: '#3734eb',
  hot: '#fa8e00',
  area,
};

const validity = {
  good: '#72d34c',
  bad: '#d34c4c',
};

// --------------------

export const palette: PaletteOptions = {
  mode: 'dark',
  common: { black: '#000', white: '#fff' },
  primary: {
    main: '#72d34c',
  },
  secondary: {
    main: '#212606',
  },
  chart: {
    single,
    coldHot,
    validity,
    gray,
    categorical2,
    categorical3,
    categorical4,
    categorical5,
    categorical10,
    categorical14,
  },
} as const;
