import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import type React from 'react';
import { useCallback, useMemo, useState } from 'react';

import type {
  ESUDetails,
  ESUDetailsWithGroupMembership,
  ESUWithController,
  MeteringGroup,
} from '~types';

import { asCactosError, http } from '~http';

import { USER_ROLES } from '~constants/auth';

import { collator } from '~utils/localization';
import { formatDateTime } from '~utils/time';

import { useMeteringGroupList } from '~hooks/useMeteringGroupList';
import { useESUList } from '~hooks/useESUList';
import { useUserHasRole } from '~hooks/useUserHasRole';

import { useSitesNavigation } from '~pages/sites/hooks/useSitesNavigation';

import { Iconify } from '~components/Iconify';

export function GroupMemberList() {
  const { meteringGroupId } = useSitesNavigation();
  const meteringGroupList = useMeteringGroupList();
  const canAddEsuToGroup = useUserHasRole(USER_ROLES.EDIT_EMS);
  const theme = useTheme();

  const [moveEsuDialogOpen, setMoveEsuDialogOpen] = useState(false);

  const onGroupMemberAdded = useCallback(() => {
    // Refresh the group list to reflect the changes.
    meteringGroupList.mutate();
  }, [meteringGroupList]);

  const meteringGroup = meteringGroupList.data?.find((group) => group.id === meteringGroupId);
  const esus = useMemo(() => {
    if (meteringGroup?.esus == null) return [];
    return [...meteringGroup.esus].sort((a, b) => collator.compare(a.name, b.name));
  }, [meteringGroup]);

  if (!meteringGroup) return null;

  return (
    <>
      <List sx={{ paddingX: 1, background: theme.palette.background.paper }}>
        {esus.map((esu) => (
          <ListItem key={esu.id}>
            <ListItemButton component={Link} to={`/esus/${esu.id}`}>
              <ListItemAvatar>
                <Avatar>
                  <Iconify icon="mdi:battery-charging-50" />
                </Avatar>
              </ListItemAvatar>
              <EsuGroupConfiguration group={meteringGroup} esu={esu} />
            </ListItemButton>
          </ListItem>
        ))}
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }} />
        <ListItem>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Iconify icon="mdi:plus" />}
            onClick={(_: React.MouseEvent) => setMoveEsuDialogOpen(true)}
            sx={{ marginLeft: 2 }}
            disabled={!canAddEsuToGroup}
          >
            Add ESU to Group...
          </Button>
        </ListItem>
      </List>
      {moveEsuDialogOpen && (
        <MoveEsuDialog
          group={meteringGroup}
          closeDialog={() => setMoveEsuDialogOpen(false)}
          onGroupMemberAdded={onGroupMemberAdded}
        />
      )}
    </>
  );
}

function EsuGroupConfiguration({
  group,
  esu,
}: {
  group: MeteringGroup;
  esu: ESUDetailsWithGroupMembership;
}) {
  // If the ESU is a Cactos One EMS unit, it can have a grid meter connected to it.
  const hasGridMeter = group.grid_meters.some((meter) => meter.resource_id === esu.resource_id);
  return (
    <ListItemText
      primary={esu.name}
      secondary={
        <>
          Since {formatDateTime(esu.group_membership_start)}
          {hasGridMeter && (
            <Chip label="has grid meter" size="small" sx={{ marginLeft: 1 }} component="span" />
          )}
        </>
      }
    />
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export function MoveEsuDialog({
  group,
  closeDialog,
  onGroupMemberAdded,
}: {
  group: MeteringGroup;
  closeDialog: () => void;
  onGroupMemberAdded?: (emsId: string) => void;
}) {
  const { data: esus, refetch: refetchESUList } = useESUList();
  const esuOptions = useMemo(
    () => esus?.filter((esu) => esu.edge_controller.metering_group.id === null) ?? [],
    [esus],
  );
  const [selectedEsu, setSelectedEsu] = useState<ESUWithController | undefined>(undefined);
  const [name, setName] = useState(getDefaultName(group, esus ?? []));
  const [hasGridMeter, setHasGridMeter] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleClose = () => {
    closeDialog();
  };
  const handleSave = async () => {
    if (!selectedEsu) {
      return;
    }
    setError(undefined);
    try {
      await http.post(`/v1/ems/${selectedEsu.id}/transfer`, {
        name,
        organization: group.organization,
        metering_group_id: group.id,
        has_grid_meter: hasGridMeter,
      });
      // Refresh the ESU list to reflect the changes.
      refetchESUList();
      // Tell the parent component to refresh the group list.
      onGroupMemberAdded?.(selectedEsu.id);
      closeDialog();
    } catch (e) {
      const error = asCactosError(e);
      setError(error.message);
    }
  };

  let nameHelperText = '';
  let nameError = false;
  if (name === '') {
    nameHelperText = 'Name is required';
    nameError = true;
  } else if (
    esus?.some((esu) => esu.name.toLowerCase() === name.toLowerCase() && esu.id !== selectedEsu?.id)
  ) {
    nameHelperText = 'Name already in use';
    nameError = true;
  }

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="dialog-title" open>
      <DialogTitle sx={{ m: 0, p: 2 }} id="dialog-title">
        Add ESU to Group
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="mdi:close" />
      </IconButton>
      <DialogContent dividers>
        <Autocomplete
          value={selectedEsu}
          onChange={(_, value) => setSelectedEsu(value)}
          options={esuOptions}
          getOptionLabel={(option) => option.name}
          sx={{ minWidth: 300, marginBottom: 2 }}
          renderInput={(params: any) => <TextField {...params} label="ESU" variant="standard" />}
          disableClearable
        />
        <TextField
          label="New Name for ESU"
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ minWidth: 300, marginBottom: 2 }}
          error={nameError}
          helperText={nameHelperText}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value={hasGridMeter}
                onChange={(e) => {
                  setHasGridMeter(e.target.checked);
                }}
              />
            }
            label="Has grid meter"
          />
        </FormGroup>
        <Box
          sx={{
            marginTop: 2,
            padding: 1,
            borderRadius: 1,
            background: 'rgba(0, 0, 0, 0.2)',
            opacity: selectedEsu ? 1 : 0,
            transition: 'opacity 0.4s',
          }}
        >
          <Typography variant="caption" sx={{ color: 'text.secondary', marginBottom: 1 }}>
            Preview of changes to the ESU:
          </Typography>
          <Table
            size="small"
            sx={{
              borderRadius: 1,
              '& td, & th': { lineHeight: '1.5rem' },
              '& td:first-of-type, & th:first-of-type': { paddingLeft: 0 },
              '& tr:last-child td': { borderBottom: 'none' },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} sx={{ fontWeight: 'bold' }}>
                  Group
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{selectedEsu?.edge_controller.metering_group.name ?? '-'} </TableCell>
                <TableCell>→</TableCell>
                <TableCell>{group.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} sx={{ fontWeight: 'bold' }}>
                  ESU name
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{selectedEsu?.name}</TableCell>
                <TableCell>→</TableCell>
                <TableCell>{name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} sx={{ fontWeight: 'bold' }}>
                  Organization
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{selectedEsu?.edge_controller.organization.human_name}</TableCell>
                <TableCell>→</TableCell>
                <TableCell>{group.organization_name}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
      <DialogActions>
        {error && (
          <Typography color="error" sx={{ flexGrow: 1, textAlign: 'center' }}>
            {error}
          </Typography>
        )}
        <Button onClick={handleSave} disabled={!selectedEsu || nameError}>
          Save
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

// Get a unique name for the new group member, following the naming convention of multi-ESU groups.
function getDefaultName(group: MeteringGroup, allEsus: ESUDetails[]) {
  let suggestion = '';
  const sortedNames = group.esus
    .map((esu) => esu.name)
    .filter((name) => name.startsWith(group.name))
    .sort();
  if (sortedNames.length > 0) {
    const last = sortedNames[sortedNames.length - 1];
    // Increment the number suffix, e.g. "Bay 1-1" -> "Bay 1-2"
    suggestion = last.replace(/-\d+$/, (match) => `-${parseInt(match.slice(1), 10) + 1}`);
  } else {
    suggestion = `${group.name}-1`;
  }
  if (allEsus.some((esu) => esu.name === suggestion)) {
    // Bail out if the name would clash with another ESU.
    return '';
  }
  return suggestion;
}
