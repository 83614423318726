import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Typography, styled } from '@mui/material';

import { plainHttp } from '~http';

import { useAbortController } from '~hooks/useAbortController';

import { LOGO_ONLY_LAYOUT_NAV_HEIGHT } from '~layouts/LogoOnlyLayout';

import { Page } from '~components/Page';

import { LoginForm } from './LoginForm';

const ContentStyle = styled('div')(({ theme }) => ({
  marginTop: 12,
  padding: theme.spacing(6, 0),
  display: 'flex',
  minHeight: `calc(100vh - ${LOGO_ONLY_LAYOUT_NAV_HEIGHT}px)`,
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    marginTop: -LOGO_ONLY_LAYOUT_NAV_HEIGHT,
    justifyContent: 'center',
  },
}));

export function Login() {
  const { inviteId, confirmationToken } = useParams();
  const [isConfirmation, setConfirmation] = useState(true);
  const [isConfirmed, setConfirmed] = useState(false);
  const getSignal = useAbortController();
  const navigate = useNavigate();

  const confirmSignup = useCallback(async () => {
    try {
      await plainHttp.get(`/v1/invite/${inviteId}/${confirmationToken}`, {
        signal: getSignal(),
        responseType: 'text',
      });
      setConfirmed(true);
    } catch (e) {
      navigate('/404');
    }
  }, [inviteId, confirmationToken, getSignal, navigate]);

  useEffect(() => {
    if (inviteId) {
      if (!confirmationToken) {
        navigate('/404');
        return;
      }
      confirmSignup();
    } else {
      setConfirmation(false);
    }
  }, [inviteId, confirmationToken, confirmSignup, navigate]);

  if (isConfirmation && !isConfirmed) return null;

  return (
    <Page title="Login | Cactos Spine">
      <Container maxWidth="sm">
        <ContentStyle>
          <Typography variant="h4" gutterBottom mb={4}>
            {isConfirmed ? 'Account confirmed! You may now sign in.' : 'Sign in to Cactos'}
          </Typography>
          <LoginForm />
        </ContentStyle>
      </Container>
    </Page>
  );
}
