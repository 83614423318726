import { Alert, Box } from '@mui/material';
import { useMemo } from 'react';

import type { Region } from '~types';

import { asCactosError } from '~http';

import { useFleetRegions } from '~hooks/useFleetRegions';

import {
  FINGRID_CONTROL_AREA,
  FingridStatusCard,
} from '~pages/fleets/components/FingridStatusCard';
import { FleetLayout } from '~pages/fleets/components/FleetLayout';
import { MonthlyAllocationsCard } from '~pages/fleets/components/MonthlyAllocationsCard';
import { useFleetsNavigation } from '~pages/fleets/hooks/useFleetsNavigation';
import { FleetStatusCard } from '~pages/fleets/components/FleetStatusCard';

export function FleetDashboard() {
  const { fleetId } = useFleetsNavigation();
  const { data: regionsData, error: regionsError } = useFleetRegions(fleetId);

  const reserveObjects = regionsData?.fcr ?? [];
  const controlAreaIds = useMemo(
    () => regionsData?.entsoe_ca?.map((region: Region) => region.id) ?? [],
    [regionsData],
  );
  return (
    <FleetLayout title="Fleet Dashboard">
      {!!regionsError && (
        <Alert severity="error">
          Failed to load list of regions: {asCactosError(regionsError).message}
        </Alert>
      )}
      <Box display="flex" gap={2} flexWrap="wrap">
        {controlAreaIds.includes(FINGRID_CONTROL_AREA) && <FingridStatusCard />}
        <MonthlyAllocationsCard fleetId={fleetId} />
      </Box>
      <FleetStatusCard fleetId={fleetId} reserveObjects={reserveObjects} />
    </FleetLayout>
  );
}
