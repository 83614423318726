import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Container,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';

import type { PartialPricing, WizardProps } from '~pages/sites/components/pricing/types';
import { StepAttachments } from '~pages/sites/components/pricing/wizard/StepAttachments';
import { StepPurchase } from '~pages/sites/components/pricing/wizard/StepPurchase';
import { StepStartTime } from '~pages/sites/components/pricing/wizard/StepStartTime';
import { StepSales } from '~pages/sites/components/pricing/wizard/StepSales';
import { StepSummary } from '~pages/sites/components/pricing/wizard/StepSummary';
import { extractPricing } from '~pages/sites/components/pricing/utils/extractPricing';
import {
  PricingWizardSchema,
  type PricingWizardSchemaType,
} from '~pages/sites/components/pricing/wizard/PricingWizardSchema';
import { createPricing } from '~pages/sites/components/pricing/utils/createPricing';

const wizardSteps = {
  'Start time': StepStartTime,
  'Purchase model': StepPurchase,
  'Sales model': StepSales,
  Attachments: StepAttachments,
  Summary: StepSummary,
};

const steps = Object.values(wizardSteps);

export function PricingWizard({
  pricing,
  setPricing,
  handleCancel,
  attachments,
  setAttachments,
  handleSave,
  isSaveLoading,
}: WizardProps) {
  const [activeStep, setActiveStep] = useState(0);

  const initialValues = useMemo(() => extractPricing(pricing), [pricing]);

  // Needs to be in a ref to get the initial value
  // The pricing wizard will edit the pricing after the first render and make it valid
  const isLegacyPricingAndMayCauseIssues = useRef(initialValues.isLegacyPricingAndMayCauseIssues);

  const formik = useFormik<PricingWizardSchemaType>({
    validationSchema: PricingWizardSchema,
    initialValues,
    onSubmit: () => {},
  });

  const StepComponent = steps[activeStep];

  const handleBack = useCallback(() => {
    setActiveStep((prevStep) => prevStep - 1);
  }, []);

  const handleNext = useCallback(() => {
    setActiveStep((prevStep) => prevStep + 1);
  }, []);

  const handleStepClick = useCallback((stepIndex: number) => {
    setActiveStep(stepIndex);
  }, []);

  useEffect(() => {
    const partialPricing: PartialPricing = {
      id: pricing.id,
      metering_group_id: pricing.metering_group_id,
      currency: pricing.currency,
      current: pricing.current,
      attachments: pricing.attachments,
    };

    setPricing(createPricing(partialPricing, formik.values));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pricing.id,
    pricing.metering_group_id,
    pricing.currency,
    pricing.current,
    pricing.attachments,
    formik.values,
    setPricing,
  ]);

  return (
    <Container maxWidth="xl" sx={{ px: '0 !important', my: 1 }}>
      {isLegacyPricingAndMayCauseIssues.current && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          This pricing was created with a previous version of the pricing wizard and it's possible
          that not all of the values were loaded correctly. Please carefully review the created
          pricing to ensure all changes are intended.
        </Alert>
      )}
      <Box marginBottom={1}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {Object.keys(wizardSteps).map((label, index) => (
            <Step key={`wizard-step-${index}`} onClick={() => handleStepClick(index)}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Card>
        <CardContent>
          <StepComponent
            formik={formik}
            attachments={attachments}
            setAttachments={setAttachments}
          />
        </CardContent>

        <CardActions style={{ justifyContent: 'end' }} sx={{ padding: '1em' }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button color="error" onClick={handleCancel}>
              Cancel
            </Button>

            <ButtonGroup>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>

              <LoadingButton
                loading={isSaveLoading}
                variant="contained"
                disabled={
                  activeStep === steps.length - 1 && Object.values(formik.errors).length > 0
                }
                onClick={activeStep === steps.length - 1 ? handleSave : handleNext}
              >
                {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
              </LoadingButton>
            </ButtonGroup>
          </Box>
        </CardActions>
      </Card>
    </Container>
  );
}
