import { useMemo } from 'react';

import type { ESUCurrentMode, ESUWithController } from '~types';

import { useTimeseriesLatest } from '~hooks/timeseries';
import { createLatestQuery } from '~hooks/timeseries/queries';
import { useESUCurrentMode } from '~hooks/useESUCurrentMode';
import { useESUList } from '~hooks/useESUList';

export type ESUsUserTableRow = ESUWithController & {
  current_mode: ESUCurrentMode | undefined;
  esu_status?: {
    time: string;
    soc: number | null;
  };
};

export function useESUsUserTableData() {
  const { data: esus, error: esusError, isLoading: esusLoading } = useESUList();

  const {
    data: currentMode,
    error: currentModeError,
    isLoading: currentModeLoading,
  } = useESUCurrentMode();

  const {
    data: esuStatus,
    error: esuStatusError,
    isLoading: esuStatusLoading,
  } = useTimeseriesLatest(
    createLatestQuery({
      resources: esus?.map((esu) => esu.resource_id) ?? [],
      columns: { esu_status: ['soc'] },
      start: { minutes: 10 },
    }),
    { refreshInterval: 60_000, revalidateOnFocus: false },
  );

  const data: ESUsUserTableRow[] | undefined = useMemo(
    () =>
      esus?.map((esu) => ({
        ...esu,
        current_mode: currentMode?.[esu.id],
        esu_status: esuStatus?.[esu.resource_id].esu_status,
      })),
    [esus, esuStatus, currentMode],
  );

  return {
    data,
    error: esusError ?? currentModeError ?? esuStatusError,
    isLoading: esusLoading || currentModeLoading || esuStatusLoading,
  };
}
