import { useMemo } from 'react';
import useSWR, { preload } from 'swr';

import { type CommonMeteringGroup, type FetchHookData, type MeteringGroup } from '~types';

import { fetcher } from '~http';

import { collator } from '~utils/localization';

export async function preloadMeteringGroupList() {
  try {
    return await preload('/v1/metering_group', fetcher);
  } catch {
    return null;
  }
}

export function useMeteringGroupList(): FetchHookData<MeteringGroup[]> {
  const { data, error, isLoading, mutate } = useSWR<MeteringGroup[]>('/v1/metering_group', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });

  const sortedData = useMemo(
    () => (data != null ? [...data].sort((a, b) => collator.compare(a.name, b.name)) : undefined),
    [data],
  );

  return {
    data: sortedData,
    error,
    isLoading,
    mutate,
  };
}

export async function preloadFleetMeteringGroupList(fleetId: string) {
  try {
    return await preload(`/v1/region/fleet/${fleetId}/metering_group`, fetcher);
  } catch {
    return null;
  }
}

export function useFleetMeteringGroupList(fleetId: string): FetchHookData<CommonMeteringGroup[]> {
  const { data, error, isLoading, mutate } = useSWR<MeteringGroup[]>(
    `/v1/region/fleet/${fleetId}/metering_group`,
    { refreshInterval: 60_000, revalidateOnFocus: false, fetcher },
  );

  const sortedData = useMemo(
    () => (data != null ? [...data].sort((a, b) => collator.compare(a.name, b.name)) : undefined),
    [data],
  );

  return {
    data: sortedData,
    error,
    isLoading,
    mutate,
  };
}
