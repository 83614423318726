import { Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';

import { PageLayout } from '~layouts/PageLayout';

import { ChangePassword } from '~pages/user/ChangePassword';
import { SetupMFA } from '~pages/user/SetupMFA';

export function UserSettings() {
  useRegisterBreadcrumbs({ label: 'User settings' });

  return (
    <PageLayout hasDivider title="User settings">
      <ContentDiv>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={5}>
            <Typography variant="h3" gutterBottom>
              User settings
            </Typography>
          </Stack>
          <Typography variant="h5" gutterBottom>
            Change password
          </Typography>
          <ChangePassword />
          <Typography variant="h5" gutterBottom>
            Setup two-factor authentication
          </Typography>
          <SetupMFA />
        </Container>
      </ContentDiv>
    </PageLayout>
  );
}

const ContentDiv = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));
