import type React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { Editor, type OnChange, type OnValidate } from '@monaco-editor/react';
import EditorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
import { type MonacoYaml, configureMonacoYaml } from 'monaco-yaml';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DateTimeField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { History } from '@mui/icons-material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab';
import { TransitionGroup } from 'react-transition-group';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import dayjs, { type Dayjs } from 'dayjs';
import { formatDistanceToNow } from 'date-fns';
import useSWR, { useSWRConfig } from 'swr';

import { asCactosError, fetcher, http } from '~http';

import { USER_ROLES } from '~constants/auth';

import { formatDateTime, formatDateTimeNoTimeZone, formatRange } from '~utils/time';
import { hash } from '~utils/string';

import { useAuth } from '~hooks/useAuth';
import { useIsDesktop } from '~hooks/useIsDesktop';
import { useMeteringGroup } from '~hooks/useMeteringGroup';

import { SIDEBAR_MOBILE_HEIGHT } from '~layouts/AppLayout/SidebarLayout';
import { PAGE_LAYOUT_HEADER_HEIGHT } from '~layouts/PageLayout';

import { useSitesNavigation } from '~pages/sites/hooks/useSitesNavigation';
import { isErrorWithDetails } from '~pages/sites/components/topology/errors';
import {
  DEFAULT_TIMEZONE,
  useMeteringGroupTimezone,
} from '~pages/sites/components/topology/timezone';
// eslint-disable-next-line import/extensions
import YamlWorker from '~pages/sites/components/topology/yaml.worker?worker';

import { Iconify } from '~components/Iconify';
import { StyledToaster } from '~components/StyledToaster';
import { TABS_HEIGHT } from '~components/Tabs';

type Monaco = typeof monaco;

window.MonacoEnvironment = {
  getWorker(_moduleId, label) {
    switch (label) {
      case 'editorWorkerService':
        return new EditorWorker();
      case 'yaml':
        return new YamlWorker();
      default:
        throw new Error(`Unknown label ${label}`);
    }
  },
};

type TopologyEntry = {
  start_time: string;
  end_time: string | null;
  topology: string;
  created_at: string;
  updated_at: string;
  author: { email: string } | null;
};

type TopologyTimelineEntry = Omit<TopologyEntry, 'topology'>;

async function fetchTopology(url: string): Promise<TopologyEntry | null> {
  try {
    const response = await http.get(url);
    return response.data;
  } catch (e: any) {
    if (e.response?.status === 404) {
      return null;
    }
    throw e;
  }
}

export function TopologyEditor() {
  const { meteringGroupId } = useSitesNavigation();
  const { data: meteringGroup } = useMeteringGroup(meteringGroupId);
  const { mutate: globalMutate } = useSWRConfig();

  const [showTimeline, setShowTimeline] = useState(false);

  const isDesktop = useIsDesktop();
  const topItemsHeight =
    (isDesktop ? 0 : SIDEBAR_MOBILE_HEIGHT) + PAGE_LAYOUT_HEADER_HEIGHT + TABS_HEIGHT;
  const containerHeight = `calc(100vh - ${topItemsHeight}px)`;

  const [searchParams, setSearchParams] = useSearchParams();
  // Attention: new Date(topology.start_time).toISOString() !== topology.start_time
  // the parameter from a current topology possibly has 6 digits after the decimal point, 3 of which new Date()
  // conveniently drops off, possibly causing the date to be in the past and the topology not being active at that time
  const atParameter = searchParams.get('at');
  const atTime: string | null =
    atParameter && Number.isFinite(new Date(atParameter).getTime()) ? atParameter : null;

  const url = `/v1/metering_group/${meteringGroupId}/topology` + (atTime ? `?at=${atTime}` : '');

  const { data, mutate } = useSWR<TopologyEntry | null>(url, { fetcher: fetchTopology });

  const saveTopology = useCallback(
    async (value: string, startTime: string) => {
      const result = await http.post(`/v1/metering_group/${meteringGroupId}/topology`, {
        start_time: startTime,
        topology: value,
      });
      await mutate(result.data, { revalidate: false });
      try {
        await globalMutate(`/v1/metering_group/${meteringGroupId}/topology/timeline`);
      } catch {
        // ignore
      }
      setSearchParams({ at: result.data.start_time });
    },
    [meteringGroupId, mutate, globalMutate, setSearchParams],
  );

  return (
    <Box display="flex" flexDirection="row" height={containerHeight}>
      <Box position="relative" width="0px" flexGrow={1} display="flex" flexDirection="column">
        {data !== undefined && !!meteringGroup && (
          <EditorContainer
            key={
              url /* Force remount when navigating between groups and revisions,
                     to reset the internal state of the editor. */
            }
            entry={data}
            saveTopology={saveTopology}
            meteringGroupName={meteringGroup.name}
            showTimeline={showTimeline}
            setShowTimeline={setShowTimeline}
          />
        )}
        <StyledToaster />
      </Box>
      {showTimeline && (
        <Box flex="0 0 240px" overflow="scroll">
          <TopologyTimeline selectedTopology={data?.start_time} />
        </Box>
      )}
    </Box>
  );
}

function EditorTitle({ currentTopology }: { currentTopology: TopologyEntry | null }) {
  if (currentTopology == null) {
    return null;
  }
  return (
    <Typography
      variant="body2"
      sx={{ paddingX: 4, paddingY: 1, background: '#1e1e1e', maxHeight: '38px' }}
    >
      Topology{' '}
      {currentTopology.end_time
        ? ' in effect between ' +
          formatRange({
            start: new Date(currentTopology.start_time),
            end: new Date(currentTopology.end_time),
          })
        : 'in effect since ' + formatDateTime(new Date(currentTopology.start_time))}
    </Typography>
  );
}

function EditorContainer({
  entry,
  saveTopology,
  meteringGroupName,
  showTimeline,
  setShowTimeline,
}: {
  entry: TopologyEntry | null;
  saveTopology: (value: string, startTime: string) => Promise<void>;
  meteringGroupName: string;
  showTimeline: boolean;
  setShowTimeline: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const monacoRef = useRef<Monaco | null>(null);
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const monacoYamlRef = useRef<MonacoYaml | null>(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [readOnly, setReadOnly] = useState(true);
  const [shouldShowDraft, setShouldShowDraft] = useState(false);
  const options = useMemo(() => ({ ...EDITOR_OPTIONS, readOnly }), [readOnly]);
  const { user } = useAuth();
  const roles = user?.organizationsByRole || {};
  const canEdit = USER_ROLES.EDIT_TOPOLOGY in roles;
  const [isMounted, setIsMounted] = useState(false);
  const [searchParams] = useSearchParams();

  const handleEditorDidMount = (
    editorInstance: monaco.editor.IStandaloneCodeEditor,
    monacoInstance: Monaco,
  ) => {
    monacoRef.current = monacoInstance;
    editorRef.current = editorInstance;
    setIsMounted(true);
  };

  useEffect(() => {
    if (isMounted) {
      const monacoYaml = configureMonacoYaml(monacoRef.current!, {
        enableSchemaRequest: true,
        schemas: [
          {
            fileMatch: ['**/*'],
            uri: window.location.origin + '/api/v1/schemas/topology/1',
          },
        ],
      });
      monacoYamlRef.current = monacoYaml;
      return () => {
        monacoYaml.dispose();
      };
    }
    return undefined;
  }, [isMounted]);

  const handleChange: OnChange = (value, _) => {
    setHasChanged(value !== entry?.topology);
  };

  const handleValidate: OnValidate = (markers) => {
    const nextValidationErrors = markers.flatMap((marker) => {
      switch (marker.severity) {
        case monaco.MarkerSeverity.Warning:
          return `Warning: ${marker.message} (line ${marker.startLineNumber})`;
        case monaco.MarkerSeverity.Error:
          return `Error: ${marker.message} (line ${marker.startLineNumber})`;
        default:
          return [];
      }
    });
    const count = nextValidationErrors.length;
    if (count > 5) {
      nextValidationErrors.length = 4;
      nextValidationErrors.push(`...and ${count - 4} more`);
    }
    setValidationErrors(nextValidationErrors);
  };

  const handleSaveClick = async (startTime: string) => {
    if (!editorRef.current) {
      return;
    }
    editorRef.current.getAction('editor.action.formatDocument')?.run();
    const value = editorRef.current.getValue();
    setIsSaving(true);
    setErrors([]);

    try {
      await saveTopology(value, startTime);
    } catch (e) {
      const message = asCactosError(e).message;
      const data: unknown = (e as any).response?.data;
      if (isErrorWithDetails(data)) {
        const errors = [];
        for (const error of data.errors) {
          errors.push(`${message}: ${error.msg} (field: ${error.loc.join('.')})`);
        }
        setErrors(errors);
      } else {
        setErrors([message]);
      }
      return;
    } finally {
      setIsSaving(false);
    }
    toast.success('Topology saved.', { duration: 5_000 });
    setHasChanged(false);
  };

  const initialValue = entry?.topology ?? getDefaultValue(meteringGroupName);

  const handleDiscardClick = () => {
    if (!editorRef.current) {
      return;
    }
    editorRef.current.setValue(initialValue);

    setErrors([]);
    setValidationErrors([]);
    setReadOnly(true);
    setShouldShowDraft(false);
  };

  const handleCreateClick = () => {
    setReadOnly(false);
    setShouldShowDraft(true);
    setHasChanged(true);
  };

  if (entry === null && !shouldShowDraft) {
    const atParameter = searchParams.get('at');
    return (
      <Card sx={{ margin: 2 }}>
        <CardContent>
          {atParameter != null
            ? `Metering group "${meteringGroupName}" has no topology active at ${
                [new Date(atParameter)]
                  .filter((d) => Number.isFinite(d.getTime()))
                  .map(formatDateTime)?.[0] ?? `"${atParameter}"`
              }.`
            : `Metering group "${meteringGroupName}" does not have a topology.`}
        </CardContent>
        {canEdit && (
          <CardActions>
            <Button onClick={handleCreateClick}>CREATE</Button>
          </CardActions>
        )}
      </Card>
    );
  }

  return (
    <>
      <EditorTitle currentTopology={entry} />
      <Box flexGrow={1}>
        <Editor
          defaultLanguage="yaml"
          defaultValue={initialValue}
          options={options}
          theme="vs-dark"
          onMount={handleEditorDidMount}
          onChange={handleChange}
          onValidate={handleValidate}
        />
      </Box>

      <Tooltip title={showTimeline ? 'Hide timeline' : 'Show timeline'} placement="left">
        <Fab
          aria-label="show timeline"
          sx={{ position: 'absolute', top: 50, right: 16 }}
          onClick={() => setShowTimeline((prev) => !prev)}
        >
          <History />
        </Fab>
      </Tooltip>
      {canEdit && (
        <Tooltip title="Edit" placement="left">
          <Fab
            aria-label="edit"
            sx={{ position: 'absolute', top: 120, right: 16 }}
            disabled={!readOnly}
            onClick={() => setReadOnly((readOnly) => !readOnly)}
          >
            <Iconify icon="mdi:pencil" width={20} height={20} />
          </Fab>
        </Tooltip>
      )}

      <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
        <TransitionGroup>
          {errors.map((error) => (
            <Collapse key={error} in>
              <Alert severity="error" sx={{ borderRadius: 0 }}>
                {error}
              </Alert>
            </Collapse>
          ))}
          {validationErrors.map((error) => (
            <Collapse key={error} in>
              <Alert severity="warning" sx={{ borderRadius: 0 }}>
                {error}
              </Alert>
            </Collapse>
          ))}
          {hasChanged && (
            <Collapse in>
              <EditorToolbar
                isSaving={isSaving}
                onSave={handleSaveClick}
                onDiscard={handleDiscardClick}
              />
            </Collapse>
          )}
        </TransitionGroup>
      </div>
    </>
  );
}

function EditorToolbar({
  isSaving,
  onSave,
  onDiscard,
}: {
  isSaving: boolean;
  onSave: (startTime: string) => void;
  onDiscard: () => void;
}) {
  const theme = useTheme();
  const [scheduleDialogOpen, setsScheduleDialogOpen] = useState(false);
  return (
    <Toolbar sx={{ width: '100%', backgroundColor: theme.palette.warning.main }}>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={onDiscard}
        disabled={isSaving}
        sx={{
          '&.Mui-disabled': {
            color: theme.palette.grey[700],
            borderColor: theme.palette.grey[700],
          },
        }}
      >
        Discard Changes
      </Button>
      <Typography variant="body2" sx={{ marginLeft: 2, color: '#000' }}>
        You have unsaved changes.
      </Typography>
      <div style={{ flex: 1 }} />
      <ButtonGroup>
        <Tooltip title="Starting immediately">
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => onSave(dayjs().add(1, 'second').startOf('second').utc().toISOString())}
            disabled={isSaving}
            sx={{
              '&.Mui-disabled': {
                color: theme.palette.grey[700],
                borderColor: theme.palette.grey[700],
              },
            }}
          >
            Save Changes
          </Button>
        </Tooltip>
        <Tooltip title="Starting at a specific time">
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            disabled={isSaving}
            sx={{
              '&.Mui-disabled': {
                color: theme.palette.grey[700],
                borderColor: theme.palette.grey[700],
              },
            }}
            onClick={() => setsScheduleDialogOpen(true)}
          >
            <Iconify icon="mdi:clock-outline" width={20} height={20} />
          </Button>
        </Tooltip>
      </ButtonGroup>
      <ScheduleChangesDialog
        open={scheduleDialogOpen}
        onClose={() => setsScheduleDialogOpen(false)}
        onSave={onSave}
      />
    </Toolbar>
  );
}

function TopologyTimeline({ selectedTopology }: { selectedTopology: string | undefined }) {
  const { meteringGroupId } = useSitesNavigation();
  const theme = useTheme();
  const [, setSearchParams] = useSearchParams();

  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 60_000);
    return () => clearInterval(interval);
  }, []);

  const { data, isLoading, error } = useSWR<{ entries: TopologyTimelineEntry[] }>(
    `/v1/metering_group/${meteringGroupId}/topology/timeline`,
    { fetcher },
  );

  const entries = [...(data?.entries ?? [])].reverse();

  const activeTopology = entries.find(
    (topology) =>
      new Date(topology.start_time) <= now &&
      (topology.end_time == null || now < new Date(topology.end_time)),
  )?.start_time;

  const getColorForEmail = (email: string) =>
    theme.palette.chart.categorical14[Math.abs(hash(email)) % 14];

  return (
    <Box>
      {error != null && (
        <Alert severity="error">Error loading history: {asCactosError(error).message}</Alert>
      )}
      {entries.length === 0 ? (
        <Box display="flex" justifyContent="center" pt={2}>
          <Typography variant="body1" color="textSecondary">
            {isLoading ? 'Loading...' : 'No previous entries.'}
          </Typography>
        </Box>
      ) : (
        <Timeline sx={{ [`& .${timelineItemClasses.root}:before`]: { flex: 0, padding: 0 } }}>
          {entries.length > 0 && entries[0].end_time != null && (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  variant={new Date(entries[0].end_time) < now ? 'filled' : 'outlined'}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="body2" color="textSecondary">
                  <b>{formatDateTimeNoTimeZone(entries[0].end_time)}</b>
                  <br />
                  <i>(No topology)</i>
                </Typography>
              </TimelineContent>
            </TimelineItem>
          )}
          {entries.map((entry, index, all) => {
            const isSelected = entry.start_time === selectedTopology;
            const isActive = entry.start_time === activeTopology;
            const onClick = () => {
              setSearchParams({ at: entry.start_time });
            };

            return (
              <TimelineItem key={entry.start_time}>
                <TimelineSeparator>
                  <TimelineDot
                    color={isSelected ? 'primary' : 'grey'}
                    variant={isActive ? 'filled' : 'outlined'}
                    onClick={onClick}
                    sx={{ cursor: 'pointer' }}
                  />
                  {index < all.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent position="relative" top="1px">
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    onClick={onClick}
                    sx={{ cursor: 'pointer' }}
                  >
                    <span
                      style={{
                        color: isSelected
                          ? theme.palette.primary.main
                          : theme.palette.text.secondary,
                      }}
                    >
                      <b>{formatDateTimeNoTimeZone(entry.start_time)}</b>
                    </span>
                    {entry.author?.email != null && (
                      <Tooltip
                        title={`Created by ${entry.author.email} ${formatDistanceToNow(
                          entry.created_at,
                          { addSuffix: true },
                        )}.`}
                      >
                        <div
                          style={{
                            width: '190px',
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: '6px',
                          }}
                        >
                          <svg width="10" height="10" style={{ marginTop: '6px' }}>
                            <g transform="translate(5, 5)">
                              <circle r="5" fill={getColorForEmail(entry.author.email)} />
                            </g>
                          </svg>
                          <div style={{ flex: 1, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            {entry.author.email}
                          </div>
                        </div>
                      </Tooltip>
                    )}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      )}
    </Box>
  );
}

function ScheduleChangesDialog({
  open,
  onClose,
  onSave,
}: {
  open: boolean;
  onClose: () => void;
  onSave: (startTime: string) => void;
}) {
  const [startTime, setStartTime] = useState<Dayjs | null>(() =>
    dayjs().add(1, 'second').startOf('second').utc(),
  );
  const hasValidStartTime = startTime != null && startTime.isValid();
  const timezone = useMeteringGroupTimezone();
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          if (!hasValidStartTime) {
            return;
          }
          onClose();
          onSave(startTime.toISOString());
        },
      }}
    >
      <DialogTitle>Schedule changes</DialogTitle>
      <DialogContent>
        <DialogContentText>The changes will take effect at the specified time.</DialogContentText>
        {timezone == null && (
          <Alert severity="warning" sx={{ marginTop: 1 }}>
            Could not determine the timezone of the metering group, showing times in zone "
            {DEFAULT_TIMEZONE}" as a fallback.
          </Alert>
        )}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimeField
            value={startTime}
            onChange={setStartTime}
            timezone={timezone ?? DEFAULT_TIMEZONE}
            format="YYYY-MM-DD HH:mm:ss"
            label="Start time"
            fullWidth
            sx={{ marginTop: 3 }}
            required
            InputLabelProps={{
              error: !hasValidStartTime,
            }}
            InputProps={{
              error: !hasValidStartTime,
              endAdornment: (
                <Typography variant="body2" color={theme.palette.grey[400]}>
                  {timezone ?? DEFAULT_TIMEZONE}
                </Typography>
              ),
            }}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button type="submit" disabled={!hasValidStartTime}>
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const EDITOR_OPTIONS: monaco.editor.IStandaloneEditorConstructionOptions = {
  acceptSuggestionOnCommitCharacter: true,
  acceptSuggestionOnEnter: 'on',
  accessibilitySupport: 'auto',
  autoIndent: 'advanced',
  automaticLayout: true,
  codeLens: true,
  colorDecorators: true,
  contextmenu: true,
  cursorBlinking: 'blink',
  cursorSmoothCaretAnimation: 'off',
  cursorStyle: 'line',
  disableLayerHinting: false,
  disableMonospaceOptimizations: false,
  dragAndDrop: false,
  fixedOverflowWidgets: false,
  folding: true,
  foldingStrategy: 'auto',
  fontLigatures: false,
  formatOnPaste: false,
  formatOnType: false,
  glyphMargin: true,
  hideCursorInOverviewRuler: false,
  links: true,
  minimap: {
    enabled: false,
  },
  mouseWheelZoom: false,
  multiCursorMergeOverlapping: true,
  multiCursorModifier: 'alt',
  overviewRulerBorder: true,
  overviewRulerLanes: 3,
  quickSuggestions: true,
  quickSuggestionsDelay: 10,
  renderControlCharacters: true,
  renderFinalNewline: 'on',
  renderLineHighlight: 'all',
  renderWhitespace: 'selection',
  revealHorizontalRightPadding: 30,
  roundedSelection: true,
  rulers: [],
  scrollBeyondLastColumn: 5,
  scrollBeyondLastLine: true,
  selectOnLineNumbers: true,
  selectionClipboard: true,
  selectionHighlight: true,
  showFoldingControls: 'mouseover',
  smoothScrolling: false,
  suggestOnTriggerCharacters: true,
  wordBasedSuggestions: 'currentDocument',
  wordSeparators: '~!@#$%^&*()-=+[{]}|;:\'",.<>/?',
  wordWrap: 'off',
  wordWrapBreakAfterCharacters: '\t})]?|&,;',
  wordWrapBreakBeforeCharacters: '{([+',
  wordWrapColumn: 80,
  wrappingIndent: 'none',
};

function getDefaultValue(meteringGroupName: string) {
  return `circuit:
  name: ${meteringGroupName}
  is_metering_point: true
edge_controllers: {}
regions: {}
`;
}
