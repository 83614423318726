import dayjs from 'dayjs';
import {
  type FormatDistanceFnOptions,
  type FormatDistanceToken,
  format,
  formatDistanceToNowStrict,
  formatDuration,
  isSameDay,
  isToday,
} from 'date-fns';
import { enUS } from 'date-fns/locale';

export const formatLocalTime = (time: dayjs.Dayjs | string) => {
  return dayjs(time).local().format('YYYY-MM-DD HH:mm:ss Z');
};

// Overriding the default formatDistance locale to
// use 's', 'm', and 'h' instead of 'seconds', 'minutes', and 'hours'
const formatDistanceLocale = {
  ...enUS,
  formatDistance: (
    token: FormatDistanceToken,
    count: number,
    options?: FormatDistanceFnOptions,
  ) => {
    if (token === 'xSeconds') return `${count}s`;
    if (token === 'xMinutes') return `${count}m`;
    if (token === 'xHours') return `${count}h`;
    return enUS.formatDistance(token, count, options);
  },
};

export function formatDurationShort(seconds: number) {
  return formatDuration({ seconds }, { locale: formatDistanceLocale });
}

export function formatRelativeTimeShort(dateTime: Date | string | undefined) {
  if (dateTime == null) {
    return '';
  }
  return (
    formatDistanceToNowStrict(dateTime, {
      locale: formatDistanceLocale,
    }) + ' ago'
  );
}

export function formatClockTime(dateTime: Date | string | undefined) {
  if (dateTime == null) {
    return '';
  }
  if (isToday(dateTime)) {
    return format(dateTime, "HH:mm:ss '(UTC'x')'");
  }
  return format(dateTime, "yyyy-MM-dd HH:mm:ss '(UTC'x')'");
}

export function formatDateTimeWithMilliseconds(dateTime: Date | string | undefined) {
  if (dateTime == null) {
    return '';
  }
  return format(dateTime, "yyyy-MM-dd HH:mm:ss.SSS '(UTC'x')'");
}

export function formatDateTime(dateTime: Date | string | undefined) {
  if (dateTime == null) {
    return '';
  }
  return format(dateTime, "yyyy-MM-dd HH:mm:ss '(UTC'x')'");
}

export function formatDateTimeNoTimeZone(dateTime: Date | string | undefined) {
  if (dateTime == null) {
    return '';
  }
  return format(dateTime, 'yyyy-MM-dd HH:mm:ss');
}

export type DateTimeRange = {
  start: Date;
  end: Date;
};

export type DateTimeRangeWithNow = DateTimeRange & { now: boolean };

export function formatRange(range: DateTimeRange) {
  const enDash = '\u2013';
  const tz = format(range.start, 'x'); // '+00' '+02' or similar
  if (isSameDay(range.start, range.end)) {
    const start = format(range.start, 'yyyy-MM-dd HH:mm:ss');
    const end = format(range.end, 'HH:mm:ss');
    return `${start} ${enDash} ${end} (UTC${tz})`;
  } else {
    const start = format(range.start, 'yyyy-MM-dd HH:mm:ss');
    const end = format(range.end, 'yyyy-MM-dd HH:mm:ss');
    return `${start} ${enDash} ${end} (UTC${tz})`;
  }
}

export function localTimeZoneOffset() {
  return format(new Date(), "'(UTC'x')'");
}

export function currentTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const isoMonthRegex = /^\d{4}-\d{2}$/;
const monthFormat = new Intl.DateTimeFormat('en', { month: 'long', year: 'numeric' });

/**
 * Given a month in YYYY-MM format, returns a human-readable string.
 * E.g. "2024-12" -> "December 2024"
 */
export function formatMonth(monthISOString: string): string | null {
  if (!isoMonthRegex.test(monthISOString)) {
    return null;
  }
  // JavaScript Date parses date-only strings as UTC and datetime strings as local time.
  const date = new Date(`${monthISOString}-01T00:00`);
  return monthFormat.format(date);
}
