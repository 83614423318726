import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { subMilliseconds, subSeconds } from 'date-fns';

import { type DateTimeRange, type DateTimeRangeWithNow } from '~utils/time';
import { getRecommendedRefreshIntervalMs } from '~utils/timeseries';

export function useURLRange(defaultRangeSeconds: number) {
  const [currentTime, setCurrentTime] = useState(() => new Date());

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(new Date()), 1_000);
    return () => clearInterval(interval);
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const parsedRange = rangeFromParams(searchParams.get('start'), searchParams.get('end'));

  const setURLRange = useCallback(
    (start: Date, end: Date) => {
      setSearchParams(
        (prevParams) => {
          const newParams = new URLSearchParams(prevParams);
          newParams.set('start', start.toISOString());
          newParams.set('end', end.toISOString());
          return newParams;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  const [now, setNow] = useState<boolean>(() => {
    if (parsedRange === null) return true;
    // if we found a range in the url,
    // set now to true if the range is very close to the present moment
    // this means that for example refreshing the page will not lose the "now" flag
    return (
      new Date().getTime() - parsedRange.end.getTime() <
      2 * getRecommendedRefreshIntervalMs(parsedRange)
    );
  });
  const rangeStartMs = (
    parsedRange?.start ?? subSeconds(currentTime, defaultRangeSeconds)
  ).getTime();
  const rangeEndMs = (parsedRange?.end ?? currentTime).getTime();

  // if no valid range in URL, set range
  useEffect(() => {
    if (parsedRange === null) {
      setURLRange(new Date(rangeStartMs), new Date(rangeEndMs));
    }
  }, [parsedRange, rangeStartMs, rangeEndMs, setURLRange]);

  // if range is supposed to be "now", yet is outdated, update it
  useEffect(() => {
    const refreshIntervalMs = getRecommendedRefreshIntervalMs({
      start: new Date(rangeStartMs),
      end: new Date(rangeEndMs),
    });
    if (now && currentTime.getTime() - rangeEndMs > refreshIntervalMs) {
      const duration = rangeEndMs - rangeStartMs;
      setURLRange(subMilliseconds(currentTime, duration), currentTime);
    }
  }, [currentTime, rangeStartMs, rangeEndMs, now, setURLRange]);

  const setRange = useCallback(
    (range: DateTimeRangeWithNow | DateTimeRange) => {
      const validRange = validateRange({ now: false, ...range });
      setNow(validRange.now);
      setURLRange(validRange.start, validRange.end);
    },
    [setURLRange],
  );

  const outputRange = useMemo(
    () => ({
      start: new Date(rangeStartMs),
      end: new Date(rangeEndMs),
      now,
    }),
    [rangeStartMs, rangeEndMs, now],
  );

  return [outputRange, setRange] as const;
}

const MAX_RANGE_MS = 60_000 * 60 * 24 * 7; // 7 days
const MIN_RANGE_MS = 60_000; // 1 minute

const validateRange = (range: DateTimeRangeWithNow): DateTimeRangeWithNow => {
  let startMs = range.start.getTime();
  let endMs = range.end.getTime();

  if (startMs > endMs) [startMs, endMs] = [endMs, startMs];

  // if the range is too large, shrink it
  const msTooLarge = endMs - startMs - MAX_RANGE_MS;
  if (msTooLarge > 0) {
    startMs += msTooLarge / 2;
    endMs -= msTooLarge / 2;
  }

  // if the range is too small, expand it outwards
  const msTooSmall = MIN_RANGE_MS - (endMs - startMs);
  if (msTooSmall > 0) {
    startMs -= msTooSmall / 2;
    endMs += msTooSmall / 2;
  }
  // if the range is in the future, slide it back
  const msInFuture = endMs - new Date().getTime();
  if (msInFuture > 0) {
    startMs -= msInFuture;
    endMs -= msInFuture;
  }

  return { start: new Date(startMs), end: new Date(endMs), now: range.now };
};

const rangeFromParams = (
  startParam: string | null,
  endParam: string | null,
): DateTimeRange | null => {
  if (startParam == null || endParam == null) return null;
  const start = new Date(startParam);
  const end = new Date(endParam);
  if (Number.isNaN(start.getTime()) || Number.isNaN(end.getTime()) || start >= end) return null;
  const validRange = validateRange({ start, end, now: false });
  return { start: validRange.start, end: validRange.end };
};
