import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { TableEntitiesProvider } from '~layouts/AppLayout/TableEntitiesProvider';
import { LoadingBar } from '~layouts/AppLayout/LoadingBar';
import { SidebarLayout } from '~layouts/AppLayout/SidebarLayout';

import { BreadcrumbsProvider } from '~components/breadcrumbs/BreadcrumbsProvider';
import { CommandPaletteProvider } from '~components/command-palette/CommandPaletteProvider';
import { StyledToaster } from '~components/StyledToaster';
import { UiV2InfoBanner } from '~components/UiV2InfoBanner';

export function AppLayout() {
  return (
    <TableEntitiesProvider>
      <BreadcrumbsProvider>
        <CommandPaletteProvider>
          <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
            <UiV2InfoBanner />
            <LoadingBar />
            <SidebarLayout>
              <Outlet />
            </SidebarLayout>
            <StyledToaster />
          </Box>
        </CommandPaletteProvider>
      </BreadcrumbsProvider>
    </TableEntitiesProvider>
  );
}
