import { type Dispatch, type SetStateAction, createContext, useContext, useEffect } from 'react';

import type { Entity, EntityType } from '~types';

export type TableEntitiesContextType = {
  fleetEntities: Entity[];
  organizationEntities: Entity[];
  siteEntities: Entity[];
  controllerEntities: Entity[];
  esuEntities: Entity[];
  setFleetEntities: Dispatch<SetStateAction<Entity[]>>;
  setOrganizationEntities: Dispatch<SetStateAction<Entity[]>>;
  setSiteEntities: Dispatch<SetStateAction<Entity[]>>;
  setControllerEntities: Dispatch<SetStateAction<Entity[]>>;
  setEsuEntities: Dispatch<SetStateAction<Entity[]>>;
};

export const TableEntitiesContext = createContext<TableEntitiesContextType>({
  fleetEntities: [],
  organizationEntities: [],
  siteEntities: [],
  controllerEntities: [],
  esuEntities: [],
  setFleetEntities: () => {},
  setOrganizationEntities: () => {},
  setSiteEntities: () => {},
  setControllerEntities: () => {},
  setEsuEntities: () => {},
});

export function useTableEntities() {
  return useContext(TableEntitiesContext);
}

export function useSetTableEntities(entityType: EntityType, entities: Entity[]) {
  const {
    fleetEntities,
    organizationEntities,
    siteEntities,
    controllerEntities,
    esuEntities,
    setFleetEntities,
    setOrganizationEntities,
    setSiteEntities,
    setControllerEntities,
    setEsuEntities,
  } = useTableEntities();

  useEffect(() => {
    switch (entityType) {
      case 'fleet':
        if (!areEntitiesEqual(fleetEntities, entities)) {
          setFleetEntities(entities);
        }
        return;
      case 'organization':
        if (!areEntitiesEqual(organizationEntities, entities)) {
          setOrganizationEntities(entities);
        }
        return;
      case 'site':
        if (!areEntitiesEqual(siteEntities, entities)) {
          setSiteEntities(entities);
        }
        return;
      case 'controller':
        if (!areEntitiesEqual(controllerEntities, entities)) {
          setControllerEntities(entities);
        }
        return;
      case 'esu':
        if (!areEntitiesEqual(esuEntities, entities)) {
          setEsuEntities(entities);
        }
        return;
    }
  }, [
    entityType,
    entities,
    fleetEntities,
    organizationEntities,
    siteEntities,
    controllerEntities,
    esuEntities,
    setFleetEntities,
    setOrganizationEntities,
    setSiteEntities,
    setControllerEntities,
    setEsuEntities,
  ]);
}

function areEntitiesEqual(a: Entity[], b: Entity[]) {
  return a.length === b.length && a.every((entity, index) => entity.id === b[index].id);
}
