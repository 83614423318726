import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

import type { WizardStepProps } from '~pages/sites/components/pricing/types';
import { handleInputWheel } from '~pages/sites/components/pricing/utils/handleInputWheel';
import {
  SALES_MODEL_OPTIONS,
  SALES_TRANSFER_MODEL_OPTIONS,
} from '~pages/sites/components/pricing/constants';
import { ErrorMessage } from '~pages/sites/components/pricing/wizard/ErrorMessage';

export function StepSales({ formik }: WizardStepProps) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <FormLabel component="legend">Electricity Sales Model</FormLabel>
          <RadioGroup
            row
            name="salesModel"
            value={formik.values.salesModel}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {Object.entries(SALES_MODEL_OPTIONS).map(([key, label]) => (
              <FormControlLabel key={key} value={key} control={<Radio />} label={label} />
            ))}
          </RadioGroup>

          <Box display="flex" alignItems="center" gap={1}>
            {formik.values.salesModel === 'spotMargin' && (
              <>
                <TextField
                  type="number"
                  size="small"
                  label="Spot factor"
                  name="salesSpotFactor"
                  value={formik.values.salesSpotFactor}
                  error={!!formik.errors.salesSpotFactor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onWheel={handleInputWheel}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">x SPOT</InputAdornment>,
                  }}
                />
                {' ﹣ '}
                <TextField
                  type="number"
                  size="small"
                  label="Margin"
                  name="salesSpotMargin"
                  value={formik.values.salesSpotMargin}
                  error={!!formik.errors.salesSpotMargin}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onWheel={handleInputWheel}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">EUR/kWh</InputAdornment>,
                  }}
                />
                <TextField
                  size="small"
                  label="Margin description"
                  name="salesEnergyDescription"
                  value={formik.values.salesEnergyDescription}
                  error={!!formik.errors.salesEnergyDescription}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </>
            )}

            {formik.values.salesModel === 'fixed' && (
              <TextField
                type="number"
                size="small"
                label="Fixed price"
                name="salesFixedPrice"
                value={formik.values.salesFixedPrice}
                error={!!formik.errors.salesFixedPrice}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onWheel={handleInputWheel}
                InputProps={{
                  endAdornment: <InputAdornment position="end">EUR/kWh</InputAdornment>,
                }}
              />
            )}
          </Box>
          <ErrorMessage formik={formik} name="salesSpotFactor" />
          <ErrorMessage formik={formik} name="salesSpotMargin" />
          <ErrorMessage formik={formik} name="salesFixedPrice" />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ my: 1 }} />
      </Grid>

      <Grid item xs={8}>
        <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <FormLabel component="legend">Sales Transfer</FormLabel>
          <RadioGroup
            row
            name="salesTransferModel"
            value={formik.values.salesTransferModel}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {Object.entries(SALES_TRANSFER_MODEL_OPTIONS).map(([key, label]) => (
              <FormControlLabel key={key} value={key} control={<Radio />} label={label} />
            ))}
          </RadioGroup>

          <Box display="flex" gap={1}>
            {formik.values.salesTransferModel === 'fixed' && (
              <>
                <TextField
                  type="number"
                  size="small"
                  label="Transfer price"
                  name="salesTransferFixedPrice"
                  value={formik.values.salesTransferFixedPrice}
                  error={!!formik.errors.salesTransferFixedPrice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onWheel={handleInputWheel}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">EUR/kWh</InputAdornment>,
                  }}
                />
                <TextField
                  size="small"
                  label="Description"
                  name="salesTransferDescription"
                  value={formik.values.salesTransferDescription}
                  error={!!formik.errors.salesTransferDescription}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </>
            )}
          </Box>
          <ErrorMessage formik={formik} name="salesTransferFixedPrice" />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ my: 1 }} />
      </Grid>

      <Grid item xs={12}>
        <FormControl>
          <FormControlLabel
            label="VAT included"
            control={
              <Checkbox
                name="salesHasVat"
                checked={formik.values.salesHasVat}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}
