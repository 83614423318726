import { useMemo } from 'react';
import { Box, Tab, useTheme } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { Link, useLocation } from 'react-router-dom';

import { isRoutePermitted } from '~utils/isRoutePermitted';

import { useAuth } from '~hooks/useAuth';

type Props<TabPath extends string> = {
  tabs: Record<TabPath, string>;
  tab: string;
  pathname: string;
  preserveSearchTabs?: TabPath[];
};

export const TABS_HEIGHT = 30;

// Returns a tab component for each allowed tab
export function Tabs<TabPath extends string>({
  tabs,
  tab,
  pathname,
  preserveSearchTabs = [],
}: Props<TabPath>) {
  const { search } = useLocation();
  const { user } = useAuth();
  const theme = useTheme();

  const tabNodes = useMemo(
    () =>
      Object.entries(tabs as Record<string, string>)
        .filter(([tabPath]) => isRoutePermitted(user, `${pathname}/${tabPath}`))
        .map(([tabPath, label]) => (
          <Tab
            key={tabPath}
            value={tabPath}
            label={label}
            component={Link}
            to={`../${tabPath}${preserveSearchTabs.includes(tabPath as TabPath) ? search : ''}`}
            sx={{
              minHeight: TABS_HEIGHT,
              py: 1,
            }}
          />
        )),
    [tabs, pathname, preserveSearchTabs, user, search],
  );

  return (
    <Box borderBottom={`1px solid ${theme.palette.divider}`}>
      {/* If 0 or 1 tab there is no need for tabs */}
      {tabNodes.length > 1 && (
        <TabContext value={tab}>
          <TabList variant="scrollable" sx={{ minHeight: TABS_HEIGHT }}>
            {tabNodes}
          </TabList>
        </TabContext>
      )}
    </Box>
  );
}
