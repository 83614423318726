import { Box, CircularProgress } from '@mui/material';

import { PageLayout } from '~layouts/PageLayout';

export function PageLayoutLoader() {
  return (
    <PageLayout>
      <Box minHeight="100%" flexGrow={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    </PageLayout>
  );
}
