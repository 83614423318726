import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useEdgeController } from '~hooks/useEdgeControllerList';

import { useControllersNavigation } from '~pages/controllers/hooks/useControllersNavigation';

export function useControllerBreadcrumbs() {
  const { edgeControllerId } = useControllersNavigation();
  const { data: edgeController } = useEdgeController(edgeControllerId);

  useRegisterBreadcrumbs({
    entityType: 'organization',
    entityId: edgeController?.organization.id,
    label: edgeController?.organization.human_name ?? null,
  });
  useRegisterBreadcrumbs({
    entityType: 'site',
    entityId: edgeController?.metering_group.id ?? undefined,
    label: edgeController?.metering_group.name ?? null,
  });
  useRegisterBreadcrumbs({
    entityType: 'controller',
    entityId: edgeController?.id,
    label: edgeController?.name ?? null,
  });
}
