import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from '@mui/material';

import type { Breadcrumb as BreadcrumbType } from '~types';

import { createEntityPath } from '~utils/createEntityPath';

import { BreadcrumbSiblings } from '~components/breadcrumbs/BreadcrumbSiblings';
import { BreadcrumbHierarchy } from '~components/breadcrumbs/BreadcrumbHierarchy';

type Props = {
  breadcrumb: BreadcrumbType;
  isLast: boolean;
};

export function Breadcrumb({ breadcrumb, isLast }: Props) {
  if (isLast) {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <BreadcrumbSiblings breadcrumb={breadcrumb} />
        <Typography fontSize={14} fontWeight={700} color="white">
          {breadcrumb.label || '...'}
        </Typography>
        <BreadcrumbHierarchy breadcrumb={breadcrumb} />
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <BreadcrumbSiblings breadcrumb={breadcrumb} />
      <Link
        to={
          breadcrumb.entityType && breadcrumb.entityId
            ? createEntityPath(breadcrumb.entityType, breadcrumb.entityId)
            : ''
        }
        component={RouterLink}
        sx={{
          fontSize: 14,
          textDecoration: 'none',
          ':hover': {
            textDecoration: 'underline',
          },
        }}
      >
        {breadcrumb.label || '...'}
      </Link>
    </Box>
  );
}
