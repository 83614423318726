import { useESUSiblings } from '~pages/esus/hooks/useESUSiblings';

import { SiblingsList } from '~components/siblings/SiblingsList';

type Props = {
  id: string;
};

export function ESUSiblingsList({ id }: Props) {
  const esuSiblings = useESUSiblings(id);

  return <SiblingsList entityType="esu" entityId={id} siblings={esuSiblings} />;
}
