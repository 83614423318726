import { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { toast } from 'react-hot-toast';
import {
  type CellChange,
  type CellStyle,
  type Column,
  ReactGrid,
  type Row,
  type TextCell,
} from '@silevis/reactgrid';

import './reactgrid-style-dark.css';

import type { BidData, MultiMarketSnapshot, ProductType } from '~types';

import { asCactosError, http } from '~http';

import { currentTimeZone, formatDateTimeNoTimeZone } from '~utils/time';

import { useMultiMarketSnapshot } from '~hooks/useMultiMarketSnapshot';

import { type BidRow, crossValidateBids } from '~pages/fleets/components/fcr/markets/fiFCR';
import { getNextDeliveryPeriodId } from '~pages/fleets/utils/getNextDeliveryPeriodId';

type BidUploadFormProps = {
  fleetId: string;
  updateBids: () => void;
  dayAheadPrices: { time: string; amount: string | null }[];
};

/** Price in €/MWh */
const MINIMUM_PRICE_FCR_N = 5;
/** Price in €/MWh */
const MINIMUM_PRICE_FCR_D = 1;

const STYLES = {
  FCR_N: {
    headerColor: '#424242',
    cellColor: '#757575',
  },
  FCR_D_UP: {
    headerColor: '#212121',
    cellColor: '#616161',
  },
  FCR_D_DOWN: {
    headerColor: '#303030',
    cellColor: '#9e9e9e',
  },
};
const GRID_COLUMNS: Column[] = [
  { columnId: 'start', width: 180 },
  { columnId: 'spot', width: 100 },
  { columnId: 'quantity_fcr_n', width: 110 },
  { columnId: 'price_fcr_n', width: 110 },
  { columnId: 'capacity_fcr_n', width: 110 },
  { columnId: 'quantity_fcr_d_up', width: 110 },
  { columnId: 'price_fcr_d_up', width: 110 },
  { columnId: 'capacity_fcr_d_up', width: 110 },
  { columnId: 'quantity_fcr_d_down', width: 110 },
  { columnId: 'price_fcr_d_down', width: 110 },
  { columnId: 'capacity_fcr_d_down', width: 110 },
];

const HISTORY_CELL_STYLE: CellStyle = {
  color: 'rgba(255, 255, 255, 0.5)',
  border: {
    bottom: {
      width: '2px',
      style: 'dashed',
    },
  },
};

export function BidUploadForm(props: BidUploadFormProps) {
  const { fleetId, updateBids } = props;

  const [selectedQuantity, setSelectedQuantity] = useState<string | null>(null);

  const [observationTime] = useState(() => dayjs().tz('CET').startOf('second').toISOString());
  const [deliveryPeriod] = useState(() =>
    getNextDeliveryPeriodId(dayjs().tz('CET').startOf('day').format('YYYY-MM-DD')),
  );

  const { data: snapshot } = useMultiMarketSnapshot(deliveryPeriod, observationTime, fleetId, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
    revalidateIfStale: false,
  });

  const totalFcrnCapacity = new Decimal(
    snapshot?.data['FI FCR-N']?.capacity_totals?.data[0]?.capacity || '0.0',
  )
    .div(1000)
    .toFixed(1);

  const defaultQuantity = '0.0';

  const quantityOptions = useMemo<ReactNode>(
    () =>
      getQuantityOptions(totalFcrnCapacity).map((value) => (
        <MenuItem key={value} value={value}>
          {value} MW
        </MenuItem>
      )),
    [totalFcrnCapacity],
  );

  const [uploadErrors, setUploadErrors] = useState<string[]>([]);

  const [bidRows, setBidRows] = useState<BidRow[]>(
    getHours().map((start) => ({
      start,
      quantity_fcr_n: '0.0',
      price_fcr_n: '0.0',
      capacity_fcr_n: '0.0',
      quantity_fcr_d_up: '0.0',
      price_fcr_d_up: '0.0',
      capacity_fcr_d_up: '0.0',
      quantity_fcr_d_down: '0.0',
      price_fcr_d_down: '0.0',
      capacity_fcr_d_down: '0.0',
    })),
  );

  const uploadBids = useCallback(
    async (dryRun: boolean) => {
      const formdata = new FormData();
      const name = 'file';
      formdata.append('name', name);
      formdata.append('file', constructCsvFile(name, bidRows));
      formdata.append('dry_run', dryRun ? 'true' : 'false');
      try {
        await http.post(`/v1/fcr/bid/${fleetId}`, formdata, { timeout: 20_000 });
        if (!dryRun) {
          toast.success('Bids uploaded!', { duration: 5_000 });
        }
        setUploadErrors([]);
      } catch (ex) {
        const errorMessage = asCactosError(ex).message;
        const errors = Array.isArray(errorMessage) ? errorMessage : [errorMessage];
        setUploadErrors(errors.map(String));
        if (!dryRun) {
          toast.error(`Bid upload failed: ${asCactosError(ex).message}`, { duration: 5_000 });
        }
      } finally {
        updateBids();
      }
    },
    [fleetId, bidRows, updateBids],
  );

  useEffect(() => {
    setBidRows(
      getHours().map((start) => {
        const fcrBid = findExistingBid(snapshot, start, 'FI FCR-N');
        const fcrDUpBid = findExistingBid(snapshot, start, 'FI FCR-D UP');
        const fcrDDownBid = findExistingBid(snapshot, start, 'FI FCR-D DOWN');

        return {
          start,
          quantity_fcr_n: fcrBid?.quantity_in_mw ?? '0.0',
          price_fcr_n: fcrBid?.price_per_mwh ?? '0.0',
          capacity_fcr_n: '0.0',
          quantity_fcr_d_up: fcrDUpBid?.quantity_in_mw ?? '0.0',
          price_fcr_d_up: fcrDUpBid?.price_per_mwh ?? '0.0',
          capacity_fcr_d_up: '0.0',
          quantity_fcr_d_down: fcrDDownBid?.quantity_in_mw ?? '0.0',
          price_fcr_d_down: fcrDDownBid?.price_per_mwh ?? '0.0',
          capacity_fcr_d_down: '0.0',
        };
      }),
    );
  }, [snapshot]);

  useEffect(() => {
    setBidRows((prevRows) =>
      prevRows.map((row) => ({ ...row, quantity_fcr_n: selectedQuantity ?? '0.0' })),
    );
  }, [selectedQuantity]);

  const validationErrors = validateBidRows(bidRows);

  const previousHour = dayjs(bidRows[0].start).subtract(1, 'hour').toISOString();
  const headerRows: Row[] = [
    {
      rowId: 'header1',
      cells: [
        { type: 'header', text: 'Start time' },
        { type: 'header', text: 'Spot' },
        { type: 'header', text: 'FCR-N', style: { background: STYLES.FCR_N.headerColor } },
        { type: 'header', text: 'FCR-N', style: { background: STYLES.FCR_N.headerColor } },
        { type: 'header', text: 'FCR-N', style: { background: STYLES.FCR_N.headerColor } },
        { type: 'header', text: 'FCR-D UP', style: { background: STYLES.FCR_D_UP.headerColor } },
        { type: 'header', text: 'FCR-D UP', style: { background: STYLES.FCR_D_UP.headerColor } },
        { type: 'header', text: 'FCR-D UP', style: { background: STYLES.FCR_D_UP.headerColor } },
        {
          type: 'header',
          text: 'FCR-D DOWN',
          style: { background: STYLES.FCR_D_DOWN.headerColor },
        },
        {
          type: 'header',
          text: 'FCR-D DOWN',
          style: { background: STYLES.FCR_D_DOWN.headerColor },
        },
        {
          type: 'header',
          text: 'FCR-D DOWN',
          style: { background: STYLES.FCR_D_DOWN.headerColor },
        },
      ],
    },
    {
      rowId: 'header2',
      cells: [
        { type: 'header', text: currentTimeZone() },
        { type: 'header', text: '€/MWh' },
        { type: 'header', text: 'Bid MW', style: { background: STYLES.FCR_N.headerColor } },
        { type: 'header', text: '€/MW', style: { background: STYLES.FCR_N.headerColor } },
        { type: 'header', text: 'Available MW', style: { background: STYLES.FCR_N.headerColor } },
        { type: 'header', text: 'Bid MW', style: { background: STYLES.FCR_D_UP.headerColor } },
        { type: 'header', text: '€/MW', style: { background: STYLES.FCR_D_UP.headerColor } },
        {
          type: 'header',
          text: 'Available MW',
          style: { background: STYLES.FCR_D_UP.headerColor },
        },
        {
          type: 'header',
          text: 'Bid MW',
          style: { background: STYLES.FCR_D_DOWN.headerColor },
        },
        { type: 'header', text: '€/MW', style: { background: STYLES.FCR_D_DOWN.headerColor } },
        {
          type: 'header',
          text: 'Capacity MW',
          style: { background: STYLES.FCR_D_DOWN.headerColor },
        },
      ],
    },
    {
      rowId: 'previous',
      cells: [
        { type: 'header', text: formatDateTimeNoTimeZone(previousHour), style: HISTORY_CELL_STYLE },
        {
          type: 'text',
          nonEditable: true,
          text: getSpotPrice(props.dayAheadPrices, previousHour),
          style: HISTORY_CELL_STYLE,
        },
        { type: 'header', text: '', style: HISTORY_CELL_STYLE },
        { type: 'header', text: '', style: HISTORY_CELL_STYLE },
        { type: 'header', text: '', style: HISTORY_CELL_STYLE },
        { type: 'header', text: '', style: HISTORY_CELL_STYLE },
        { type: 'header', text: '', style: HISTORY_CELL_STYLE },
        { type: 'header', text: '', style: HISTORY_CELL_STYLE },
        { type: 'header', text: '', style: HISTORY_CELL_STYLE },
        { type: 'header', text: '', style: HISTORY_CELL_STYLE },
        { type: 'header', text: '', style: HISTORY_CELL_STYLE },
      ],
    },
  ];

  const gridRows: Row[] =
    snapshot === undefined
      ? []
      : bidRows.map((row, idx) => {
          const validationResult = crossValidateBids(snapshot, row);
          const fcrn = validationResult['FI FCR-N'];
          const fcrdUp = validationResult['FI FCR-D UP'];
          const fcrdDown = validationResult['FI FCR-D DOWN'];
          return {
            rowId: idx,
            cells: [
              { type: 'header', text: formatDateTimeNoTimeZone(row.start) },
              {
                type: 'text',
                nonEditable: true,
                text: getSpotPrice(props.dayAheadPrices, row.start),
              },
              {
                type: 'text',
                text: row.quantity_fcr_n,
                style: { background: STYLES.FCR_N.cellColor },
              },
              {
                type: 'text',
                text: row.price_fcr_n,
                style: { background: STYLES.FCR_N.cellColor },
              },
              {
                type: 'text',
                nonEditable: true,
                text: `${fcrn.remainingCapacity.toFixed(1)} / ${fcrn.totalCapacity.toFixed(1)}`,
                style: {
                  background: fcrn.error ? 'darkred' : STYLES.FCR_N.cellColor,
                },
              },
              {
                type: 'text',
                text: row.quantity_fcr_d_up,
                style: { background: STYLES.FCR_D_UP.cellColor },
              },
              {
                type: 'text',
                text: row.price_fcr_d_up,
                style: { background: STYLES.FCR_D_UP.cellColor },
              },
              {
                type: 'text',
                nonEditable: true,
                text: `${fcrdUp.remainingCapacity.toFixed(1)} / ${fcrdUp.totalCapacity.toFixed(1)}`,
                style: {
                  background: fcrdUp.error ? 'darkred' : STYLES.FCR_D_UP.cellColor,
                },
              },
              {
                type: 'text',
                text: row.quantity_fcr_d_down,
                style: { background: STYLES.FCR_D_DOWN.cellColor },
              },
              {
                type: 'text',
                text: row.price_fcr_d_down,
                style: { background: STYLES.FCR_D_DOWN.cellColor },
              },
              {
                type: 'text',
                nonEditable: true,
                text: `${fcrdDown.remainingCapacity.toFixed(1)} / ${fcrdDown.totalCapacity.toFixed(
                  1,
                )}`,
                style: {
                  background: fcrdDown.error ? 'darkred' : STYLES.FCR_D_DOWN.cellColor,
                },
              },
            ],
          };
        });

  return (
    <Box>
      <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
        Bids to upload
      </Typography>

      <Box display="flex" gap={1} rowGap={2} my={2} flexWrap="wrap">
        <FormControl>
          <InputLabel id="quantity-select-label">Quantity</InputLabel>
          <Select
            labelId="quantity-select-label"
            value={selectedQuantity ?? defaultQuantity}
            label="Quantity"
            onChange={(e) => setSelectedQuantity(e.target.value)}
            sx={{ width: 160 }}
          >
            {quantityOptions}
          </Select>
        </FormControl>
      </Box>

      <Box mb={2} sx={{ overflowX: 'auto' }}>
        <ReactGrid
          columns={GRID_COLUMNS}
          stickyLeftColumns={1}
          rows={[...headerRows, ...gridRows]}
          onCellsChanged={(changes) => {
            setBidRows((prevRows) =>
              newBidsWithChanges(prevRows, changes as CellChange<TextCell>[]),
            );
            uploadBids(true);
          }}
          enableRangeSelection
          enableColumnSelection
          enableRowSelection
          enableFillHandle
        />
      </Box>

      {validationErrors.length > 0 && (
        <Box>
          {validationErrors.map((error) => (
            <Alert key={error} severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          ))}
        </Box>
      )}

      {uploadErrors.length > 0 && (
        <Box>
          {uploadErrors.map((error) => (
            <Alert key={error} severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          ))}
        </Box>
      )}

      <Box display="flex" gap={2}>
        <Button
          component="label"
          variant="contained"
          disabled={validationErrors.length > 0}
          onClick={() => uploadBids(false)}
        >
          Upload Bids
        </Button>
        <Button
          component="label"
          variant="text"
          disabled={validationErrors.length > 0}
          onClick={() => downloadCsv(bidRows)}
        >
          Download CSV
        </Button>
      </Box>
    </Box>
  );
}

function getSpotPrice(dayAheadPrices: { time: string; amount: string | null }[], start: string) {
  const spotRow = dayAheadPrices.find((p) => dayjs(p.time).isSame(start));
  return spotRow?.amount ?? '';
}

function findExistingBid(
  snapshot: MultiMarketSnapshot | undefined,
  start: string,
  type: ProductType,
): BidData | null {
  const bid = snapshot?.data[type]?.bids.data.find((b) => dayjs(b.start_time).isSame(start));
  if (bid == null || bid.quantity_in_mw === 'NaN') {
    return null;
  }
  return bid;
}

function getHours(): string[] {
  // Bids are done per one full CET calendar day
  const start = dayjs.utc().add(24, 'hours').tz('CET').startOf('day');
  let end = start.utc().add(24, 'hours').tz('CET');
  const startUtcOffset = start.utcOffset();
  const endUtcOffset = end.utcOffset();
  if (startUtcOffset !== endUtcOffset) {
    // If the there's a DST change between start and end, we need to adjust the end time.
    // When DST starts, the day is 23 hours long, so we need to subtract an hour.
    // When DST ends, the day is 25 hours long, so we need to add an extra hour.
    end = end.add(startUtcOffset - endUtcOffset, 'minutes');
  }
  const hours: string[] = [];
  let hour = start.utc();
  while (hour.isBefore(end)) {
    hours.push(hour.toISOString());
    hour = hour.add(1, 'hour');
  }
  return hours;
}

export function getQuantityOptions(totalFcrnCapacity: string | undefined): string[] {
  const options = ['0.0'];
  const max = new Decimal(totalFcrnCapacity ?? '0.0').toDecimalPlaces(1, Decimal.ROUND_HALF_UP);
  let value = Decimal.max('0.1', max.sub('0.5'));
  while (value.lte(max)) {
    options.push(value.toFixed(1));
    value = value.add('0.1');
  }
  return options;
}

function newBidsWithChanges(prevBids: BidRow[], changes: CellChange<TextCell>[]): BidRow[] {
  const newBids = [...prevBids];
  changes.forEach((change) => {
    const index = change.rowId;
    const columnName = change.columnId;
    if (
      typeof index === 'number' &&
      0 <= index &&
      index < newBids.length &&
      (columnName === 'quantity_fcr_n' ||
        columnName === 'price_fcr_n' ||
        columnName === 'quantity_fcr_d_up' ||
        columnName === 'price_fcr_d_up' ||
        columnName === 'quantity_fcr_d_down' ||
        columnName === 'price_fcr_d_down')
    ) {
      let value = change.newCell.text.trim();
      if (/^\d+,\d+$/.test(value)) {
        value = value.replace(',', '.');
      }
      newBids[index][columnName] = value;
    }
  });
  return newBids;
}

const parseDecimal = (value: string): Decimal => {
  try {
    return new Decimal(value);
  } catch {
    return new Decimal(NaN);
  }
};

function validatePrice(price: string, label: string, minimumPrice: number): string | null {
  const priceValue = parseDecimal(price);
  if (!priceValue.isFinite()) {
    return (
      `${label}: Invalid price "${price}". ` +
      (parseDecimal(price.replace(',', '.')).isFinite()
        ? `${label}: A period (".") must be used as the decimal separator.`
        : `${label}: The price must be a number.`)
    );
  }
  if (priceValue.lt(minimumPrice)) {
    return `${label}: Price of ${priceValue} is too low. The minimum is ${minimumPrice}.`;
  }
  return null;
}

function validateQuantity(quantity: string, label: string): string | null {
  const value = parseDecimal(quantity);
  if (!value.isFinite()) {
    return (
      `${label}: Invalid quantity "${quantity}". ` +
      (parseDecimal(quantity.replace(',', '.')).isFinite()
        ? `${label}: A period (".") must be used as the decimal separator.`
        : `${label}: The quantity must be a number.`)
    );
  }
  return null;
}

function validateBidRows(rows: BidRow[]): string[] {
  const errors: string[] = [];

  rows.forEach((row) => {
    // FCR-N validations
    const quantityErrorN = validateQuantity(row.quantity_fcr_n, 'FCR-N');
    if (quantityErrorN) {
      errors.push(quantityErrorN);
    } else if (parseDecimal(row.quantity_fcr_n).gt(0)) {
      const priceErrorN = validatePrice(row.price_fcr_n, 'FCR-N', MINIMUM_PRICE_FCR_N);
      if (priceErrorN) errors.push(priceErrorN);
    }

    // FCR-D UP validations
    const quantityErrorUp = validateQuantity(row.quantity_fcr_d_up, 'FCR-D UP');
    if (quantityErrorUp) {
      errors.push(quantityErrorUp);
    } else if (parseDecimal(row.quantity_fcr_d_up).gt(0)) {
      const priceErrorUp = validatePrice(row.price_fcr_d_up, 'FCR-D UP', MINIMUM_PRICE_FCR_D);
      if (priceErrorUp) errors.push(priceErrorUp);
    }

    // FCR-D DOWN validations
    const quantityErrorDown = validateQuantity(row.quantity_fcr_d_down, 'FCR-D DOWN');
    if (quantityErrorDown) {
      errors.push(quantityErrorDown);
    } else if (parseDecimal(row.quantity_fcr_d_down).gt(0)) {
      const priceErrorDown = validatePrice(row.price_fcr_d_down, 'FCR-D DOWN', MINIMUM_PRICE_FCR_D);
      if (priceErrorDown) errors.push(priceErrorDown);
    }
  });

  return Array.from(new Set(errors));
}

function constructCsvString(rows: BidRow[]): string {
  const csvRows: string[] = [];

  csvRows.push(
    [
      'start',
      'quantity_fcr_n',
      'price_fcr_n',
      'quantity_fcr_d_up',
      'price_fcr_d_up',
      'quantity_fcr_d_down',
      'price_fcr_d_down',
    ].join(','),
  );

  rows.forEach((row: BidRow) => {
    csvRows.push(
      [
        row.start,
        row.quantity_fcr_n,
        row.price_fcr_n,
        row.quantity_fcr_d_up,
        row.price_fcr_d_up,
        row.quantity_fcr_d_down,
        row.price_fcr_d_down,
      ].join(','),
    );
  });

  return csvRows.join('\n');
}

function constructCsvFile(name: string, rows: BidRow[]): File {
  return new File([new Blob([constructCsvString(rows)])], name);
}

function downloadCsv(bidRows: BidRow[]) {
  const link = document.createElement('a');
  link.id = 'download-link';
  // Element 10 should be for the representative date
  link.download = `FCR-bids-${dayjs(bidRows[10].start).format('YYYY-MM-DD')}.csv`;
  link.href = `data:text/plain;charset=utf-8,${encodeURIComponent(constructCsvString(bidRows))}`;
  link.click();
  link.remove();
}
