import { useMemo, useState } from 'react';
import {
  type MRT_ColumnDef,
  type MRT_TableInstance,
  MaterialReactTable,
  useMRT_Rows,
  useMaterialReactTable,
} from 'material-react-table';
import { Box } from '@mui/material';

import { type MeteringGroup } from '~types';

import { USER_ROLES } from '~constants/auth';

import { collator } from '~utils/localization';

import { useMeteringGroupList } from '~hooks/useMeteringGroupList';
import { useTableColumnState } from '~hooks/useTableColumnState';
import { useUserHasRole } from '~hooks/useUserHasRole';
import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useSetTableEntities } from '~hooks/useTableEntities';
import { useTableCommonProps } from '~hooks/useTableCommonProps';
import { useGetTableBodyRowProps } from '~hooks/useGetTableBodyRowProps';

import { PageLayout } from '~layouts/PageLayout';

import { GroupCreateModal } from '~pages/sites/components/GroupCreateModal';
import { createSitePath } from '~pages/sites/utils/createSitePath';

import { MRTEmptyRowsFallback } from '~components/MRTEmptyRowsFallback';

const COLUMNS: MRT_ColumnDef<MeteringGroup>[] = [
  {
    header: 'Name',
    id: 'name',
    accessorFn: (row) => row.name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    Footer: SiteNameFooter,
  },
  {
    header: 'Organization',
    id: 'organization_name',
    accessorFn: (row) => row.organization_name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    filterVariant: 'autocomplete',
    size: 400,
  },
];

export function Sites() {
  const { data, error } = useMeteringGroupList();

  useRegisterBreadcrumbs({ label: 'Sites' });

  const [isCreateMeteringGroupModalOpen, setIsCreateMeteringGroupModalOpen] = useState(false);
  const userCanCreateNewGroup = useUserHasRole(USER_ROLES.EDIT_EMS);

  const actions = useMemo(() => {
    if (!userCanCreateNewGroup) return [];

    return [
      {
        icon: 'mdi:plus',
        label: 'Create Site',
        onClick: () => setIsCreateMeteringGroupModalOpen(true),
      },
    ];
  }, [userCanCreateNewGroup]);

  const {
    columnSizing,
    columnFilters,
    columnVisibility,
    sorting,
    setColumnSizing,
    setColumnFilters,
    setColumnVisibility,
    setSorting,
  } = useTableColumnState('sites');
  const tableCommonProps = useTableCommonProps<MeteringGroup>();
  const getTableBodyRowProps = useGetTableBodyRowProps(createSitePath);
  const table = useMaterialReactTable<MeteringGroup>({
    ...tableCommonProps,
    data: data ?? [],
    columns: COLUMNS,
    state: {
      columnSizing,
      columnFilters,
      columnVisibility,
      sorting,
    },
    onColumnSizingChange: setColumnSizing,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => getTableBodyRowProps(row),
    renderEmptyRowsFallback: () => (
      <MRTEmptyRowsFallback error={error} noItemsMessage="No sites to display" />
    ),
  });

  useSetTableEntities(
    'site',
    table.getRowModel().rows.map((row) => ({ id: row.original.id })),
  );

  return (
    <>
      <PageLayout hasDivider title="Sites" actions={actions}>
        <MaterialReactTable table={table} />
      </PageLayout>
      <GroupCreateModal
        open={isCreateMeteringGroupModalOpen}
        onClose={() => setIsCreateMeteringGroupModalOpen(false)}
      />
    </>
  );
}

function SiteNameFooter({ table }: { table: MRT_TableInstance<MeteringGroup> }) {
  const rows = useMRT_Rows(table);

  return (
    <Box display="flex" alignItems="center">
      {rows.length} groups
    </Box>
  );
}
