import { ESUDiagnostics as ESUDiagnosticsContent } from '~pages/esus/components/ESUDiagnosticsContent';
import { ESULayout } from '~pages/esus/components/ESULayout';

export function ESUDiagnostics() {
  return (
    <ESULayout title="ESU Diagnostics">
      <ESUDiagnosticsContent />
    </ESULayout>
  );
}
