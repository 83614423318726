import type React from 'react';
import { type Dispatch, type SetStateAction, useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import type { Attachment, Category, Direction } from '~pages/sites/components/pricing/types';

import { Iconify } from '~components/Iconify';

type AttachmentListItemProps = {
  item: Attachment;
  itemIndex: number;
  handleChange: (index: number, change: Partial<Attachment>) => void;
  handleRemove: (index: number) => void;
};

type AttachmentFormProps = {
  attachments: Attachment[];
  setAttachments: Dispatch<SetStateAction<Attachment[]>>;
};

export const formatSize = (sizeInBytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (sizeInBytes === 0) return '0 Byte';
  const i = Number(Math.floor(Math.log(sizeInBytes) / Math.log(1024)));
  return `${Math.round(sizeInBytes / 1024 ** i)} ${sizes[i]}`;
};

export const fileIcon = (fileName: string) => {
  const fileSuffix = fileName.slice(fileName.lastIndexOf('.'));
  switch (fileSuffix) {
    case '.jpg':
    case '.jpge':
    case '.png':
    case '.gif':
      return 'mdi:image';
    case '.zip':
    case '.rar':
    case '.7z':
      return 'mdi:folder';
    case '.pdf':
      return 'mdi:file-pdf';
    default:
      return 'mdi:attachment';
  }
};

export const isValidAttachmentURL = (url?: string | null | undefined) => {
  if (!url) return false;

  const urlPattern = /^(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
  return urlPattern.test(url);
};

export const attachmentControlSx = {
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  backgroundColor: 'rgba(240, 240, 240, 0.03)',
  borderRadius: '0.5rem',
  padding: '0.5rem',
};

export const attachmentListSx = {
  border: '1px solid #ccc',
  borderRadius: 1,
  padding: '8px',
  minHeight: '10rem',
  maxHeight: '40rem',
  overflowY: 'auto',
  '& > *:nth-of-type(odd)': {
    backgroundColor: 'rgba(240, 240, 240, 0.05)',
  },
  '& > *:nth-of-type(even)': {
    backgroundColor: 'rgba(10, 10, 10, 0.05)',
  },
};

export const attachmentPaperSx = {
  border: '2px dashed #ccc',
  borderRadius: '3px',
  textAlign: 'center',
  marginBottom: '16px',
  cursor: 'pointer',
};

function AttachmentListItem({
  item,
  itemIndex,
  handleChange,
  handleRemove,
}: AttachmentListItemProps) {
  const handleDirectionChange = useCallback(
    (checked: boolean, direction: Direction) => {
      handleChange(itemIndex, {
        ...item,
        content_metadata: {
          ...item.content_metadata,
          direction: checked
            ? [...(item.content_metadata?.direction || []), direction]
            : (item.content_metadata?.direction || []).filter((dir) => dir !== direction),
        },
      });
    },
    [handleChange, item, itemIndex],
  );

  const handleCategoryChange = useCallback(
    (checked: boolean, category: Category) => {
      handleChange(itemIndex, {
        ...item,
        content_metadata: {
          ...item.content_metadata,
          category: checked
            ? [...(item.content_metadata?.category || []), category]
            : (item.content_metadata?.category || []).filter((cat) => cat !== category),
        },
      });
    },
    [handleChange, item, itemIndex],
  );

  return (
    <ListItem
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem',
        gap: 1,
      }}
    >
      <IconButton disabled sx={{ width: '5rem' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
          {item.content_metadata.file ? (
            <>
              <Iconify icon={fileIcon(item.content_metadata.name)} />
              <Typography>{item.file && formatSize(item.file.size)}</Typography>
            </>
          ) : (
            <>
              <Iconify icon="mdi:open-in-new" />
              <Typography>URL</Typography>
            </>
          )}
        </Box>
      </IconButton>

      <Grid container spacing={1} justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {item.content_metadata.file && (
              <TextField
                label="Name"
                value={item.content_metadata.name}
                size="small"
                aria-readonly
              />
            )}

            {!item.content_metadata.file && (
              <>
                <TextField
                  label="Name"
                  size="small"
                  value={item.content_metadata.name}
                  onChange={(e) =>
                    handleChange(itemIndex, {
                      ...item,
                      content_metadata: {
                        ...(item.content_metadata ?? {}),
                        name: e.target.value,
                      },
                    })
                  }
                />
                <TextField
                  label="URL"
                  size="small"
                  value={item.content_metadata.url}
                  error={!isValidAttachmentURL(item.content_metadata.url)}
                  onChange={(e) =>
                    handleChange(itemIndex, {
                      ...item,
                      content_metadata: { ...(item.content_metadata ?? {}), url: e.target.value },
                    })
                  }
                />
              </>
            )}

            <TextField
              size="small"
              label="Description"
              multiline
              value={item.content_metadata.description}
              onChange={(e) =>
                handleChange(itemIndex, {
                  ...item,
                  content_metadata: {
                    ...(item.content_metadata ?? {}),
                    description: e.target.value,
                  },
                })
              }
            />
          </Box>
        </Grid>

        <Grid item xs={5}>
          <FormControl fullWidth>
            <FormLabel>Direction</FormLabel>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <FormControlLabel
                label="Import"
                control={
                  <Checkbox
                    checked={item.content_metadata.direction.includes('IMPORT')}
                    onChange={(e) => handleDirectionChange(e.target.checked, 'IMPORT')}
                  />
                }
              />
              <FormControlLabel
                label="Export"
                control={
                  <Checkbox
                    checked={item.content_metadata.direction.includes('EXPORT')}
                    onChange={(e) => handleDirectionChange(e.target.checked, 'EXPORT')}
                  />
                }
              />
            </Box>

            <FormLabel>Category</FormLabel>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <FormControlLabel
                label="Transfer"
                control={
                  <Checkbox
                    checked={item.content_metadata.category.includes('TRANSFER')}
                    onChange={(e) => handleCategoryChange(e.target.checked, 'TRANSFER')}
                  />
                }
              />
              <FormControlLabel
                label="Energy"
                control={
                  <Checkbox
                    checked={item.content_metadata.category.includes('ENERGY')}
                    onChange={(e) => handleCategoryChange(e.target.checked, 'ENERGY')}
                  />
                }
              />
              <FormControlLabel
                label="Fee"
                control={
                  <Checkbox
                    checked={item.content_metadata.category.includes('FEE')}
                    onChange={(e) => handleCategoryChange(e.target.checked, 'FEE')}
                  />
                }
              />
              <FormControlLabel
                label="Tax"
                control={
                  <Checkbox
                    checked={item.content_metadata.category.includes('TAX')}
                    onChange={(e) => handleCategoryChange(e.target.checked, 'TAX')}
                  />
                }
              />
            </Box>
          </FormControl>
        </Grid>
      </Grid>

      <IconButton
        onClick={() => handleRemove(itemIndex)}
        edge="end"
        color="error"
        sx={{ marginLeft: '1rem' }}
      >
        <Iconify icon="mdi:delete" />
      </IconButton>
    </ListItem>
  );
}

export function AttachmentForm({ attachments, setAttachments }: AttachmentFormProps) {
  const handleUrlClick = useCallback(() => {
    setAttachments((prevFiles) => [
      ...prevFiles,
      {
        content_metadata: {
          url: '',
          name: '',
          file: false,
          description: '',
          direction: [],
          category: [],
        },
      },
    ]);
  }, [setAttachments]);

  const handleFiles = useCallback(
    (files: Attachment[]) => {
      setAttachments((prevFiles) => [...prevFiles, ...files]);
    },
    [setAttachments],
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleFiles(
        Array.from(e.target.files || [])
          .filter((file) => file.size > 0)
          .map((file) => ({
            file,
            content_metadata: {
              name: file.name,
              file: true,
              description: '',
              direction: [],
              category: [],
            },
          })),
      );
    },
    [handleFiles],
  );

  const handleDropFiles = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      handleFiles(
        Array.from(e.dataTransfer.files || [])
          .filter((file) => file.size > 0)
          .map((file) => ({
            file,
            content_metadata: {
              name: file.name,
              file: true,
              description: '',
              direction: [],
              category: [],
            },
          })),
      );
    },
    [handleFiles],
  );

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  const handleRemove = useCallback(
    (index: number) => {
      setAttachments((prevAttachments) => {
        const updatedAttachments = [...prevAttachments];
        updatedAttachments.splice(index, 1);
        return updatedAttachments;
      });
    },
    [setAttachments],
  );

  const handleChange = useCallback(
    (index: number, change: Partial<Attachment>) => {
      setAttachments((prevAttachments) =>
        prevAttachments.map((item, itemIndex) =>
          index !== itemIndex
            ? item
            : {
                ...item,
                content_metadata: {
                  ...(item.content_metadata ?? {}),
                  ...change.content_metadata,
                },
              },
        ),
      );
    },
    [setAttachments],
  );

  const attachmentsMemo = useMemo(
    () =>
      attachments.map((item, itemIndex) => (
        <AttachmentListItem
          key={`attachment-item-${itemIndex}`}
          item={item}
          itemIndex={itemIndex}
          handleChange={handleChange}
          handleRemove={handleRemove}
        />
      )),
    [attachments, handleChange, handleRemove],
  );

  return (
    <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <FormLabel>Attachments</FormLabel>

      {attachmentsMemo.length > 0 && <List sx={attachmentListSx}>{attachmentsMemo}</List>}

      <FormControl sx={attachmentControlSx}>
        <FormLabel>File attachment</FormLabel>
        <Paper sx={attachmentPaperSx} onDragOver={handleDragOver} onDrop={handleDropFiles}>
          <label htmlFor="fileInput">
            <Typography
              sx={{
                cursor: 'pointer',
                flex: 1,
                padding: attachments.length > 0 ? '20px' : '150px',
              }}
            >
              Drag and drop some files here, or <strong>click to select files</strong>
            </Typography>

            <input
              multiple
              type="file"
              accept="image/*, application/pdf"
              onChange={handleInputChange}
              style={{ display: 'none' }}
              id="fileInput"
            />
          </label>
        </Paper>
      </FormControl>

      <FormControl sx={attachmentControlSx}>
        <FormLabel>URL attachment</FormLabel>

        <Box>
          <Button variant="contained" onClick={handleUrlClick}>
            Add URL
          </Button>
        </Box>
      </FormControl>
    </FormControl>
  );
}
