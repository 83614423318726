import { Outlet } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import { ThemeConfig } from '~theme/index';
import { GlobalStyles } from '~theme/globalStyles';

import { AuthProvider } from '~hooks/useAuth';

export function App() {
  return (
    <ThemeConfig>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <GlobalStyles />
        <AuthProvider>
          <Outlet />
        </AuthProvider>
      </LocalizationProvider>
    </ThemeConfig>
  );
}
