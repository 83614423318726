import { type Dispatch, type SetStateAction, createContext, useContext, useEffect } from 'react';

import type { Breadcrumb } from '~types';

export type BreadcrumbsContextType = {
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: Dispatch<SetStateAction<Breadcrumb[]>>;
  registerBreadcrumbs: (breadcrumb: Breadcrumb) => () => void;
};

export const BreadcrumbsContext = createContext<BreadcrumbsContextType>({
  breadcrumbs: [],
  setBreadcrumbs: () => {},
  registerBreadcrumbs: () => () => {},
});

export function useBreadcrumbs() {
  return useContext(BreadcrumbsContext);
}

export function useRegisterBreadcrumbs(breadcrumb: Breadcrumb) {
  const { registerBreadcrumbs } = useBreadcrumbs();

  useEffect(
    () => registerBreadcrumbs(breadcrumb),
    // Putting `breadcrumb` as a dependency would cause infinite render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [breadcrumb.entityType, breadcrumb.entityId, breadcrumb.label, registerBreadcrumbs],
  );
}
