import { type MouseEvent, useCallback, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';

import type { PageLayoutAction } from '~types';

import { Iconify } from '~components/Iconify';

type Props = {
  actions: PageLayoutAction[];
};

export function Actions({ actions }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  if (!actions.length) return null;

  return (
    <>
      <IconButton onClick={handleClick}>
        <Iconify icon="mdi:dots-vertical" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {actions.map((action) => (
          <MenuItem
            key={action.label}
            onClick={() => {
              action.onClick();
              handleClose();
            }}
          >
            {action.icon && <Iconify icon={action.icon} mr={1} />}
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
