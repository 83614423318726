import { Box, useTheme } from '@mui/material';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { curveStepAfter } from '@visx/curve';
import { localPoint } from '@visx/event';
import { Grid } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleLinear, scaleOrdinal, scaleTime } from '@visx/scale';
import { AreaStack, Line, LinePath } from '@visx/shape';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import type { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip';
import { bisector } from 'd3-array';
import dayjs from 'dayjs';
import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { ParentSize } from '@visx/responsive';

import type { Region } from '~types';

import { type DateTimeRange } from '~utils/time';

import {
  ChartLegend,
  TOOLTIP_STYLES,
  formatAxisDate,
  formatTooltipDate,
  getContinuousAxisNumTicks,
  useCommonChartStyles,
} from '~components/charts/utils';

const CHART_MARGIN = { top: 20, left: 40, right: 20, bottom: 20 };

const fcrAllocationLineColor = '#FFFFFF';

export type AggregateActivationDataPoint = {
  time: Date;
  [reserveObjectId: string]: number | Date;
};

export type AllocationDataPoint = {
  time: Date;
  quantity: number;
  duration: number;
};

type FCRGraphProps = {
  productDisplayName: string;
  allocations: AllocationDataPoint[];
  activations: AggregateActivationDataPoint[];
  now: Date;
  range: DateTimeRange;
  reserveObjects: Region[];
};

export function FCRGraph({
  productDisplayName,
  activations,
  allocations,
  now,
  range,
  reserveObjects,
}: FCRGraphProps) {
  const [highlightedChartElementId, setHighlightedChartElementId] = useState<string | null>(null);
  return (
    <Box>
      <ParentSize debounceTime={100}>
        {({ width }) => (
          <FCRStackedAreaChart
            now={now}
            range={range}
            width={width}
            height={380}
            reserveObjects={reserveObjects}
            highlightedChartElementId={highlightedChartElementId}
            allocations={allocations}
            activations={activations}
            productDisplayName={productDisplayName}
          />
        )}
      </ParentSize>
      <FCRChartLegend
        productName={productDisplayName}
        reserveObjects={reserveObjects}
        highlightedChartElementId={highlightedChartElementId}
        setHighlightedChartElementId={setHighlightedChartElementId}
      />
    </Box>
  );
}

type FCRChartLegendProps = {
  productName: string;
  reserveObjects: Region[];
  highlightedChartElementId: string | null;
  setHighlightedChartElementId: React.Dispatch<React.SetStateAction<string | null>>;
};

export function FCRChartLegend({
  productName,
  reserveObjects,
  highlightedChartElementId,
  setHighlightedChartElementId,
}: FCRChartLegendProps) {
  const colorScale = useColorScale(reserveObjects);
  const legendItems = useMemo(
    () => [
      {
        id: 'fcr-allocation-line',
        displayName: `${productName} allocation`,
        color: fcrAllocationLineColor,
      },
      ...reserveObjects.map((reserveObject) => ({
        id: reserveObject.id,
        displayName: `${reserveObject.id} (${reserveObject.name})`,
        color: colorScale(reserveObject.id),
      })),
    ],
    [reserveObjects, productName, colorScale],
  );

  return (
    <ChartLegend
      items={legendItems}
      selectedItemID={highlightedChartElementId}
      onSelectedItemChange={setHighlightedChartElementId}
    />
  );
}

type FCRStackedAreaChartProps = {
  allocations: AllocationDataPoint[];
  activations: AggregateActivationDataPoint[];
  now: Date;
  range: DateTimeRange;
  width: number;
  height: number;
  reserveObjects: Region[];
  // either a reserve object id, 'fcr-allocation-line' or null
  highlightedChartElementId: string | null;
  productDisplayName: string;
};

export const FCRStackedAreaChart = ({
  allocations,
  activations,
  now,
  range,
  width,
  height,
  reserveObjects,
  productDisplayName,
  highlightedChartElementId,
}: FCRStackedAreaChartProps) => {
  const colorScale = useColorScale(reserveObjects);
  const { start: timeRangeMin, end: timeRangeMax } = range;
  const tooltip = useTooltip<TooltipData>();
  const { showTooltip, hideTooltip, tooltipData, tooltipOpen } = tooltip;

  const {
    highlightColor,
    axisColor,
    axisBottomTickLabelProps,
    axisLeftTickLabelProps,
    gridColor,
    gridStrokeDasharray,
  } = useCommonChartStyles();

  const yMax = height - CHART_MARGIN.top - CHART_MARGIN.bottom;
  const xMax = width - CHART_MARGIN.left - CHART_MARGIN.right;

  const timeScale = scaleTime<number>({
    range: [0, xMax],
    // we don't want to have the current time at the very right edge of the chart
    domain: [
      timeRangeMin,
      dayjs.max([dayjs.utc(timeRangeMax), dayjs.utc(now).add(5, 'minute')]).toDate(),
    ],
  });

  const yScale = useMemo(() => {
    const totals = [
      ...allocations.flatMap((row) => (Number.isFinite(row.quantity) ? row.quantity : [])),
      ...activations.map((row) =>
        reserveObjects
          .map(({ id }) => (Number.isFinite(row[id]) ? (row[id] as number) : 0))
          .reduce((acc, val) => acc + val, 0),
      ),
    ];
    const maxValue = totals.length ? Math.max(...totals) : null;
    return scaleLinear<number>({
      range: [yMax, 0],
      domain: [0, maxValue || 1],
      nice: true,
    });
  }, [allocations, activations, reserveObjects, yMax]);

  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>): void => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 };
      const bisectDate = bisector((d: { time: Date }) => d.time);
      const dateUnderCursor = timeScale.invert(x - CHART_MARGIN.left);
      const allocationIndex = bisectDate.left(allocations, dateUnderCursor);

      showTooltip({
        tooltipData: {
          time: dateUnderCursor,
          allocation:
            allocations.length === 0 || allocationIndex === 0
              ? NaN
              : allocations[allocationIndex - 1].quantity,
          activations: Object.fromEntries(
            reserveObjects.map(({ id }) => {
              if (activations.length === 0) return [id, NaN];
              const index = bisectDate.left(activations, dateUnderCursor);
              if (index === 0 || index >= activations.length) return [id, NaN];
              return [id, activations[index - 1][id] as number];
            }),
          ),
        },
        tooltipLeft: x,
        tooltipTop: y,
      });
    },
    [showTooltip, allocations, activations, reserveObjects, timeScale],
  );

  if (yMax < 0 || xMax < 0) return null;

  return (
    <div style={{ position: 'relative' }}>
      <svg width={width} height={height}>
        <Group left={CHART_MARGIN.left} top={CHART_MARGIN.top}>
          <Grid
            xScale={timeScale}
            numTicksColumns={getContinuousAxisNumTicks(width)}
            yScale={yScale}
            width={xMax}
            height={yMax}
            stroke={gridColor}
            strokeDasharray={gridStrokeDasharray}
          />
          <AxisLeft
            scale={yScale}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={axisLeftTickLabelProps}
          />
          <AxisBottom
            hideAxisLine
            hideTicks
            top={yMax}
            scale={timeScale}
            numTicks={getContinuousAxisNumTicks(width)}
            tickFormat={(date) => formatAxisDate(date as Date)}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={axisBottomTickLabelProps}
          />

          <AreaStack
            top={0}
            left={0}
            keys={[...reserveObjects].map(({ id }) => id).reverse()} // same order as in tooltip/legend
            data={activations}
            x={(d) => timeScale(d.data.time)}
            y0={(d) => yScale(d[0])}
            y1={(d) => yScale(d[1])}
            defined={(d) => Number.isFinite(d[0]) && Number.isFinite(d[1])}
            curve={curveStepAfter}
          >
            {({ stacks, path }) =>
              stacks.map((stack) => (
                <path
                  key={`stack-${stack.key}`}
                  d={path(stack) ?? ''}
                  stroke="transparent"
                  fill={colorScale(stack.key)}
                  opacity={
                    highlightedChartElementId != null && highlightedChartElementId !== stack.key
                      ? 0.15
                      : 0.7
                  }
                />
              ))
            }
          </AreaStack>

          <Line
            from={{ x: timeScale(now), y: 0 }}
            to={{ x: timeScale(now), y: yMax }}
            stroke={highlightColor}
            strokeWidth={1}
            pointerEvents="none"
          />
          <Group left={timeScale(now)} top={-8}>
            <rect
              x={-15}
              y={-8}
              width={30}
              height={16}
              strokeWidth={1}
              stroke={highlightColor}
              fill="transparent"
              rx={3.5}
            />
            <text fontSize="10px" dominantBaseline="middle" textAnchor="middle" fill="white">
              Now
            </text>
          </Group>

          <LinePath
            key="fcr-allocation-line"
            stroke={fcrAllocationLineColor}
            strokeWidth={1.5}
            strokeDasharray="1,3"
            data={allocations}
            curve={curveStepAfter}
            x={(d) => timeScale(d.time)}
            y={(d) => yScale(d.quantity)}
            defined={(d) => Number.isFinite(d.quantity)}
            opacity={
              highlightedChartElementId != null &&
              highlightedChartElementId !== 'fcr-allocation-line'
                ? 0.4
                : 1
            }
          />

          {tooltipData && (
            <Line
              from={{ x: timeScale(tooltipData.time), y: 0 }}
              to={{ x: timeScale(tooltipData.time), y: yMax }}
              stroke={highlightColor}
              strokeWidth={1}
              pointerEvents="none"
              strokeDasharray="5,2"
            />
          )}
        </Group>

        <rect
          x={CHART_MARGIN.left}
          y={CHART_MARGIN.top}
          width={xMax}
          height={yMax}
          fill="transparent"
          onTouchStart={handleTooltip}
          onTouchMove={handleTooltip}
          onMouseMove={handleTooltip}
          onMouseLeave={() => hideTooltip()}
        />
      </svg>
      {tooltipOpen && (
        <FCRChartTooltip
          productName={productDisplayName}
          tooltip={tooltip}
          reserveObjects={reserveObjects}
        />
      )}
    </div>
  );
};

type TooltipData = {
  time: Date;
  allocation: number;
  activations: { [reserveObjectId: string]: number };
};

type FCRChartTooltipProps = {
  productName: string;
  tooltip: UseTooltipParams<TooltipData>;
  reserveObjects: Region[];
};

export function FCRChartTooltip({ productName, tooltip, reserveObjects }: FCRChartTooltipProps) {
  const colorScale = useColorScale(reserveObjects);
  const { highlightColor } = useCommonChartStyles();
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen } = tooltip;

  if (!tooltipOpen || !tooltipData) return null;
  return (
    <TooltipWithBounds
      key={`tooltip-${tooltipTop}-${tooltipLeft}-${tooltipData.time.getTime()}`}
      top={tooltipTop}
      left={tooltipLeft}
      style={TOOLTIP_STYLES}
    >
      <div style={{ color: highlightColor }}>
        <strong>{formatTooltipDate(tooltipData.time)}</strong>
      </div>
      {[
        {
          key: 'fcr-allocation-line',
          name: `${productName} allocation`,
          color: fcrAllocationLineColor,
          value: Number.isFinite(tooltipData.allocation)
            ? `${tooltipData.allocation.toFixed(3)} MW`
            : '-',
        },
        {
          key: 'maintained-capacity',
          name: 'Maintained capacity',
          color: '#FFFFFF',
          value:
            [
              reserveObjects
                .map(({ id }) => tooltipData.activations[id])
                .filter((value) => Number.isFinite(value)),
            ]
              .filter((values) => values.length > 0)
              .map((values) => `${values.reduce((acc, value) => acc + value, 0).toFixed(3)} MW`)
              .at(0) ?? '-',
        },
        ...reserveObjects.map(({ id }) => ({
          key: `reserve-object-${id}`,
          name: id,
          color: colorScale(id),
          value: Number.isFinite(tooltipData.activations[id])
            ? `${tooltipData.activations[id].toFixed(3)} MW`
            : '-',
        })),
      ].map((item) => (
        <div
          key={item.key}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <div style={{ display: 'flex', alignItems: 'center', marginRight: 5 }}>
            <div style={{ marginRight: 3 }}>
              <svg width="10" height="10">
                <g transform="translate(5, 5)">
                  <circle r="5" fill={item.color} />
                </g>
              </svg>
            </div>
            {item.name}:
          </div>
          <div>{item.value}</div>
        </div>
      ))}
    </TooltipWithBounds>
  );
}

const useColorScale = (reserveObjects: Region[]) => {
  const theme = useTheme();
  const colorScale = useMemo(
    () =>
      scaleOrdinal<string, string>({
        domain: reserveObjects.map((r) => r.id),
        range: theme.palette.chart.categorical14,
      }),
    [reserveObjects, theme],
  );
  return colorScale;
};
