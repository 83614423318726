import type { Tab } from '~pages/controllers/types';
import { TABS } from '~pages/controllers/constants';
import { useControllersNavigation } from '~pages/controllers/hooks/useControllersNavigation';
import { createControllerPath } from '~pages/controllers/utils/createControllerPath';

import { TabsRedirection } from '~components/TabsRedirection';

// Smart redirection for controllers index route
export function ControllerRedirect() {
  const { edgeControllerId, tab } = useControllersNavigation();

  return (
    <TabsRedirection
      tabs={TABS}
      currentTab={tab}
      createPath={(tab: Tab) => createControllerPath(edgeControllerId, tab)}
    />
  );
}
