import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { parse } from 'csv-parse/browser/esm/sync';

import { asCactosError, http } from '~http';

import { SCHEDULE_CSV_COLUMNS } from '~constants/schedule';

import { useScheduleHistory } from '~hooks/useScheduleHistory';

import { useESUsNavigation } from '~pages/esus/hooks/useESUsNavigation';

export function ScheduleUpload() {
  const [csvData, setCsvData] = useState([]);
  const [csvFile, setCsvFile] = useState();
  const [status, setStatus] = useState('');
  const [inputValue, setInputValue] = useState('');
  const { esuId } = useESUsNavigation();
  const { mutate: refetchScheduleHistory } = useScheduleHistory(esuId);

  const handleFileSelect = (e) => {
    if (e?.target?.files?.[0] === undefined) {
      return;
    }
    const file = e.target.files[0];
    selectFile(file);
  };

  const parseCsvRecords = (csv) =>
    parse(csv, {
      columns: true,
      delimiter: ',',
      trim: true,
      skip_empty_lines: true,
      strict: true,
    });

  const selectFile = useCallback(
    (file) => {
      console.log(file);
      const { name } = file;
      setStatus(`Chosen file: ${name}`);

      const reader = new FileReader();
      reader.onload = (evt) => {
        if (!evt?.target?.result) {
          return;
        }
        const { result } = evt.target;
        const records = parseCsvRecords(result);
        setCsvData(records);
        setCsvFile(file);
      };
      reader.readAsBinaryString(file);
    },
    [setStatus, setCsvData, setCsvFile],
  );

  const handleFileUpload = () => {
    if (esuId === '' || esuId === undefined) {
      return;
    }
    uploadFile();
  };

  const resetState = useCallback(() => {
    setCsvData([]);
    setCsvFile(null);
    setStatus('');
    setInputValue('');
  }, [setCsvData, setCsvFile, setStatus]);

  const uploadFile = useCallback(async () => {
    const formdata = new FormData();
    formdata.append('name', 'file');
    formdata.append('file', csvFile);
    try {
      await http.post(`/v1/ems/${esuId}/schedule_upload`, formdata, { responseType: 'text' });
      resetState();
      setStatus('File uploaded!');
    } catch (ex) {
      const message = asCactosError(ex).message;
      resetState();
      setStatus(`File upload failed: ${message}`);
    } finally {
      await refetchScheduleHistory();
    }
  }, [esuId, csvFile, resetState, setStatus, refetchScheduleHistory]);

  useEffect(() => {
    resetState();
  }, [resetState, esuId]);

  return (
    <Card>
      <CardHeader title="Custom schedule" />
      <CardContent>
        <Button component="label" variant="contained" sx={{ marginBottom: 2 }}>
          Select local CSV file…
          <input type="file" accept=".csv" hidden onChange={handleFileSelect} value={inputValue} />
        </Button>
        <Box marginBottom={2}>{status}</Box>
        {csvData.length > 0 && (
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {SCHEDULE_CSV_COLUMNS.map((column) => (
                    <TableCell key={column.id} align="left">
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ minWidth: 800 }}>
                {csvData.map((row) => (
                  <TableRow hover key={row.from_time} tabIndex={-1} role="checkbox">
                    {SCHEDULE_CSV_COLUMNS.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align="left">
                          {column.formatter(value)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {csvData.length > 0 && (
          <Button
            component="label"
            variant="contained"
            sx={{ marginRight: '1rem' }}
            onClick={handleFileUpload}
          >
            Upload CSV
          </Button>
        )}
      </CardContent>
    </Card>
  );
}
