import { type PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppBar, Box, Divider, Drawer, IconButton, List, Toolbar, styled } from '@mui/material';

import { useLocalStorage } from '~hooks/useLocalStorage';
import { SidebarWidthContext } from '~hooks/useSidebarWidth';
import { useCommandPalette } from '~hooks/useCommandPalette';
import { useIsDesktop } from '~hooks/useIsDesktop';

import { FleetsSidebarItem } from '~layouts/AppLayout/sidebar-items/FleetsSidebarItem';
import { OrganizationsSidebarItem } from '~layouts/AppLayout/sidebar-items/OrganizationsSidebarItem';
import { SitesSidebarItem } from '~layouts/AppLayout/sidebar-items/SitesSidebarItem';
import { ControllersSidebarItem } from '~layouts/AppLayout/sidebar-items/ControllersSidebarItem';
import { ESUsSidebarItem } from '~layouts/AppLayout/sidebar-items/ESUsSidebarItem';
import { ExplorerSidebarItem } from '~layouts/AppLayout/sidebar-items/ExplorerSidebarItem';
import { AccountMenu } from '~layouts/AppLayout/AccountMenu';

import { Logo } from '~components/Logo';
import { Iconify } from '~components/Iconify';

export const SIDEBAR_OPENED_WIDTH = 162;
export const SIDEBAR_CLOSED_WIDTH = 53;
export const SIDEBAR_MOBILE_HEIGHT = 48;
export const SIDEBAR_USER_HEIGHT = 46;

const ANIMATION_DURATION = 300;

const Main = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));

const SIDEBAR_ITEMS = (
  <>
    <Box flexGrow={1}>
      <List disablePadding>
        <FleetsSidebarItem />
        <OrganizationsSidebarItem />
        <SitesSidebarItem />
        <ControllersSidebarItem />
        <ESUsSidebarItem />
        <ExplorerSidebarItem />
      </List>
    </Box>
    <Divider />
    <AccountMenu />
  </>
);

export function SidebarLayout({ children }: PropsWithChildren) {
  const isDesktop = useIsDesktop();
  const location = useLocation();
  const { setOpen: setCommandPaletteOpen } = useCommandPalette();

  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isDesktopSidebarHovered, setIsDesktopSidebarHovered] = useState(false);
  const [isDesktopSidebarForcedClosed, setIsDesktopSidebarForcedClosed] = useState(false);
  const [isDesktopSidebarKeptOpen, setIsDesktopSidebarKeptOpen] = useLocalStorage(
    'desktop-sidebar-keep-open',
    true,
  );

  const isDesktopSidebarOpen =
    (isDesktopSidebarKeptOpen || isDesktopSidebarHovered) && !isDesktopSidebarForcedClosed;

  // Close the sidebar drawer when location changes
  useEffect(() => {
    setIsMobileSidebarOpen(false);
  }, [isDesktop, location]);

  const sidebarWidthContextValue = isDesktop
    ? isDesktopSidebarKeptOpen
      ? SIDEBAR_OPENED_WIDTH
      : SIDEBAR_CLOSED_WIDTH
    : 0;

  return (
    <SidebarWidthContext.Provider value={sidebarWidthContextValue}>
      <Box display="flex" flexGrow={1}>
        {!isDesktop && (
          <>
            <AppBar>
              <Toolbar variant="dense" disableGutters sx={{ px: 0.5 }}>
                <IconButton onClick={() => setIsMobileSidebarOpen((x) => !x)}>
                  <Iconify icon="mdi:menu" fontSize={24} color="grey.600" />
                </IconButton>
                <Box flexGrow={1} display="flex" justifyContent="center">
                  <Logo width={32} />
                </Box>
                <IconButton onClick={() => setCommandPaletteOpen((x) => !x)}>
                  <Iconify icon="mdi:magnify" fontSize={24} color="grey.600" />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Drawer
              open={isMobileSidebarOpen}
              onClose={() => setIsMobileSidebarOpen(false)}
              PaperProps={{
                sx: {
                  width: SIDEBAR_OPENED_WIDTH,
                  maxWidth: '100vw',
                  paddingTop: `${SIDEBAR_MOBILE_HEIGHT}px`,
                },
              }}
            >
              {SIDEBAR_ITEMS}
            </Drawer>
          </>
        )}

        {isDesktop && (
          <Box
            width={isDesktopSidebarKeptOpen ? SIDEBAR_OPENED_WIDTH : SIDEBAR_CLOSED_WIDTH}
            sx={{ transition: `width ${ANIMATION_DURATION}ms ease` }}
            onPointerEnter={() => setIsDesktopSidebarHovered(true)}
            onPointerLeave={() => setIsDesktopSidebarHovered(false)}
          >
            <Drawer
              open
              variant="persistent"
              PaperProps={{
                sx: {
                  width: isDesktopSidebarOpen ? SIDEBAR_OPENED_WIDTH : SIDEBAR_CLOSED_WIDTH,
                  transition: `width ${ANIMATION_DURATION}ms ease`,
                  overflow: 'hidden',
                },
              }}
            >
              <Box
                py={1}
                pr={1}
                position="relative"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Logo
                  ml={isDesktopSidebarOpen ? 2 : 0.75}
                  flexShrink={0}
                  sx={{ transition: `margin-left ${ANIMATION_DURATION}ms ease` }}
                />
                <IconButton
                  onClick={() => {
                    // Force collapse the sidebar on collapse click
                    if (isDesktopSidebarKeptOpen) {
                      setIsDesktopSidebarForcedClosed(true);
                      setTimeout(() => {
                        setIsDesktopSidebarForcedClosed(false);
                      }, ANIMATION_DURATION);
                    }
                    setIsDesktopSidebarKeptOpen((x) => !x);
                  }}
                  sx={{
                    opacity: isDesktopSidebarOpen ? 1 : 0,
                    transition: `opacity ${ANIMATION_DURATION / 2}ms ease ${
                      isDesktopSidebarOpen ? ANIMATION_DURATION / 2 : 0
                    }ms`,
                  }}
                >
                  <Iconify
                    icon="mdi:chevron-right"
                    color="grey.500"
                    sx={{
                      transform: `rotate(${isDesktopSidebarKeptOpen ? 180 : 0}deg)`,
                      transition: `transform ${ANIMATION_DURATION}ms ease`,
                    }}
                  />
                </IconButton>
              </Box>
              {SIDEBAR_ITEMS}
            </Drawer>
          </Box>
        )}

        <Main mt={!isDesktop ? `${SIDEBAR_MOBILE_HEIGHT}px` : undefined}>{children}</Main>
      </Box>
    </SidebarWidthContext.Provider>
  );
}
