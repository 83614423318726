import { useMemo } from 'react';
import useSWR from 'swr';

import { http } from '~http';

import { useFleetList } from '~hooks/useFleetList';

export function useMeteringGroupFleetMapping() {
  const { data: fleets, isLoading: fleetsLoading, error: fleetsError } = useFleetList();

  const key = fleets
    ? {
        uniqueKey: '/v1/region/fleet/:id/metering_group',
        fleets: fleets.map((f) => [f.id, `/v1/region/fleet/${f.id}/metering_group`]),
      }
    : null;
  const { data, isLoading, error } = useSWR<[string, string][]>(key, {
    fetcher: fetchMeteringGroupFleets,
  });

  const mappedData = useMemo(() => {
    if (fleets == null || data == null) return undefined;
    const fleetById = new Map(fleets.map((f) => [f.id, f]));
    return new Map(data.map(([groupId, fleetId]) => [groupId, fleetById.get(fleetId)!]));
  }, [fleets, data]);

  return {
    data: mappedData,
    isLoading: fleetsLoading || isLoading,
    error: fleetsError ?? error,
  };
}

async function fetchMeteringGroupFleets({ fleets }: { fleets: [fleetId: string, url: string][] }) {
  const results: [meteringGroupId: string, fleetId: string][] = [];
  for (const [fleetId, url] of fleets) {
    const result = await http.get(url);
    const fleetGroups = result.data as { id: string; name: string }[];
    for (const group of fleetGroups) {
      results.push([group.id, fleetId]);
    }
  }
  return results;
}
