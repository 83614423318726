import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useESU } from '~hooks/useESUList';

import { useESUsNavigation } from '~pages/esus/hooks/useESUsNavigation';

export function useESUBreadcrumbs() {
  const { esuId } = useESUsNavigation();
  const { data: esu } = useESU(esuId);

  useRegisterBreadcrumbs({
    entityType: 'organization',
    entityId: esu?.edge_controller.organization.id,
    label: esu?.edge_controller.organization.human_name ?? null,
  });
  useRegisterBreadcrumbs({
    entityType: 'site',
    entityId: esu?.edge_controller.metering_group.id ?? undefined,
    label: esu?.edge_controller.metering_group.name ?? null,
  });
  useRegisterBreadcrumbs({
    entityType: 'controller',
    entityId: esu?.edge_controller.id,
    label: esu?.edge_controller.name ?? null,
  });
  useRegisterBreadcrumbs({
    entityType: 'esu',
    entityId: esu?.id,
    label: esu?.name ?? null,
  });
}
