import { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Check } from '@mui/icons-material';
import { toast } from 'react-hot-toast';
import dayjs from 'dayjs';

import type { OrganizationInvite, OrganizationUser, UserOrInvite } from '~types';

import { asCactosError, http } from '~http';

import { collator } from '~utils/localization';

import { useOrganization } from '~hooks/useOrganization';
import { useUserMfa } from '~hooks/useUserMfa';
import { useCurrentOrganizationId } from '~hooks/useCurrentOrganizationId';

import { OrganizationUserInviteModal } from '~pages/organizations/components/OrganizationUserInviteModal';
import { printRole } from '~pages/organizations/utils/printRole';
import { MfaAlertModal } from '~pages/organizations/components/MfaAlertModal';
import { OrganizationUserRoleModal } from '~pages/organizations/components/OrganizationUserRoleModal';

export function OrganizationUserList() {
  const organizationId = useCurrentOrganizationId();
  const { data: organization, mutate } = useOrganization(organizationId);
  const { data: userMfa } = useUserMfa();

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [roleModalUser, setRoleModalUser] = useState<OrganizationUser | null>(null);
  const [loadingInviteEmail, setLoadingInviteEmail] = useState<string | null>(null);

  const usersOrInvites = useMemo(() => {
    const usersOrInvites: UserOrInvite[] = [];

    if (!organization) return usersOrInvites;

    organization.roles.forEach((role) => {
      const user = usersOrInvites.find((u) => u.email === role.user.email);

      if (user) {
        user.roles.add(role.role_type);
      } else {
        usersOrInvites.push({
          id: role.user.id,
          email: role.user.email,
          roles: new Set([role.role_type]),
          mfaEnabled: role.user.is_mfa_enabled,
          active: role.user.is_active,
        });
      }
    });

    organization.invites.forEach((invite) => {
      const user = usersOrInvites.find((u) => u.email === invite.email);

      if (user) {
        if (!user.invite || dayjs(invite.expires_at).isBefore(dayjs(user.invite.expires_at))) {
          return;
        }

        user.roles = new Set(invite.roles);
        user.invite = invite;
        user.inviteExpired = dayjs(invite.expires_at).subtract(1, 'second').isBefore(dayjs());
      } else {
        usersOrInvites.push({
          email: invite.email,
          roles: new Set(invite.roles),
          invite,
          inviteExpired: dayjs(invite.expires_at).subtract(1, 'second').isBefore(dayjs()),
        });
      }
    });

    return usersOrInvites.sort((a, b) => collator.compare(a.email, b.email));
  }, [organization]);

  const resendInvite = useCallback(
    async (invite: OrganizationInvite) => {
      if (loadingInviteEmail) return;

      setLoadingInviteEmail(invite.email);

      try {
        await http.post(`/v1/organization/${organizationId}/invite`, {
          email: invite.email,
          roles: invite.roles,
        });
        await mutate();
      } catch (error) {
        toast.error(`Failed to resend invite: ${asCactosError(error).message}`, {
          duration: 5_000,
        });
      }

      setLoadingInviteEmail(null);
    },
    [loadingInviteEmail, organizationId, mutate],
  );

  const cancelInvite = useCallback(
    async (email: string) => {
      if (loadingInviteEmail) return;

      setLoadingInviteEmail(email);

      try {
        await http.post(`/v1/organization/${organizationId}/cancel_invite`, {
          email,
        });
        await mutate();
      } catch (error) {
        toast.error(`Failed to cancel invite: ${asCactosError(error).message}`, {
          duration: 5_000,
        });
      }

      setLoadingInviteEmail(null);
    },
    [loadingInviteEmail, organizationId, mutate],
  );

  return (
    <>
      <Box pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" mb="1rem">
          Users
        </Typography>
        <Button onClick={() => setIsInviteModalOpen(true)}>Invite user</Button>
      </Box>
      {!!usersOrInvites.length && (
        <TableContainer>
          <Table size="small" style={{ tableLayout: 'fixed', minWidth: 896 }}>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell width={76}>Active</TableCell>
                <TableCell width={64}>2FA</TableCell>
                <TableCell width={128} />
                <TableCell width={128} />
              </TableRow>
            </TableHead>
            <TableBody>
              {usersOrInvites.map((userOrInvite) => (
                <TableRow
                  key={userOrInvite.email}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Typography noWrap>{userOrInvite.email}</Typography>
                  </TableCell>
                  <TableCell>{[...userOrInvite.roles].map(printRole).join(', ')}</TableCell>
                  <TableCell>{isUser(userOrInvite) && userOrInvite.active && <Check />}</TableCell>
                  <TableCell>
                    {isUser(userOrInvite) && userOrInvite.mfaEnabled && <Check />}
                  </TableCell>
                  <TableCell>
                    {!!userOrInvite.invite && !userOrInvite.inviteExpired && (
                      <Tooltip
                        title={`Expires ${dayjs(userOrInvite.invite.expires_at).fromNow()}`}
                        placement="top"
                      >
                        <Chip label="Invited" color="secondary" size="small" />
                      </Tooltip>
                    )}
                    {!!userOrInvite.invite && userOrInvite.inviteExpired && (
                      <Tooltip
                        title={`Expired ${dayjs(userOrInvite.invite.expires_at).fromNow()}`}
                        placement="top"
                      >
                        <Chip label="Invite expired" color="error" size="small" />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {!!userOrInvite.invite && userOrInvite.inviteExpired && (
                      <LoadingButton
                        size="small"
                        loading={loadingInviteEmail === userOrInvite.email}
                        onClick={() => resendInvite(userOrInvite.invite!)}
                      >
                        Resend invite
                      </LoadingButton>
                    )}
                    {!!userOrInvite.invite && !userOrInvite.inviteExpired && (
                      <LoadingButton
                        size="small"
                        loading={loadingInviteEmail === userOrInvite.email}
                        onClick={() => cancelInvite(userOrInvite.email)}
                      >
                        Cancel invite
                      </LoadingButton>
                    )}
                    {isUser(userOrInvite) && (
                      <Button size="small" onClick={() => setRoleModalUser(userOrInvite)}>
                        Edit roles
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!usersOrInvites.length && (
        <Box px={2}>
          <Typography variant="body1" color="textSecondary">
            No users yet
          </Typography>
        </Box>
      )}
      <OrganizationUserInviteModal
        open={isInviteModalOpen && !!userMfa?.mfa_enabled}
        onClose={() => setIsInviteModalOpen(false)}
      />
      <MfaAlertModal
        open={isInviteModalOpen && !userMfa?.mfa_enabled}
        onClose={() => setIsInviteModalOpen(false)}
      />
      <OrganizationUserRoleModal user={roleModalUser} onClose={() => setRoleModalUser(null)} />
    </>
  );
}

function isUser(user: UserOrInvite): user is OrganizationUser {
  return 'id' in user && 'mfaEnabled' in user && 'active' in user;
}
