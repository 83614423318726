import { useOrganization } from '~hooks/useOrganization';

import { HierarchyOrganization } from '~components/hierarchy/HierarchyOrganization';
import { HierarchyLoading } from '~components/hierarchy/HierarchyLoading';
import { ListEmptyPlaceholder } from '~components/ListEmptyPlaceholder';

type Props = {
  id: string;
};

export function OrganizationHierarchy({ id }: Props) {
  const { data: organization, isLoading } = useOrganization(id);

  if (isLoading) return <HierarchyLoading />;
  if (!organization) return <ListEmptyPlaceholder />;

  return <HierarchyOrganization isRoot organization={organization} />;
}
