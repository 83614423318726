import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useMeteringGroup } from '~hooks/useMeteringGroup';

import { useSitesNavigation } from '~pages/sites/hooks/useSitesNavigation';

export function useSiteBreadcrumbs() {
  const { meteringGroupId } = useSitesNavigation();
  const { data: meteringGroup } = useMeteringGroup(meteringGroupId);

  useRegisterBreadcrumbs({
    entityType: 'organization',
    entityId: meteringGroup?.organization_id,
    label: meteringGroup?.organization_name ?? null,
  });
  useRegisterBreadcrumbs({
    entityType: 'site',
    entityId: meteringGroup?.id,
    label: meteringGroup?.name ?? null,
  });
}
