import { SidebarItem } from '~layouts/AppLayout/SidebarItem';

import { Iconify } from '~components/Iconify';

export function ExplorerSidebarItem() {
  return (
    <SidebarItem
      path="/explorer"
      label="Explorer"
      icon={<Iconify icon="mdi:toy-brick-search" fontSize={20} />}
    />
  );
}
