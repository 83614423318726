import type { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

import { useESU } from '~hooks/useESUList';

import { PageLayout } from '~layouts/PageLayout';
import { PageLayoutLoader } from '~layouts/PageLayout/PageLayoutLoader';

import { PRESERVE_SEARCH_TABS, TABS } from '~pages/esus/constants';
import { useESUsNavigation } from '~pages/esus/hooks/useESUsNavigation';
import { useESUBreadcrumbs } from '~pages/esus/hooks/useESUBreadcrumbs';
import { createESUPath } from '~pages/esus/utils/createESUPath';
import { NotFound } from '~pages/NotFound';

import { Tabs } from '~components/Tabs';
import { ErrorInfo } from '~components/ErrorInfo';

type Props = PropsWithChildren<{
  title: string;
}>;

export function ESULayout({ title, children }: Props) {
  const { esuId, tab } = useESUsNavigation();
  const { data: esu, isLoading, error } = useESU(esuId);

  useESUBreadcrumbs();

  if (!esu) {
    if (error) return <ErrorInfo error={error} />;
    if (isLoading) return <PageLayoutLoader />;

    return <NotFound />;
  }

  return (
    <PageLayout title={title} entityType="esu" entityId={esuId} entityName={esu?.name}>
      <Tabs
        tabs={TABS}
        tab={tab}
        pathname={createESUPath(esuId)}
        preserveSearchTabs={PRESERVE_SEARCH_TABS}
      />
      <Box overflow="auto">
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          sx={{ containerName: 'panel', containerType: 'inline-size' }}
        >
          {children}
        </Box>
      </Box>
    </PageLayout>
  );
}
