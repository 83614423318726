import { useMemo } from 'react';
import useSWR from 'swr';

import { type MeteringGroup } from '~types';

import { fetcher } from '~http';

export function useMeteringGroup(meteringGroupId: string | null) {
  const { data, error, isLoading } = useSWR<MeteringGroup[]>('/v1/metering_group', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });

  const meteringGroup = useMemo(() => {
    if (data == null || meteringGroupId == null) return undefined;
    return data.find((group) => group.id === meteringGroupId);
  }, [data, meteringGroupId]);

  return { data: meteringGroup, error, isLoading };
}
