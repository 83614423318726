import { Box, type BoxProps, useTheme } from '@mui/material';

import { STICKY_BAR_Z_INDEX } from '~theme/index';

import { useResponsive } from '~hooks/useResponsive';

export function StickyBar(props: BoxProps) {
  const theme = useTheme();
  const isSticky = useResponsive('up', 'sm');

  return (
    <Box
      position={isSticky ? 'sticky' : 'static'}
      top={0}
      width="100%"
      py={2}
      zIndex={STICKY_BAR_Z_INDEX}
      bgcolor={theme.palette.background.default}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      {...props}
    />
  );
}
