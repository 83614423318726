import { Navigate } from 'react-router-dom';

import { USER_ROLES } from '~constants/auth';

import { useMeteringGroupList } from '~hooks/useMeteringGroupList';
import { useUserHasRootRole } from '~hooks/useUserHasRootRole';

import { PageLayoutLoader } from '~layouts/PageLayout/PageLayoutLoader';

export function RootRedirect() {
  const { data: meteringGroups = [], isLoading } = useMeteringGroupList();
  const canViewFleetsDashboard = useUserHasRootRole(USER_ROLES.OPERATOR_VIEW);
  const canViewFleetsMarket = useUserHasRootRole(USER_ROLES.FCR_BIDDING);

  // Redirect to fleets if user has access to fleets dashboard or fleets market
  if (canViewFleetsDashboard || canViewFleetsMarket) {
    return <Navigate replace to="fleets" />;
  }

  if (isLoading) {
    return <PageLayoutLoader />;
  }

  // Sites is always available to users
  // If only 1 site we redirect to it
  if (meteringGroups.length === 1) {
    return <Navigate replace to={`sites/${meteringGroups[0].id}`} />;
  }

  return <Navigate replace to="sites" />;
}
