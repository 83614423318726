import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  type Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { groupBy } from 'lodash-es';
import React from 'react';

import { asCactosError } from '~http';

import { formatClockTime } from '~utils/time';
import { splitPascalCase } from '~utils/string';

import {
  type ESUDiagnosticsResponse,
  type EdgeControllerDiagnosticsResponse,
  type Fault,
  type Field,
  type FieldStyle,
} from '~hooks/useDiagnosticsData';

import { RelativeTime } from '~components/RelativeTime';
import { Iconify } from '~components/Iconify';

export type DiagnosticsPanelProps = {
  data: EdgeControllerDiagnosticsResponse | ESUDiagnosticsResponse | null | undefined;
  isLoading: boolean;
  isValidating: boolean;
  error: any;
  viewingHistoricalData: boolean;
};

export const DiagnosticsPanel = React.memo(
  ({ data, isLoading, isValidating, error, viewingHistoricalData }: DiagnosticsPanelProps) => {
    return (
      <Box mb={2}>
        {error != null && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {asCactosError(error).message}
          </Alert>
        )}
        {data == null ? (
          <Box display="flex" alignItems="center" justifyContent="center" gap={2} padding={3}>
            <em>Loading diagnostics view</em> <CircularProgress size={20} />
          </Box>
        ) : data.data_found === false ? (
          <Alert severity="warning">No data found at the selected time.</Alert>
        ) : (
          <>
            {viewingHistoricalData && (
              <Alert severity="info" sx={{ mb: 1 }}>
                Viewing historical data.
              </Alert>
            )}
            <Box marginBottom={1} display="flex" gap="0.5em" flexWrap="wrap" alignItems="center">
              <Timestamp
                time={data.reported_timestamp}
                viewingHistoricalData={viewingHistoricalData}
              />
              <Button
                href={`/api/v1/edge_controller/${data.edge_controller_id}/pings?start=${new Date(
                  data.received_timestamp,
                ).toISOString()}&limit=1`}
                target="_blank"
                rel="noreferrer"
                startIcon={<Iconify icon="mdi:code-json" />}
              >
                View JSON
              </Button>
              <Box flexGrow={1} display="flex" justifyContent="flex-end" alignContent="center">
                <CircularProgress
                  size={16}
                  sx={{ display: isLoading || isValidating ? undefined : 'none' }}
                />
              </Box>
            </Box>

            <ActiveFaults faultCodes={data.fault_codes ?? []} />
            <FieldsPanel fields={data.fields} />
          </>
        )}
      </Box>
    );
  },
);

function FieldsPanel({ fields }: { fields: Field[] }) {
  const theme = useTheme();
  const groups: { [groupName: string]: Field[] } = groupBy(fields, (field) => field.group_name);
  return (
    <Box
      sx={{
        // Container query determines the number of columns
        '@container panel (min-width: 600px)': {
          columns: 2,
        },
        '@container panel (min-width: 900px)': {
          columns: 3,
        },
        '@container panel (min-width: 1200px)': {
          columns: 4,
        },
        '@container panel (min-width: 1500px)': {
          columns: 5,
        },
      }}
    >
      {Object.entries(groups).map(([groupName, fields]) => (
        <Card
          key={groupName}
          sx={{
            marginBottom: '1em',
          }}
        >
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell colSpan={3} sx={{ paddingTop: '1em' }}>
                    <Typography variant="subtitle1">{groupName}</Typography>
                  </TableCell>
                </TableRow>

                {fields.map((field: Field) => (
                  <TableRow
                    key={field.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {field.label}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        backgroundColor: getBackgroundColor(field.style, theme),
                        maxWidth: 180,
                        overflowWrap: 'break-word',
                      }}
                    >
                      <Typography variant="data" color={getTextColor(field.style, theme)}>
                        {formatValue(field)}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="data">{field.unit}</Typography>
                      {field.description && (
                        <Tooltip title={field.description}>
                          <IconButton size="small" sx={{ cursor: 'help' }}>
                            <Iconify icon="mdi:help-circle" color={theme.palette.grey[600]} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      ))}
    </Box>
  );
}

type TimestampProps = { time: string; viewingHistoricalData: boolean };

function Timestamp({ time, viewingHistoricalData }: TimestampProps) {
  const theme = useTheme();
  const isOutdated = !viewingHistoricalData && new Date(time) < new Date(Date.now() - 5 * 60_000);
  const color = isOutdated ? theme.palette.error.main : 'inherit';
  return (
    <Typography variant="body2">
      <em>
        Ping at {formatClockTime(time)}{' '}
        <span style={{ color }}>
          (<RelativeTime date={time} />)
        </span>
      </em>
      {isOutdated && (
        <Tooltip title="Data appears to be outdated">
          <IconButton size="small" sx={{ cursor: 'help', color: theme.palette.error.main }}>
            <Iconify icon="material-symbols:warning" />
          </IconButton>
        </Tooltip>
      )}
    </Typography>
  );
}

function ActiveFaults({ faultCodes }: { faultCodes: Fault[] }) {
  if (faultCodes.length === 0) {
    return null;
  }
  return (
    <Card sx={{ marginBottom: '1em' }}>
      <CardHeader
        title="Errors"
        titleTypographyProps={{ variant: 'subtitle1' }}
        sx={{ padding: '14px 16px 0' }}
      />
      <CardContent sx={{ padding: '14px 16px' }}>
        {faultCodes.map((fault, i) => (
          <Alert key={i} severity="error" sx={{ marginBottom: 1 }}>
            <AlertTitle>
              <code>
                Error: &quot;{fault.code}&quot;{' '}
                {fault.details != null ? JSON.stringify(fault.details) : ''}
              </code>
            </AlertTitle>
            <Typography variant="body1" sx={{ marginTop: '0.5em' }}>
              {fault.description}
            </Typography>
          </Alert>
        ))}
      </CardContent>
    </Card>
  );
}

function formatValue(field: Field) {
  const { value } = field;
  if (value == null) {
    return '-';
  }
  switch (typeof value) {
    case 'boolean':
      return value ? 'true' : 'false';
    case 'string': {
      // split PascalCase strings with <wbr> at capital letters
      // this allows the browser to break the string at these points,
      // and avoids the value column growing too wide
      const parts = splitPascalCase(value);
      return parts.flatMap((part, index, all) =>
        index < all.length - 1 ? [part, <wbr key={index} />] : [part],
      );
    }
    case 'number':
      return field.precision != null ? value.toFixed(field.precision) : value;
    default:
      return value;
  }
}

function getBackgroundColor(style: FieldStyle, theme: Theme): string | undefined {
  switch (style) {
    case 'good':
      return theme.palette.success.dark;
    case 'warning':
      return theme.palette.warning.light;
    case 'error':
      return theme.palette.error.light;
    default:
      return undefined;
  }
}

function getTextColor(style: FieldStyle, theme: Theme): string | undefined {
  switch (style) {
    case 'good':
      return theme.palette.success.contrastText;
    case 'warning':
      return theme.palette.warning.contrastText;
    case 'error':
      return theme.palette.error.contrastText;
    default:
      return undefined;
  }
}
