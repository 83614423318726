import { type PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { CommandPaletteContext } from '~hooks/useCommandPalette';

import { CommandPalette } from '~components/command-palette/CommandPalette';

const IS_MAC_OR_IOS =
  typeof navigator === 'object' && /Mac|iPod|iPhone|iPad/.test(navigator.platform);

export function CommandPaletteProvider({ children }: PropsWithChildren) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Open command palette with Cmd+K on macOS, Ctrl+K on other platforms
      if (event.key === 'k' && (IS_MAC_OR_IOS ? event.metaKey : event.ctrlKey)) {
        event.preventDefault();
        setOpen((open) => !open);
      }
      if (event.key === 'Escape') {
        setOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const commandPaletteContextValue = useMemo(
    () => ({
      open,
      setOpen,
    }),
    [open],
  );

  return (
    <CommandPaletteContext.Provider value={commandPaletteContextValue}>
      {open && <CommandPalette />}
      {children}
    </CommandPaletteContext.Provider>
  );
}
