import { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { color, rgb } from 'd3-color';

import { asCactosError } from '~http';

import { type DateTimeRangeWithNow, formatRange, localTimeZoneOffset } from '~utils/time';
import { hash } from '~utils/string';

import { type EdgeControllerEvent, useEdgeControllerEvents } from '~hooks/useEdgeControllerEvents';

// Number of consecutive events of the same type to show before collapsing them
const MAX_CONSECUTIVE_EVENTS = 10;

// generally use lighter color for "good/ok/connected/closed" in pair of events
const COLORS: { [eventName: string]: string } = {
  TCP_KEEPALIVE_REUSE: '#ffd7d9',
  TCP_CONNECTED: '#a6cee3',
  TCP_DISCONNECTED: '#1f78b4',
  TCP_CONNECTION_FAILED: '#d02670',
  INVERTER_FAULT: '#e31a1c',
  INVERTER_RECOVERY_STARTED: '#fb9a99',
  INVERTER_FAULT_CODE: '#c6c6c6',
  INVERTER_WARNING: '#f1c21b',
  GRID_UP_D7_OK: '#bdee63',
  GRID_DOWN_D7_NOT_OK: '#ad7f58',
  KA1_FEEDBACK_CLOSED: '#cab2d6',
  KA1_FEEDBACK_OPENED: '#6a3d9a',
  INV_D7_SYNC: '#12a594',
  INV_D7_NOT_SYNC: '#005d5d',
  INV_DC_VOLTAGE_MATCHES_BMS: '#cec378',
  INV_DC_VOLTAGE_DIFFERS_BMS: '#60580e',
  KA2_FEEDBACK_CLOSED: '#fdbf6f',
  KA2_FEEDBACK_OPENED: '#ff7f00',
  ETHERNET_CONNECTED: '#b2df8a',
  ETHERNET_DISCONNECTED: '#33a02c',
};

const getEventStyle = (eventName: string): { background: string; color: string } => {
  const background = COLORS[eventName] ?? stringToColor(eventName);
  const { r, g, b } = rgb(background);
  const fontColor = r * 0.299 + g * 0.587 + b * 0.114 > 145 ? '#000000' : '#FFFFFF';
  return { background, color: fontColor };
};

export function EventLog({
  edgeControllerId,
  range,
}: {
  edgeControllerId: string;
  range: DateTimeRangeWithNow;
}) {
  const theme = useTheme();

  const [ignoredEvents, setIgnoredEvents] = useState(() => new Set(['TCP_KEEPALIVE_REUSE']));
  const [allSeenEvents, setAllSeenEvents] = useState(() => new Set(ignoredEvents));

  const {
    data: eventData,
    isLoading: loadingEventData,
    error: eventDataError,
  } = useEdgeControllerEvents(edgeControllerId, range, { ignore: Array.from(ignoredEvents) });

  // remember event names so the ignore selector doesn't flash when loading
  const newEvents = (eventData?.events ?? []).filter(
    (event) => !allSeenEvents.has(event.event_code),
  );
  if (newEvents.length > 0) {
    setAllSeenEvents(new Set([...allSeenEvents, ...newEvents.map((event) => event.event_code)]));
  }

  return (
    <Card>
      <CardHeader
        title={
          <>Event log {loadingEventData && <CircularProgress size={12} sx={{ mt: 1, ml: 1 }} />}</>
        }
        subheader={formatRange(range)}
      />

      <Box padding={2}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="mutiple-select-label" size="small">
            Ignore events
          </InputLabel>
          <Select
            labelId="mutiple-select-label"
            label="Ignore events"
            multiple
            variant="outlined"
            size="small"
            value={Array.from(ignoredEvents).sort()}
            onChange={(event) => {
              const value = event.target.value as string[];
              setIgnoredEvents(new Set(value));
            }}
            renderValue={(selected) => selected.join(', ')}
            fullWidth
            autoWidth
          >
            {Array.from(allSeenEvents)
              .sort()
              .map((name) => (
                <MenuItem key={name} value={name}>
                  <ListItemIcon>
                    <Checkbox checked={ignoredEvents.has(name)} />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        {eventDataError != null && (
          <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
            {asCactosError(eventDataError)?.message}
          </Alert>
        )}

        <TableContainer sx={{ minWidth: '450px' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>Time {localTimeZoneOffset()}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>Event name</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>Parameters</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                minWidth: '450px',
                '& tr:nth-of-type(odd)': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              {eventData?.events == null ? (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                    <i>Loading events...</i>
                  </TableCell>
                </TableRow>
              ) : eventData.events.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                    No events found for the selected range and filters.
                  </TableCell>
                </TableRow>
              ) : (
                groupRepeatedEvents(eventData.events).map((group) => (
                  <EventGroup key={group[0].event_id} events={group} />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
}

function EventGroup({ events }: { events: EdgeControllerEvent[] }) {
  const [maxVisibleCount, setMaxVisibleCount] = useState(MAX_CONSECUTIVE_EVENTS);
  if (events.length <= maxVisibleCount) {
    return (
      <>
        {events.map((event) => (
          <Event key={event.event_id} event={event} />
        ))}
      </>
    );
  }
  const visibleEvents = events.slice(0, maxVisibleCount - 1);
  return (
    <>
      {visibleEvents.map((event) => (
        <Event key={event.event_id} event={event} />
      ))}
      <TableRow>
        <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
          <Typography variant="caption" color="text.secondary">
            <Button onClick={() => setMaxVisibleCount((n) => n + 500)}>
              Show more… ({events.length - visibleEvents.length} similar events hidden)
            </Button>
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
}

function Event({ event }: { event: EdgeControllerEvent }) {
  return (
    <TableRow key={event.event_id}>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Typography variant="data">{format(event.time, 'yyyy-MM-dd HH:mm:ss.SSS')}</Typography>
      </TableCell>
      <TableCell>
        <Chip
          label={<Typography variant="data">{event.event_code}</Typography>}
          size="small"
          sx={getEventStyle(event.event_code)}
        />
      </TableCell>
      <TableCell>
        <Typography variant="data">{JSON.stringify(event.parameters)}</Typography>
      </TableCell>
    </TableRow>
  );
}

function groupRepeatedEvents(events: EdgeControllerEvent[]): EdgeControllerEvent[][] {
  const groups: EdgeControllerEvent[][] = [];
  if (events.length === 0) {
    return groups;
  }
  let currentGroup = [events[0]];
  for (let i = 1; i < events.length; i++) {
    const event = events[i];
    if (currentGroup[0].event_code === event.event_code) {
      currentGroup.push(event);
    } else {
      groups.push(currentGroup);
      currentGroup = [event];
    }
  }
  groups.push(currentGroup);
  return groups;
}

const stringToColor = (str: string): string => {
  const value = Math.abs(hash(str));
  const h = (value & 0x0fff) % 360;
  const s = (((value >> 12) & 0x03ff) % 60) + 20;
  const l = (((value >> 22) & 0x03ff) % 50) + 30;
  return color(`hsl(${h}, ${s}%, ${l}%)`)?.formatHex() ?? '#707070';
};
