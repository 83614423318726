import { Outlet, type RouteObject, createBrowserRouter } from 'react-router-dom';

import { USER_ROLES } from '~constants/auth';

import { preloadFleetList } from '~hooks/useFleetList';
import { preloadEdgeControllerList } from '~hooks/useEdgeControllerList';
import { preloadESUList, preloadFleetESUList } from '~hooks/useESUList';
import {
  preloadFleetMeteringGroupList,
  preloadMeteringGroupList,
} from '~hooks/useMeteringGroupList';
import { preloadOrganizationList } from '~hooks/useOrganizationList';
import { preloadFleetRegions } from '~hooks/useFleetRegions';

import { ForceMfaBouncer } from '~layouts/ForceMfaBouncer';
import { AppLayout } from '~layouts/AppLayout';
import { AuthenticationBouncer } from '~layouts/AuthenticationBouncer';
import { RoleBouncer } from '~layouts/RoleBouncer';
import { LogoOnlyLayout } from '~layouts/LogoOnlyLayout';

import { Login } from '~pages/authentication/Login';
import { Signup } from '~pages/authentication/Signup';
import { NotFound } from '~pages/NotFound';
import { Fleets } from '~pages/fleets/components/Fleets';
import { FleetRedirect } from '~pages/fleets/components/FleetRedirect';
import { FleetDashboard } from '~pages/fleets/components/FleetDashboard';
import { FleetsMarket } from '~pages/fleets/components/FleetMarket';
import { FleetsDiagnostics } from '~pages/fleets/components/FleetDiagnostics';
import { Organizations } from '~pages/organizations/components/Organizations';
import { OrganizationRedirect } from '~pages/organizations/components/OrganizationRedirect';
import { OrganizationUsers } from '~pages/organizations/components/OrganizationUsers';
import { Sites } from '~pages/sites/components/Sites';
import { SiteRedirect } from '~pages/sites/components/SiteRedirect';
import { SiteDashboard } from '~pages/sites/components/SiteDashboard';
import { SitePricing } from '~pages/sites/components/SitePricing';
import { SiteTopology } from '~pages/sites/components/SiteTopology';
import { SiteSettings } from '~pages/sites/components/SiteSettings';
import { Controllers } from '~pages/controllers/components/Controllers';
import { ControllerRedirect } from '~pages/controllers/components/ControllerRedirect';
import { ControllerDiagnostics } from '~pages/controllers/components/ControllerDiagnostics';
import { ControllerEvents } from '~pages/controllers/components/ControllerEvents';
import { ControllerSettings } from '~pages/controllers/components/ControllerSettings';
import { ESUs } from '~pages/esus/components/ESUs';
import { ESURedirect } from '~pages/esus/components/ESURedirect';
import { ESUDashboard } from '~pages/esus/components/ESUDashboard';
import { ESUDiagnostics as ESUDiagnosticsV2 } from '~pages/esus/components/ESUDiagnostics';
import { ESUControl as ESUControlV2 } from '~pages/esus/components/ESUControl';
import { ESUSchedule } from '~pages/esus/components/ESUSchedule';
import { ESUReports } from '~pages/esus/components/ESUReports';
import { ESUSettings as ESUSettingsV2 } from '~pages/esus/components/ESUSettings';
import { Explorer } from '~pages/explorer/Explorer';
import { UserSettings as UserSettingsV2 } from '~pages/user/UserSettings';
import { OptimizerFleetRuns } from '~pages/v2/optimizer/OptimizerFleetRuns';
import { OptimizerFleetRun } from '~pages/v2/optimizer/OptimizerFleetRun';

import { RootRedirect } from '~components/RootRedirect';
import { ErrorBoundary } from '~components/ErrorBoundary';

import { App } from './App';

export const routerConfiguration: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <AuthenticationBouncer>
            <RoleBouncer>
              <ForceMfaBouncer>
                <AppLayout />
              </ForceMfaBouncer>
            </RoleBouncer>
          </AuthenticationBouncer>
        ),
        errorElement: <ErrorBoundary />,
        children: [
          {
            loader: preloadMeteringGroupList,
            index: true,
            element: <RootRedirect />,
          },
          {
            path: 'user',
            element: <UserSettingsV2 />,
          },
          {
            path: 'fleets',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <Fleets />,
                loader: preloadFleetList,
                handle: {
                  permission: {
                    rootRoleRequired: [USER_ROLES.OPERATOR_VIEW, USER_ROLES.FCR_BIDDING],
                  },
                },
              },
              {
                path: ':fleetId',
                element: <Outlet />,
                handle: {
                  permission: {
                    rootRoleRequired: [USER_ROLES.OPERATOR_VIEW, USER_ROLES.FCR_BIDDING],
                  },
                },
                children: [
                  {
                    index: true,
                    element: <FleetRedirect />,
                  },
                  {
                    path: 'dashboard',
                    element: <FleetDashboard />,
                    loader: ({ params }) => {
                      if (params.fleetId != null) {
                        return preloadFleetRegions(params.fleetId);
                      }
                      return null;
                    },
                    handle: {
                      permission: {
                        rootRoleRequired: USER_ROLES.OPERATOR_VIEW,
                      },
                    },
                  },
                  {
                    path: 'market',
                    element: <FleetsMarket />,
                    handle: {
                      permission: {
                        rootRoleRequired: USER_ROLES.FCR_BIDDING,
                      },
                    },
                  },
                  {
                    path: 'diagnostics',
                    element: <FleetsDiagnostics />,
                    handle: {
                      permissition: {
                        rootRoleRequired: USER_ROLES.OPERATOR_VIEW,
                      },
                    },
                  },
                  {
                    path: 'optimizer',
                    element: <OptimizerFleetRuns />,
                    loader: ({ params }) => {
                      if (!params.fleetId) return null;
                      preloadFleetMeteringGroupList(params.fleetId);
                      preloadFleetESUList(params.fleetId);
                      /* we do not want to block the UI while these promises are resolving */
                      return null;
                    },
                    handle: {
                      permission: {
                        rootRoleRequired: USER_ROLES.OPERATOR_VIEW,
                      },
                    },
                    children: [
                      {
                        path: ':optimizerRunId',
                        element: <OptimizerFleetRun />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'organizations',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <Organizations />,
                loader: preloadOrganizationList,
                handle: {
                  permission: {
                    rootRoleRequired: USER_ROLES.VIEW_ORGANIZATION,
                  },
                },
              },
              {
                path: ':organizationId',
                element: <Outlet />,
                handle: {
                  permission: {
                    rootRoleRequired: USER_ROLES.VIEW_ORGANIZATION,
                  },
                },
                children: [
                  {
                    index: true,
                    element: <OrganizationRedirect />,
                  },
                  {
                    path: 'users',
                    element: <OrganizationUsers />,
                  },
                ],
              },
            ],
          },
          {
            path: 'sites',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <Sites />,
                loader: preloadMeteringGroupList,
              },
              {
                path: ':meteringGroupId',
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <SiteRedirect />,
                  },
                  {
                    path: 'dashboard',
                    element: <SiteDashboard />,
                  },
                  {
                    path: 'topology',
                    element: <SiteTopology />,
                    handle: {
                      permission: {
                        rootRoleRequired: USER_ROLES.VIEW_TOPOLOGY,
                      },
                    },
                  },
                  {
                    path: 'pricing',
                    element: <SitePricing />,
                    handle: {
                      permission: {
                        rootRoleRequired: USER_ROLES.VIEW_PRICING,
                      },
                    },
                  },
                  {
                    path: 'settings',
                    element: <SiteSettings />,
                    handle: {
                      permission: {
                        rootRoleRequired: USER_ROLES.OPERATOR_VIEW,
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'controllers',
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <Controllers />,
                loader: preloadEdgeControllerList,
                handle: {
                  permission: {
                    roleRequired: [
                      USER_ROLES.EMS_DIAGNOSTIC_VIEW,
                      USER_ROLES.DEBUG_EMS,
                      USER_ROLES.OPERATOR_VIEW,
                    ],
                  },
                },
              },
              {
                path: ':edgeControllerId',
                element: <Outlet />,
                handle: {
                  permission: {
                    roleRequired: [
                      USER_ROLES.EMS_DIAGNOSTIC_VIEW,
                      USER_ROLES.DEBUG_EMS,
                      USER_ROLES.OPERATOR_VIEW,
                    ],
                  },
                },
                children: [
                  {
                    index: true,
                    element: <ControllerRedirect />,
                  },
                  {
                    path: 'diagnostics',
                    element: <ControllerDiagnostics />,
                    handle: {
                      permission: {
                        roleRequired: USER_ROLES.EMS_DIAGNOSTIC_VIEW,
                      },
                    },
                  },
                  {
                    path: 'events',
                    element: <ControllerEvents />,
                    handle: {
                      permission: {
                        roleRequired: USER_ROLES.DEBUG_EMS,
                      },
                    },
                  },
                  {
                    path: 'settings',
                    element: <ControllerSettings />,
                    handle: {
                      permission: {
                        roleRequired: USER_ROLES.OPERATOR_VIEW,
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'esus',
            element: <Outlet />,
            children: [
              {
                index: true,
                loader: preloadESUList,
                element: <ESUs />,
              },
              {
                path: ':esuId',
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <ESURedirect />,
                  },
                  {
                    path: 'dashboard',
                    element: <ESUDashboard />,
                  },
                  {
                    path: 'diagnostics',
                    element: <ESUDiagnosticsV2 />,
                    handle: {
                      permission: {
                        roleRequired: USER_ROLES.EMS_DIAGNOSTIC_VIEW,
                      },
                    },
                  },
                  {
                    path: 'control',
                    element: <ESUControlV2 />,
                    handle: {
                      permission: {
                        roleRequired: [USER_ROLES.EDIT_EMS_SCHEDULE, USER_ROLES.EMS_DIAGNOSTIC_RUN],
                      },
                    },
                  },
                  {
                    path: 'schedule',
                    element: <ESUSchedule />,
                  },
                  {
                    path: 'reports',
                    element: <ESUReports />,
                    handle: {
                      permission: {
                        roleRequired: USER_ROLES.VIEW_REPORT,
                      },
                    },
                  },
                  {
                    path: 'settings',
                    element: <ESUSettingsV2 />,
                    handle: {
                      permission: {
                        rootRoleRequired: USER_ROLES.EDIT_EMS_SCHEDULE,
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'explorer',
            element: <Explorer />,
            handle: {
              permission: {
                rootRoleRequired: USER_ROLES.OPERATOR_VIEW,
              },
            },
          },
        ],
      },
      {
        path: '/',
        element: <LogoOnlyLayout />,
        children: [
          {
            path: 'login',
            element: <Login />,
          },
          {
            path: '404',
            element: <NotFound />,
          },
          {
            path: 'signup/:inviteId/:confirmationToken',
            element: <Login />,
          },
          {
            path: 'signup/:inviteId',
            element: <Signup />,
          },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
    ],
  },
];

export const router = createBrowserRouter(routerConfiguration);
