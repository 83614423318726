import { subMinutes } from 'date-fns';
import useSWR from 'swr';

import type { FaultData } from '~types';

import { fetcher, http } from '~http';

import type { DateTimeRange } from '~utils/time';

export const CURRENT_FAULTS_RANGE_MINUTES = 5;

export function useFaults(rangeMinutes: number) {
  const key = ['/v1/diagnostics/faults', {}, rangeMinutes];
  return useSWR<FaultData>(key, { fetcher: fetchRecentFaults });
}

export function useFleetFaults(fleetId: string | null, rangeMinutes: number) {
  const key = fleetId != null ? ['/v1/diagnostics/faults', { fleet: fleetId }, rangeMinutes] : null;
  return useSWR<FaultData>(key, { fetcher: fetchRecentFaults });
}

export function useEdgeControllerFaultsForRange(
  edgeControllerId: string | null,
  range: DateTimeRange,
) {
  const key =
    edgeControllerId != null
      ? `/v1/diagnostics/faults?edge_controller_ids=${edgeControllerId}&start=${range.start.toISOString()}&end=${range.end.toISOString()}`
      : null;
  return useSWR<FaultData>(key, { fetcher });
}

type FetcherArgs = [url: string, params: { [key: string]: string }, rangeMinutes: number];

async function fetchRecentFaults([url, params, rangeMinutes]: FetcherArgs) {
  const end = new Date();
  const start = subMinutes(end, rangeMinutes);
  const searchParams = new URLSearchParams({
    ...params,
    start: start.toISOString(),
    end: end.toISOString(),
  });
  const response = await http.get(`${url}?${searchParams}`);
  return response.data;
}
