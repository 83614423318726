import { type ReactNode } from 'react';
import { Box, Tooltip } from '@mui/material';
import {
  ErrorOutline as ErrorIcon,
  PersonOutlined as PersonIcon,
  QuestionMarkOutlined as QuestionMarkIcon,
  ThermostatOutlined as ThermostatIcon,
  WarningAmberOutlined as WarningIcon,
} from '@mui/icons-material';
import styled from '@emotion/styled';
import { formatDistanceToNow } from 'date-fns';

import { type ESUCurrentMode } from '~types';
import type { FaultEvent } from '~types';

import { CURRENT_FAULTS_RANGE_MINUTES } from '~hooks/useFaults';

import { Iconify } from '~components/Iconify';

export type StatusCellCompatibleRow = {
  current_mode?: ESUCurrentMode | undefined;
  faults?: FaultEvent[] | undefined;
};

export type StatusCellProps = {
  row: { original: StatusCellCompatibleRow };
  renderedCellValue: ReactNode;
};

export function StatusCell(props: StatusCellProps) {
  const { row, renderedCellValue } = props;
  const mode: ESUCurrentMode | undefined = row.original.current_mode;
  const faults: FaultEvent[] = row.original.faults ?? [];

  if (mode == null)
    return (
      <Box sx={{ margin: '0px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <ModeBox sx={{ backgroundColor: 'transparent', color: 'rgb(158, 158, 158)' }}>
          {renderedCellValue}
        </ModeBox>
      </Box>
    );

  const [badgeColor, iconColor, textColor] = getBadgeIconAndTextColor(mode);

  return (
    <CellContainer>
      <ModeBox
        sx={{
          backgroundColor: badgeColor,
        }}
      >
        {mode.mode === 'operating' && mode.control_source === 'program' && (
          <IconBox>
            <Tooltip
              title={
                mode.program_detail.type === 'program' &&
                mode.program_detail.active &&
                new Date(mode.program_detail.start_time) <= new Date()
                  ? `Running a program, started ${formatDistanceToNow(
                      new Date(mode.program_detail.start_time),
                      { addSuffix: true },
                    )}`
                  : 'Running a program'
              }
              placement={tooltipPlacement}
            >
              <PersonIcon sx={{ color: iconColor }} style={{ fontSize: iconSize }} />
            </Tooltip>
          </IconBox>
        )}
        {mode.mode === 'operating' && mode.control_source === 'manual_override' && (
          <IconBox>
            <Tooltip title="Manual override active" placement={tooltipPlacement}>
              <PersonIcon sx={{ color: iconColor }} style={{ fontSize: iconSize }} />
            </Tooltip>
          </IconBox>
        )}
        {mode.mode === 'operating' && mode.control_source === 'optimizer_deactivated' && (
          <IconBox>
            <Tooltip title="Removed from optimizer" placement={tooltipPlacement}>
              <WarningIcon sx={{ color: iconColor }} style={{ fontSize: iconSize }} />
            </Tooltip>
          </IconBox>
        )}
        {mode.mode === 'operating' && mode.control_source === 'default_schedule' && (
          <IconBox>
            <Tooltip title="Running default schedule" placement={tooltipPlacement}>
              <WarningIcon sx={{ color: iconColor }} style={{ fontSize: iconSize }} />
            </Tooltip>
          </IconBox>
        )}
        {mode.mode === 'powered_off' && (
          <IconBox>
            <Tooltip title="Powered off" placement={tooltipPlacement}>
              <div style={{ height: iconSize, lineHeight: iconSize }}>
                <Iconify
                  icon="mdi:electric-switch"
                  color={iconColor}
                  width={iconSize}
                  height={iconSize}
                />
              </div>
            </Tooltip>
          </IconBox>
        )}
        {mode.mode === 'no_recent_data' && (
          <IconBox>
            <Tooltip
              title={
                mode.last_data_time != null
                  ? `Offline, last seen ${formatDistanceToNow(new Date(mode.last_data_time), {
                      addSuffix: true,
                    })}`
                  : 'Offline'
              }
              placement={tooltipPlacement}
            >
              <div style={{ height: iconSize, lineHeight: iconSize }}>
                <Iconify
                  icon="mdi:signal-off"
                  color={iconColor}
                  width={iconSize}
                  height={iconSize}
                />
              </div>
            </Tooltip>
          </IconBox>
        )}
        {mode.mode === 'invalid_data' && (
          <IconBox>
            <Tooltip title="Invalid status data" placement={tooltipPlacement}>
              <QuestionMarkIcon sx={{ color: iconColor }} style={{ fontSize: iconSize }} />
            </Tooltip>
          </IconBox>
        )}
        <StatusTextBox sx={{ color: textColor }}>{renderedCellValue}</StatusTextBox>
      </ModeBox>
      {(mode.mode === 'operating' || mode.mode === 'powered_off') && !mode.grid_connected && (
        <IconBox>
          <Tooltip title="Off-grid" placement={tooltipPlacement}>
            <div style={{ height: iconSize, lineHeight: iconSize }}>
              <Iconify
                icon="mdi:transmission-tower-off"
                color={colors.red}
                width={iconSize}
                height={iconSize}
              />
            </div>
          </Tooltip>
        </IconBox>
      )}
      {faults.length > 0 && (
        <IconBox>
          <Tooltip
            title={`${faults.length} ${
              faults.length === 1 ? 'fault' : 'faults'
            } within the last ${CURRENT_FAULTS_RANGE_MINUTES} minutes`}
            placement={tooltipPlacement}
          >
            <ErrorIcon sx={{ color: colors.amber }} style={{ fontSize: iconSize }} />
          </Tooltip>
        </IconBox>
      )}
      {mode.mode === 'operating' && mode.temperature_override && (
        <IconBox>
          <Tooltip title="Temperature override active" placement={tooltipPlacement}>
            <ThermostatIcon sx={{ color: colors.amber }} style={{ fontSize: iconSize }} />
          </Tooltip>
        </IconBox>
      )}
      {mode.mode === 'operating' && mode.peak_shaving && (
        <IconBox>
          <Tooltip title="Peak shaving" placement={tooltipPlacement}>
            <div style={{ height: iconSize, lineHeight: iconSize }}>
              <Iconify
                icon="mdi:graph-bell-curve"
                color={colors.green}
                width={iconSize}
                height={iconSize}
              />
            </div>
          </Tooltip>
        </IconBox>
      )}
    </CellContainer>
  );
}

export function getStatusText(dataRow: StatusCellCompatibleRow): string {
  const mode = dataRow.current_mode;

  if (mode == null) return '';
  if (mode.mode === 'invalid_data') return 'Data invalid';
  if (mode.mode === 'no_recent_data') return 'Offline';

  if (mode.mode === 'powered_off') return 'Powered off';

  // mode.mode === 'operating'

  if (mode.control_source === 'program') {
    if (mode.program_detail.type === 'program') {
      // if a program is just about to begin we consider it as running
      if (mode.program_detail.active || new Date() < new Date(mode.program_detail.start_time)) {
        return mode.program_detail.display_name;
      } else {
        return 'Program over';
      }
    } else {
      // program type ad_hoc
      return mode.program_detail.detail;
    }
  }

  if (mode.fcr_d_up === 'not_delivering' || mode.fcr_d_down === 'not_delivering')
    return 'FCR-D not delivering';
  if (mode.fcr_d_up === 'active' || mode.fcr_d_down === 'active') return 'FCR-D';

  if (mode.fcr_n.active) {
    let modeText = 'FCR-N';
    if (mode.fcr_n.not_delivering) modeText += ' not delivering';
    if (mode.fcr_n.aem && mode.fcr_n.nem) {
      modeText += ' (AEM+NEM)';
    } else {
      if (mode.fcr_n.aem) modeText += ' (AEM)';
      if (mode.fcr_n.nem) modeText += ' (NEM)';
    }
    return modeText;
  }

  if (mode.peak_shaving) return 'Peak-shaving';
  if (mode.load_shifting) return 'Load-shifting';

  return 'Idle';
}

export function getSortingSeverity(dataRow: StatusCellCompatibleRow): number {
  const mode: ESUCurrentMode | undefined = dataRow.current_mode;
  const faults: FaultEvent[] = dataRow.faults ?? [];

  if (mode == null) return 0;

  if (mode.mode === 'no_recent_data') {
    if (mode.last_data_time != null) {
      // recently offline
      return 10;
    } else {
      return 9;
    }
  }
  if (mode.mode === 'invalid_data') return 8;
  if (mode.mode === 'powered_off') return 6;

  // mode.mode === 'operating'

  if (!mode.grid_connected) return 7;
  if (faults.length > 0) return 6.5;

  switch (mode.control_source) {
    case 'default_schedule':
    case 'optimizer_deactivated':
      return 5;
    case 'manual_override':
      return 4;
    case 'program':
      return 3;
    case 'optimizer':
      if (mode.temperature_override) return 2;
      if (mode.fcr_d_up === 'not_delivering' || mode.fcr_d_down === 'not_delivering') return 1.9;
      if (mode.fcr_d_up === 'active' || mode.fcr_d_down === 'active') return 1.8;
      if (mode.fcr_n.active) {
        if (mode.fcr_n.not_delivering) return 1.7;
        if (mode.fcr_n.aem && mode.fcr_n.nem) return 1.6;
        if (mode.fcr_n.aem) return 1.5;
        if (mode.fcr_n.nem) return 1.4;
        return 1.3;
      }
      return 1;
    default:
      throw new Error('Unknown control source.');
  }
}

const getBadgeIconAndTextColor = (mode: ESUCurrentMode): [string, string, string] => {
  if (mode.mode !== 'operating') return [colors.red, 'black', 'black'];
  if (mode.control_source === 'optimizer') return ['transparent', colors.green, 'white'];
  if (mode.control_source === 'program') return [colors.amber, 'black', 'black'];
  // control_source one of: manual_override, optimizer_deactivated, default_schedule
  return [colors.red, 'black', 'black'];
};

const colors = {
  green: '#72d34c',
  amber: 'rgb(255, 193, 7)',
  red: 'rgb(229, 115, 115)',
};

const tooltipPlacement = 'top';
const iconSize = '20px';

const CellContainer = styled(Box)({
  margin: '0px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const IconBox = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '22px', // iconSize + 2
  height: '22px', // iconSize + 2
  margin: '0',
});

const ModeBox = styled(Box)({
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px',
  padding: '0px 4px 0px 2px',
  marginRight: '4px',
});

const StatusTextBox = styled(Box)({
  // Align text vertically with the icons
  position: 'relative',
  top: '1px',
});
