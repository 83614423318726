import { Stack, useTheme } from '@mui/material';
import { useCallback } from 'react';

import { formatRange } from '~utils/time';
import { getAggregageQueryTimeStepSeconds } from '~utils/timeseries';
import { transformAbsoluteValue } from '~utils/number';

import { useURLRange } from '~hooks/useURLRange';
import { useMeteringGroup } from '~hooks/useMeteringGroup';

import { useSitesNavigation } from '~pages/sites/hooks/useSitesNavigation';
import { useSiteChartData } from '~pages/sites/hooks/useSiteChartData';
import { useSitePeakshavingThreshold } from '~pages/sites/hooks/useSitePeakshavingThreshold';
import { useGridMeterData } from '~pages/sites/hooks/useGridMeterData';
import { getEnergyMeterResourceId } from '~pages/sites/utils/getEnergyMeterResourceId';
import { useFacilityPower } from '~pages/sites/hooks/useFacilityPower';

import { CopyLinkButton } from '~components/CopyLinkButton';
import { TimeSeriesMultiLineChart } from '~components/charts';
import { DateTimeRangePicker } from '~components/DateTimeRangePicker';
import { StickyBar } from '~components/StickyBar';

export function SiteDashboardCharts() {
  const theme = useTheme();
  const { meteringGroupId } = useSitesNavigation();
  const { data: meteringGroup } = useMeteringGroup(meteringGroupId);
  const energyMeterResourceId = getEnergyMeterResourceId(meteringGroup);

  const [range, setRange] = useURLRange(6 * 3600);

  const {
    data: chartData,
    isLoading: isLoadingChartData,
    error: chartDataError,
  } = useSiteChartData(meteringGroupId, range);
  const isAggregatedOverTime = chartData.isAggregatedOverTime;
  const {
    data: scheduleData,
    extendedData: extendedScheduleData,
    isLoading: isLoadingSchedule,
    error: scheduleError,
  } = useSitePeakshavingThreshold(meteringGroupId, range);
  const {
    data: gridMeterData,
    isLoading: isLoadingGridMeter,
    error: gridMeterError,
  } = useGridMeterData(meteringGroup, range);
  const {
    data: facilityPowerData,
    isLoading: isLoadingFacilityPower,
    error: facilityPowerError,
  } = useFacilityPower(meteringGroupId, range);

  const chartExportFilename = useCallback(
    (startTime: Date, endTime: Date, chartTitle: string) =>
      `${chartTitle} ${meteringGroup?.name} at ${startTime.toISOString()}`,
    [meteringGroup?.name],
  );

  const rangeString = formatRange(range);

  // don't draw over gap when 5 pings dropped
  const rawDataLongestGapMs = 60_000;
  // don't draw over gap when 5 data points are missing
  const aggregateLongestGapMs = getAggregageQueryTimeStepSeconds(range) * 5_000;

  const annotationLineColor = theme.palette.grey[500];
  const annotationStrokeDasharray = '4,2';

  const timeAxis = {
    clipMin: range.start,
    include: [range.start, range.end],
    clipMax: range.end,
  };

  return (
    <>
      <StickyBar px={2}>
        <DateTimeRangePicker value={range} onChange={setRange} />
        <CopyLinkButton link={window.location.href} />
      </StickyBar>
      <Stack spacing={2}>
        <TimeSeriesMultiLineChart
          title="Active power"
          subtitle={rangeString}
          height={280}
          data={{
            grid_active_power: facilityPowerData.gridActivePower,
            esu_active_power: facilityPowerData.esuActivePower,
            facility_active_power: facilityPowerData.facilityActivePower,
          }}
          isLoading={isLoadingFacilityPower}
          error={facilityPowerError}
          units="kW"
          timeAxis={timeAxis}
          valueAxis={{ include: [-1, 1] }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            esu_active_power: {
              variables: [
                {
                  name: 'Cactos power',
                  sourceColumn: 'esu_active_power:mean',
                  denominator: 1000,
                  color: theme.palette.chart.categorical14[6],
                },
              ],
              longestDrawnGapMs: facilityPowerData.timeStep * 5_000,
            },
            facility_active_power: {
              variables: [
                {
                  name: 'Local power',
                  sourceColumn: 'facility_active_power_corrected:mean',
                  denominator: 1000,
                  color: theme.palette.chart.categorical14[1],
                },
              ],
              // it's possible that the meter outputs data only every 10 seconds,
              // so the output will not have many data points even when asking with a 1s step
              longestDrawnGapMs: Math.max(15_000, facilityPowerData.timeStep * 5_000),
            },
            grid_active_power: {
              variables: [
                {
                  name: 'Total grid power',
                  sourceColumn: 'grid_active_power:mean',
                  denominator: 1000,
                  color: theme.palette.chart.categorical14[8],
                },
              ],
              // it's possible that the meter outputs data only every 10 seconds...
              longestDrawnGapMs: Math.max(15_000, facilityPowerData.timeStep * 5_000),
            },
          }}
          chartAnnotations={[
            {
              type: 'line',
              height: 0,
              color: annotationLineColor,
              strokeDasharray: annotationStrokeDasharray,
            },
          ]}
        />
        {energyMeterResourceId != null && (
          <TimeSeriesMultiLineChart
            title="Reactive power"
            subtitle={rangeString}
            height={280}
            data={{
              energy_meter_momentary: gridMeterData.energy_meter_momentary,
            }}
            isLoading={isLoadingGridMeter}
            error={gridMeterError}
            units="kvar"
            timeAxis={timeAxis}
            valueAxis={{ include: [-1, 1] }}
            onZoom={setRange}
            exportFilename={chartExportFilename}
            variableSources={{
              energy_meter_momentary: {
                variables: gridMeterData.isAggregatedOverTime
                  ? [
                      {
                        name: 'Reactive power (max)',
                        sourceColumn: 'reactive_power:max',
                        denominator: 1000,
                        color: theme.palette.chart.categorical10[0],
                        opacity: 0.25,
                        includeInLegend: false,
                      },
                      {
                        name: 'Reactive power (mean)',
                        sourceColumn: 'reactive_power:mean',
                        denominator: 1000,
                        color: theme.palette.chart.categorical10[0],
                        centerOfArea: {
                          name: 'Reactive power',
                          lowerBoundSeriesName: 'Reactive power (min)',
                          upperBoundSeriesName: 'Reactive power (max)',
                          areaOpacity: 0.2,
                        },
                      },
                      {
                        name: 'Reactive power (min)',
                        sourceColumn: 'reactive_power:min',
                        denominator: 1000,
                        color: theme.palette.chart.categorical10[0],
                        opacity: 0.25,
                        includeInLegend: false,
                      },
                    ]
                  : [
                      {
                        name: 'Reactive power',
                        sourceColumn: 'reactive_power',
                        denominator: 1000,
                        color: theme.palette.chart.categorical10[0],
                      },
                    ],
                longestDrawnGapMs: aggregateLongestGapMs,
                snapTooltip: true,
              },
            }}
            chartAnnotations={[
              {
                type: 'line',
                height: 0,
                color: annotationLineColor,
                strokeDasharray: annotationStrokeDasharray,
              },
            ]}
          />
        )}

        <TimeSeriesMultiLineChart
          title="Grid frequency"
          subtitle={rangeString}
          height={400}
          data={{ esu_power_control: chartData.esu_power_control }}
          isLoading={isLoadingChartData}
          error={chartDataError}
          units="Hz"
          tooltipDecimalDigits={3}
          timeAxis={timeAxis}
          valueAxis={{
            clipMin: 49.7,
            include: [49.8, 50.2],
            clipMax: 50.3,
          }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            esu_power_control: {
              variables: isAggregatedOverTime
                ? [
                    {
                      name: 'Frequency (max)',
                      sourceColumn: 'control_grid_f:max',
                      denominator: 1000,
                      color: theme.palette.chart.single,
                      opacity: 0.25,
                      includeInLegend: false,
                    },
                    {
                      name: 'Frequency (mean)',
                      sourceColumn: 'control_grid_f:mean',
                      denominator: 1000,
                      color: theme.palette.chart.single,
                      centerOfArea: {
                        name: 'Frequency',
                        lowerBoundSeriesName: 'Frequency (min)',
                        upperBoundSeriesName: 'Frequency (max)',
                        areaOpacity: 0.2,
                      },
                    },
                    {
                      name: 'Frequency (min)',
                      sourceColumn: 'control_grid_f:min',
                      denominator: 1000,
                      color: theme.palette.chart.single,
                      opacity: 0.25,
                      includeInLegend: false,
                    },
                  ]
                : [
                    {
                      name: 'Frequency',
                      sourceColumn: 'control_grid_f',
                      denominator: 1000,
                      color: theme.palette.chart.single,
                    },
                  ],
              longestDrawnGapMs: isAggregatedOverTime ? aggregateLongestGapMs : rawDataLongestGapMs,
              snapTooltip: true,
            },
          }}
          chartAnnotations={[
            {
              type: 'area',
              y: { from: 49.9, to: 50.1 },
              color: theme.palette.chart.validity.good,
              opacity: 0.15,
            },
            {
              type: 'line',
              height: 50,
              color: annotationLineColor,
              strokeDasharray: annotationStrokeDasharray,
            },
          ]}
        />

        <TimeSeriesMultiLineChart
          title="Grid current"
          subtitle={rangeString}
          height={280}
          data={{
            energy_meter_momentary: gridMeterData.energy_meter_momentary,
            schedule_history: extendedScheduleData,
            'schedule_history#export': scheduleData,
          }}
          isLoading={isLoadingGridMeter || isLoadingSchedule}
          error={gridMeterError ?? scheduleError}
          units="A"
          tooltipDecimalDigits={0}
          timeAxis={timeAxis}
          valueAxis={{ include: [0, 100] }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            energy_meter_momentary: {
              variables: gridMeterData.isAggregatedOverTime
                ? [
                    {
                      name: 'L1 (max)',
                      sourceColumn: 'current_a:max',
                      transform: transformAbsoluteValue,
                      color: theme.palette.chart.categorical3[0],
                      opacity: 0.25,
                      includeInLegend: false,
                    },
                    {
                      name: 'L1 (mean)',
                      sourceColumn: 'current_a:mean',
                      transform: transformAbsoluteValue,
                      color: theme.palette.chart.categorical3[0],
                      centerOfArea: {
                        name: 'L1',
                        lowerBoundSeriesName: 'L1 (min)',
                        upperBoundSeriesName: 'L1 (max)',
                        areaOpacity: 0.2,
                      },
                    },
                    {
                      name: 'L1 (min)',
                      sourceColumn: 'current_a:min',
                      transform: transformAbsoluteValue,
                      color: theme.palette.chart.categorical3[0],
                      opacity: 0.25,
                      includeInLegend: false,
                    },
                    {
                      name: 'L2 (max)',
                      sourceColumn: 'current_b:max',
                      transform: transformAbsoluteValue,
                      color: theme.palette.chart.categorical3[1],
                      opacity: 0.25,
                      includeInLegend: false,
                    },
                    {
                      name: 'L2 (mean)',
                      sourceColumn: 'current_b:mean',
                      transform: transformAbsoluteValue,
                      color: theme.palette.chart.categorical3[1],
                      centerOfArea: {
                        name: 'L2',
                        lowerBoundSeriesName: 'L2 (min)',
                        upperBoundSeriesName: 'L2 (max)',
                        areaOpacity: 0.2,
                      },
                    },
                    {
                      name: 'L2 (min)',
                      sourceColumn: 'current_b:min',
                      transform: transformAbsoluteValue,
                      color: theme.palette.chart.categorical3[1],
                      opacity: 0.25,
                      includeInLegend: false,
                    },
                    {
                      name: 'L3 (max)',
                      sourceColumn: 'current_c:max',
                      transform: transformAbsoluteValue,
                      color: theme.palette.chart.categorical3[2],
                      opacity: 0.25,
                      includeInLegend: false,
                    },
                    {
                      name: 'L3 (mean)',
                      sourceColumn: 'current_c:mean',
                      transform: transformAbsoluteValue,
                      color: theme.palette.chart.categorical3[2],
                      centerOfArea: {
                        name: 'L3',
                        lowerBoundSeriesName: 'L3 (min)',
                        upperBoundSeriesName: 'L3 (max)',
                        areaOpacity: 0.2,
                      },
                    },
                    {
                      name: 'L3 (min)',
                      sourceColumn: 'current_c:min',
                      transform: transformAbsoluteValue,
                      color: theme.palette.chart.categorical3[2],
                      opacity: 0.25,
                      includeInLegend: false,
                    },
                  ]
                : [
                    {
                      name: 'L1',
                      sourceColumn: 'current_a',
                      transform: transformAbsoluteValue,
                      color: theme.palette.chart.categorical3[0],
                    },

                    {
                      name: 'L2',
                      sourceColumn: 'current_b',
                      transform: transformAbsoluteValue,
                      color: theme.palette.chart.categorical3[1],
                    },

                    {
                      name: 'L3',
                      sourceColumn: 'current_c',
                      transform: transformAbsoluteValue,
                      color: theme.palette.chart.categorical3[2],
                    },
                  ],
              longestDrawnGapMs: aggregateLongestGapMs,
              snapTooltip: true,
            },
            schedule_history: {
              variables: [
                {
                  name: 'Peak shaving threshold',
                  sourceColumn: 'peak_shaving_threshold',
                  color: theme.palette.chart.gray,
                  interpolation: 'step-after',
                  strokeDasharray: '6,3',
                },
              ],
            },
          }}
        />
      </Stack>
    </>
  );
}
