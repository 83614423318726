import useSWR from 'swr';

import { fetcher } from '~http';

import type { ResourceID } from '~hooks/timeseries';

const DIAGNOSTICS_VIEW_REFRESH_INTERVAL = 10_000; // ms

export type FieldStyle = 'good' | 'warning' | 'error' | null;

export type Field = {
  name: string;
  group_name: string;
  label: string;
  description: string | null;
  unit: string | null;
  precision: number | null;
  style: FieldStyle;
  value: number | string | boolean;
  reported_timestamp: string; // RFC3339
};

export type BatteryInfo = {
  cell_count: number;
  module_count: number;
  rack_count: number;
  cells_per_module: number;
  modules_per_rack: number;
  temp_probes_per_module: number;
  nominal_cell_voltage_min: number;
  nominal_cell_voltage_max: number;
};

export type Fault = {
  code: string;
  details: Record<string, number>;
  description: string | null;
};

export type BMSDetails = {
  name: string;
  resource_id: ResourceID;
  battery_info: BatteryInfo | null;
};

export type EdgeControllerDiagnosticsResponse =
  | {
      data_found: true;
      edge_controller_id: string;
      esu_id?: string;
      reported_timestamp: string; // RFC3339
      received_timestamp: string; // RFC3339
      fields: Field[];
      fault_codes?: Fault[];
      battery_info?: BatteryInfo | null;
    }
  | {
      data_found: false;
      edge_controller_id: string;
      esu_id?: string;
    };

// esu_id is guaranteed to be always present in the response of /api/v1/diagnostics/esu/:id
export type ESUDiagnosticsResponse = EdgeControllerDiagnosticsResponse & {
  esu_id: string;
  bms_resources: BMSDetails[];
};

export function useEdgeControllerDiagnosticsData(
  edgeControllerId: string | null,
  atTime: 'now' | Date,
) {
  const atTimeParam = atTime === 'now' ? '' : `?at_time=${atTime.toISOString()}`;
  const key =
    edgeControllerId != null
      ? `/v1/diagnostics/edge_controller/${edgeControllerId}${atTimeParam}`
      : null;
  return useSWR<EdgeControllerDiagnosticsResponse>(key, {
    fetcher,
    // refresh periodically only if atTime is 'now'
    refreshInterval: atTime === 'now' ? DIAGNOSTICS_VIEW_REFRESH_INTERVAL : 0,
  });
}

export function useESUDiagnosticsData(esuId: string | null, atTime: 'now' | Date) {
  const atTimeParam = atTime === 'now' ? '' : `?at_time=${atTime.toISOString()}`;
  const key = esuId != null ? `/v1/diagnostics/esu/${esuId}${atTimeParam}` : null;
  return useSWR<ESUDiagnosticsResponse>(key, {
    fetcher,
    // refresh periodically only if atTime is 'now'
    refreshInterval: atTime === 'now' ? DIAGNOSTICS_VIEW_REFRESH_INTERVAL : 0,
  });
}
