import type { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

import { useFleet } from '~hooks/useFleetList';

import { PageLayout } from '~layouts/PageLayout';
import { PageLayoutLoader } from '~layouts/PageLayout/PageLayoutLoader';

import { useFleetsNavigation } from '~pages/fleets/hooks/useFleetsNavigation';
import { useFleetBreadcrumbs } from '~pages/fleets/hooks/useFleetBreadcrumbs';
import { createFleetPath } from '~pages/fleets/utils/createFleetPath';
import { TABS } from '~pages/fleets/constants';
import { NotFound } from '~pages/NotFound';

import { Tabs } from '~components/Tabs';
import { ErrorInfo } from '~components/ErrorInfo';

type Props = PropsWithChildren<{
  title: string;
}>;

export function FleetLayout({ title, children }: Props) {
  const { fleetId, tab } = useFleetsNavigation();
  const { data: fleet, isLoading, error } = useFleet(fleetId);

  useFleetBreadcrumbs();

  if (!fleet) {
    if (error) return <ErrorInfo error={error} />;
    if (isLoading) return <PageLayoutLoader />;

    return <NotFound />;
  }

  return (
    <PageLayout title={title} entityType="fleet" entityId={fleetId} entityName={fleet?.name}>
      <Tabs tabs={TABS} tab={tab} pathname={createFleetPath(fleetId)} />
      <Box overflow="auto">
        <Box p={2} display="flex" flexDirection="column" gap={2} position="relative">
          {children}
        </Box>
      </Box>
    </PageLayout>
  );
}
