import { Breadcrumbs as MuiBreadcrumbs, styled } from '@mui/material';

import { useBreadcrumbs } from '~hooks/useBreadcrumbs';

import { Breadcrumb } from '~components/breadcrumbs/Breadcrumb';

const StyledBreadcrumbs = styled(MuiBreadcrumbs)({
  flexShrink: 1,
  maxWidth: '100%',
});

export function Breadcrumbs() {
  const { breadcrumbs } = useBreadcrumbs();

  return (
    <StyledBreadcrumbs>
      {breadcrumbs.map((breadcrumb) => (
        <Breadcrumb
          key={`${breadcrumb.entityType}${breadcrumb.label}`}
          breadcrumb={breadcrumb}
          isLast={breadcrumb === breadcrumbs[breadcrumbs.length - 1]}
        />
      ))}
    </StyledBreadcrumbs>
  );
}
