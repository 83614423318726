import { useMemo } from 'react';

import type { ESUCurrentMode, ESUWithController, FaultEvent, Region } from '~types';

import { createLatestQuery, useTimeseriesLatest } from '~hooks/timeseries/queries';
import { useESUCurrentMode } from '~hooks/useESUCurrentMode';
import { useESUList } from '~hooks/useESUList';
import { CURRENT_FAULTS_RANGE_MINUTES, useFaults } from '~hooks/useFaults';
import { useMeteringGroupFleetMapping } from '~hooks/useMeteringGroupFleetMapping';
import { useMeteringGroupList } from '~hooks/useMeteringGroupList';

export type ESUsAdvancedTableRow = ESUWithController & {
  current_mode: ESUCurrentMode | undefined;
  fleet: Region | undefined;
  faults: FaultEvent[] | undefined;
  group_membership_start: string | undefined;

  bms_cell_voltages?: {
    time: string;
    'max(*)': number | null;
    'min(*)': number | null;
  };
  bms_temperatures?: {
    time: string;
    'max(*)': number | null;
    'min(*)': number | null;
  };
  esu_status?: {
    time: string;
    soc: number | null;
  };
  esu_power_control?: {
    time: string;
    fcrn_allocated_cap: number | null;
    fcrn_maintained_cap: number | null;
    fcrd_up_allocated_cap: number | null;
    fcrd_up_maintained_cap: number | null;
    fcrd_down_allocated_cap: number | null;
    fcrd_down_maintained_cap: number | null;
  };
  ems_optimizer_configuration?: {
    device_energy_capacity: string | null;
    energy_transfer_max: string | null;
    idle_low_soc: string | null;
    idle_high_soc: string | null;
    default_esu_import_power_max: string | null;
    default_esu_export_power_max: string | null;
    max_fcrn_power: string | null;
    max_fcrn_discharge_power: string | null;
    desired_fcrn_power_offset: string | null;
    fcrn_low_soc: string | null;
    fcrn_high_soc: string | null;
    fcrn_priority_group: number | null;
    max_fcrd_charge_power: string | null;
    max_fcrd_discharge_power: string | null;
    fcrd_low_soc: string | null;
    fcrd_high_soc: string | null;
    import_efficiency: string | null;
    export_efficiency: string | null;
    run_optimizer: number | null;
    peak_shaving_threshold_override: number | null;
  };
};

export function useESUsAdvancedTableData() {
  const esus = useESUList();
  const esuCurrentMode = useESUCurrentMode();
  const meteringGroups = useMeteringGroupList();
  const fleetForGroup = useMeteringGroupFleetMapping();
  const faults = useFaults(CURRENT_FAULTS_RANGE_MINUTES);

  const resources = esus.data?.map((esu) => esu.resource_id) ?? [];
  const swrOptions = { refreshInterval: 60_000, revalidateOnFocus: false };

  const statusSeries = useTimeseriesLatest(
    createLatestQuery({
      resources,
      columns: {
        bms_cell_voltages: ['max(*)', 'min(*)'],
        bms_temperatures: ['max(*)', 'min(*)'],
        esu_status: ['soc'],
        esu_power_control: [
          'fcrn_allocated_cap',
          'fcrn_maintained_cap',
          'fcrd_up_allocated_cap',
          'fcrd_up_maintained_cap',
          'fcrd_down_allocated_cap',
          'fcrd_down_maintained_cap',
        ],
      },
      start: { minutes: 10 },
    }),
    swrOptions,
  );

  const configSeries = useTimeseriesLatest(
    createLatestQuery({
      resources,
      columns: {
        ems_optimizer_configuration: [
          'device_energy_capacity',
          'energy_transfer_max',
          'idle_low_soc',
          'idle_high_soc',
          'default_esu_import_power_max',
          'default_esu_export_power_max',
          'max_fcrn_power',
          'max_fcrn_discharge_power',
          'desired_fcrn_power_offset',
          'fcrn_low_soc',
          'fcrn_high_soc',
          'fcrn_priority_group',
          'max_fcrd_charge_power',
          'max_fcrd_discharge_power',
          'fcrd_low_soc',
          'fcrd_high_soc',
          'import_efficiency',
          'export_efficiency',
          'run_optimizer',
          'peak_shaving_threshold_override',
        ],
      },
    }),
    swrOptions,
  );

  const data: ESUsAdvancedTableRow[] | undefined = useMemo(
    () =>
      esus.data?.map((esu) => {
        const esuMode = esuCurrentMode.data?.[esu.id];
        const esuFaults = faults.data?.faults.filter(
          (fault) => fault.resource_id === esu.resource_id,
        );
        const meteringGroupId = esu.edge_controller.metering_group?.id;
        const fleet = meteringGroupId ? fleetForGroup.data?.get(meteringGroupId) : undefined;
        const meteringGroupEsuData = meteringGroups.data
          ?.find((meteringGroup) => meteringGroup.id === meteringGroupId)
          ?.esus.find((e) => e.id === esu.id);

        const statusSeriesData = statusSeries.data?.[esu.resource_id];
        const configSeriesData = configSeries.data?.[esu.resource_id];

        return {
          ...statusSeriesData,
          ...configSeriesData,
          ...esu,
          fleet,
          group_membership_start: meteringGroupEsuData?.group_membership_start,
          current_mode: esuMode,
          faults: esuFaults,
        };
      }),
    [
      esus.data,
      esuCurrentMode.data,
      faults.data,
      fleetForGroup.data,
      meteringGroups.data,
      statusSeries.data,
      configSeries.data,
    ],
  );

  const error =
    esus.error ??
    meteringGroups.error ??
    fleetForGroup.error ??
    esuCurrentMode.error ??
    faults.error ??
    statusSeries.error ??
    configSeries.error;

  const isLoading =
    esus.isLoading ||
    meteringGroups.isLoading ||
    fleetForGroup.isLoading ||
    esuCurrentMode.isLoading ||
    faults.isLoading ||
    statusSeries.isLoading ||
    configSeries.isLoading;

  return { data, error, isLoading };
}
