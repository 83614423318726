import { Popover, useTheme } from '@mui/material';
import { type MouseEvent, useCallback, useMemo, useState } from 'react';

import type { Breadcrumb } from '~types';

import { FleetSiblingsList } from '~pages/fleets/components/FleetSiblingsList';
import { OrganizationSiblingsList } from '~pages/organizations/components/OrganizationSiblingsList';
import { SiteSiblingsList } from '~pages/sites/components/SiteSiblingsList';
import { ControllerSiblingsList } from '~pages/controllers/components/ControllerSiblingsList';
import { ESUSiblingsList } from '~pages/esus/components/ESUSiblingsList';

import { EntityIcon } from '~components/EntityIcon';
import { TinyIconButton } from '~components/TinyIconButton';

type Props = {
  breadcrumb: Breadcrumb;
};

export function BreadcrumbSiblings({ breadcrumb }: Props) {
  const theme = useTheme();
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const handleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorElement(null);
  }, []);

  const SiblingsComponent = useMemo(() => {
    switch (breadcrumb.entityType) {
      case 'fleet':
        return FleetSiblingsList;
      case 'organization':
        return OrganizationSiblingsList;
      case 'site':
        return SiteSiblingsList;
      case 'controller':
        return ControllerSiblingsList;
      case 'esu':
        return ESUSiblingsList;
      default:
        return null;
    }
  }, [breadcrumb.entityType]);

  if (!SiblingsComponent) return null;
  if (!(breadcrumb.entityId && breadcrumb.entityType)) return null;

  return (
    <>
      <TinyIconButton
        title="Hierarchy"
        onClick={handleOpen}
        sx={{ color: theme.palette.text.secondary }}
      >
        <EntityIcon entityType={breadcrumb.entityType} />
      </TinyIconButton>
      <Popover
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SiblingsComponent id={breadcrumb.entityId} />
      </Popover>
    </>
  );
}
