import { Alert, Box } from '@mui/material';

import { useURLRange } from '~hooks/useURLRange';

import { EventLog } from '~pages/esus/components/EventLog';
import { useControllersNavigation } from '~pages/controllers/hooks/useControllersNavigation';

import { EdgeControllerFaultsCard } from '~components/FaultsCard';
import { DateTimeRangePicker } from '~components/DateTimeRangePicker';
import { CopyLinkButton } from '~components/CopyLinkButton';
import { StickyBar } from '~components/StickyBar';

export function Events() {
  const { edgeControllerId } = useControllersNavigation();

  const [range, setRange] = useURLRange(24 * 3600);

  return (
    <Box px={2}>
      <StickyBar>
        <DateTimeRangePicker value={range} onChange={setRange} showSkipButtons />
        <CopyLinkButton
          link={
            `${window.location.origin}${window.location.pathname}` +
            `?start=${range.start.toISOString()}&end=${range.end.toISOString()}`
          }
        />
      </StickyBar>

      <Box display="flex" flexDirection="column" gap={2}>
        {!range.now && <Alert severity="info">Viewing historical data.</Alert>}

        <EdgeControllerFaultsCard edgeControllerId={edgeControllerId} range={range} />

        <EventLog edgeControllerId={edgeControllerId} range={range} />
      </Box>
    </Box>
  );
}
