import { type Dispatch, type SetStateAction, createContext, useContext } from 'react';

export type CommandPaletteContextType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const CommandPaletteContext = createContext<CommandPaletteContextType>({
  open: false,
  setOpen: () => {},
});

export function useCommandPalette() {
  return useContext(CommandPaletteContext);
}
