import { type PropsWithChildren, useMemo } from 'react';
import {
  type MRT_ColumnDef,
  type MRT_RowData,
  type MRT_TableInstance,
  MaterialReactTable,
  useMRT_Rows,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { USER_ROLES } from '~constants/auth';
import { ESU_MODELS } from '~constants/esuModels';

import { collator } from '~utils/localization';
import { calculateDeviceFcrnCapacity, calculateTotalFcrnCapacity } from '~utils/esu';
import { formatDateTime } from '~utils/time';
import { prefixMRTColumnIds } from '~utils/prefixMRTColumnIds';

import { useLocalStorage } from '~hooks/useLocalStorage';
import { type ESUsUserTableRow, useESUsUserTableData } from '~hooks/useESUsUserTableData';
import { useUserHasRootRole } from '~hooks/useUserHasRootRole';
import { useTableColumnState } from '~hooks/useTableColumnState';
import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import {
  type ESUsAdvancedTableRow,
  useESUsAdvancedTableData,
} from '~hooks/useESUsAdvancedTableData';
import { useSetTableEntities } from '~hooks/useTableEntities';
import { useTableCommonProps } from '~hooks/useTableCommonProps';
import { useGetTableBodyRowProps } from '~hooks/useGetTableBodyRowProps';

import { PageLayout } from '~layouts/PageLayout';

import { StatusCell, getSortingSeverity, getStatusText } from '~pages/esus/components/StatusCell';
import {
  ESUsFleetFilter,
  FLEET_FILTER_NO_FLEET_VALUE,
} from '~pages/esus/components/ESUsFleetFilter';
import { createESUPath } from '~pages/esus/utils/createESUPath';

import { MRTEmptyRowsFallback } from '~components/MRTEmptyRowsFallback';

type ColumnsMode = 'diagnostics' | 'configuration';

export function ESUs() {
  useRegisterBreadcrumbs({ label: 'Cactos Units' });

  const canViewAdvancedTable = useUserHasRootRole(USER_ROLES.EMS_DIAGNOSTIC_VIEW);

  return (
    <PageLayout hasDivider title="Cactos Units">
      {canViewAdvancedTable ? <ESUsTableAdvanced /> : <ESUsTableUser />}
    </PageLayout>
  );
}

function ESUsTableUser() {
  const {
    columnSizing,
    columnFilters,
    columnVisibility,
    sorting,
    setColumnSizing,
    setColumnFilters,
    setColumnVisibility,
    setSorting,
  } = useTableColumnState('esus.user');

  const { data, error } = useESUsUserTableData();

  const tableCommonProps = useTableCommonProps<ESUsUserTableRow>();
  const getTableBodyRowProps = useGetTableBodyRowProps(createESUPath);
  const table = useMaterialReactTable<ESUsUserTableRow>({
    ...tableCommonProps,
    data: data ?? [],
    columns: COLUMNS_USER,
    state: {
      columnSizing,
      columnFilters,
      columnVisibility,
      sorting,
      columnPinning: { left: ['name'] },
    },
    onColumnSizingChange: setColumnSizing,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => getTableBodyRowProps(row),
    renderEmptyRowsFallback: () => (
      <MRTEmptyRowsFallback error={error} noItemsMessage="No energy storage units to display" />
    ),
  });

  useSetTableEntities(
    'esu',
    table.getRowModel().rows.map((row) => ({ id: row.original.id })),
  );

  return <MaterialReactTable table={table} />;
}

function ESUsTableAdvanced() {
  const [columnsMode, setColumnsMode] = useLocalStorage<ColumnsMode>(
    'esus.columnsMode',
    'diagnostics',
  );
  const [fleetFilterSelection, setFleetFilterSelection] = useLocalStorage<string[]>(
    'esus.fleetFilterSelection',
    [],
  );

  const viewConfig = columnsMode === 'configuration';

  const columnStateDiagnostics = useTableColumnState('esus.diagnostics');
  const columnStateConfiguration = useTableColumnState('esus.configuration');
  const columnState = viewConfig ? columnStateConfiguration : columnStateDiagnostics;

  const { data, error } = useESUsAdvancedTableData();
  const columns = viewConfig ? COLUMNS_ADVANCED_CONFIGURATION : COLUMNS_ADVANCED_DIAGNOSTICS;

  const filteredData = useMemo(
    () => filterByFleet(data, fleetFilterSelection),
    [data, fleetFilterSelection],
  );

  const tableCommonProps = useTableCommonProps<ESUsAdvancedTableRow>();
  const getTableBodyRowProps = useGetTableBodyRowProps(createESUPath);
  const table = useMaterialReactTable<ESUsAdvancedTableRow>({
    ...tableCommonProps,
    data: filteredData ?? [],
    columns,
    state: {
      columnPinning: { left: ['name'] },
      columnSizing: columnState.columnSizing,
      columnFilters: columnState.columnFilters,
      columnVisibility: columnState.columnVisibility,
      sorting: columnState.sorting,
    },
    onColumnSizingChange: columnState.setColumnSizing,
    onColumnFiltersChange: columnState.setColumnFilters,
    onColumnVisibilityChange: columnState.setColumnVisibility,
    onSortingChange: columnState.setSorting,
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => getTableBodyRowProps(row),
    renderTopToolbarCustomActions: () => (
      <TopToolbarCustomActions
        columnsMode={columnsMode}
        onColumnsModeChange={setColumnsMode}
        fleetFilterSelection={fleetFilterSelection}
        onFleetFilterSelectionChange={setFleetFilterSelection}
      />
    ),
    renderEmptyRowsFallback: () => (
      <MRTEmptyRowsFallback error={error} noItemsMessage="No energy storage units to display" />
    ),
  });

  useSetTableEntities(
    'esu',
    table.getRowModel().rows.map((row) => ({ id: row.original.id })),
  );

  return <MaterialReactTable table={table} />;
}

type TopToolbarCustomActionsProps = {
  columnsMode: ColumnsMode;
  onColumnsModeChange: (value: ColumnsMode) => void;
  fleetFilterSelection: string[];
  onFleetFilterSelectionChange: (value: string[]) => void;
};

function TopToolbarCustomActions({
  columnsMode,
  onColumnsModeChange: setColumnsMode,
  fleetFilterSelection,
  onFleetFilterSelectionChange: setFleetFilterSelection,
}: TopToolbarCustomActionsProps) {
  return (
    <Box display="flex" gap={2}>
      <ToggleButtonGroup
        exclusive
        size="small"
        color="primary"
        value={columnsMode}
        onChange={(_event, value) => {
          if (value != null) setColumnsMode(value);
        }}
      >
        <ToggleButton value="diagnostics">Diagnostics</ToggleButton>
        <ToggleButton value="configuration">Configuration</ToggleButton>
      </ToggleButtonGroup>

      <ESUsFleetFilter value={fleetFilterSelection} onChange={setFleetFilterSelection} />
    </Box>
  );
}

function filterByFleet(data: ESUsAdvancedTableRow[] | undefined, fleetFilterSelection: string[]) {
  if (data == null) return undefined;
  if (fleetFilterSelection.length === 0) return data;
  const includeESUsWithoutFleet = fleetFilterSelection.includes(FLEET_FILTER_NO_FLEET_VALUE);
  const filteredData = data.filter((row) => {
    const fleetId = row.fleet?.id;
    if (fleetId == null) return includeESUsWithoutFleet;
    return fleetFilterSelection.includes(fleetId);
  });
  return filteredData;
}

function NameFooter<T extends MRT_RowData>({ table }: { table: MRT_TableInstance<T> }) {
  const rows = useMRT_Rows(table);
  return <CenteredBox>ESUs: {rows.length}</CenteredBox>;
}

function FCRNCapacityFooter({ table }: { table: MRT_TableInstance<ESUsAdvancedTableRow> }) {
  const rows = useMRT_Rows(table);
  const totalFcrnCapacity = useMemo(
    () => calculateTotalFcrnCapacity(rows.map((row) => row.original.ems_optimizer_configuration)),
    [rows],
  );

  return <CenteredBox>Total: {totalFcrnCapacity} MW</CenteredBox>;
}

function DeviceEnergyCapacityFooter({ table }: { table: MRT_TableInstance<ESUsAdvancedTableRow> }) {
  const rows = useMRT_Rows(table);
  const totalDeviceEnergyCapacity = useMemo(
    () => calculateTotalDeviceEnergyCapacity(rows.map((row) => row.original)),
    [rows],
  );

  return <CenteredBox>Total: {totalDeviceEnergyCapacity} kWh</CenteredBox>;
}

function CenteredBox({ children }: PropsWithChildren) {
  return (
    <Box height="100%" display="flex" alignItems="center">
      {children}
    </Box>
  );
}

function calculateTotalDeviceEnergyCapacity(
  data: ESUsAdvancedTableRow[] | undefined,
): string | undefined {
  if (!data) return undefined;
  const total = data.reduce((acc, row) => {
    if (!row.ems_optimizer_configuration?.device_energy_capacity) return acc;
    const capacity = parseInt(row.ems_optimizer_configuration.device_energy_capacity, 10);
    return acc + capacity;
  }, 0);
  return total.toFixed(3);
}

function rangeSliderFilterFn(row: any, columnId: string, filterValue: any): boolean {
  const value = row.getValue(columnId);
  const [min, max] = filterValue as [number, number];
  return value != null && min <= value && value <= max;
}

const COLUMNS_USER: MRT_ColumnDef<ESUsUserTableRow>[] = prefixMRTColumnIds<ESUsUserTableRow>(
  'esus.user',
  [
    {
      header: 'Name',
      id: 'name',
      accessorFn: (row) => row.name,
      sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
      size: 150,
      Footer: NameFooter,
    },
    {
      header: 'Model',
      id: 'model_name',
      accessorFn: (row) =>
        ESU_MODELS.find((esuModel) => esuModel.name === row.model_name)?.label ?? row.model_name,
      sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
      size: 150,
      filterVariant: 'autocomplete',
    },
    {
      header: 'Site',
      id: 'metering_group_name',
      accessorFn: (row) => row.edge_controller.metering_group.name,
      sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
      size: 150,
      filterVariant: 'autocomplete',
    },
    {
      header: 'Status',
      id: '@calculated/esu_mode',
      size: 200,
      minSize: 200,
      filterVariant: 'autocomplete',
      accessorFn: (row) => getStatusText(row),
      sortingFn: (a, b, columnId) => {
        const severityDifference = getSortingSeverity(b.original) - getSortingSeverity(a.original);
        if (severityDifference !== 0) {
          return severityDifference;
        } else {
          return collator.compare(a.getValue(columnId), b.getValue(columnId));
        }
      },
      Cell: StatusCell,
    },
    {
      header: 'SoC',
      id: 'esu_status.soc',
      accessorFn: (row) => {
        const value = row.esu_status?.soc;
        return value != null ? Math.round(value) : '';
      },
      sortUndefined: 'last',
      filterVariant: 'range',
      Cell: ({ renderedCellValue }) =>
        typeof renderedCellValue === 'number' ? `${renderedCellValue}%` : '',
      size: 80,
    },
  ],
);

const COLUMNS_ADVANCED_DIAGNOSTICS: MRT_ColumnDef<ESUsAdvancedTableRow>[] =
  prefixMRTColumnIds<ESUsAdvancedTableRow>('esus.diagnostics', [
    {
      header: 'Energy Storage Unit',
      id: 'energy-storage-unit',
      columns: [
        {
          header: 'Name',
          id: 'name',
          accessorFn: (row) => row.name,
          sortingFn: (a, b, columnId) =>
            collator.compare(a.getValue(columnId), b.getValue(columnId)),
          size: 150,
          Footer: NameFooter,
        },
        {
          header: 'Model',
          id: 'model_name',
          accessorFn: (row) =>
            ESU_MODELS.find((esuModel) => esuModel.name === row.model_name)?.label ??
            row.model_name,
          sortingFn: (a, b, columnId) =>
            collator.compare(a.getValue(columnId), b.getValue(columnId)),
          size: 150,
          filterVariant: 'autocomplete',
        },
        {
          header: 'Status',
          id: '@calculated/esu_mode',
          size: 200,
          minSize: 200,
          filterVariant: 'autocomplete',
          accessorFn: (row) => getStatusText(row),
          sortingFn: (a, b, columnId) => {
            const severityDifference =
              getSortingSeverity(b.original) - getSortingSeverity(a.original);
            if (severityDifference !== 0) {
              return severityDifference;
            } else {
              return collator.compare(a.getValue(columnId), b.getValue(columnId));
            }
          },
          Cell: StatusCell,
        },
        {
          header: 'Fleet',
          id: 'fleet',
          accessorFn: (row) => row.fleet?.name ?? '',
          filterVariant: 'autocomplete',
        },
      ],
    },
    {
      header: 'SoC',
      id: 'soc',
      columns: [
        {
          header: '%',
          id: 'esu_status.soc',
          accessorFn: (row) => {
            const value = row.esu_status?.soc;
            return value != null ? Math.round(value) : undefined;
          },
          Cell: ({ renderedCellValue }) =>
            renderedCellValue != null ? <>{renderedCellValue}%</> : '',
          sortUndefined: 'last',
          filterVariant: 'range-slider',
          muiFilterSliderProps: { step: 1 },
          filterFn: rangeSliderFilterFn,
          size: 80,
        },
      ],
    },
    {
      header: 'Cell temp (°C)',
      id: 'cell-temp',
      columns: [
        {
          header: 'Min',
          id: 'bms_temperatures_min',
          accessorFn: (row) => row.bms_temperatures?.['min(*)'] ?? undefined,
          sortUndefined: 'last',
          filterVariant: 'range-slider',
          muiFilterSliderProps: { step: 1 },
          filterFn: rangeSliderFilterFn,
          size: 80,
        },
        {
          header: 'Max',
          id: 'bms_temperatures_max',
          accessorFn: (row) => row.bms_temperatures?.['max(*)'] ?? undefined,
          sortUndefined: 'last',
          filterVariant: 'range-slider',
          muiFilterSliderProps: { step: 1 },
          filterFn: rangeSliderFilterFn,
          size: 80,
        },
      ],
    },
    {
      header: 'Cell voltage (V)',
      id: 'cell-voltage',
      columns: [
        {
          header: 'Min',
          id: 'bms_cell_voltages_min',
          accessorFn: (row) =>
            row.bms_cell_voltages?.['min(*)'] != null
              ? // convert mV to V while rounding to 2 decimal places
                Math.round(row.bms_cell_voltages['min(*)'] / 10) / 100
              : undefined,
          sortUndefined: 'last',
          filterVariant: 'range-slider',
          muiFilterSliderProps: { step: 0.1 },
          filterFn: rangeSliderFilterFn,
          size: 80,
        },
        {
          header: 'Max',
          id: 'bms_cell_voltages_max',
          accessorFn: (row) =>
            row.bms_cell_voltages?.['max(*)'] != null
              ? // convert mV to V while rounding to 2 decimal places
                Math.round(row.bms_cell_voltages['max(*)'] / 10) / 100
              : undefined,
          sortUndefined: 'last',
          filterVariant: 'range-slider',
          muiFilterSliderProps: { step: 0.1 },
          filterFn: rangeSliderFilterFn,
          size: 80,
        },
      ],
    },
    {
      header: 'FCR-N (kW)',
      id: 'fcr-n',
      columns: [
        {
          header: 'Allocated',
          id: 'esu_power_control.fcrn_allocated_cap',
          accessorFn: (row) =>
            row.esu_power_control?.fcrn_allocated_cap != null
              ? row.esu_power_control.fcrn_allocated_cap / 1000
              : undefined,
          sortUndefined: 'last',
          filterVariant: 'range-slider',
          muiFilterSliderProps: { step: 1 },
          filterFn: rangeSliderFilterFn,
          size: 120,
        },
        {
          header: 'Maintained',
          id: 'esu_power_control.fcrn_maintained_cap',
          accessorFn: (row) =>
            row.esu_power_control?.fcrn_maintained_cap != null
              ? row.esu_power_control.fcrn_maintained_cap / 1000
              : undefined,
          sortUndefined: 'last',
          filterVariant: 'range-slider',
          muiFilterSliderProps: { step: 1 },
          filterFn: rangeSliderFilterFn,
          size: 120,
        },
      ],
    },
    {
      header: 'FCR-D up (kW)',
      id: 'fcr-d-up',
      columns: [
        {
          header: 'Allocated',
          id: 'esu_power_control.fcrd_up_allocated_cap',
          accessorFn: (row) =>
            row.esu_power_control?.fcrd_up_allocated_cap != null
              ? row.esu_power_control.fcrd_up_allocated_cap / 1000
              : undefined,
          sortUndefined: 'last',
          filterVariant: 'range-slider',
          muiFilterSliderProps: { step: 1 },
          filterFn: rangeSliderFilterFn,
          size: 120,
        },
        {
          header: 'Maintained',
          id: 'esu_power_control.fcrd_up_maintained_cap',
          accessorFn: (row) =>
            row.esu_power_control?.fcrd_up_maintained_cap != null
              ? row.esu_power_control.fcrd_up_maintained_cap / 1000
              : undefined,
          sortUndefined: 'last',
          filterVariant: 'range-slider',
          muiFilterSliderProps: { step: 1 },
          filterFn: rangeSliderFilterFn,
          size: 120,
        },
      ],
    },
    {
      header: 'FCR-D down (kW)',
      id: 'fcr-d-down',
      columns: [
        {
          header: 'Allocated',
          id: 'esu_power_control.fcrd_down_allocated_cap',
          accessorFn: (row) =>
            row.esu_power_control?.fcrd_down_allocated_cap != null
              ? row.esu_power_control.fcrd_down_allocated_cap / 1000
              : undefined,
          sortUndefined: 'last',
          filterVariant: 'range-slider',
          muiFilterSliderProps: { step: 1 },
          filterFn: rangeSliderFilterFn,
          size: 120,
        },
        {
          header: 'Maintained',
          id: 'esu_power_control.fcrd_down_maintained_cap',
          accessorFn: (row) =>
            row.esu_power_control?.fcrd_down_maintained_cap != null
              ? row.esu_power_control.fcrd_down_maintained_cap / 1000
              : undefined,
          sortUndefined: 'last',
          filterVariant: 'range-slider',
          muiFilterSliderProps: { step: 1 },
          filterFn: rangeSliderFilterFn,
          size: 120,
        },
      ],
    },
  ]);

const COLUMNS_ADVANCED_CONFIGURATION: MRT_ColumnDef<ESUsAdvancedTableRow>[] =
  prefixMRTColumnIds<ESUsAdvancedTableRow>('esus.configuration', [
    {
      header: 'Energy Storage Unit',
      id: 'energy-storage-unit',
      columns: [
        {
          header: 'Name',
          id: 'name',
          accessorFn: (row) => row.name,
          sortingFn: (a, b, columnId) =>
            collator.compare(a.getValue(columnId), b.getValue(columnId)),
          Footer: NameFooter,
        },
        {
          header: 'Model',
          id: 'model_name',
          accessorFn: (row) =>
            ESU_MODELS.find((esuModel) => esuModel.name === row.model_name)?.label ??
            row.model_name,
          sortingFn: (a, b, columnId) =>
            collator.compare(a.getValue(columnId), b.getValue(columnId)),
          size: 150,
          filterVariant: 'autocomplete',
        },
        {
          header: 'Site',
          id: 'metering_group_name',
          accessorFn: (row) => row.edge_controller.metering_group.name,
          sortingFn: (a, b, columnId) =>
            collator.compare(a.getValue(columnId), b.getValue(columnId)),
          filterVariant: 'autocomplete',
        },
        {
          header: 'In Site Since',
          id: 'metering_group_since',
          accessorFn: (row) => formatDateTime(row.group_membership_start),
          sortingFn: (a, b, columnId) =>
            collator.compare(a.getValue(columnId), b.getValue(columnId)),
        },
        {
          header: 'Organization',
          id: 'organization_name',
          accessorFn: (row) => row.edge_controller.organization.human_name,
          sortingFn: (a, b, columnId) =>
            collator.compare(a.getValue(columnId), b.getValue(columnId)),
          filterVariant: 'autocomplete',
        },
        {
          header: 'Fleet',
          id: 'fleet',
          accessorFn: (row) => row.fleet?.name ?? '',
          filterVariant: 'autocomplete',
        },
      ],
    },
    // Optimizer
    {
      header: 'Energy transfer (kWh)',
      id: 'energy-transfer',
      columns: [
        {
          header: 'Max (24h)',
          id: 'ems_optimizer_configuration.energy_transfer_max',
          accessorFn: (row) => row.ems_optimizer_configuration?.energy_transfer_max,
          size: 170,
        },
      ],
    },
    {
      header: 'Idle SoC',
      id: 'idle-soc',
      columns: [
        {
          header: 'Min',
          id: 'ems_optimizer_configuration.idle_low_soc',
          accessorFn: (row) => row.ems_optimizer_configuration?.idle_low_soc,
          size: 80,
        },
        {
          header: 'Max',
          id: 'ems_optimizer_configuration.idle_high_soc',
          accessorFn: (row) => row.ems_optimizer_configuration?.idle_high_soc,
          size: 80,
        },
      ],
    },
    {
      header: 'Optimizer',
      id: 'optimizer',
      columns: [
        {
          header: 'Include',
          id: 'ems_optimizer_configuration.run_optimizer',
          accessorFn: (row) => {
            if (!row.ems_optimizer_configuration) {
              return '';
            }
            return row.ems_optimizer_configuration.run_optimizer ? 'Yes' : 'No';
          },
          size: 100,
        },
      ],
    },
    // Installation
    {
      header: 'Max power (kW)',
      id: 'max-power',
      columns: [
        {
          header: 'Import',
          id: 'ems_optimizer_configuration.default_esu_import_power_max',
          accessorFn: (row) => row.ems_optimizer_configuration?.default_esu_import_power_max,
          size: 90,
        },
        {
          header: 'Export',
          id: 'ems_optimizer_configuration.default_esu_export_power_max',
          accessorFn: (row) => row.ems_optimizer_configuration?.default_esu_export_power_max,
          size: 90,
        },
      ],
    },
    // FCR-N
    {
      header: 'FCR-N power (kW)',
      id: 'fcr-n-power',
      columns: [
        {
          header: 'Charge',
          id: 'ems_optimizer_configuration.max_fcrn_power',
          accessorFn: (row) => row.ems_optimizer_configuration?.max_fcrn_power,
          size: 100,
        },
        {
          header: 'Discharge',
          id: 'ems_optimizer_configuration.max_fcrn_discharge_power',
          accessorFn: (row) => row.ems_optimizer_configuration?.max_fcrn_discharge_power,
          size: 120,
        },
        {
          header: 'Capacity',
          id: '@calculated/fcrn_cap_max',
          accessorFn: (row) => calculateDeviceFcrnCapacity(row.ems_optimizer_configuration),
          Footer: FCRNCapacityFooter,
        },
        {
          header: 'Offset',
          id: 'ems_optimizer_configuration.desired_fcrn_power_offset',
          accessorFn: (row) => row.ems_optimizer_configuration?.desired_fcrn_power_offset,
          size: 100,
        },
      ],
    },
    {
      header: 'FCR-N SoC',
      id: 'fcr-n-soc',
      columns: [
        {
          header: 'Min',
          id: 'ems_optimizer_configuration.fcrn_low_soc',
          accessorFn: (row) => row.ems_optimizer_configuration?.fcrn_low_soc,
          size: 80,
        },
        {
          header: 'Max',
          id: 'ems_optimizer_configuration.fcrn_high_soc',
          accessorFn: (row) => row.ems_optimizer_configuration?.fcrn_high_soc,
          size: 80,
        },
      ],
    },
    {
      header: 'FCR-N',
      id: 'fcr-n',
      columns: [
        {
          header: 'Priority',
          id: 'ems_optimizer_configuration.fcrn_priority_group',
          accessorFn: (row) => row.ems_optimizer_configuration?.fcrn_priority_group,
          size: 100,
        },
      ],
    },
    // FCR-D
    {
      header: 'FCR-D power (kW)',
      id: 'fcr-d-power',
      columns: [
        {
          header: 'Charge',
          id: 'ems_optimizer_configuration.max_fcrd_charge_power',
          accessorFn: (row) => row.ems_optimizer_configuration?.max_fcrd_charge_power,
          size: 100,
        },
        {
          header: 'Discharge',
          id: 'ems_optimizer_configuration.max_fcrd_discharge_power',
          accessorFn: (row) => row.ems_optimizer_configuration?.max_fcrd_discharge_power,
          size: 120,
        },
      ],
    },
    {
      header: 'FCR-D SoC',
      id: 'fcr-d-soc',
      columns: [
        {
          header: 'Min',
          id: 'ems_optimizer_configuration.fcrd_low_soc',
          accessorFn: (row) => row.ems_optimizer_configuration?.fcrd_low_soc,
          size: 80,
        },
        {
          header: 'Max',
          id: 'ems_optimizer_configuration.fcrd_high_soc',
          accessorFn: (row) => row.ems_optimizer_configuration?.fcrd_high_soc,
          size: 80,
        },
      ],
    },
    // Peak shaving
    {
      header: 'Peak shaving',
      id: 'peak-shaving',
      columns: [
        {
          header: 'Threshold (A)',
          id: 'ems_optimizer_configuration.peak_shaving_threshold_override',
          accessorFn: (row) => row.ems_optimizer_configuration?.peak_shaving_threshold_override,
          size: 140,
        },
      ],
    },
    // Device
    {
      header: 'Energy (kWh)',
      id: 'energy',
      columns: [
        {
          header: 'Capacity',
          id: 'ems_optimizer_configuration.device_energy_capacity',
          accessorFn: (row) => row.ems_optimizer_configuration?.device_energy_capacity,
          size: 110,
          Footer: DeviceEnergyCapacityFooter,
        },
      ],
    },
    {
      header: 'Efficiency',
      id: 'efficiency',
      columns: [
        {
          header: 'Import',
          id: 'ems_optimizer_configuration.import_efficiency',
          accessorFn: (row) => row.ems_optimizer_configuration?.import_efficiency,
          size: 90,
        },
        {
          header: 'Export',
          id: 'ems_optimizer_configuration.export_efficiency',
          accessorFn: (row) => row.ems_optimizer_configuration?.export_efficiency,
          size: 90,
        },
      ],
    },
  ]);
