import { Box, Grid } from '@mui/material';

import { useESU } from '~hooks/useESUList';
import { createLatestQuery, useTimeseriesLatest } from '~hooks/timeseries/queries';

import { ESUBatteryCard } from '~pages/esus/components/ESUBatteryCard';
import { ESUChargeCard } from '~pages/esus/components/ESUChargeCard';
import { ESUGridFrequencyCard } from '~pages/esus/components/ESUGridFrequencyCard';
import { ESUPowerCard } from '~pages/esus/components/ESUPowerCard';
import { useESUsNavigation } from '~pages/esus/hooks/useESUsNavigation';
import { ESUInsights } from '~pages/esus/components/ESUInsights';
import { ESUStatus } from '~pages/esus/components/ESUStatus';
import { ESULayout } from '~pages/esus/components/ESULayout';

export function ESUDashboard() {
  const { esuId } = useESUsNavigation();
  const { data: esu } = useESU(esuId);
  const { data: queryResult, isLoading: esuDataLoading } = useTimeseriesLatest(
    createLatestQuery({
      resources: esu ? [esu.resource_id] : [],
      columns: {
        bms_cell_voltages: ['max(*)', 'min(*)'],
        bms_temperatures: ['max(*)'],
        esu_status: ['soc'],
        esu_diagnostics_power: ['bms_battery_pack_voltage'],
        esu_power_control: ['esu_realized_power', 'control_grid_f'],
      },
      start: { minutes: 10 },
    }),
    {
      refreshInterval: 10_000,
      revalidateOnFocus: true,
    },
  );

  const esuData = esu ? queryResult?.[esu.resource_id] : undefined;

  return (
    <ESULayout title="ESU Dashboard">
      <Box m={2}>
        <ESUStatus />
      </Box>
      <Grid container spacing={2} px={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <ESUPowerCard
            powerWatts={esuData?.esu_power_control?.esu_realized_power}
            isLoading={esuDataLoading}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <ESUChargeCard socPercentage={esuData?.esu_status?.soc} isLoading={esuDataLoading} />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <ESUGridFrequencyCard
            frequencyMilliHz={esuData?.esu_power_control?.control_grid_f}
            isLoading={esuDataLoading}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <ESUBatteryCard data={esuData} isLoading={esuDataLoading} />
        </Grid>
      </Grid>

      <ESUInsights />
    </ESULayout>
  );
}
