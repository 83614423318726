import { Box, FormControl, FormLabel, Typography } from '@mui/material';

import type { WizardStepProps } from '~pages/sites/components/pricing/types';

export function StepSummary({ formik }: WizardStepProps) {
  const errors = Object.values(formik.errors);

  return (
    <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <FormLabel component="legend">Summary</FormLabel>
      {!errors.length && (
        <Typography>
          <strong>All steps of the wizard are valid.</strong>
          <br />
          Please take a moment to review the information in the grid below and ensure its accuracy.
          <br />
          If the information is correct, you may proceed to submit the form.
        </Typography>
      )}

      {!!errors.length && (
        <Box display="flex" flexDirection="column" gap={1}>
          There are errors in your steps. Please review the following:
          <ul>
            {errors
              .filter((error) => typeof error === 'string')
              .map((error, i) => (
                <Typography key={i} color="error" component="li" ml={2}>
                  {error as string}
                </Typography>
              ))}
          </ul>
        </Box>
      )}
    </FormControl>
  );
}
