import {
  type MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, CardHeader, CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { useMemo } from 'react';
import { differenceInSeconds, formatDistanceToNowStrict } from 'date-fns';

import { type FaultEvent } from '~types';

import { asCactosError } from '~http';

import {
  type DateTimeRangeWithNow,
  formatClockTime,
  formatDurationShort,
  formatRange,
  formatRelativeTimeShort,
} from '~utils/time';

import { useEdgeControllerFaultsForRange, useFleetFaults } from '~hooks/useFaults';
import { useEdgeControllerList } from '~hooks/useEdgeControllerList';

import { DashboardCardHeader } from '~components/DashboardCard';

export function ESUFaultEventDetails({ fault }: { fault: FaultEvent }) {
  const { code, description, parameters, resource_id } = fault;

  return (
    <Box>
      <Typography variant="data" sx={{ fontWeight: 'bold' }}>
        {code} {JSON.stringify(parameters)}
      </Typography>
      <Typography variant="body1">Resource id: {resource_id}</Typography>
      <Typography variant="body1">{description}</Typography>
    </Box>
  );
}

type FaultWithControllerName = FaultEvent & { edge_controller_name: string };

export function FleetFaultsCard({ fleetId }: { fleetId: string }) {
  const { data } = useFleetFaults(fleetId, 24 * 60);
  const { data: edgeControllers } = useEdgeControllerList();
  const faults: FaultWithControllerName[] | undefined = useMemo(() => {
    if (!data) return undefined;
    const ecsById = new Map(edgeControllers?.map((esu) => [esu.id, esu]));
    return data.faults.map((fault) => ({
      ...fault,
      edge_controller_name: ecsById.get(fault.edge_controller_id)?.name ?? fault.edge_controller_id,
    }));
  }, [data, edgeControllers]);

  const resultStartTime = data?.start_time;
  const ecNames = useMemo<string[]>(
    () => Array.from(new Set(faults?.map((fault) => fault.edge_controller_name))),
    [faults],
  );

  const columns = useMemo<MRT_ColumnDef<FaultWithControllerName>[]>(
    () => [
      {
        header: 'Controller',
        id: 'esu.name',
        accessorFn: (row) => row.edge_controller_name,
        filterVariant: 'multi-select',
        filterSelectOptions: ecNames,
      },
      {
        header: 'Code',
        id: 'code',
        accessorFn: (row) => row.code,
      },
      {
        header: 'Last seen',
        id: 'last_seen',
        accessorFn: (row) => row.last_seen,
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <Typography variant="body2">
            {formatRelativeTimeShort(cell.getValue<string>())}
          </Typography>
        ),
      },
      {
        header: 'Start',
        id: 'start',
        accessorFn: (row) => row.start_time,
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <Typography variant="body2">{formatClockTime(cell.getValue<string>())}</Typography>
        ),
      },
      {
        header: 'Duration',
        id: 'duration',
        accessorFn: ({ start_time, end_time }) => {
          if (start_time && end_time) {
            return differenceInSeconds(end_time, start_time);
          }
          return '';
        },
        filterVariant: 'range',
        Cell: ({ cell }) => (
          <Typography variant="body2">{formatDurationShort(cell.getValue<number>())}</Typography>
        ),
      },
    ],
    [ecNames],
  );

  const table = useMaterialReactTable<FaultWithControllerName>({
    data: faults ?? [],
    columns,
    initialState: {
      density: 'compact',
      sorting: [{ id: 'last_seen', desc: true }],
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    positionToolbarAlertBanner: 'none',
    muiTableProps: {
      sx: {
        caption: {
          captionSide: 'top',
        },
      },
    },
    renderTopToolbarCustomActions: () => (
      <Stack marginX={1} marginY={1}>
        <DashboardCardHeader>Faults</DashboardCardHeader>
        {resultStartTime ? (
          <>Faults since {formatDistanceToNowStrict(resultStartTime, { addSuffix: true })}</>
        ) : (
          'Loading...'
        )}
      </Stack>
    ),
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor: theme.palette.grey[800],
      }),
    }),
    renderDetailPanel: ({ row }) => <ESUFaultEventDetails fault={row.original} />,
  });

  return <MaterialReactTable table={table} />;
}

export function EdgeControllerFaultsCard({
  edgeControllerId,
  range,
}: {
  edgeControllerId: string;
  range: DateTimeRangeWithNow;
}) {
  const { data, isLoading, error } = useEdgeControllerFaultsForRange(edgeControllerId, range);
  const resultRange =
    data != null ? { start: new Date(data.start_time), end: new Date(data.end_time) } : null;

  const columns = useMemo<MRT_ColumnDef<FaultEvent>[]>(
    () => [
      {
        header: 'Code',
        id: 'code',
        accessorFn: (row) => row.code,
      },
      {
        header: 'Last seen',
        id: 'last_seen',
        accessorFn: (row) => row.last_seen,
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <Typography variant="body2">
            {range.now
              ? formatRelativeTimeShort(cell.getValue<string>())
              : formatClockTime(cell.getValue<string>())}
          </Typography>
        ),
      },
      {
        header: 'Start',
        id: 'start',
        accessorFn: (row) => row.start_time,
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <Typography variant="body2">{formatClockTime(cell.getValue<string>())}</Typography>
        ),
      },
      {
        header: 'Duration',
        id: 'duration',
        accessorFn: ({ start_time, end_time }) => {
          if (start_time && end_time) {
            return differenceInSeconds(end_time, start_time);
          }
          return '';
        },
        filterVariant: 'range',
        Cell: ({ cell }) => (
          <Typography variant="body2">{formatDurationShort(cell.getValue<number>())}</Typography>
        ),
      },
    ],
    [range.now],
  );

  const table = useMaterialReactTable<FaultEvent>({
    data: data?.faults ?? [],
    columns,
    initialState: {
      density: 'compact',
      sorting: [{ id: 'last_seen', desc: true }],
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    positionToolbarAlertBanner: 'none',
    muiTableProps: {
      sx: {
        caption: {
          captionSide: 'top',
        },
      },
    },
    renderTopToolbarCustomActions: () => (
      <CardHeader
        title={
          <>
            Faults {isLoading && <CircularProgress size={12} sx={{ mt: 1, ml: 1 }} />}
            {error != null && (
              <Tooltip
                title={'Error loading ESU faults: ' + asCactosError(error).message}
                placement="right"
              >
                <ErrorOutline
                  color="error"
                  fontSize="small"
                  sx={{ ml: 1, position: 'relative', top: '3px' }}
                />
              </Tooltip>
            )}
          </>
        }
        subheader={resultRange ? formatRange(resultRange) : 'Loading...'}
        sx={{ p: 1 }}
      />
    ),
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor: theme.palette.grey[800],
      }),
    }),
    renderDetailPanel: ({ row }) => <ESUFaultEventDetails fault={row.original} />,
  });

  return <MaterialReactTable table={table} />;
}
