import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export type useQueryParamStateConfig<T> = {
  key: string;
  defaultValue: T;
  stringify: (value: T) => string;
  parse: (str: string) => T | null;
};

/**
 * Provides access to a query parameter in the URL, with an interface similar to `useState`.
 *
 * Note: If the setter functions for multiple instances of this hook are called within
 * the same render cycle, only one of them will be able to update the URL.
 */
export function useQueryParamState<T>({
  key,
  defaultValue,
  stringify,
  parse,
}: useQueryParamStateConfig<T>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const param = searchParams.get(key);

  const state: T = useMemo(() => {
    if (param == null) return defaultValue;
    const parsed = parse(param);
    return parsed ?? defaultValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param, parse]);

  const setState = useCallback(
    (arg: T | ((prev: T) => T)) => {
      const newState = typeof arg === 'function' ? (arg as (prev: T) => T)(state) : arg;
      setSearchParams(
        (prevParams) => {
          const params = new URLSearchParams(prevParams);
          params.set(key, stringify(newState));
          return params;
        },
        { replace: true },
      );
    },
    [state, key, stringify, setSearchParams],
  );

  // if the URL param is null, set the fields to the default
  useEffect(() => {
    if (param === null) setState(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  return [state, setState] as const;
}
