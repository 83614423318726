import { useEffect } from 'react';
import { useMatch, useParams } from 'react-router-dom';

import { useLocalStorage } from '~hooks/useLocalStorage';

import type { Tab } from '~pages/esus/types';
import { ROUTER_MATCH_PATH } from '~pages/esus/constants';

const DEFAULT_TAB: Tab = 'dashboard';

export function useESUsNavigation() {
  const { esuId } = useParams();

  if (esuId == null) {
    throw new Error('useESUsNavigation must be used within a esu route');
  }

  const params = useMatch(ROUTER_MATCH_PATH)?.params;
  const [tabFromStorage, setTabFromStorage] = useLocalStorage<Tab>('esus.tab', DEFAULT_TAB);
  const tab: Tab = (params?.tab as Tab | undefined) || tabFromStorage;

  useEffect(() => {
    setTabFromStorage(tab);
  }, [tab, setTabFromStorage]);

  return { esuId, tab };
}
